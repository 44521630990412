import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { breakpoints } from '@99designs/emu/settings';
import FixedViewportElement from './FixedViewportElement';

const cssTransitionName = 'fullscreen-overlay-';
const FullscreenOverlayAnimation = styled.div`
    /* ReactCSSTransitionGroup classes */
    .${cssTransitionName}-appear {
        opacity: 0.01;
        transform: scale(0.8);
    }

    .${cssTransitionName}-appear.${cssTransitionName}-appear-active {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
        transition: transform 0.2s, opacity 0.1s ease-out;
    }

    .${cssTransitionName}-leave {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    }

    .${cssTransitionName}-leave.${cssTransitionName}-leave-active {
        opacity: 0.01;
        transform: scale(0.8);
        transition: transform 0.2s, opacity 0.1s ease-out;
    }
`;

interface FullscreenOverlayContentProps {
    isActive?: boolean;
    hasAdminBarOffset?: boolean;
    enableScroll?: boolean;
    isScrollableSmallScreens?: boolean;
    transparent?: boolean;
}

const adminBarHeight = '50px !default';
const FullscreenOverlayContent = styled.div<FullscreenOverlayContentProps>`
    /* Full view-port container for animations */
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #fff;

    /* Some high z-index amount */
    z-index: 96;

    ${({ isActive = false }) =>
        isActive &&
        css`
            display: block;
        `}

    ${({ isActive = false, enableScroll = false }) =>
        isActive &&
        enableScroll &&
        css`
            overflow-y: scroll;
        `}

    ${({ hasAdminBarOffset = false }) =>
        hasAdminBarOffset &&
        css`
            top: ${adminBarHeight};
            height: calc(100% - ${adminBarHeight});
        `}

    ${({ transparent = false }) =>
        transparent &&
        css`
            background: transparent;
        `}

    ${({ isScrollableSmallScreens = false }) =>
        isScrollableSmallScreens &&
        css`
            @media (min-width: ${breakpoints.small[0]}) and (max-width: ${breakpoints.small[1]}px),
                (min-width: ${breakpoints.medium[0]}px) {
                overflow: auto;
            }
        `}
`;

interface FullscreenOverlayProps extends FullscreenOverlayContentProps {
    children: React.ReactNode;
    hasOpeningAndClosingAnimation?: boolean;
    animationDuration?: number;
    isClosing?: boolean;
    enableScroll?: boolean;
    onClick?: () => void;
}

function FullscreenOverlay({
    children,
    isClosing,
    onClick,
    animationDuration,
    hasOpeningAndClosingAnimation,
    isActive,
    hasAdminBarOffset,
    enableScroll,
    isScrollableSmallScreens,
    transparent,
}: FullscreenOverlayProps) {
    const overlayBody = (
        <FullscreenOverlayContent
            isActive={isActive}
            hasAdminBarOffset={hasAdminBarOffset}
            enableScroll={enableScroll}
            isScrollableSmallScreens={isScrollableSmallScreens}
            transparent={transparent}
            data-testid="FullscreenOverlay"
            role="button"
            onClick={onClick}
        >
            {!isClosing && children}
        </FullscreenOverlayContent>
    );
    const animatedOverlayBody = (
        <CSSTransition
            in={true}
            classNames={cssTransitionName}
            timeout={animationDuration || 230}
            appear={true}
            unmountOnExit
        >
            {overlayBody}
        </CSSTransition>
    );
    const renderBody = (
        <FullscreenOverlayAnimation>
            {hasOpeningAndClosingAnimation ? animatedOverlayBody : overlayBody}
        </FullscreenOverlayAnimation>
    );

    if (isActive && !isClosing) {
        return <FixedViewportElement>{renderBody}</FixedViewportElement>;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{renderBody}</>;
}
export default FullscreenOverlay;
