import { Dispatch, SetStateAction, useState } from 'react';

export interface TrackedTriggerHook {
    hasNewTrigger: boolean;
    setTrackedTriggers: Dispatch<SetStateAction<string[]>>;
}

const useTrackedTriggers = (currentTriggers: string[]): TrackedTriggerHook => {
    const [previousTriggers, setPreviousTriggers] = useState<string[]>([]);
    const isSameLength = currentTriggers.length === previousTriggers.length;

    return {
        hasNewTrigger: !isSameLength,
        setTrackedTriggers: setPreviousTriggers,
    };
};

export default useTrackedTriggers;
