import { User } from '../types';
import { circumventionKeywordMatches } from './circumventionKeywordMatches';
import { useCircumventionWarning } from './useCircumventionWarning';
import {
    OnSubmit,
    OnSubmitMessageValue,
    UseMessageInputHook,
    UseMessageInputProps,
    useMessageInput,
} from './useMessageInput';

export type CreateMessageFn = (
    messageValue: OnSubmitMessageValue,
    trigger?: 'button' | 'enter'
) => void;

export interface MessageTracking {
    eventName:
        | 'Message Sent'
        | 'Message Edited'
        | 'Project File Message Sent'
        | 'Project Message Edited';
    properties: {
        location: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
}

export interface UseMessageInputWithCircumventionWarningProps
    extends Omit<UseMessageInputProps, 'onSubmit'> {
    createMessage: CreateMessageFn;
    viewer: User;
    disableCircumventionWarningCheck?: boolean;
    tracking?: MessageTracking;
    trackEvent: (name: string, properties?: Record<string, unknown>) => void;
}
export interface UseMessageInputWithCircumventionWarningHook extends UseMessageInputHook {
    matches: string[];
    showCircumventionWarning: boolean;
}

export function useMessageInputWithCircumventionWarning({
    initialMessageValue = '',
    createMessage,
    viewer,
    disableCircumventionWarningCheck = false,
    tracking,
    trackEvent,
}: UseMessageInputWithCircumventionWarningProps): UseMessageInputWithCircumventionWarningHook {
    const onSubmit: OnSubmit = (messageValue, trigger) => {
        if (tracking) {
            trackEvent(tracking.eventName, {
                trigger,
                postedWithCircumventionWarning:
                    circumventionKeywordMatches(messageValue.message).length > 0,
                message_length: messageValue.message.length,
                sender_id: viewer.id,
                ...tracking.properties,
            });
        }
        createMessage(messageValue, trigger);
    };
    const { message, setMessage, inputOnChange, inputOnKeyDown, inputOnSubmit } = useMessageInput({
        initialMessageValue,
        onSubmit,
    });
    const [matches, showCircumventionWarning] = useCircumventionWarning(
        message,
        !disableCircumventionWarningCheck
    );

    return {
        message,
        setMessage,
        matches,
        showCircumventionWarning,
        inputOnChange,
        inputOnKeyDown,
        inputOnSubmit,
    };
}
