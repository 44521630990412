import { colors } from './colors';

export const content = `1px solid ${colors.grey300}`,
    textInput = `1px solid ${colors.grey400}`;

export const borderRadiuses = {
    small: 4,
    medium: 8,
    large: 16,
};
