import styled from 'styled-components';
import { Size } from '../../Field';
import { sizes } from './sizes';

export const HiddenInput = styled.input<{ inputSize: Size }>`
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: ${({ inputSize }) => (sizes[inputSize] / 2) * -1}px;
    left: ${({ inputSize }) => (sizes[inputSize] / 2) * -1}px;

    /* Increase specificity, due to CSS reset */
    && {
        width: ${({ inputSize }) => sizes[inputSize] * 2}px;
        height: ${({ inputSize }) => sizes[inputSize] * 2}px;
    }
`;
