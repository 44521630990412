import React from 'react';
import styled, { css } from 'styled-components';
import { spaces } from '../../settings';
import { Box } from '../Box';
import { FieldFocusStyling, FieldPadding, FieldPlaceholderStyling, FieldStyling } from '../Field';
import { Icon, IconSize, IconType } from './Icon';
import { Prefix } from './Prefix';
import { Suffix } from './Suffix';

export interface BaseInputProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
    /**
     * When true, adds error styling
     */
    error?: boolean;
    /**
     * A non-interactive icon rendered in the left of the input
     */
    icon?: IconType;
    id: string;
    /**
     * Renders content in the right side of the input
     */
    suffix?: React.ReactNode;
    type?: 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'date';
    /**
     * Removes the default browser minimum width, and allows the
     * component to wrap.
     * Used for MultiSelect
     */
    wrap?: boolean;
}

interface PrivateBaseInputProps extends BaseInputProps {
    /**
     * Renders content in the left side of the input
     * If rendering an icon, @see `icon`
     */
    prefix?: React.ReactNode;
}

const BaseInputContainer = styled(Box).attrs<{ className?: string }>({ className: 'emu' })<{
    disabled?: boolean;
    error?: boolean;
}>`
    ${FieldStyling}
    padding: 0;

    &:focus-within {
        ${FieldFocusStyling}
    }
`;

const BaseInputComponent = styled.input.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
        !['hasIcon', 'hasSuffix'].includes(prop) && defaultValidatorFn(prop),
})<{
    disabled?: BaseInputProps['disabled'];
    hasIcon?: boolean;
    hasSuffix?: boolean;
    wrap?: BaseInputProps['wrap'];
}>`
    appearance: none;
    background-color: inherit;
    border: 0;
    border-radius: inherit;
    color: inherit;
    line-height: inherit;
    padding: ${FieldPadding.y} ${FieldPadding.x};

    ${({ hasIcon }) =>
        hasIcon &&
        css`
            padding-left: calc(${FieldPadding.x} + ${IconSize} + ${spaces['0.5bu']});
        `}

    ${({ hasSuffix }) =>
        hasSuffix &&
        css`
            padding-right: 0;
        `}

    ${({ wrap }) =>
        wrap &&
        css`
            flex-grow: 1;
            min-width: 100px;
            width: 0;
        `}

    /* Disable Microsoft Edge default password reveal functionality */
    &::-ms-reveal,
    &::-ms-clear {
        display: none;
    }

    &:focus {
        outline: none;
    }

    &::placeholder {
        ${FieldPlaceholderStyling}
    }
`;

/**
 * A styled text input, which can be used to build speciality form components.
 * When used directly, ensure that an accessible label is present.
 *
 * For a more comprehensive component, @see Input.
 */
export const BaseInput = React.forwardRef<HTMLInputElement, PrivateBaseInputProps>(function (
    { disabled, error, icon, prefix, required, suffix, type = 'text', wrap, ...rest },
    ref
) {
    const hasIcon = !!icon && !prefix;
    const hasPrefix = !!prefix && !icon;
    const hasSuffix = !!suffix;

    return (
        <BaseInputContainer
            display="flex"
            alignItems="center"
            background="white"
            disabled={disabled}
            error={error}
        >
            <Box
                display="flex"
                flexGrow={1}
                flexWrap={wrap ? 'wrap' : undefined}
                position="relative"
            >
                {hasIcon && <Icon icon={icon} />}
                {hasPrefix && <Prefix>{prefix}</Prefix>}
                <BaseInputComponent
                    ref={ref}
                    type={type}
                    disabled={disabled}
                    required={required}
                    wrap={wrap}
                    aria-invalid={error}
                    hasIcon={hasIcon}
                    hasSuffix={hasSuffix}
                    {...rest}
                />
            </Box>
            {suffix && <Suffix>{suffix}</Suffix>}
        </BaseInputContainer>
    );
});
