import { useEffect, useState } from 'react';

export const useAdminBarOffset = (): number => {
    const viewportOffset = parseInt(
        getComputedStyle(document.documentElement).getPropertyValue('--viewport-offset')
    );

    const [adminbarOffset, setAdminbarOffset] = useState(viewportOffset);

    useEffect(() => {
        if (!viewportOffset) {
            setAdminbarOffset(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adminbarOffset]);

    return adminbarOffset;
};
