import isEqual from 'lodash.isequal';
import Popper, { Boundary, Data, Placement, Position } from 'popper.js';
import React from 'react';

import { getComponentClasses } from '../../../utils/app';

interface Props {
    children: React.ReactNode;
    variations?: string[];
    placement?: Placement;
    target: Element;
}
interface State {
    popperStyle: object;
    popperAttributes: object;
    arrowStyle: object;
}

export default class Card extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.popper = null;
        this.card = null;
        this.arrow = null;
        this.state = {
            popperStyle: {},
            popperAttributes: {},
            arrowStyle: {},
        };
        this.applyReactStyle = this.applyReactStyle.bind(this);
        this.update = this.update.bind(this);
    }
    popper: any;
    card: Element | null;
    arrow: Element | null;

    componentDidMount() {
        const { placement } = this.props;
        const behavior: Position[] = ['bottom', 'top', 'left', 'right'];
        const boundariesElement: Boundary = 'viewport';

        if (!this.arrow) return;

        const options = {
            placement: placement || 'bottom',
            modifiers: {
                offset: {
                    offset: '10,10',
                },
                flip: placement ? {} : { behavior }, // Turn off automatic placement features when a placement is manually set
                preventOverflow: placement // Turn off automatic placement features when a placement is manually set
                    ? undefined
                    : { boundariesElement, padding: 10 },
                arrow: {
                    element: this.arrow,
                },
                applyStyle: { enabled: false },
                applyReactStyle: {
                    enabled: true,
                    fn: this.applyReactStyle,
                    order: 900,
                },
            },
        };

        this.popper = new Popper(this.props.target, this.card!, options);

        window.addEventListener('promo-bar-show', this.update);
        window.addEventListener('promo-bar-hide', this.update);
    }

    shouldComponentUpdate(props: Props, state: State) {
        return !isEqual(this.props, props) || !isEqual(this.state, state);
    }

    componentDidUpdate() {
        this.popper.update();
    }

    componentWillUnmount() {
        this.popper.destroy();
        this.popper = null;

        window.removeEventListener('promo-bar-show', this.update);
        window.removeEventListener('promo-bar-hide', this.update);
    }

    update() {
        if (this.popper) {
            this.popper.update();
        }
    }

    applyReactStyle(data: Data) {
        const attributes = {
            'data-placement': data.placement || 'bottom',
            'data-out-of-boundaries': data.hide || undefined,
        };

        this.setState({
            popperStyle: data.styles,
            popperAttributes: attributes,
            arrowStyle: data.offsets.arrow,
        });
        return data;
    }

    render() {
        const classes = getComponentClasses('popover-card', this.props.variations);
        return (
            <div
                className={classes}
                ref={card => (this.card = card)}
                style={this.state.popperStyle}
                {...this.state.popperAttributes}
                data-testid="popover-card"
            >
                <div
                    className="popover-card__arrow"
                    style={this.state.arrowStyle}
                    ref={arrow => (this.arrow = arrow)}
                />
                <div className="popover-card__content">
                    <div className="popover-card__content__inner">{this.props.children}</div>
                </div>
            </div>
        );
    }
}
