import { useCallback, useState } from 'react';
import { useMessageState } from '../quote/useMessageState';

export interface OnSubmitMessageValue {
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
}

export type OnSubmit = (messageValue: OnSubmitMessageValue, trigger: 'button' | 'enter') => void;
export interface UseMessageInputProps {
    initialMessageValue?: string;
    onSubmit: OnSubmit;
}
export interface UseMessageInputHook {
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    inputOnChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    inputOnKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    inputOnSubmit: () => void;
}

export function useMessageInput({
    initialMessageValue = '',
    onSubmit,
}: UseMessageInputProps): UseMessageInputHook {
    const [message, setMessage] = useMessageState(initialMessageValue);
    const inputOnChange = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setMessage(event.target.value);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [message]
    );

    const [isPostingEnabled, setIsPostingEnabled] = useState(true);
    const createMessageAndClearInput = useCallback(
        (trigger: 'button' | 'enter'): void => {
            if (isPostingEnabled) {
                setIsPostingEnabled(false);
                setMessage('');
                setIsPostingEnabled(true);
                onSubmit({ message, setMessage }, trigger);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [message, isPostingEnabled]
    );

    const inputOnKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
                createMessageAndClearInput('enter');
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [message]
    );

    const handleSendButtonClick = useCallback(() => {
        createMessageAndClearInput('button');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    return {
        message,
        setMessage,
        inputOnChange,
        inputOnKeyDown,
        inputOnSubmit: handleSendButtonClick,
    };
}
