import React from 'react';
import { getComponentClasses } from '../../utils/app';
import { IconType } from './IconType';

interface Props {
    type: IconType;
    variations?: string[];
    children?: React.ReactNode;
}

/**
 * @deprecated Use icon components from `@99designs/icons` instead
 */
export default function Icon(props: Props) {
    let variations: string[] = [];
    if (props.variations) {
        variations = [...props.variations, props.type];
    } else {
        variations = [props.type];
    }

    const classes = getComponentClasses('icon', variations);

    return <span className={classes}>{props.children}</span>;
}
