/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconLightbulb3(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M17.166 23.66h3.796s.138-3.299 1.502-6.112 4.631-4.19 3.582-10.282C24.996 1.174 20.411.001 16.595.001S6.076 1.362 5.772 7.266c-.304 5.904 2.699 6.682 4.185 9.73s1.363 6.642 2.822 6.642c1.46 0 4.388.022 4.388.022zM18.769 32s-.573-1.44 0-1.889 2.19.45 2.19-.26.346-3.081-.37-3.506-1.754-.39-4.088-.39c-2.333 0-4.526 0-4.671.39s-.146 2.209-.146 2.209.073 1.951.876 1.624 1.387.13 1.387.13V32h4.823z"
            fill="currentColor"
        />
    );
}
