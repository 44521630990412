import React from 'react';
import styled from 'styled-components';
import { colors, typography } from '@99designs/emu/settings';
import { Icon, IconType } from '@99designs/frontend-components/components/Icon';

interface Props {
    icon: IconType;
    size?: 'small' | 'large';
    children?: React.ReactNode;
}

const DesignPlaceholderContainer = styled.div`
    position: relative;
    padding-bottom: 100%;
`;

const DesignPlaceholderInner = styled.div`
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    text-align: center;
    opacity: 1;
    flex-direction: column;
    justify-content: center;
    padding: 0;
`;

const DesignPlaceholderIcon = styled.div<{ isLarge?: boolean }>`
    display: block;
    color: ${colors.grey500};
    font-size: ${(props) =>
        props.isLarge ? typography.typeScale.size9 : typography.typeScale.size6};
`;

const DesignPlaceholderMessage = styled.p<{ isLarge?: boolean }>`
    display: block;
    color: ${colors.grey700};
    margin: 0;
    font-size: ${(props) =>
        props.isLarge ? typography.typeScale.size3 : typography.typeScale.size2};
`;

export function DesignPlaceholder({ icon, children, size = 'small' }: Props) {
    return (
        <DesignPlaceholderContainer>
            <DesignPlaceholderInner>
                <DesignPlaceholderIcon isLarge={size === 'large'}>
                    <Icon type={icon} />
                </DesignPlaceholderIcon>

                {children && (
                    <DesignPlaceholderMessage isLarge={size === 'large'}>
                        {children}
                    </DesignPlaceholderMessage>
                )}
            </DesignPlaceholderInner>
        </DesignPlaceholderContainer>
    );
}
