import React from 'react';
import styled, { css } from 'styled-components';
import { borders, boxShadows, colors } from '../../../settings';
import { Box } from '../../Box';
import { FieldClickable, FieldClickableProps, Size } from '../../Field';
import { HiddenInput } from './HiddenInput';
import { Tick } from './Tick';
import { radioInnerSizes, sizes } from './sizes';

export type ClickableInputProps = FieldClickableProps & {
    type: 'checkbox' | 'radio';
};

const checkboxStyles = css`
    border-radius: ${borders.borderRadiuses.small}px;

    ${HiddenInput}:checked + && {
        background-color: ${colors.grey900};

        ${Tick} {
            opacity: 1;
        }
    }
    ${HiddenInput}:not(:disabled):checked:hover + && {
        box-shadow: inset 0 0 0 1px ${colors.grey600};
        background-color: ${colors.grey600};
    }

    ${HiddenInput}:disabled:checked + && {
        background-color: ${colors.grey300};
    }
`;

const radioStyles = css<{ size: Size }>`
    border-radius: 50%;

    ${HiddenInput}:checked + && {
        ::before {
            content: '';
            display: inline-block;
            background-color: ${colors.grey900};
            height: ${({ size }) => radioInnerSizes[size]}px;
            width: ${({ size }) => radioInnerSizes[size]}px;
            position: absolute;
            top: ${({ size }) => (sizes[size] - radioInnerSizes[size]) / 2}px;
            left: ${({ size }) => (sizes[size] - radioInnerSizes[size]) / 2}px;
            border-radius: 50%;
        }
    }
    ${HiddenInput}:not(:disabled):checked:hover + && {
        ::before {
            background-color: ${colors.grey600};
        }
    }
    ${HiddenInput}:disabled:checked + && {
        background-color: ${colors.grey100};

        ::before {
            background-color: ${colors.grey300};
        }
    }
`;

const VisibleInput = styled(Box)<{
    size: Size;
    type: ClickableInputProps['type'];
}>`
    ${({ type }) => (type === 'checkbox' ? checkboxStyles : radioStyles)}

    cursor: pointer;
    pointer-events: none;
    background-color: ${colors.white};
    /* box-shadow doesn't have aliasing issues like border */
    box-shadow: inset 0 0 0 1px ${colors.grey500};
    height: ${({ size }) => sizes[size]}px;
    width: ${({ size }) => sizes[size]}px;

    ${HiddenInput}:not(:disabled):not(:checked):hover + & {
        box-shadow: inset 0 0 0 1px ${colors.grey900};
    }
    ${HiddenInput}:not(:disabled):focus + & {
        ::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: inherit;
            box-shadow: 0 0 0 4px ${colors.carnaval};
        }
    }
    ${HiddenInput}:not(:disabled):focus:not(:focus-visible) + & {
        ::after {
            content: none;
        }
    }
    ${HiddenInput}:not(:disabled):focus-visible + & {
        ::after {
            content: '';
            position: absolute;
            border-radius: inherit;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            box-shadow: ${boxShadows.focusRing};
            z-index: 1;
            pointer-events: none;
        }
    }
    ${HiddenInput}:not(:disabled):checked + & {
        box-shadow: inset 0 0 0 1px ${colors.grey900};
    }
    ${HiddenInput}:disabled + & {
        box-shadow: inset 0 0 0 1px ${colors.grey300};
    }
`;

export const ClickableInput = React.forwardRef<HTMLInputElement, ClickableInputProps>(function (
    { disabled, id, size = 'regular', type, ...rest },
    ref
) {
    return (
        <FieldClickable
            disabled={disabled}
            id={id}
            label={'label' in rest ? rest.label : undefined}
            size={size}
        >
            <HiddenInput
                ref={ref}
                id={id}
                type={type}
                disabled={disabled}
                inputSize={size}
                {...rest}
            />
            <VisibleInput
                display="flex"
                justifyContent="center"
                alignItems="center"
                size={size}
                type={type}
            >
                {type === 'checkbox' && (
                    <Tick color={colors.white} size={size} aria-hidden="true" />
                )}
            </VisibleInput>
        </FieldClickable>
    );
});
