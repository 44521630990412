import { breakpoints } from '../settings';

export type ResponsiveProp<T> = T | Readonly<[T, T]> | Readonly<[T, T, T]> | Readonly<[T, T, T, T]>;

export function normalizeResponsiveProp<T extends string | number>(
    value: ResponsiveProp<T>
): Readonly<[T, T, T, T]> {
    if (typeof value === 'string' || typeof value === 'number') {
        return [value, value, value, value];
    }

    if (Array.isArray(value)) {
        const { length } = value;

        if (length === 2) {
            const [smallValue, normalValue] = value as Readonly<[T, T]>;
            return [smallValue, smallValue, normalValue, normalValue];
        }

        if (length === 3) {
            const [smallValue, mediumValue, normalValue] = value as Readonly<[T, T, T]>;
            return [smallValue, mediumValue, normalValue, normalValue];
        }

        if (length === 4) {
            return value as Readonly<[T, T, T, T]>;
        }
    }

    throw new Error(`Invalid responsive prop value: ${JSON.stringify(value)}`);
}

export function resolveResponsiveProp<T extends string | number>(
    value: ResponsiveProp<T>,
    propName: string,
    propValues?: Record<T, string> | ((value: T | string) => string)
): string {
    const [smallValue, mediumValue, normalValue, largeValue] = normalizeResponsiveProp(value);

    const getPropValue = (
        value: T,
        values?: Record<T, string> | ((value: T | string) => string)
    ): T | string => {
        if (typeof values === 'function') {
            return values(value);
        } else {
            if (!values || !values[value]) {
                return value;
            } else {
                return values[value];
            }
        }
    };

    let css = `${propName}: ${getPropValue(normalValue, propValues)};`;

    // Tablet is different from both Desktop and Mobile
    if (mediumValue !== normalValue && mediumValue !== smallValue) {
        css += `
            @media (min-width: ${breakpoints.medium[0]}px) and (max-width: ${
            breakpoints.medium[1]
        }px) {
                ${propName}: ${getPropValue(mediumValue, propValues)};
            }`;
    }

    // Mobile is different from both Tablet and Desktop
    if (smallValue !== mediumValue && smallValue !== normalValue) {
        css += `
            @media (min-width: ${breakpoints.small[0]}) and (max-width: ${breakpoints.small[1]}px) {
                ${propName}: ${getPropValue(smallValue, propValues)};
            }`;
    }

    // Tablet and Mobile are the same
    if (mediumValue !== normalValue && mediumValue === smallValue) {
        css += `
            @media (min-width: ${breakpoints.small[0]}) and (max-width: ${
            breakpoints.medium[1]
        }px) {
                ${propName}: ${getPropValue(mediumValue, propValues)};
            }`;
    }

    // Large value different to desktop
    if (largeValue !== normalValue) {
        css += `
            @media (min-width: ${breakpoints.large[0]}px) {
                ${propName}: ${getPropValue(largeValue, propValues)};
            }`;
    }

    return css;
}
