/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconSearch1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M32 29.174c0-.377-9.365-9.305-9.677-9.557s-.187-.817.125-1.509 3.871-8.299-1.374-13.958S7.713-1.131 3.218 3.773c-4.495 4.904-4.121 12.826.125 16.662s9.87 5.156 14.924 1.949c.876-.508 1.375.247 1.375.247s9.056 9.24 9.43 9.366c.375.126 2.927-2.445 2.927-2.822zM4.04 12.442c-.203-3.9 3.265-8.682 7.948-8.367s8.304 3.964 8.116 7.736c-.187 3.772-3.119 8.488-8.366 8.236s-7.495-3.705-7.698-7.605z"
            fill="currentColor"
        />
    );
}
