/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function MarketingTwitterHeaderDesign(props: Props) {
    const newProps = { ...props, viewBox: '0 0 48 48' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            fill="currentColor"
            d="M41.438 14.475c-.037-1.35.112-2.7-.15-3.975-.487-2.363-1.688-3.188-4.125-3.188h-20.25c-1.612 0-3.188-.075-4.8 0-3.563.188-5.663 2.363-5.7 5.962v9.3h.037c0 3.188-.037 6.375 0 9.6.038 1.837 1.05 3.188 2.7 3.487.863.15 1.725.15 2.625.15h8.137c.075 0 .15.075.15.112l-.112 2.063c-.038.15-.113.263-.263.3-.675.113-1.125.638-1.087 1.275.038.675.488 1.125 1.2 1.125h5.85a2.3 2.3 0 001.725-.75c1.088-1.163 1.013-2.55.825-3.975 0-.037.112-.188.15-.188h3.863c1.387-.037 2.775.038 4.162-.15 3-.413 4.913-2.738 4.95-5.775l.112-4.912c.038-1.463.075-2.925.075-4.388.038-1.987-.038-4.013-.075-6.075zM25.8 38.438c0 .075-.225.15-.337.15h-4.051c-.075 0-.225-.225-.225-.338.075-.412.225-.825.338-1.275.037-.037.075-.075.15-.075 1.387 0 2.775 0 4.163.037.037 0 .112.038.112.075-.037.487-.075.938-.15 1.425zm9.3-4.388c-.563.188-1.237-.075-1.875-.075-3.262-.075-6.487-.112-9.75-.15-2.587-.038-5.212 0-7.8-.038-1.875 0-3.75-.112-5.662-.075-1.05 0-1.388-.75-1.688-1.5a4.888 4.888 0 01-.3-1.613c-.038-2.963 0-5.887 0-8.85v-8.587c0-2.663 1.275-4.05 3.938-4.2 1.2-.075 2.437-.075 3.637-.075h19.725c1.612 0 2.25.563 2.25 2.137.038 3.412.038 6.825.075 10.275.037 2.963.112 5.888.15 8.85.038 1.65-1.162 3.45-2.7 3.9z"
        />,
        <path
            fill="currentColor"
            d="M33.75 10.5a281.294 281.294 0 00-5.662-.075c-2.7-.038-5.363-.038-8.063-.075-1.125-.038-2.25-.225-3.413-.188-1.65.037-3.3.262-4.95.337s-2.85 1.238-2.85 2.888v13.388c0 1.912 1.162 3.075 3.075 3.075h21.712c1.8 0 2.963-1.2 2.963-2.962V13.313c0-.75-.263-1.462-.788-2.025s-1.275-.825-2.025-.788zm1.537 16.313c.038.375-.15.75-.45.975-.15.113-.375.188-.563.188-1.8.037-3.638 0-5.438-.075-2.063-.075-4.163-.263-6.225-.3-2.363-.037-4.688.113-7.05.075-.975-.037-1.912-.3-2.888-.525-.788-.188-1.163-.825-1.2-1.875-.038-2.25-.225-4.463-.113-6.713.075-1.912.45-3.825.75-5.738.038-.337.3-.638.487-.938l.037-.038c.038-.075.15-.075.263-.075h8.1v.038h7.95c1.463 0 2.925.037 4.35.075.375 0 .75-.038 1.087.075.6.225.9.675.9 1.35v13.5zM22.987 31.162c-.825-.037-1.087.188-1.087.787-.038.563.15.975.675 1.05.563.075 1.125-.45 1.125-.9s-.15-.9-.713-.938z"
        />,
        <path
            fill="currentColor"
            d="M26.85 25.537h-4.163l-3.825.037c-.337 0-.563.188-.563.412s.225.338.563.375h.038l8.063.037c.3-.037.488-.225.488-.45-.038-.225-.263-.412-.6-.412zM28.95 23.888H19.2c-.15 0-.3 0-.413.037-.3.075-.45.188-.45.413s.15.338.45.412c.15.037.337.037.487.037h9.675c.412 0 .712-.188.712-.45.038-.225-.262-.413-.712-.45zM23.587 22.987c1.988 0 3.788-1.2 4.575-3.037.75-1.838.338-3.975-1.088-5.4s-3.525-1.838-5.4-1.088c-1.837.75-3.038 2.55-3.038 4.575a4.945 4.945 0 004.95 4.95zm-2.475-3.224c.525-.038.675-.038.938-.188-.15-.15-.3-.263-.45-.45a.655.655 0 01-.188-.563c0-.075-.038-.15-.075-.225a4.05 4.05 0 01-.3-.563c-.113-.3 0-.413.337-.337h.113a5.243 5.243 0 01-.3-.675 1.087 1.087 0 010-.488c.038-.188.188-.225.338-.113.112.075.188.15.3.225.45.488 1.013.6 1.65.563a.293.293 0 00.15-.15c.113-.788.788-1.275 1.575-1.05.188.075.338.188.45.338.075.112.15.15.262.075h.037l.338-.075-.037.337c.188.188.188.225-.075.375a.476.476 0 00-.262.337c-.113 1.688-1.012 2.925-2.7 3.188-.563.113-1.125.188-1.688.038-.15-.038-.3-.075-.45-.188-.075-.075-.15-.188-.15-.3-.075-.038.112-.113.188-.113z"
        />
    );
}
