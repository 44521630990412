/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconDollar1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M18 6a4.01 4.01 0 013.876 3.01c.136.534.572.99 1.124.99h2c.552 0 1.008-.452.938-.998C25.446 5.058 22.074 2 18 2V1a1 1 0 00-1-1h-2a1 1 0 00-1 1v1c-4.41 0-8 3.588-8 8s3.59 8 8 8v8a4.01 4.01 0 01-3.876-3.01C9.988 22.456 9.552 22 9 22H7c-.552 0-1.008.452-.938.998C6.554 26.942 9.926 30 14 30v1a1 1 0 001 1h2a1 1 0 001-1v-1c4.41 0 8-3.588 8-8s-3.59-8-8-8V6zm-8 4c0-2.206 1.792-4 4-4v8c-2.208 0-4-1.794-4-4zm8 16v-8c2.208 0 4 1.794 4 4s-1.792 4-4 4z"
            fill="currentColor"
        />
    );
}
