import { linkTo } from '@99designs/routing';
import { FormatAndLinkify, LinkReplaceFunc } from '../FormatAndLinkify';

export interface HandoverMessageFormatAndLinkifyProps {
    rawHtml: string;
    handoverId: string;
    className?: string;
    appendElement?: JSX.Element;
}

const FILE_ID_REGEXP = /#(\d+)([^0-9;])/giu;

export const HandoverMessageFormatAndLinkify = (props: HandoverMessageFormatAndLinkifyProps) => {
    const { handoverId } = props;

    return FormatAndLinkify({
        ...props,
        linkReplaceRegexp: FILE_ID_REGEXP,
        linkReplaceCallback: fileLinkReplaceCallBack(handoverId),
    });
};

const fileLinkReplaceCallBack = (handoverId: string): LinkReplaceFunc => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (match: string, ...args: any[]) => {
        return (
            '<a href="' +
            linkTo.handoverFile(handoverId, args[0]) +
            '" class="link">#' +
            args[0] +
            '</a>' +
            args[1]
        );
    };
};
