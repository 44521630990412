/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconSidebar1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M1.632 27.265c.521 0 14.553-.001 15.169.162s.724-.175.868.671c.144.846.174 2.652 0 3.048s-.652.281-1.086.281c-.434 0-14.289.125-14.774.045s-1.456-.05-1.415-.877c.04-.827-.047-.951-.019-2.196s.737-1.133 1.258-1.133zM2.637 18.319c.935 0 26.131-.001 27.235.162s1.3-.175 1.558.671c.259.846.312 2.652 0 3.048s-1.17.281-1.949.281c-.779 0-25.656.125-26.527.045s-2.614-.05-2.541-.877c.072-.827-.084-.951-.034-2.196s1.323-1.133 2.258-1.133zM2.637 9.373c.935 0 26.131-.001 27.235.162s1.3-.175 1.558.671c.259.846.312 2.652 0 3.048s-1.17.281-1.949.281c-.779 0-25.656.125-26.527.045S.34 13.53.413 12.703c.072-.827-.084-.951-.034-2.196s1.323-1.133 2.258-1.133zM2.637.427c.935 0 26.131-.001 27.235.162s1.3-.175 1.558.671c.259.846.312 2.652 0 3.048s-1.17.281-1.949.281c-.779 0-25.656.125-26.527.045S.34 4.584.413 3.757C.485 2.93.329 2.805.379 1.561S1.702.428 2.637.428z"
            fill="currentColor"
        />
    );
}
