/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconTick2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M16.057.001C4.401.126-.043 9.592 0 16.023s5.323 15.938 16.435 15.976C27.547 32.037 32.048 21.76 32 16.186S27.713-.125 16.058 0zm-7.57 15.423c.252 0 3.949 3.924 4.282 4.236s.309.716.839.223S23.555 9.617 23.822 9.617s2.216 1.799 2.125 2.24c-.091.441-11.633 11.799-12.047 12.188s-.922.255-1.075.117c-.153-.139-6.636-6.446-6.636-6.556s2.047-2.182 2.299-2.182z"
            fill="currentColor"
        />
    );
}
