/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconZoomIn1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M32 29.174c0-.377-9.365-9.305-9.677-9.557s-.187-.817.125-1.509 3.871-8.299-1.374-13.958S7.713-1.131 3.218 3.773c-4.495 4.904-4.121 12.826.125 16.662s9.87 5.156 14.924 1.949c.876-.508 1.375.247 1.375.247s9.056 9.24 9.43 9.366c.375.126 2.927-2.445 2.927-2.822zM5.372 13.705c-.148-.147-.322-3.104-.113-3.193s4.021.098 4.408.067c.387-.031.834-.063.867-.803s-.156-4.589.053-4.62c.209-.03 3.034.054 3.153.113s.068 3.967.066 4.44c-.002.474.174.827.859.794s4.2-.138 4.378-.021c.178.118.144 3.045-.006 3.194s-4.32.081-4.588.052c-.268-.028-.833.18-.718.832s.07 4.143.036 4.354c-.034.211-3.008.335-3.157.127s-.041-3.762-.039-4.354c.002-.592-.532-1.004-.979-.973s-4.072.137-4.22-.011z"
            fill="currentColor"
        />
    );
}
