import { Resource } from 'i18next';
import dede from './de-DE.json';
import enus from './en-US.json';
import esus from './es-US.json';
import frfr from './fr-FR.json';
import itit from './it-IT.json';
import jajp from './ja-JP.json';
import nlnl from './nl-NL.json';
import ptbr from './pt-BR.json';

const resources: Resource = {
    'de-de': {
        translation: {
            ...dede,
        },
    },
    'en-us': {
        translation: {
            ...enus,
        },
    },
    'es-us': {
        translation: {
            ...esus,
        },
    },
    'fr-fr': {
        translation: {
            ...frfr,
        },
    },
    'it-it': {
        translation: {
            ...itit,
        },
    },
    'ja-jp': {
        translation: {
            ...jajp,
        },
    },
    'nl-nl': {
        translation: {
            ...nlnl,
        },
    },
    'pt-br': {
        translation: {
            ...ptbr,
        },
    },
};

export default resources;
