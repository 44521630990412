import React from 'react';
import styled, { css } from 'styled-components';
import { colors, typography } from '../../settings';
import { Box } from '../Box';
import { Text } from '../Text';

type BadgeSize = 'small' | 'regular';

export interface BadgeProps {
    /**
     * When value > max, max is displayed instead, suffixed with a `+`
     */
    max?: number;
    size?: BadgeSize;
    /**
     * Value to display, when value < max
     */
    value: number;
}

const sizeMap: Record<BadgeSize, { height: string; text: keyof typeof typography.typeScale }> = {
    small: {
        height: '23px',
        text: 'size1',
    },
    regular: {
        height: '26px',
        text: 'size2',
    },
};

export const Container = styled(Box).attrs<{ className?: string }>({ className: 'emu' })<{
    size: BadgeSize;
}>`
    border-radius: 28px;
    ${({ size }) => css`
        height: ${sizeMap[size].height};
        aspect-ratio: 1 / 1; // set min-width to height, to stop 'vertical ovals'
    `}
`;

/**
 * Used for displaying numeric counters, in the context of messages or as an unread indicator.
 */
export function Badge({ max = 99, size = 'regular', value }: BadgeProps) {
    return (
        <Container
            as="span"
            display="inline-flex"
            justifyContent="center"
            alignItems="center"
            background={colors.experimental.red600}
            paddingY="0.25bu"
            paddingX="0.5bu"
            size={size}
        >
            <Text color="white" size={sizeMap[size].text} weight="bold">
                {value > max ? `${max}+` : value}
            </Text>
        </Container>
    );
}
