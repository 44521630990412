/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconDesign1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M2.599.307C3.974.382 27.305-.107 28.926.022s2.802 1.154 2.86 2.982c.058 1.828.366 23.37.123 25.443s-1.304 3.075-3.403 3.029c-2.099-.046-21.426.429-24.102.521S.788 29.938.802 28.73C.817 27.521.059 5.585.003 3.992S1.225.231 2.6.307zm3.677 5.696c.033.459.478 6.779.486 7.128s.303.96 1.077.907c.774-.053 6.434-.528 7.043-.536s.984-.165 1.033-.766c.049-.6-.409-6.806-.444-7.333s-.388-.811-.859-.832c-.471-.021-7.219.423-7.618.415s-.749.558-.717 1.017zm17.127 5.563c-2.717.197-4.699 1.313-4.55 4.919s2.151 4.76 5.001 4.76c2.851 0 4.705-1.333 4.538-4.619s-2.273-5.257-4.99-5.061zm-10.962 5.713c-.371 0-.496.06-.743.485S7.92 24.985 7.797 25.35c-.124.365-.438.962.307.901s8.869.318 9.364.318c.495 0 .992-.36.559-.843s-5.215-8.448-5.586-8.448z"
            fill="currentColor"
        />
    );
}
