/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconLoveIt1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M32 16.187C31.951 10.613 27.713-.124 16.058.001S-.042 9.592.001 16.023C.044 22.454 5.324 31.961 16.436 32s15.613-10.239 15.565-15.813zm-21.751-5.336c2.85-.128 3.671 4.665.193 4.603s-3.043-4.475-.193-4.603zm11.407.019c3.114-.089 3.614 4.689.297 4.65s-3.411-4.561-.297-4.65zM9.253 20.858c0-.788 0-.882.925-.882.538 0 4.393.041 7.62.075 2.323.024 4.321.046 4.522.046.479 0 .638.182.638.904s-1.911 5.601-7.234 5.787-6.471-5.141-6.471-5.93z"
            fill="currentColor"
        />
    );
}
