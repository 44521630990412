/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconDribbble1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M2.599.307C1.224.232-.054 2.4.002 3.992S.816 27.521.801 28.73c-.015 1.209.926 3.359 3.602 3.267s22.003-.567 24.102-.521c2.099.046 3.16-.956 3.403-3.029s-.065-23.615-.123-25.443c-.058-1.828-1.239-2.853-2.86-2.982S3.973.383 2.598.307zM15.972 6.07c5.49 0 9.955 4.467 9.955 9.961 0 5.499-4.467 9.969-9.963 9.969S5.997 21.52 6 16.015c.003-5.483 4.477-9.945 9.972-9.945zm8.398 11.326c-1.752-.487-3.503-.632-5.268-.366.307 1 .637 1.989.911 2.992.276 1.007.497 2.028.745 3.054 1.965-1.389 3.179-3.273 3.612-5.68zm-6.552-3.377c.222.504.448 1.002.659 1.506.056.133.121.162.258.149.438-.042.878-.089 1.317-.093.604-.006 1.21.007 1.813.048.611.042 1.219.125 1.827.198.259.031.515.084.782.129-.044-1.983-.678-3.737-1.916-5.281-1.293 1.55-2.927 2.579-4.74 3.344zm-3.847-6.256a46.46 46.46 0 013.179 4.992c1.728-.678 3.276-1.596 4.464-3.075-2.243-1.884-4.775-2.507-7.643-1.916zm-6.344 6.543a32.402 32.402 0 007.869-1.042A54.075 54.075 0 0012.34 8.34c-2.491 1.268-4.063 3.235-4.713 5.966zm9.245 1.705c0-.014.003-.024 0-.03-.213-.448-.425-.896-.642-1.341-.012-.024-.077-.041-.11-.032-.196.049-.39.108-.586.16-1.578.42-3.181.7-4.806.86a28.18 28.18 0 01-3.142.156c-.108-.001-.136.037-.129.134.041.511.049 1.028.126 1.534.21 1.378.765 2.616 1.604 3.728.142.187.297.365.442.543 1.793-2.713 4.107-4.733 7.242-5.71zm-6.141 6.736c1.459 1.123 3.082 1.719 4.888 1.799a8.299 8.299 0 003.663-.669c-.267-1.649-1.518-6.041-1.837-6.444-2.824 1.022-5.158 2.662-6.714 5.314z"
            fill="currentColor"
        />
    );
}
