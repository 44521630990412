import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;

export function VistaprintLogomark(props: Props) {
    const newProps = { ...props, viewBox: '0 0 25.47 22' };
    return React.createElement(
        SvgIcon,
        newProps,
        <defs>
            <style>{'.vistaprint-logo_svg__cls-1{fill:#295e9d}'}</style>
        </defs>,
        <g id="vistaprint-logo_svg__g25556">
            <path
                id="vistaprint-logo_svg__path25558"
                className="vistaprint-logo_svg__cls-1"
                d="M12.74 22l5.58-9.66H7.16z"
            />
        </g>,
        <g id="vistaprint-logo_svg__g25560">
            <path
                id="vistaprint-logo_svg__path25562"
                d="M7.15 12.32h11.16l-5.58-9.66z"
                fill="#44a2dd"
            />
        </g>,
        <g id="vistaprint-logo_svg__g25564">
            <path
                id="vistaprint-logo_svg__path25566"
                d="M11.21 0H0l7.13 12.31 5.58-9.66z"
                fill="#73d2f2"
            />
        </g>,
        <g id="vistaprint-logo_svg__g25568">
            <path
                id="vistaprint-logo_svg__path25570"
                className="vistaprint-logo_svg__cls-1"
                d="M14.26 0l-1.49 2.65 5.57 9.66L25.47 0z"
            />
        </g>
    );
}
