import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import resources from '../translations';

const fallbackLanguages = {
    'en-ca': ['en-au', 'en-gb', 'en-us'],
    'en-nz': ['en-au', 'en-gb', 'en-us'],
    'en-au': ['en-gb', 'en-us'],
    'en-ie': ['en-gb', 'en-us'],
    'en-in': ['en-gb', 'en-us'],
    'en-sg': ['en-gb', 'en-us'],
    'en-gb': ['en-us'],
    'fr-be': ['fr-fr', 'en-us'],
    'fr-ca': ['fr-fr', 'en-us'],
    'fr-ch': ['fr-fr', 'en-us'],
    'es-us': ['es-es', 'en-us'],
    'nl-be': ['nl-nl', 'en-us'],
    'de-at': ['de-de', 'en-us'],
    'de-ch': ['de-de', 'en-us'],
    'it-ch': ['it-it', 'en-us'],
    default: ['en-us'],
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: fallbackLanguages,
    lowerCaseLng: true, //locales are set to lower case e.g. "en-us" not "en-US"
    interpolation: {
        escapeValue: false,
    },
    nsSeparator: '*****',
    returnObjects: true,
});

export default i18n;
