/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconTrophy1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M7.992.022c.996.061 15.191.071 16.747.071s1.058.986 1.121 1.775c.062.789 0 1.055.436 1.12s3.365-.197 4.61-.197 1.121.526 1.058 1.575c-.29 9.615-1.291 10.303-2.918 11.366s-4.058-.058-5.178 1.388c-1.121 1.446-2.952 3.648-5.34 3.825-.811.063-.686 1.106-.686 1.369s-.195 4.653.178 5.442c.374.789 3.566 1.438 4.505 1.876s1.189 2.251-.336 2.341c-1.525.089-11.206-.066-12.949-.066s-.471-1.996.214-2.522c.346-.266 4.015-.639 4.567-1.503.558-.875.442-4.589.38-5.57s.122-1.183-.925-1.379c-2.393-.175-4.732-3.09-5.105-3.484s-1.482-.16-5.167-1.381C-.481 14.847.024 7.193.024 4.892s.498-1.775 1.121-1.841c.623-.066 3.486-.131 4.233-.131s.556-.986.618-1.972c.062-.986 1-.986 1.997-.925zM3.27 5.354c-.438.063-.938 0-.938.875s.063 5.878 1.251 7.129c1.189 1.251 4.56 1.505 4.372.943S6.453 10.367 6.453 6.74c.063-1.126-.557-1.441-1.124-1.441s-1.621-.007-2.059.055zm25.461-.183c-.438-.063-2.183.122-2.75.122s-.688.25-.626 1.376c0 3.627-.998 6.697-1.123 7.192s2.878.813 4.067-.438 1.32-6.569 1.32-7.444-.45-.746-.888-.808z"
            fill="currentColor"
        />
    );
}
