import { createGlobalStyle } from 'styled-components';
import { colors, spaces, typography } from '../../settings';

// @font-face definitions are not supported by styled components, therefore if you are using this
// component, ensure that the definitions in ./fonts.css are also included in your HTML somehow.

export const BaseCss = createGlobalStyle`

// ==========================================================================
// 99designs Normalize
// ==========================================================================

// Originally based on normalize.css v2.1.3

// ==========================================================================
// HTML5 display definitions
// ==========================================================================

//
// Correct block display not defined in IE 8/9.
//

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}

//
// Correct inline-block display not defined in IE 8/9.
//

audio,
canvas,
video {
    display: inline-block;
}

//
// Remove the gap between images, videos, audio and canvas and the bottom of
// their containers: h5bp.com/i/440
//

audio,
canvas,
img,
svg,
video {
    vertical-align: middle;
}

//
// Prevent modern browsers from displaying audio without controls.
// Remove excess height in iOS 5 devices.
//

audio:not([controls]) {
    display: none;
    height: 0;
}

//
// Address [hidden] styling not present in IE 8/9.
// Hide the template element in IE, Safari, and Firefox < 22.
//

[hidden],
template {
    display: none;
}

// ==========================================================================
// Base
// ==========================================================================

//
// 1. Set everything to border-box
// 2. Prevent iOS text size adjust after orientation change, without disabling
//    user zoom.
// 3. Ensure iOS momentum scrolling is active (this doesn't work in iOS7 apparently)
//    http://stackoverflow.com/questions/18736297/webkit-overflow-scrolling-touch-broken-in-ios7
// 4. Remove ugly tap highlighting
//

*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

//
// Remove default margin.
//

body {
    margin: 0;
    padding: 0;
}

// ==========================================================================
// Typography
// ==========================================================================

//
// Address styling not present in Safari 5 and Chrome.
//

dfn {
    font-style: italic;
}

//
// Address styling not present in IE 8/9.
//

mark {
    background: #ff0;
    color: #000;
}

//
// Correct font family set oddly in Safari 5 and Chrome.
//

kbd,
samp {
    font-family: monospace, serif;
    font-size: 1em;
}

//
// Set consistent quote types.
//

q {
    quotes: '\u201C''\u201D''\u2018''\u2019';
}

//
// Prevent sub and sup affecting line-height in all browsers.
//

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

// ==========================================================================
// Embedded content
// ==========================================================================

//
// 1. Remove border when inside a element in IE 8/9.
// 2. Responsive images (ensure images don't scale beyond their parents)
//    a) Set a maxium relative to the parent
//    b) Scale the height according to the width, otherwise you get stretching
// 3. Match vertical alignment with other comment elements

img {
    border: 0;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

//
// Correct overflow displayed oddly in IE 9.
//

svg:not(:root) {
    overflow: hidden;
}

// ==========================================================================
// Figures
// ==========================================================================

//
// Address margin not present in IE 8/9 and Safari 5.
//

figure {
    margin: 0;
}

// ==========================================================================
// Forms
// ==========================================================================

// 1. Remove default margin from forms

form {
    margin: 0;
}

fieldset {
    margin: 0;
    padding: 0;
    border: none;
}

// 1. Correct color not being inherited in IE 8/9.

legend {
    padding: 0;
    display: block;
    width: 100%;
    line-height: inherit;
    border: 0;
    border: none;
}

label {
    display: inline;
}

//
// 1. Correct font family not being inherited in all browsers.
// 2. Correct font size not being inherited in all browsers.
// 3. Address margins set differently in Firefox 4+, Safari 5, and Chrome.
//

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
}

//
// Reset appearance properties for textual inputs and textarea
//

input,
select,
textarea {
    width: 100%;
}

//
// Address Firefox 4+ setting line-height on input using !important in
// the UA stylesheet.
//

button,
input {
    line-height: ${typography.lineHeights.root};
}

//
// Address inconsistent text-transform inheritance for button and select.
// All other form control elements do not inherit text-transform values.
// Correct button style inheritance in Chrome, Safari 5+, and IE 8+.
// Correct select style inheritance in Firefox 4+ and Opera.
//

button,
select {
    text-transform: none;
}

//
// Optgroup sizing
//

optgroup {
    font-size: inherit;
    font-style: inherit;
    font-family: inherit;
}

//
// Address element alignment
//

select,
textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'] {
    vertical-align: baseline;
}

//
// Reset width of input images, radios, checkboxes
//

input[type='file'],
input[type='image'],
input[type='radio'],
input[type='checkbox'] {
    width: auto;
}

//
// Focus for select, file, radio, and checkbox
//

input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

//
// 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native audio
//    and video controls.
// 2. Correct inability to style clickable input types in iOS.
// 3. Improve usability and consistency of cursor style between image-type
//    input and others.
//

button,
html input[type="button"], /* 1*/
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

//
// Re-set default cursor for disabled elements.
//

button[disabled],
html input[disabled] {
    cursor: default;
}

//
// Set default cursor for disabled inputs, selects, textareas
//

input[disabled],
select[disabled],
textarea[disabled] {
    cursor: not-allowed;
}

//
// Give disabled elements a background
//

input[disabled],
textarea[disabled] {
    background-color: ${colors.grey100};
}

//
// 1. Address box sizing set to content-box in IE 8/9/10.
// 2. Remove excess padding in IE 8/9/10.
//

input[type='checkbox'],
input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}

//
// 1. Address appearance set to searchfield in Safari 5 and Chrome.
// 2. Set box sizing (needs more specificity)
//

input[type='search'] {
    -webkit-appearance: textfield;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

//
// Remove inner padding and search cancel button in Safari 5 and Chrome
// on OS X.
//

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

//
// Remove inner padding and border in Firefox 4+.
//

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

//
// 1. Remove default vertical scrollbar in IE 8/9.
// 2. Improve readability and alignment in all browsers.
//

textarea {
    overflow: auto;
    vertical-align: top;
}

// ==========================================================================
// Tables
// ==========================================================================

//
// 1. Remove most spacing between table cells.
// 2. Make default tables look ok
//

table {
    border-collapse: collapse;
    border-spacing: 0;

    margin-bottom: ${spaces['1.5bu']};
    max-width: 100%;
    background-color: transparent;
}

td {
    vertical-align: baseline;
}

ul,
ol {
    padding-left: 1em;
}

ul ul,
ul ol,
ol ol,
ol ul {
    margin-bottom: 0;
}

li {
    line-height: ${typography.lineHeights.root};
}

html,
body,
input,
button,
select,
textarea,
address,
blockquote {
    color: ${colors.grey800};
    font-family: ${typography.fontFamilies.root};
    font-weight: ${typography.fontWeights.root};
    font-size: ${typography.fontSizes.root}px;
    line-height: ${typography.lineHeights.root};
    text-rendering: ${typography.textRendering.root};
}

p {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

ul {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 1.6rem;
}

ol {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 1.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: ${typography.fontFamilies.heading};
    font-weight: ${typography.fontWeights.heading};
    text-rendering: ${typography.textRendering.heading};
}

small {
    font-size: ${typography.fontSizes.small};
    color: ${colors.grey500};
}

strong,
b {
    font-weight: ${typography.fontWeights.bold};
    line-height: inherit;
}

em,
i {
    font-style: ${typography.fontStyles.em};
    line-height: inherit;
}

a {
    color: ${colors.pacific};
    background: transparent;
    text-decoration: ${typography.textDecoration.anchor.base};
    line-height: inherit;
}

a:hover, a:focus {
    color: ${colors.pacific};
}

a:focus {
    outline: thin dotted;
}

a:active,
a:hover {
    outline: 0;
}

a:hover {
    text-decoration: ${typography.textDecoration.anchor.hover};
}

a:active {
    text-decoration: ${typography.textDecoration.anchor.active};
}

a img {
    border: none;
}

blockquote {
    border-left: 5px solid ${colors.pacific};
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 1.6rem;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 1rem;
    color: inherit;
    line-height: inherit;
}

blockquote cite {
    display: block;
    font-size: ${typography.fontSizes.blockquote.cite};
    color: ${colors.grey500};
    font-family: ${typography.fontFamilies.cite};
}

blockquote cite:before {
    content: '\2014 \0020';
}

blockquote cite a,
blockquote cite a:visited {
    color: inherit;
}

// Normalize quotes
// http://www.paulchaplin.com/blog/css-reset-and-quirky-quotes
blockquote,
q {
    quotes: none;

    &:before,
    &:after {
        content: '';
        content: none;
    }
}

pre,
code {
    font-family: ${typography.fontFamilies.code};
    font-weight: inherit;
    font-size: 1em;
    color: inherit;
    margin: 0;
}

pre {
    font-size: ${typography.fontSizes.code}px;
    line-height: inherit;
    white-space: pre-wrap;
}

// Only show border and help cursor for abbr if there is a title
abbr[title],
acronym {
    border-bottom: 1px dotted ${colors.grey500};
    cursor: help;
    text-transform: none;
}

abbr,
acronym {
    color: inherit;
}

acronym {
    text-transform: uppercase;
}

hr {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 1.6rem;
    height: 1px;
    border-style: none;
    color: ${colors.grey800};
    background-color: ${colors.grey800};
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

th {
    font-weight: normal;
}

`;
