/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconTag1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M15.185 1.867c-1.067.919-12.399 12.606-13.866 13.919S-.548 18.287.925 19.6c1.472 1.313 7.066 8.01 10.133 10.899s3.6.919 5.066-.263c1.467-1.182 12.399-12.344 13.999-13.788s1.733-1.97 1.6-4.202.133-6.172.267-9.455S30.923.165 29.323.165c-1.6 0-6.661-.257-10.133-.131s-2.939.914-4.006 1.833zm11.066 1.839c3.337 0 3.081 3.934.128 4.071s-3.465-4.071-.128-4.071z"
            fill="currentColor"
        />
    );
}
