import React, { SelectHTMLAttributes } from 'react';
import { Stack } from '@99designs/emu';
import { colors } from '@99designs/emu/settings';
import { ErrorText } from '../ErrorText';

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
    disabled?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputRef?: any;
    name?: string;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    value?: SelectHTMLAttributes<HTMLSelectElement>['value'];
    children: React.ReactNode;
    error?: string;
    fullWidth?: boolean;
}

/**
 * @deprecated replaced by `Select` in `@99designs/emu`
 */
export const Select = React.forwardRef(function Select(props: SelectProps, ref) {
    const {
        disabled: _disabledIgnored,
        inputRef,
        name,
        onChange,
        value,
        children,
        error,
        fullWidth,
        ...other
    } = props;

    const style: React.CSSProperties = {};
    if (error) {
        style.borderColor = colors.semantic.error;
    }
    if (fullWidth) {
        style.maxWidth = '100%';
    }

    return (
        <Stack space="0.25bu">
            <span className="select" style={style}>
                <select
                    name={name}
                    onChange={onChange}
                    value={value}
                    ref={inputRef || ref}
                    data-testid={name + '-select'}
                    {...other}
                >
                    {children}
                </select>
            </span>
            {error && <ErrorText text={error} />}
        </Stack>
    );
});
Select.displayName = 'Select';
