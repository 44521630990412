/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconMessage1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M15.041 25.278c.04 2.187-.032 6.722-.032 6.722s16.51-5.037 16.979-18.423C32.457.191 19.967-.105 15.476.015S3.395 2.938 1.573 7.129c-1.822 4.191-2.711 7.94 1.025 12.756s9.704 5.341 12.444 5.394z"
            fill="currentColor"
        />
    );
}
