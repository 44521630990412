/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconQuestionMark1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M16.057.001c11.656-.125 15.894 10.611 15.942 16.186S27.546 32.038 16.434 32C5.322 31.962.043 22.455-.001 16.024S4.4.127 16.056.002zM9.928 10.443c-.059.848-.007 1.082 1.047 1.06s.655.06 1.499.06c.844 0 .995-.5 1.048-1.171s1.037-2.057 2.531-1.854c1.493.203 2.903 1.465 2.5 2.958s-1.59 2.821-3.131 4.059c-1.54 1.238-1.642 3.916-1.54 4.256s.322.234.649.234c.326 0 2.244-.088 2.584-.088s.496.197.496-.437c0-.633-.024-1.574 1.004-2.382s3.585-2.944 3.532-5.962c-.053-3.018-2.098-5.669-5.891-5.527s-5.838 2.104-6.326 4.794zm5.566 11.732c-1.875 0-2.191 1.432-2.191 2.419s.589 2.154 2.424 2.258c1.835.104 2.275-1.589 2.275-2.479s-.633-2.199-2.508-2.199z"
            fill="currentColor"
        />
    );
}
