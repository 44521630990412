import React from 'react';
import styled from 'styled-components';
import { OnColorIconButton } from './OnColorIconButton';

/**
 * @deprecated replaced by `IconButton` in `@99designs/emu`
 */
export const OnWhiteIconButton = styled((props) => {
    return (
        <OnColorIconButton {...props} activeIconColor="grey900" activeBackgroundColor="grey100" />
    );
})``;
