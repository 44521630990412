import React from 'react';
import { MultiSelect } from './MultiSelect';
import { SingleSelect } from './SingleSelect';
import { MultiSelectProps, SingleSelectProps } from './types';

export type PolymorphicMultiSelectProps = MultiSelectProps & {
    multiple: true;
};

export type PolymorphicSingleSelectProps = SingleSelectProps & {
    multiple?: never;
};

export type BaseComboboxProps = PolymorphicMultiSelectProps | PolymorphicSingleSelectProps;

export const BaseCombobox = React.forwardRef<HTMLInputElement, BaseComboboxProps>(function (
    props,
    ref
) {
    if ('multiple' in props && props.multiple) {
        const { multiple: _multiple, ...rest } = props;
        return <MultiSelect ref={ref} {...rest} />;
    }

    const { multiple: _multiple, ...rest } = props;
    return <SingleSelect ref={ref} {...rest} />;
});
