import React, { useContext, useEffect, useState } from 'react';
import { MessageContext } from './index';

/**
 * When the message context is updated, set the local message input to that value.
 */
export function useMessageState(
    initialMessageValue: string
): [string, React.Dispatch<React.SetStateAction<string>>] {
    const messageContext = useContext(MessageContext);
    const [message, setMessage] = useState(initialMessageValue);
    useEffect(() => {
        if (
            messageContext?.message != null &&
            messageContext.message !== '' &&
            !isEditingMessage(initialMessageValue)
        ) {
            setMessage(
                (prevMessage) =>
                    prevMessage + messageSeparator(prevMessage) + messageContext.message
            );
        }
    }, [messageContext?.message, initialMessageValue]);

    return [message, setMessage];
}

/**
 * When a user is editing an existing message the initial value is set as that message.
 * This check prevents the edit input from being overwritten by the message context.
 * If other situations arise where the initial value being set is not an edit, this
 * check will need to be updated.
 */
function isEditingMessage(initialValue: string) {
    return initialValue !== '';
}

function messageSeparator(prevMessage: string) {
    return prevMessage === '' ? '' : '\n';
}
