import React from 'react';
import styled from 'styled-components';
import { Box } from '@99designs/emu';
import { mediaQuery } from '@99designs/emu/css';
import { avatars, boxShadows, colors, spaces } from '@99designs/emu/settings';
import { MarketingAlbumCoverDesign } from '@99designs/icons';
import { FacebookLogo, SquareLogo, SquarespaceLogo } from '../../../images/partners';
import { InlinedSvg } from '../../InlinedSvg';

const iconColor = colors.grey400;

const PartnerLogo = styled(InlinedSvg)`
    fill: ${iconColor};
    width: ${avatars.sizes['medium'].avatarSize}px;

    ${mediaQuery.only('medium')} {
        width: ${avatars.sizes['small'].avatarSize}px;
    }

    ${mediaQuery.only('small')} {
        width: ${avatars.sizes['extra-small'].avatarSize}px;
    }
`;

const DefaultIcon = styled(MarketingAlbumCoverDesign).attrs(() => ({
    color: iconColor,
    width: `${avatars.sizes['large'].avatarSize}px`,
    height: `${avatars.sizes['large'].avatarSize}px`,
}))``;

function CategoryLogo({ category }: { category: string | undefined }) {
    switch (category) {
        case 'square-online-store':
            return <PartnerLogo>{SquareLogo}</PartnerLogo>;
        case 'squarespace':
            return <PartnerLogo>{SquarespaceLogo}</PartnerLogo>;
        case 'facebook-ad':
            return <PartnerLogo>{FacebookLogo}</PartnerLogo>;
        default:
            return <DefaultIcon />;
    }
}

const IconWrapper = styled(Box).attrs(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: colors.white,
}))`
    box-shadow: ${boxShadows.low};
`;

const IconPlaceholder = styled(Box).attrs(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spaces['0.75bu'],
    width: [
        `${avatars.sizes['medium'].avatarSize}px`,
        `${avatars.sizes['large'].avatarSize}px`,
        `${avatars.sizes['extra-extra-large'].avatarSize}px`,
    ],
    height: [
        `${avatars.sizes['medium'].avatarSize}px`,
        `${avatars.sizes['large'].avatarSize}px`,
        `${avatars.sizes['extra-extra-large'].avatarSize}px`,
    ],
}))``;

export interface ItemIconProps {
    categoryKey?: string;
}

export function ItemIcon({ categoryKey }: ItemIconProps) {
    return (
        <IconWrapper>
            <IconPlaceholder>
                <CategoryLogo category={categoryKey} />
            </IconPlaceholder>
        </IconWrapper>
    );
}
