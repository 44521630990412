import React from 'react';
import { AccordionItem } from './AccordionItem';

interface AccordionItemData {
    title: string;
    child: React.ReactNode;
}

export interface Props {
    data: AccordionItemData[];
}

export function Accordion({ data }: Props) {
    return (
        <div className="accordion" data-testid="accordion">
            {data.map((d) => (
                <AccordionItem key={d.title} title={d.title}>
                    {d.child}
                </AccordionItem>
            ))}
        </div>
    );
}
