/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconPlay1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm7.498-15.436c0 .476-.077.636-.623.954s-9.271 4.85-9.739 5.009c-.468.159-1.235.562-1.157-.395s-.408-11.386-.408-12.021c0-.635.463-1.274 1.082-.718s10.846 6.695 10.846 7.171z"
            fill="currentColor"
        />
    );
}
