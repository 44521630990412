/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconLinkedin4(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M25.6 32V20.053c0-2.987-1.461-5.045-4.128-5.045-2.037 0-3.648 1.387-4.181 2.72-.181.469-.224 1.141-.224 1.803V32h-6.4s.171-21.333.064-23.467h6.336l-.075 4.267c.971-2.133 2.517-4.267 6.475-4.267C28.278 8.533 32 12.362 32 19.2V32h-6.4zM0 8.533h6.4V32H0V8.533zM3.2 6.4C1.429 6.4 0 4.971 0 3.2S1.429 0 3.2 0c1.771 0 3.2 1.429 3.2 3.2S4.971 6.4 3.2 6.4z"
            fill="currentColor"
        />
    );
}
