import styled from 'styled-components';
import { IconDown1 } from '@99designs/icons';
import { colors } from '../../settings';

export const chevronSize = '20px';

export const ChevronIcon = styled(IconDown1).attrs(() => ({
    width: chevronSize,
    height: chevronSize,
}))<{
    isDisabled?: boolean;
    isRotated?: boolean;
}>`
    color: ${({ isDisabled }) => (isDisabled ? colors.grey400 : colors.grey800)};
    ${({ isRotated }) => (isRotated ? 'transform: rotate(-180deg)' : '')};
    transition: transform 0.2s;

    &:hover,
    &:focus {
        color: ${colors.grey900};
    }
`;
