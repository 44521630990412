import React, { createElement, isValidElement } from 'react';
import formatMessage from './formatMessage';

interface FormattedMessageProps {
    message: string;
    values: { [key: string]: any };
}

/**
 * This component is a direct TypeScript port of react-intl/FormattedMessage
 */
export default function FormattedMessage(props: FormattedMessageProps) {
    const { message, values } = props;

    // Creates a token with a random UID that should not be guessable or
    // conflict with other parts of the `message` string.
    const uid = Math.floor(Math.random() * 0x10000000000).toString(16);

    const generateToken = (() => {
        let counter = 0;
        return () => `ELEMENT-${uid}-${(counter += 1)}`;
    })();

    // Splitting with a delimiter to support IE8. When using a regex
    // with a capture group IE8 does not include the capture group in
    // the resulting array.
    const tokenDelimiter = `@__${uid}__@`;
    const tokenizedValues: { [key: string]: string } = {};
    const elements: { [key: string]: any } = {};

    const hasValues = values && Object.keys(values).length > 0;
    if (hasValues) {
        // Iterates over the `props` to keep track of any React Element
        // values so they can be represented by the `token` as a placeholder
        // when the `message` is formatted. This allows the formatted
        // message to then be broken-up into parts with references to the
        // React Elements inserted back in.
        Object.keys(values).forEach(name => {
            const value = values[name];

            if (isValidElement(value)) {
                const token = generateToken();
                tokenizedValues[name] = tokenDelimiter + token + tokenDelimiter;
                elements[token] = value;
            } else {
                tokenizedValues[name] = value;
            }
        });
    }

    const formattedMessage = formatMessage(message, tokenizedValues || values);
    let nodes;

    const hasElements = elements && Object.keys(elements).length > 0;
    if (hasElements) {
        // Split the message into parts so the React Element values captured
        // above can be inserted back into the rendered message. This
        // approach allows messages to render with React Elements while
        // keeping React's virtual diffing working properly.
        nodes = formattedMessage
            .split(tokenDelimiter)
            .filter((part: string) => !!part)
            .map((part: string) => elements[part] || part);
    } else {
        nodes = [formattedMessage];
    }

    return createElement(React.Fragment, null, ...nodes);
}
