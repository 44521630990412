import styled from 'styled-components';
import { Box } from '@99designs/emu';
import { mediaQuery } from '@99designs/emu/css';
import { borders, colors, spaces } from '@99designs/emu/settings';

export const JobCardContainer = styled(Box).attrs(() => ({
    display: 'flex',
    paddingTop: ['none', 'none', '2bu'],
    paddingBottom: '2bu',
    position: 'relative',
}))`
    border-bottom: ${borders.content};
    gap: ${spaces['2bu']};

    &:last-child {
        padding-bottom: none;
        border-bottom: none;
    }

    &:hover {
        background: #f6f6f6;
        background: linear-gradient(
            to right,
            ${colors.white} 0%,
            #f6f6f6 35%,
            #f6f6f6 65%,
            ${colors.white} 100%
        );
    }

    ${mediaQuery.between('small', 'medium')} {
        flex-wrap: wrap;
    }
`;
