import {
    Disclosure as AriakitDisclosure,
    DisclosureContent as AriakitDisclosureContent,
    useDisclosureStore,
} from '@ariakit/react';
import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Box } from '../Box';

type AllOrNone<T> = T | { [K in keyof T]?: never };

interface UncontrolledDisclosureProps {
    children: React.ReactNode;
    /**
     * Trigger that shows/hides the content when interacted with.
     */
    trigger: React.ReactNode;
}

export type DisclosureProps = UncontrolledDisclosureProps &
    AllOrNone<{
        /**
         * Callback fired when the disclosure is attempting to be shown/hidden.
         */
        onToggle?: () => void;
        /**
         * Dictates if the content is shown.
         */
        visible?: boolean;
    }>;

const DisclosureContent = styled(AriakitDisclosureContent)`
    overflow: hidden;
`;

/**
 * Controls visibility of a section of content.
 *
 * Follows the WAI-ARIA Disclosure pattern.
 *
 * Can be controlled using `onToggle` & `visible` together.
 */
export const Disclosure = forwardRef<HTMLDivElement, DisclosureProps>(function (
    { children, onToggle, trigger, visible },
    ref
) {
    const disclosureProps = useDisclosureStore({
        animated: true,
        open: visible,
        setOpen: onToggle,
    });
    const state = disclosureProps.useState();

    const calculatedHeight = state.open ? 'auto' : 0;

    return (
        <>
            <AriakitDisclosure
                store={disclosureProps}
                render={(renderProps) => {
                    if (React.isValidElement(trigger)) {
                        return React.cloneElement(trigger, renderProps);
                    }

                    return;
                }}
            />
            <Box marginTop="0.5bu" />
            <DisclosureContent ref={ref} store={disclosureProps}>
                <motion.div
                    animate={{ height: calculatedHeight }}
                    onAnimationComplete={disclosureProps.stopAnimation}
                >
                    {children}
                </motion.div>
            </DisclosureContent>
        </>
    );
});
