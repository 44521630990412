import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../settings';
import { Link } from '../Link';
import { Text } from '../Text';
import { getPaginationArray } from './getPaginationArray';
import { RenderPageCounterProps } from './types';

// This is not a component for two reasons:
// 1. We want to use this as a render prop to allow callsites to render their own page counter section
// 2. The wrapping component in `Pagination` is `Inline` which detects a component
//    as a ( React ) Node which means it will only wrap that Node with inline items ONCE.
//    However, we want inline items to wrap **every** Node, which means we have to return the Nodes through
//    a function like this one.
export const defaultRenderPageCounter = ({
    currentPage,
    totalPageCount,
    href,
    behaviour,
    onClick,
}: RenderPageCounterProps) => {
    const items = getPaginationArray({ currentPage, totalPageCount });
    return (
        <>
            {items.map((item, index) => {
                if (item === '...') {
                    return <span key={`truncation-${index}`}>&hellip;</span>;
                }

                const isItemCurrentPage = currentPage === item.pageNumber;

                return (
                    <PaginationLink
                        key={item.pageNumber}
                        underline="none"
                        href={href(item.pageNumber)}
                        aria-current={isItemCurrentPage ? 'page' : undefined}
                        behaviour={behaviour}
                        onClick={onClick}
                    >
                        <PageText isCurrent={isItemCurrentPage} weight="bold" size="size4">
                            {item.pageNumber}
                        </PageText>
                    </PaginationLink>
                );
            })}
        </>
    );
};

const PageText = styled(Text)<{ isCurrent?: boolean }>`
    display: inline-block;
    text-align: center;
    min-width: 14px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    &:hover,
    &:focus {
        border-bottom-color: ${colors.grey700};
    }

    ${({ isCurrent }) =>
        isCurrent &&
        css`
            border-bottom-color: ${colors.grey800};
        `}
`;

const PaginationLink = styled(Link)`
    display: block;
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 44px;
        height: 44px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &:focus-visible::after {
        /* We have to reset this to 0px here as Link is set to -2px, see Link for more info */
        bottom: 0px;
    }
    &:hover,
    &:focus {
        ${PageText} {
            border-bottom-color: ${colors.grey700};
        }
    }
`;
