import styled from 'styled-components';
import { Clickable } from '../Clickable';

/**
 * Clickable with some sane base styling to remove browser default styling.
 *
 * Resetting the styling at the lowest level possible (i.e. a new component that Clickable builds
 * on top of) produced inconsistent styled-components behaviour that would overwrite styles.
 * See: #6630
 * Thus, this component sits _above_ Clickable.
 *
 * In the future, we can look to move this to the lowest level and remove reset-like styling
 * from Button and Link.
 */
export const Interactive = styled(Clickable)`
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align: left;

    color: inherit;
    line-height: inherit;
    text-decoration: none;

    /* For buttons, the default width is effectively fit-content. */
    /* This doesn't change anything for anchors as they are inline. */
    width: 100%;

    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
    }
`;
