import React from 'react';
import styled, { css } from 'styled-components';
import { IconLeft1, IconRight1 } from '@99designs/icons';
import { useComponentsProps, useTranslations } from '../../Context';
import { ResponsiveProp, resolveResponsiveProp } from '../../lib/responsiveProps';
import { Inline } from '../Inline';
import { defaultRenderNavigator } from './defaultRenderNavigator';
import { defaultRenderPageCounter } from './defaultRenderPageCounter';
import { RenderNavigator, RenderPageCounterProps } from './types';
import type { CustomLinkComponent } from '../Clickable';

export interface PaginationProps {
    currentPage: number;
    totalPageCount: number;
    href: (pageNumber: number) => string;
    'aria-label': string;
    width?: ResponsiveProp<'auto' | 'fill'>;
    onClick?: React.MouseEventHandler;
    behaviour?: CustomLinkComponent;
    renderPreviousNavigator?: RenderNavigator;
    renderNextNavigator?: RenderNavigator;
    renderPageCounter?: (props: RenderPageCounterProps) => React.ReactNode;
}

export type PaginationAppLevelProps = Pick<
    PaginationProps,
    'renderPreviousNavigator' | 'renderNextNavigator' | 'renderPageCounter'
>;

/**
 * Pagination allows users to access large amounts of contents split across multiple pages
 *
 * An accessible pagination uses links which change URLs so this component uses the browser
 * routing behaviour by default. Custom routing behaviours such as `react-router` can
 * also be used through `behaviour` prop.
 */
export function Pagination({
    currentPage,
    totalPageCount,
    href,
    'aria-label': ariaLabel,
    width = 'auto',
    onClick,
    behaviour,
    renderPreviousNavigator: componentRenderPreviousNavigator,
    renderNextNavigator: componentRenderNextNavigator,
    renderPageCounter: componentRenderPageCounter,
}: PaginationProps) {
    const translations = useTranslations();
    const contextProps = useComponentsProps();

    const isCurrentPageFirst = currentPage === 1;
    const isCurrentPageLast = currentPage === totalPageCount;
    const previousPage = currentPage - 1;
    const nextPage = currentPage + 1;

    // TODO: find a TypeScript friendly way to do the
    // logic to decide the final functions
    const renderPreviousNavigator =
        componentRenderPreviousNavigator ||
        contextProps?.Pagination?.renderPreviousNavigator ||
        defaultRenderNavigator;
    const renderNextNavigator =
        componentRenderNextNavigator ||
        contextProps?.Pagination?.renderNextNavigator ||
        defaultRenderNavigator;
    const renderPageCounter =
        componentRenderPageCounter ||
        contextProps?.Pagination?.renderPageCounter ||
        defaultRenderPageCounter;

    return (
        <StyledNav aria-label={ariaLabel} width={width}>
            <StyledInline inlineAs={{ root: ResetUl, child: ResetLi }} width={width} space="2bu">
                {renderPreviousNavigator(
                    {
                        disabled: isCurrentPageFirst,
                        'aria-label': translations.Pagination.Previous,
                        icon: IconLeft1,
                        href: href(previousPage),
                        behaviour,
                        onClick,
                    },
                    {
                        pageNumber: previousPage,
                        isCurrent: isCurrentPageFirst,
                    }
                )}

                {renderPageCounter({ currentPage, totalPageCount, href, behaviour, onClick })}

                {renderNextNavigator(
                    {
                        disabled: isCurrentPageLast,
                        'aria-label': translations.Pagination.Next,
                        icon: IconRight1,
                        href: href(nextPage),
                        behaviour,
                        onClick,
                    },
                    {
                        pageNumber: nextPage,
                        isCurrent: isCurrentPageLast,
                    }
                )}
            </StyledInline>
        </StyledNav>
    );
}

const StyledNav = styled.nav.attrs<{ className?: string }>({ className: 'emu' })<{
    width: NonNullable<PaginationProps['width']>;
}>`
    ${({ width }) => css`
        ${resolveResponsiveProp(width, 'width', { auto: '', fill: '100%' })}
    `}
`;

/**
 * FIXME: StyledInline is a custom component to apply reponsive `width` because Inline has `display: inline-flex`
 * by default which makes it slightly hard to align in Pagination context. This should probably be built into
 * Inline in the future
 *
 * - Inline has an `as` prop that conflicts with `styled`'s `as` prop which is always handled first.
 *   `inlineAs` is used as a local prop to bypass the default `as` behaviour.
 * - `width` is taken from `props` to avoid being passed to underlying DOM
 */
const StyledInline = styled(({ inlineAs, ...props }) => <Inline {...props} as={inlineAs} />)<{
    width: NonNullable<PaginationProps['width']>;
}>`
    ${({ width }) => css`
        ${resolveResponsiveProp(width, 'display', { auto: '', fill: 'flex' })}
        ${resolveResponsiveProp(width, 'justify-content', { auto: '', fill: 'space-between' })}
    `}
`;

const ResetUl = styled.ul`
    list-style: none;
    padding: 0;
`;

const ResetLi = styled.li`
    text-indent: 0;
    list-style-type: none;
`;
