/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconUnlock1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M7.508 15.286h-.262c-2.892.393-3.32.262-3.32 2.355s-.145 9.16 0 11.253c.145 2.094.289 3.141 2.603 3.131s15.906 0 18.798 0c2.892 0 2.35-.523 2.484-1.838s-.037-11.123-.037-13.216c0-2.094-.323-1.649-2.292-1.811-1.561-.128-9.728.026-14.933.095 0-1.305-.001-3.408-.005-4.934-.005-2.073-.003-7.09 5.423-7.027s5.237 6.53 5.237 7.472c0 .942.063 1.319 1.764 1.319s1.393-.502 1.456-1.068c.063-.565-.189-2.198-.32-4.46S21.202.087 15.776.025C10.35-.038 7.574 4.61 7.508 6.871s0 4.59 0 8.107v.305zm8.194 4.562c.776.005 2.085.546 2.079 2.076s.08 3.135.08 4.129c0 .994-.608 1.835-1.738 1.835s-1.998-.382-1.998-1.835c0-1.453-.434-3.364-.261-4.435s1.062-1.775 1.838-1.77z"
            fill="currentColor"
        />
    );
}
