import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { typography } from '../../settings';
import { Button } from '../Button';
import { ButtonAppearance, ButtonColorConfig, ButtonSize } from '../Button/types';
import {
    ClickableComponent,
    ClickableNoRoutingProps,
    ClickableProps,
    ClickableWithRoutingProps,
} from '../Clickable';
import { Tooltip } from '../Tooltip';

interface IconButtonStylesProps {
    appearance?: ButtonAppearance | ButtonColorConfig | 'transparent';
    'aria-label': string;
    children?: never;
    icon: React.ComponentType;
    loading?: boolean;
    noTooltip?: boolean;
    selected?: boolean;
    size?: ButtonSize;
}

export type IconButtonWithRoutingProps = ClickableWithRoutingProps<IconButtonStylesProps>;
export type IconButtonNoRoutingProps = ClickableNoRoutingProps<IconButtonStylesProps>;
export type IconButtonProps = ClickableProps<IconButtonStylesProps>;
export type IconButtonComponent<E = unknown> = ClickableComponent<IconButtonStylesProps & E>;

const fontSizeMap: Record<ButtonSize, number> = {
    small: typography.typeScale.size3,
    medium: typography.typeScale.size4,
    large: typography.typeScale.size5,
};

const StyledIconButton = styled(Button)<{ size: ButtonSize }>(({ size }) => {
    return css`
        font-size: ${fontSizeMap[size]}px;

        ${size === 'small' &&
        css`
            position: relative;

            &::before {
                content: '';
                position: absolute;
                inset: -10px;
            }
        `}
    `;
});

export const IconButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, IconButtonProps>(
    function (props, ref) {
        const {
            appearance = 'primary',
            'aria-label': ariaLabel,
            noTooltip = false,
            size = 'medium',
            ...rest
        } = props;

        const buttonComponent = (
            <StyledIconButton
                ref={ref}
                {...rest}
                appearance={appearance}
                aria-label={ariaLabel}
                size={size}
            >
                {/* Pass undefined as `Button` requires children */}
                {undefined}
            </StyledIconButton>
        );

        if (noTooltip) {
            return buttonComponent;
        }

        return (
            <Tooltip delay={300} title={ariaLabel}>
                {buttonComponent}
            </Tooltip>
        );
    }
);
