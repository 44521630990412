/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconPlus2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M.075 13.795c.469-.202 10.792-.081 11.662-.151s1.775.261 1.848-1.411c.073-1.672-.352-12.13.116-12.199S17.642 0 17.909.132c.267.133.154 10.724.15 11.794s-.017 1.776 1.521 1.703c1.538-.073 11.498-.31 11.898-.044s.324 3.85-.012 4.185c-.336.336-11.463.485-12.064.42s-1.507.123-1.514 1.476c-.007 1.353.161 11.428.084 11.904s-3.877.604-4.213.134c-.335-.47-.248-10.415-.242-11.752s-.792-1.866-1.795-1.795c-1.003.071-11.364.154-11.697-.179s-.42-3.982.049-4.184z"
            fill="currentColor"
        />
    );
}
