import React, { ElementType } from 'react';
import styled from 'styled-components';
import { Space, breakpoints, colors, spaces, typography } from '../../settings';

type AccessibilityHeadingLevel = Exclude<typography.HeadingLevel, 0>;

const headingLevelComponent: Record<AccessibilityHeadingLevel, ElementType> = {
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
    6: 'h6',
    7: 'span',
};

export interface StandardHeadingProps extends React.HTMLAttributes<HTMLElement> {
    as?: ElementType<React.HTMLAttributes<HTMLElement>>;
    accessibilityLevel?: AccessibilityHeadingLevel;
    children: React.ReactNode;
    color?: colors.Color;
    fontFamily?: typography.FontFamily;
    fontWeight?: string;
    withDivider?: boolean;
    LEGACY_marginBottom?: Space;
}

export interface HeadingProps extends StandardHeadingProps {
    appearanceLevel?: typography.HeadingLevel;
    accessibilityLevel: AccessibilityHeadingLevel;
}

export const Heading = styled((props: HeadingProps) => {
    const {
        as,
        accessibilityLevel,
        appearanceLevel: _appearanceLevelIgnored,
        fontFamily: _fontFamilyIgnored,
        fontWeight: _fontWeightIgnored,
        color: _colorIgnored,
        withDivider: _withDividerIgnored,
        LEGACY_marginBottom: _LEGACY_marginBottomIgnored,
        ...headingProps
    } = props;

    const Component = as || headingLevelComponent[accessibilityLevel];
    return <Component {...headingProps} />;
}).attrs<{ className?: string }>({ className: 'emu' })<HeadingProps>`
    ${({ appearanceLevel, accessibilityLevel }) => {
        const headingLevel = appearanceLevel ?? accessibilityLevel;
        return `
            font-size: ${typography.headings[headingLevel].fontSize[1]}px;
            @media (min-width: 0) and (max-width: ${breakpoints.medium[1]}px) {
                font-size: ${typography.headings[headingLevel].fontSize[0]}px;
            }
            margin-top: 0;
            margin-bottom: 0;
            line-height: ${typography.headings[headingLevel].lineHeight};
            letter-spacing: ${typography.headings[headingLevel].letterSpacing};
    `;
    }}
    ${({ fontFamily = 'heading' }) => `font-family: ${typography.fontFamilies[fontFamily]};`}
    ${({ fontWeight = 400 }) => `font-weight: ${fontWeight};`}
    ${({ color }) => color && `color: ${colors[color]};`}
    ${({ withDivider }) =>
        withDivider &&
        `
        &:after {
            content: '—';
            display: block;
        }
    `}
    ${({ LEGACY_marginBottom }) =>
        LEGACY_marginBottom && `margin-bottom: ${spaces.sizes[LEGACY_marginBottom]};`}
`;

export function PageHeading({ accessibilityLevel = 1, ...props }: StandardHeadingProps) {
    return <Heading {...props} accessibilityLevel={accessibilityLevel} appearanceLevel={3} />;
}

export function SmallPageHeading({ accessibilityLevel = 1, ...props }: StandardHeadingProps) {
    return <Heading {...props} accessibilityLevel={accessibilityLevel} appearanceLevel={4} />;
}

export function SectionHeading({ accessibilityLevel = 2, ...props }: StandardHeadingProps) {
    return <Heading {...props} accessibilityLevel={accessibilityLevel} appearanceLevel={4} />;
}

export function SmallSectionHeading({
    accessibilityLevel = 3,
    fontFamily = 'text',
    fontWeight = 'bold',
    ...props
}: StandardHeadingProps) {
    return (
        <Heading
            {...props}
            accessibilityLevel={accessibilityLevel}
            appearanceLevel={6}
            fontFamily={fontFamily}
            fontWeight={fontWeight}
        />
    );
}

export function SmallSectionSubheading({
    accessibilityLevel = 4,
    fontFamily = 'text',
    ...props
}: StandardHeadingProps) {
    return (
        <Heading
            {...props}
            accessibilityLevel={accessibilityLevel}
            appearanceLevel={5}
            fontFamily={fontFamily}
        />
    );
}
