import React from 'react';
import styled from 'styled-components';
import { Box } from '../Box';
import { FieldPadding } from '../Field';
import { Text } from '../Text';

const SuffixContainer = styled(Box)`
    padding: 0 ${FieldPadding.x};
`;

export function Suffix({ children }: { children: React.ReactNode }) {
    return (
        <SuffixContainer
            display="flex"
            alignItems="center"
            alignSelf="stretch"
            justifyContent="center"
        >
            {typeof children === 'string' ? <Text size="size2">{children}</Text> : children}
        </SuffixContainer>
    );
}
