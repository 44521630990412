import clsx from 'clsx';

const getComponentClasses = (
    baseClassName: string,
    variations: string[] = [],
    extraClassName?: string
) =>
    clsx(
        baseClassName,
        variations.filter(v => v !== '').map(v => `${baseClassName}--${v}`),
        extraClassName
    ).trim();

export default getComponentClasses;
