import { Placement } from 'popper.js';
import React from 'react';
import ReactDOM, {
    unstable_renderSubtreeIntoContainer as renderSubtreeIntoContainer,
} from 'react-dom';
import Card from './Card';

/**
 * Example:
 * <PopoverCard
 *     layerSelector="[data-modal-overlay-layer]" // Position relative to a layer (recommended)
 *     card={ <div>Hello</div> }
 * >
 *   <span>target</span>
 * </PopoverCard>
 */

interface Props {
    layerSelector?: string;
    children: JSX.Element;
    variations?: string[];
    card: JSX.Element;
    placement: Placement;
}

export default class PopoverCard extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.cardNode = null;
        this.layerNode = null;
        this.target = null;
    }
    cardNode: HTMLDivElement | null;
    layerNode: Element | null;
    target: ReturnType<typeof ReactDOM.findDOMNode>;
    // @ts-ignore: strictPropertyInitialization
    tempLayer: HTMLElement;

    componentDidMount() {
        this.layerNode = this.props.layerSelector
            ? document.querySelector(this.props.layerSelector)
            : this.createTempLayer();
        this.cardNode = document.createElement('div');
        this.layerNode!.appendChild(this.cardNode);
        this.update();
    }

    // Creates a layer element at the end of the body if a selector wasn't given
    createTempLayer() {
        this.tempLayer = document.createElement('div');
        this.tempLayer.style.zIndex = '10';
        this.tempLayer.style.position = 'absolute';
        this.tempLayer.style.width = '100%';
        this.tempLayer.style.top = '0';

        document.body.appendChild(this.tempLayer);

        return this.tempLayer;
    }

    componentDidUpdate() {
        this.update();
    }

    update() {
        if (this.target instanceof HTMLElement) {
            renderSubtreeIntoContainer(
                this,
                <Card
                    target={this.target}
                    variations={this.props.variations}
                    placement={this.props.placement}
                >
                    {this.props.card}
                </Card>,
                this.cardNode!
            );
        } else {
            console.error('PopoverCard render error');
        }
    }

    componentWillUnmount() {
        this.cardNode!.dataset!['closing'] = 'true';
        window.setTimeout(() => {
            ReactDOM.unmountComponentAtNode(this.cardNode!);
            this.layerNode!.removeChild(this.cardNode!);
            if (this.tempLayer) {
                document.body.removeChild(this.tempLayer);
            }
        }, 200);
    }

    render() {
        return React.cloneElement(React.Children.only(this.props.children), {
            ref: (el: React.ReactInstance) => (this.target = ReactDOM.findDOMNode(el)), // eslint-disable-line react/no-find-dom-node
        });
    }
}
