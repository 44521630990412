import React from 'react';
import styled from 'styled-components';
import { joinAndFilter } from '../../lib/joinAndFilter';
import { ResponsiveProp, resolveResponsiveProp } from '../../lib/responsiveProps';
import { Space, spaces } from '../../settings';

const StyledBleed = styled.div<BleedProps>`
    ${({ space, spaceTop, spaceRight, spaceBottom, spaceLeft, spaceX, spaceY }) => {
        const topSpace = spaceTop || spaceY || space;
        const rightSpace = spaceRight || spaceX || space;
        const bottomSpace = spaceBottom || spaceY || space;
        const leftSpace = spaceLeft || spaceX || space;

        return joinAndFilter(
            topSpace &&
                resolveResponsiveProp(
                    topSpace,
                    'margin-top',
                    (value) => `-${spaces.sizes[value as Space]}`
                ),
            rightSpace &&
                resolveResponsiveProp(
                    rightSpace,
                    'margin-right',
                    (value) => `-${spaces.sizes[value as Space]}`
                ),
            bottomSpace &&
                resolveResponsiveProp(
                    bottomSpace,
                    'margin-bottom',
                    (value) => `-${spaces.sizes[value as Space]}`
                ),
            leftSpace &&
                resolveResponsiveProp(
                    leftSpace,
                    'margin-left',
                    (value) => `-${spaces.sizes[value as Space]}`
                )
        );
    }}
`;

export interface BleedProps {
    children: React.ReactNode;
    space?: ResponsiveProp<Space>;
    spaceTop?: ResponsiveProp<Space>;
    spaceRight?: ResponsiveProp<Space>;
    spaceBottom?: ResponsiveProp<Space>;
    spaceLeft?: ResponsiveProp<Space>;
    spaceX?: ResponsiveProp<Space>;
    spaceY?: ResponsiveProp<Space>;
}

/**
 * Applies negative margins to 'remove space' from parent DOM.
 */
export function Bleed(props: BleedProps) {
    return <StyledBleed {...props} />;
}
