import React from 'react';
import styled from 'styled-components';
import { IconInfo } from '@99designs/icons';
import { colors } from '../../../settings';
import { BaseAlert, BaseAlertProps } from '../BaseAlert';

export const StyledInfoAlert = styled(BaseAlert)`
    background: ${colors.grey900};
    color: white;
`;

type InfoAlertProps = Omit<BaseAlertProps, 'icon'>;

export function InfoAlert({ children, ...rest }: InfoAlertProps) {
    return (
        <StyledInfoAlert icon={<IconInfo />} {...rest}>
            {children}
        </StyledInfoAlert>
    );
}
