import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import styled from 'styled-components';
import { Box, Button, Inline, Link, Tag, Tooltip } from '@99designs/emu';
import { mediaQuery } from '@99designs/emu/css';
import { spaces, typography } from '@99designs/emu/settings';
import { Currency, RelativeTimeDisplay } from '@99designs/i18n';
import { IconDate1, IconServer1, IconService1 } from '@99designs/icons';
import { linkTo } from '@99designs/routing';
import { PartnerContext, partnerFromCategory } from '../Briefs';
import { DismissJobButton } from '../DismissJobButton';
import { Desktop, Mobile } from '../Hidden';
import AuthorDisplay from './AuthorDisplay';
import { ItemIcon } from './ItemIcon';
import { JobCardContainer } from './JobCardContainer';
import { JobCardTitle } from './JobCardTitle';
import TextWithClampedOverflow from './TextWithClampedOverflow';

const ContentWrapper = styled(Box).attrs(() => ({
    display: 'flex',
    width: ['100%', 'none'],
}))`
    gap: ${spaces['1bu']};
`;

const ActionsInfoWrapper = styled(Box).attrs(() => ({
    display: 'flex',
    flexDirection: ['row-reverse', 'column'],
    width: ['100%', 'none'],
}))`
    gap: ${spaces['1.5bu']};

    ${mediaQuery.minimum('normal')} {
        min-width: 232px;
    }

    ${mediaQuery.between('small', 'medium')} {
        align-items: center;
        justify-content: space-between;
    }
`;

const ActionsWrapper = styled.div`
    z-index: 3;
`;

const DottedUnderline = styled.span`
    text-decoration: underline dotted currentColor;
    text-underline-offset: 0.25em;

    ${mediaQuery.between('small', 'medium')} {
        text-decoration: none;
    }
`;

const InfoWrapper = styled(Box).attrs(() => ({
    display: 'flex',
    flexDirection: ['row', 'column'],
}))`
    gap: ${spaces['0.75bu']};

    ${mediaQuery.between('small', 'medium')} {
        gap: 0 ${spaces['1.5bu']};
        flex-wrap: wrap;
    }
`;

const InfoItem = styled(Box).attrs(() => ({
    display: 'flex',
}))`
    font-size: ${typography.fontSizes.paragraphs['small']}px;

    ${mediaQuery.minimum('medium')} {
        gap: ${spaces['1bu']};
    }

    ${mediaQuery.between('small', 'medium')} {
        gap: ${spaces['0.5bu']};
        * {
            text-decoration: none;
        }
    }
`;

const RouterLinkOverlay = styled(RouterLink)`
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const DisabledCardContainer = styled(JobCardContainer)`
    pointer-events: none;
`;

type Maybe<T> = T | null;

interface DefaultCardProps {
    user: User;
}

interface User {
    id: string;
    displayName: string;
}

export interface JobCardProps {
    jobForDesigner: {
        job: {
            id: string;
            createdAt: string;
            isTest: boolean;
            user: Maybe<User>;
            brief: Maybe<{
                title: string;
                currency: string;
                description: string;
                industries: Array<{ key: string; title: string }>;
                category: Maybe<{ title: string; key: string }>;
                product: Maybe<{
                    key: string;
                    title: string;
                    description: string;
                    pricing: {
                        earningsInUsd: number;
                    };
                }>;
            }>;
        };
    };
    onCtaActionCompleted?: () => void;
    dismissible: boolean;
}

function DefaultCard({ user }: DefaultCardProps) {
    return (
        <DisabledCardContainer data-testid="job-card">
            <ContentWrapper>
                <Desktop>
                    <ItemIcon />
                </Desktop>
                <Mobile>
                    <ContentWrapper display="flex" alignItems="center">
                        <ItemIcon />
                        <Box flexDirection="column">
                            <JobCardTitle url="#" text={'Not available'} />
                            <AuthorDisplay user={user} />
                        </Box>
                    </ContentWrapper>
                </Mobile>
            </ContentWrapper>
            <ContentWrapper flexDirection="column" flex="0 1 1000px" paddingTop="0.25bu">
                <Desktop>
                    <JobCardTitle url="#" text="Not available" />
                    <AuthorDisplay user={user} />
                </Desktop>
                <TextWithClampedOverflow rawHtml="Description not available" size="size2" />
            </ContentWrapper>
        </DisabledCardContainer>
    );
}

export function JobCard({ jobForDesigner, onCtaActionCompleted, dismissible }: JobCardProps) {
    const { job } = jobForDesigner;
    const jobUrl = linkTo.browseProjectsJob(job.id);
    const brief = job.brief;
    const user = job.user ?? { id: '', displayName: 'unknown user' };

    if (!brief) {
        Bugsnag.notify(new Error(`job ${job.id} is missing a brief`));
        return <DefaultCard user={user} />;
    }

    if (!brief.product) {
        Bugsnag.notify(new Error(`brief with title ${brief.title} is missing a package`));
        return <DefaultCard user={user} />;
    }

    const categoryKey = brief.category?.key;
    const [color, partner] = partnerFromCategory(categoryKey);

    const {
        pricing: { earningsInUsd },
        title,
        description,
    } = brief.product;

    return (
        <PartnerContext.Provider value={{ color: color, partner: partner }}>
            <JobCardContainer data-testid="job-card">
                <Mobile>
                    <RouterLinkOverlay to={jobUrl} aria-label={brief.title} />
                </Mobile>
                <ContentWrapper>
                    <Desktop>
                        <Link href={jobUrl} underline="none">
                            <ItemIcon categoryKey={categoryKey} />
                        </Link>
                    </Desktop>
                    <Mobile>
                        <ContentWrapper display="flex" alignItems="center">
                            <ItemIcon categoryKey={categoryKey} />
                            <Box flexDirection="column">
                                <JobCardTitle url={jobUrl} text={brief.title} />
                                <AuthorDisplay user={user} />
                            </Box>
                        </ContentWrapper>
                    </Mobile>
                </ContentWrapper>
                <ContentWrapper flexDirection="column" flex="0 1 1000px" paddingTop="0.25bu">
                    <Desktop>
                        <JobCardTitle url={jobUrl} text={brief.title} />
                        <AuthorDisplay user={user} />
                    </Desktop>
                    <TextWithClampedOverflow rawHtml={brief.description} size="size2" />
                    <Inline space="0.5bu">
                        {brief.category && (
                            <Tag appearance="transparent">{brief.category.title}</Tag>
                        )}
                        {brief.industries.map(({ key, title }) => (
                            <Tag key={key} appearance="transparent">
                                {title}
                            </Tag>
                        ))}
                        {job.isTest && <Tag appearance="callout">Test</Tag>}
                    </Inline>
                </ContentWrapper>
                <ActionsInfoWrapper>
                    <ActionsWrapper>
                        <Desktop>
                            <Box display="flex" width="100%">
                                <Box flexGrow={1} marginRight="0.75bu">
                                    <Button href={jobUrl} appearance="secondary" width="fill">
                                        {__('View')}
                                    </Button>
                                </Box>
                                <Box flexShrink={1}>
                                    {dismissible && (
                                        <DismissJobButton
                                            jobId={job.id}
                                            onCompleted={onCtaActionCompleted}
                                            iconOnly={true}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Desktop>
                        <Mobile>
                            {dismissible && (
                                <DismissJobButton
                                    jobId={job.id}
                                    onCompleted={onCtaActionCompleted}
                                    iconOnly={true}
                                />
                            )}
                        </Mobile>
                    </ActionsWrapper>
                    <InfoWrapper>
                        <InfoItem>
                            <Box display="flex" alignItems="center">
                                <IconServer1 />
                            </Box>
                            <Tooltip title={__('The amount to be paid to the selected designer.')}>
                                <DottedUnderline>
                                    <Currency currency="USD" value={earningsInUsd} />
                                </DottedUnderline>
                            </Tooltip>
                        </InfoItem>
                        <InfoItem>
                            <Box display="flex" alignItems="center">
                                <IconDate1 />
                            </Box>
                            <RelativeTimeDisplay timestamp={job.createdAt} />
                        </InfoItem>
                        <InfoItem>
                            <Box display="flex" alignItems="center">
                                <IconService1 />
                            </Box>
                            <Tooltip title={description}>
                                <DottedUnderline>{title}</DottedUnderline>
                            </Tooltip>
                        </InfoItem>
                    </InfoWrapper>
                </ActionsInfoWrapper>
            </JobCardContainer>
        </PartnerContext.Provider>
    );
}
