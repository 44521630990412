/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconContacts1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M2.348 30c1.106 0 14.024-.075 14.837-.075s2.005-.526 2.005-.786-.288-2.612-.786-2.91-4.653-1.229-5.275-1.555c-.621-.326-1.159-.663-1.159-2.209s1.78-2.984 1.78-3.613c0-.63 1.444-1.199 1.733-2.217s-.959-1.372-.959-1.372.671-2.103.671-3.501.422-1.908-.813-2.735c-1.235-.828-.378-.328-.921-.797s.289-1.588-2.459-1.243-5.148 1.423-5.56 2.04.734.665-.512.315c-1.245-.35-1.545 1.909-1.545 3.237s.545 1.684.545 2.406c0 .723-.954.386-.954 1.588s1.438 1.603 1.438 1.928c0 .326.806 2.099 1.391 2.77s-.016 2.36-.016 3.058-3.442 1.114-4.693 1.465-1.09 1.662-1.09 2.883 1.235 1.325 2.341 1.325zm27.902-6.287H16.656c-.815 0-2.849-.107-2.849-.347 0-.07.575-3.642 2.077-4.045 1.12-.3 3.137-.479 3.76-.78s.942-.292.942-1.721c0-.561-.285-.716-.942-1.166s-.843-1.819-.843-2.173c0-.582-1.448-1.108-1.737-2.049s.962-1.267.962-1.267-.673-1.943-.673-3.235-.423-1.762.815-2.527c1.238-.765.379-.303.924-.736s1.048-2.368 3.131-1.404c2.013.932 4.561 1.001 4.9 1.697s.015.069.018.105c.098 1.1-.246.338.505.629s1.549 1.764 1.549 2.99c0 1.227-.547 1.555-.547 2.223s.957.357.957 1.467-1.442 1.481-1.442 1.782-.31 2.081-1.379 2.745c-1.069.664-1.41.706-1.41 1.35 0 .224-.211.886.774 1.288s3.027.568 3.846.78c1.254.324 2.008 2.085 2.008 3.213s-.64 1.179-1.75 1.179z"
            fill="currentColor"
        />
    );
}
