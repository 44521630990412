//
// Segment analytics link tracking that includes experiment data.
// The Segment implementation allows a single element or an Array to be passed
// in, so we're keeping this as close as possible to this.
// See: https://segment.com/docs/sources/website/analytics.js/#track-link
// - https://github.com/segmentio/analytics.js/blob/a111102f50118c92d0f5034b71cad8cfb44c3592/analytics.js#L483-L522
// - https://github.com/DefinitelyTyped/DefinitelyTyped/blob/92434b8642aeff543ca3736ae9ef93aa7d93b764/types/segment-analytics/index.d.ts#L150-L156

// Copied from frontend-components
export function getLinkTargetPath(node: Element | null) {
    if (!node) return null;

    const href = node.getAttribute('href');

    if (href && href.match(/^http/)) {
        return href.replace(/^http[s]{0,1}:\/\/.[^/]+[/]*/, '/');
    }

    return href;
}

// Copied from frontend-components
function getLinkTargetUrl(node: Element | null, location = window.location) {
    if (!node) return null;

    const href = node.getAttribute('href');
    const origin = `${location.protocol}//${location.hostname}${
        location.port ? ':' + location.port : ''
    }`;

    if (href && !href.match(/^http/)) {
        return `${origin}${href}`;
    }

    return href;
}

export function trackLink(
    trackable: Element[] | Element | NodeListOf<Element> | null,
    event: string | { (elm: Element): string },
    params: Record<string, unknown> | { (elm: Element): Record<string, unknown> } = {}
): void {
    if (!trackable) return;

    if (trackable instanceof Array) {
        trackable.forEach((el) => setupTrackLink(el, event, params));

        return;
    } else if (trackable instanceof NodeList) {
        for (let i = 0; i < trackable.length; i++) {
            setupTrackLink(trackable[i], event, params);
        }

        return;
    } else {
        setupTrackLink(trackable, event, params);

        return;
    }
}

function setupTrackLink(
    element: Element,
    event: string | { (elm: Element): string },
    params?: Record<string, unknown> | { (elm: Element): Record<string, unknown> }
): void {
    const trackParams = window.trackParams || {};

    params = {
        url: window.location.href,
        target_path: getLinkTargetPath(element),
        target_url: getLinkTargetUrl(element, window.location),
        ...trackParams,
        ...params,
    };

    if (window.analytics) {
        window.analytics.trackLink(element, event, params);
    }
}
