import React from 'react';
import styled from 'styled-components';
import { colors, typography } from '@99designs/emu/settings';
import { TrackEvent, useTrackLink } from '@99designs/tracking';

const StyledDisplayName = styled.a`
    font-family: ${typography.fontFamilies.attribution};
    font-style: italic;
    text-decoration: none;
    transition: border-color 0.15s ease-out;
    color: ${(props) => props.color || colors.typography.displayName};
    border-bottom: solid 2px ${colors.withAlpha(colors.typography.displayName, 0)};

    &:link,
    &:visited {
        text-decoration: none;
        color: ${(props) => props.color || colors.typography.displayName};
        border-color: ${colors.withAlpha(colors.typography.displayName, 0)};
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        color: ${(props) => props.color || colors.typography.displayName};
        border-color: ${colors.withAlpha(colors.typography.displayName, 0.4)};
    }
`;

export interface DisplayNameProps {
    className?: string;
    color?: string;
    profileUrl: string;
    displayName: string;
    trackEvent?: TrackEvent;
}

export function DisplayName({
    className,
    color,
    profileUrl,
    displayName,
    trackEvent,
}: DisplayNameProps) {
    const linkRef = useTrackLink(trackEvent);

    return (
        <StyledDisplayName
            className={className}
            color={color}
            ref={linkRef}
            href={profileUrl}
            onClick={(event) => event.stopPropagation()}
            data-qm-block
        >
            {displayName}
        </StyledDisplayName>
    );
}
