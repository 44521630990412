/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconUndo2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M22.024 9.227l-.849-.032-1.537-.024-3.53.056-3.274-.056-4.772.056c-.015-.002-.025-.01-.044-.012-.024-.003-.022-.002-.04-.003a.587.587 0 00-.079-.086c.008-.015.017-.024.025-.044.034-.044.039-.086.061-.128l5.404-4.914A2.074 2.074 0 0010.597.969L1.953 8.827c-.177.126-.357.246-.531.389-.629.512-.898.883-1.172 1.654a.682.682 0 00-.014.398c-.002.061.008.116.017.175 0 0 0 .008-.002.014a.77.77 0 00.153.48c.017.027.03.052.049.074.105.302.248.585.491.804l6.839 6.218 1.306 1.286.812.64.695.632a2.08 2.08 0 102.791-3.089l-5.446-4.93c-.019-.02-.024-.04-.045-.061a1.814 1.814 0 01-.072-.1c.005-.012.012-.024.015-.039.015.01.027.02.074 0h3.939l2.657-.062 1.539.014 1.794-.014 1.52.014 2.662.049a6.64 6.64 0 016.169 6.625c0 3.183-2.257 5.846-5.296 6.474-.288.049-.575.098-.866.128l-.029.008c-.042.003-.083 0-.128.01H16.63c-.32 0-.613.086-.883.214-.459.137-.888.386-1.101.828-.243.495-.278 1.394.046 1.863.024.034.052.054.079.078.03.061.049.125.096.182.035.046.086.078.126.12.374.514.952.866 1.637.866h5.394a10.788 10.788 0 0010.19-10.771 10.788 10.788 0 00-10.19-10.773z"
            fill="currentColor"
        />
    );
}
