/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconTrophy2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M31.83 15.969c.341 8.312-7.436 16.113-15.992 16.113S-.171 26.199-.16 16.245C-.149 6.291 6.266.252 15.935.084s15.553 7.572 15.894 15.884zM11.435 8.011c-.544-.033-1.056-.033-1.09.505s.07 1.076-.337 1.076c-.408 0-1.971.036-2.311.072s-.612-.251-.612 1.005c0 1.256-.276 5.434 1.736 6.1s2.617.538 2.821.753c.204.215 1.48 1.806 2.787 1.902.572.107.471.217.505.753s.097 2.562-.207 3.04c-.301.472-2.304.676-2.493.821-.374.287-1.068 1.377-.117 1.377s6.236.085 7.068.036c.832-.049.696-1.039.183-1.277s-2.255-.593-2.459-1.024c-.204-.431-.097-2.827-.097-2.97s-.068-.713.374-.747c1.304-.097 2.303-1.298 2.915-2.088s1.939-.177 2.826-.758c.888-.58 1.434-.956 1.593-6.203.034-.573.102-.86-.578-.86s-2.278.144-2.516.108c-.238-.036-.204-.181-.238-.611s.238-.969-.612-.969-8.598-.006-9.141-.039zm-2.577 2.91c-.239.034-.512 0-.512.478s.034 3.208.683 3.891c.649.683 2.489.822 2.386.514s-.82-2.147-.82-4.127c.034-.614-.304-.786-.614-.786s-.885-.004-1.124.03zm13.897-.1c.239.034.485-.037.485.441s-.072 3.38-.721 4.063c-.649.683-2.288.509-2.22.239s.613-1.946.613-3.925c-.034-.614.032-.751.341-.751s1.262-.101 1.501-.067z"
            fill="currentColor"
        />
    );
}
