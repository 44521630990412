import { FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import { CurrencyCode } from '@99designs/i18n';
import { toUnit } from './currency';

const FormerPrice = styled.span`
    text-decoration: line-through;
`;

const Color = styled.span`
    color: ${(props) => props.color};
`;

type paymentSource = 'paid' | 'fully-funded' | undefined;

export function Price({
    amount,
    currency,
    minimumFractionDigits = 0,
    color,
    paymentSource,
}: {
    amount: number;
    currency: CurrencyCode;
    minimumFractionDigits?: number;
    color?: string;
    paymentSource?: paymentSource;
}) {
    const price = (
        <FormattedNumber
            value={toUnit(amount, currency)}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency={currency}
            minimumFractionDigits={minimumFractionDigits}
        />
    );
    if (!paymentSource) {
        return price;
    }

    return (
        <>
            <FormerPrice>{price}</FormerPrice>
            <strong>
                {/* eslint-disable-next-line eqeqeq */}
                <Color color={color}> {paymentSource == 'paid' ? __('Paid') : __('Free!')}</Color>
            </strong>
        </>
    );
}
