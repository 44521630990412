import styled from 'styled-components';
import { IconButton } from '@99designs/emu';
import { borders, colors } from '@99designs/emu/settings';
import { IconMessage1 } from '@99designs/icons';
import { trackEvent } from '@99designs/tracking';
import { useTextSelection } from './useTextSelection';

interface ContainerProps {
    active: boolean;
    top: number;
    left?: number;
}

const MessageActionsContainer = styled.div.attrs<ContainerProps>((props) => ({
    style: {
        top: props.top + 'px',
        left: props.left + 'px',
        display: props.active ? 'block' : 'none',
    },
}))<ContainerProps>`
    position: fixed;
    z-index: 100;
    background: ${colors.white};
    border: ${borders.content};
    border-radius: ${borders.borderRadiuses.small}px;
`;

const quoteFormat = (text: string) => text.replace(/^(.*)$/gm, ' > $1');

export function QuoteReplyButton({
    setMessage,
    viewerId,
    setReplyToId,
}: {
    setMessage: (message: string) => void;
    setReplyToId: (replyTo: string | null) => void;
    viewerId?: string;
    projectId?: string;
}) {
    const { clientRect, textContent, eventId, eventSourceId } = useTextSelection();
    function setChatMessageToHighlighted() {
        if (textContent != null) {
            setReplyToId(eventSourceId ?? null);
            setMessage(quoteFormat(textContent) + '\n');
            trackEvent('Message Quoted', {
                location: 'Project chat',
                sender_id: viewerId,
                message_id: eventId,
            });
        }
    }

    const active = clientRect != null && (textContent?.length ?? 0) > 0;
    return (
        <MessageActionsContainer
            active={active}
            top={(clientRect?.top ?? 0) - 50}
            left={(clientRect?.left ?? 0) + (clientRect?.width ?? 0) / 2}
        >
            <IconButton
                appearance="transparent"
                aria-label={__('Quote')}
                onClick={setChatMessageToHighlighted}
                disabled={!active}
                icon={IconMessage1}
            />
        </MessageActionsContainer>
    );
}
