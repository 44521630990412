import React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import { colors } from '../../settings';
import { Box } from '../Box';
import { FieldPadding } from '../Field';

export const IconSize = '16px';

export type IconType = React.ComponentType<{
    color: CSS.Property.Color | colors.Color;
    height: string;
    width: string;
}>;

const IconContainer = styled(Box)`
    padding-left: ${FieldPadding.x};
    pointer-events: none;
`;

export function Icon({ icon: SVG }: { icon: IconType }) {
    return (
        <IconContainer display="flex" alignItems="center" position="absolute" height="100%">
            <SVG color={colors.grey700} width={IconSize} height={IconSize} />
        </IconContainer>
    );
}
