/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconInstagram2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M13.525.178C20.361.07 28.021-.05 28.925.022c1.621.129 2.802 1.154 2.86 2.982s.366 23.37.123 25.443c-.243 2.073-1.303 3.075-3.403 3.029s-21.426.429-24.102.521C1.727 32.089.787 29.939.801 28.73S.058 5.585.002 3.992C-.055 2.399 1.224.231 2.599.307 3.207.34 8.107.263 13.525.178zM16 7c-2.444 0-2.751.01-3.711.054-.958.044-1.612.196-2.185.418-.592.23-1.094.538-1.594 1.038s-.808 1.002-1.038 1.594c-.223.572-.375 1.227-.418 2.185C7.01 13.249 7 13.555 7 16s.01 2.751.054 3.711c.044.958.196 1.612.418 2.185.23.592.538 1.094 1.038 1.594s1.002.808 1.594 1.038c.572.223 1.227.375 2.185.418.96.044 1.266.054 3.711.054s2.751-.01 3.711-.054c.958-.044 1.612-.196 2.185-.418.592-.23 1.094-.538 1.594-1.038s.808-1.002 1.038-1.594c.223-.572.375-1.227.418-2.185.044-.96.054-1.266.054-3.711s-.01-2.751-.054-3.711c-.044-.958-.196-1.612-.418-2.185-.23-.592-.538-1.094-1.038-1.594s-1.002-.808-1.594-1.038c-.572-.223-1.227-.375-2.185-.418C18.751 7.01 18.445 7 16 7zm0 1.622c2.403 0 2.688.009 3.637.052.878.04 1.354.187 1.671.31.42.163.72.358 1.035.673s.51.615.673 1.035c.123.317.27.794.31 1.671.043.949.052 1.234.052 3.637s-.009 2.688-.052 3.637c-.04.878-.187 1.354-.31 1.671-.163.42-.358.72-.673 1.035s-.615.51-1.035.673c-.317.123-.794.27-1.671.31-.949.043-1.233.052-3.637.052s-2.688-.009-3.637-.052c-.877-.04-1.354-.187-1.671-.31-.42-.163-.72-.358-1.035-.673s-.51-.615-.673-1.035c-.123-.317-.27-.794-.31-1.671-.043-.949-.052-1.234-.052-3.637s.009-2.688.052-3.637c.04-.877.187-1.354.31-1.671.163-.42.358-.72.673-1.035s.615-.51 1.035-.673c.317-.123.794-.27 1.671-.31.949-.043 1.234-.052 3.637-.052zm4.798 3.646a1.08 1.08 0 100-2.16 1.08 1.08 0 000 2.16zm-4.818-.91a4.622 4.622 0 100 9.243 4.622 4.622 0 000-9.243zm0 7.622a3 3 0 100-6 3 3 0 000 6z"
            fill="currentColor"
        />
    );
}
