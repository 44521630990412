import { linkTo } from '@99designs/routing';
import { FormatAndLinkify, LinkReplaceFunc } from '../FormatAndLinkify';

export interface ProjectMessageFormatAndLinkifyProps {
    rawHtml: string;
    projectId: string;
    className?: string;
    appendElement?: JSX.Element;
}

const FILE_ID_REGEXP = /#(\d+)([^0-9;])/giu;

export const ProjectMessageFormatAndLinkify = (props: ProjectMessageFormatAndLinkifyProps) => {
    const { projectId } = props;

    return FormatAndLinkify({
        ...props,
        linkReplaceRegexp: FILE_ID_REGEXP,
        linkReplaceCallback: fileLinkReplaceCallBack(projectId),
    });
};

const fileLinkReplaceCallBack = (projectId: string): LinkReplaceFunc => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (match: string, ...args: any[]) => {
        return (
            '<a href="' +
            linkTo.projectFile(projectId, args[0]) +
            '" class="link">#' +
            args[0] +
            '</a>' +
            args[1]
        );
    };
};
