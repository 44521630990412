import React, { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Collapse, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { Box } from '@99designs/emu';
import { colors, spaces, typography } from '@99designs/emu/settings';
import { IconMinus2, IconPlus2 } from '@99designs/icons';

export interface Props {
    title: string;
    children: React.ReactNode;
    isOpen?: boolean;
    onToggleOpen?: (isOpen: boolean) => void;
}

const Item = styled.div`
    border-color: ${colors.grey300};
    border-style: solid;
    border-top-width: 1px;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;

    &:last-of-type {
        border-bottom-width: 1px;
    }
`;

const Trigger = styled.button`
    border: none;
    border-radius: 0;
    background: transparent;
    text-align: left;
    padding: 0;

    &:hover,
    &:focus {
        box-shadow: none;
        text-decoration: none;
        outline: none;
    }

    width: 100%;
    padding-top: ${spaces['1.5bu']};
    padding-bottom: ${spaces['1.5bu']};
    padding-left: ${spaces['0.75bu']};
    padding-right: ${spaces['3bu']};
`;

const TriggerIcon = styled.div`
    padding-left: ${spaces['1bu']};
    color: ${colors.grey500};
    font-size: ${typography.typeScale.size4}px;
`;

export const AccordionItem = ({ title, children, isOpen, onToggleOpen }: Props) => {
    const [open, setOpen] = useState(isOpen ?? false);
    const elementId = uuid();

    useEffect(() => {
        if (isOpen !== undefined) {
            setOpen(isOpen);
        }
    }, [isOpen]);

    const handleToggleOpen = () => {
        if (isOpen === undefined) {
            setOpen(!open);
        }

        onToggleOpen?.(!isOpen);
    };

    return (
        <Item data-testid="accordion-item">
            <Trigger
                onClick={handleToggleOpen}
                aria-controls={`control-${elementId}`}
                aria-expanded={open}
                id={elementId}
                className="trigger"
            >
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                >
                    {title}
                    <TriggerIcon className="trigger-icon" data-testid="trigger-icon">
                        {open ? <IconMinus2 /> : <IconPlus2 />}
                    </TriggerIcon>
                </Grid>
            </Trigger>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box paddingX="0.75bu" className="accordion-item-children">
                    <div aria-hidden={!open} id={`${elementId}`}>
                        {children}
                    </div>
                </Box>
            </Collapse>
        </Item>
    );
};
