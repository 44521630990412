/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function MarketingNewBrief(props: Props) {
    const newProps = { ...props, viewBox: '0 0 48 48' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            fill="currentColor"
            d="M15.484 35.654c-.364-1.21-.986-2.248-1.267-3.461.41.134.778.281 1.159.372.39.093.792.133 1.221.2l-1.113 2.889zm27.567-24.13c-.073-.021-.148-.034-.222-.048-.4-.073-.414-.093-.315-.497.057-.233.121-.465.176-.699.029-.123.044-.248.055-.313-.007-.652-.316-1.05-.894-1.199L19.833 3.071c-.612-.158-.747-.077-.915.538-.007.024-.01.05-.016.074L17.378 9.55a2.82 2.82 0 01.327-.174c.264-.12.527-.241.789-.363l1.165-4.499c.136-.525.135-.523.655-.388l20.799 5.409c.144.038.287.082.498.142l-8.33 32.116-16.937-4.374c.135-.164.232-.303.35-.42.421-.42.863-.82 1.27-1.253.371-.396.717-.817 1.055-1.243.185-.233.279-.508.184-.817-.092-.301-.266-.444-.582-.432-.249.01-.496.049-.791.081.066-.206.119-.369.17-.533.041-.133.094-.264.116-.4.055-.342-.115-.546-.458-.536-.239.007-.478.046-.716.074-.811.096-1.588.021-2.304-.418a2.212 2.212 0 01-.999-1.287c-.09-.302-.203-.601-.26-.91a1.52 1.52 0 01.021-.63c.699-2.73 1.408-5.456 2.115-8.184l.225-.869-.911 1.161-.565.722c-.604 2.318-1.21 4.636-1.822 6.953a3.49 3.49 0 00-.025 1.743c.508 2.196 1.187 4.339 1.973 6.448.413 1.109.701 2.271 1.357 3.286.125.194.237.343.446.42.178.065.351.145.533.197.668.19 1.337.379 2.01.554 4.867 1.266 9.734 2.529 14.601 3.794.234.061.461.128.704.02.073-.033.174-.015.262-.006.398.039.581-.071.704-.452.074-.228.132-.462.193-.694 1.503-5.785 3.011-11.567 4.505-17.354 1.103-4.269 2.188-8.543 3.278-12.816.134-.526.253-1.056.369-1.587.064-.295-.021-.426-.301-.508zM14.631 5.096c.214.56.438 1.115.659 1.672l.026-.01c.059.082.112.168.177.246.128.153.274.31.491.262.223-.049.294-.256.354-.453.006-.018.004-.038.008-.056.084-.406-.019-.794-.145-1.169-.164-.49-.362-.968-.548-1.451a.626.626 0 00-.782-.39c-.321.099-.497.424-.41.77.049.195.099.392.171.58z"
        />,
        <path
            fill="currentColor"
            d="M8.136 26.029c-.025.03-.053.059-.088.097a2.068 2.068 0 00-.597-.838c-.087-.074-.012-.124.03-.174l1.492-1.789c2.625-3.142 5.262-6.274 7.863-9.435.516-.628 1.079-1.21 1.594-1.836.064-.078.119-.162.212-.289.175.283.338.536.483.799.02.037-.041.132-.084.185-.772.945-1.548 1.887-2.322 2.83L8.137 26.03zm-.83.03c-.101.2-.246.219-.38.074a12.485 12.485 0 01-.374-.425c-.11-.131-.046-.302.134-.345.21-.05.359.069.481.214.125.147.191.317.138.482zm.243-3.268c3.068-3.737 6.139-7.472 9.202-11.213.258-.315.584-.268.964-.282-.125.207-.212.388-.332.543-.409.531-.828 1.055-1.247 1.579a19.82 19.82 0 00-1.466 2.057c-.208.341-.497.633-.755.943-2.316 2.776-4.634 5.549-6.947 8.328-.105.127-.183.136-.324.071-.181-.083-.378-.132-.589-.203l1.494-1.823zm10.549-12.544c.342-.172.693-.328 1.037-.495.073-.036.116-.025.186.021.237.153.483.291.729.429.083.047.111.088.08.19-.15.485-.29.973-.444 1.495-.426-.782-1.042-1.255-1.92-1.397.121-.09.217-.184.331-.241zm-2.357 9.397l2.33-2.97c1.031-1.313 2.065-2.625 3.091-3.943.2-.257.416-.52.536-.816.334-.819.535-1.681.717-2.545a.22.22 0 00-.034-.165c-.158-.197-.478-.23-.684-.079l-.218.159-.03-.02c.088-.293.172-.588.265-.879.065-.201.027-.369-.132-.505-.165-.143-.343-.165-.546-.068-.846.402-1.692.808-2.541 1.203l-.789.363a2.84 2.84 0 00-1.045.81c-3.9 4.77-7.812 9.529-11.72 14.292a.757.757 0 00-.143.229 3.24 3.24 0 00-.152 1.358c.013.118.035.236.075.345a.772.772 0 00.215.323c.319.273.66.516 1.097.572.177.023.351.07.527.106l.008.056c-.223.07-.441.164-.669.206-.233.042-.475.033-.738.048.011.081.005.187.043.275.101.236.204.475.34.691.085.135.218.254.355.339.386.239.824.243 1.261.238a.374.374 0 00.277-.125c.753-.737 1.511-1.471 2.267-2.205.455-.442.935-.863 1.359-1.333.415-.46.774-.969 1.157-1.458l2.047-2.615.565-.722.911-1.161zM12.445 7.478c.351.119.682.269 1.013.435.455.227.855-.455.399-.683a9.362 9.362 0 00-1.202-.514c-.484-.165-.69.599-.21.762z"
        />
    );
}
