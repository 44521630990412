/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconBlind1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M.368 9.803c-.674 1.032-.674 2.008.048 2.631s1.71 1.5 1.466 2.532-.722 1.442-.192 2.061.72-.049 1.54-.462c.82-.413.987-.155 2.094.723s1.467 1.238 1.419 2.115c-.048.877-.385 1.548.192 1.806s.914-.413 1.396-.929c.481-.516.651-.516 1.95-.206s2.141.309 2.237 1.393c.096 1.083-.048 1.96.674 2.115s.866-.774 1.251-1.599c.385-.825.245-1.238 1.977-1.187s1.728.052 1.969.825c.241.774.676 1.703 1.35 1.599s.529-.929.481-1.909c-.048-.98.535-.877 1.737-1.322s1.299-.634 1.969.394c.67 1.028.683 1.238 1.255 1.031s.336-.977.239-2.063c-.096-1.086-.285-.774 1.443-2.158s1.397-1.351 2.408-.577 1.082 1.002 1.468.501-.12-1.068-.361-2.1-.052-1.14.722-2.58 1.208-1.182.101-2.678-6.114 7.923-15.644 7.923S1.043 8.771.369 9.803z"
            fill="currentColor"
        />
    );
}
