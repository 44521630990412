import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { mediaQuery } from '../../css';
import { colors, spaces, typography } from '../../settings';
import { Badge } from '../Badge';
import { Box } from '../Box';
import { ClickableWithRoutingProps } from '../Clickable';
import { Link } from '../Link';
import { Text } from '../Text';

interface TabStylesBase {
    active?: boolean;
    /**
     * Injected via Tabs
     */
    navRef?: React.RefObject<HTMLElement>;
}

interface TabStylesWithBadge extends TabStylesBase {
    badge?: number;
    count?: never;
}

interface TabStylesWithCount extends TabStylesBase {
    badge?: never;
    count?: number;
}

export type TabProps = Omit<
    ClickableWithRoutingProps<TabStylesWithBadge | TabStylesWithCount>,
    'disabled'
>;

export const focusStylePadding = 6; // px

const TabContent = styled.span`
    display: inline-block;
    padding-bottom: ${spaces['0.25bu']};
    border-bottom: 2px solid;
    border-color: transparent;

    ${mediaQuery.minimum('normal')} {
        font-size: ${typography.typeScale.size4}px;
    }
`;

const TabLink = styled(Link).withConfig({
    shouldForwardProp: (prop) => !['active'].includes(prop),
})<{ active?: boolean }>`
    display: inline-block;
    color: ${colors.grey600};

    &:focus {
        ${TabContent} {
            color: ${colors.grey600};
        }
    }

    ${({ active }) =>
        active &&
        css`
            &,
            &:link,
            &:visited {
                ${TabContent} {
                    color: ${colors.grey900};
                    border-color: ${colors.grey900};
                }
            }
        `};

    &:hover {
        ${TabContent} {
            color: ${colors.grey700};
            border-color: ${colors.grey700};
        }
    }

    &:focus-visible::after {
        /* We have to reset this to 0px here as Link is set to -2px, see Link for more info */
        bottom: 0px;
    }
`;

/**
 * An individual <a> element to be used within `Tabs`.
 * Optionally renders a badge *or* count to display.
 *
 * If the active `Tab` isn't visible in the `Tabs` container, it
 * is scrolled to on mount.
 */
export function Tab({ children, navRef, ...props }: TabProps) {
    const { active } = props;
    const ref = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        if (active && navRef?.current) {
            if (ref.current?.offsetLeft && ref.current?.offsetLeft > focusStylePadding) {
                navRef?.current?.scrollTo({ left: ref.current?.offsetLeft - focusStylePadding });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TabLink
            {...props}
            ref={ref}
            weight="bold"
            underline="none"
            aria-current={active ? 'page' : undefined}
        >
            <TabContent>{children}</TabContent>
            {'count' in props && typeof props.count !== 'undefined' && (
                <Box as="span" marginLeft="0.25bu">
                    <Text color="grey700" size="size1">
                        ({props.count})
                    </Text>
                </Box>
            )}
            {'badge' in props && typeof props.badge !== 'undefined' && (
                <Box as="span" marginLeft="0.5bu" verticalAlign="text-bottom">
                    <Badge size="small" value={props.badge} />
                </Box>
            )}
        </TabLink>
    );
}
