import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Spinner } from '@99designs/emu';
import { useDeferNavComplete } from '@99designs/tracking';

const loadingFadeIn = keyframes`
    0%,
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
`;

type LoadingSize = 'small' | 'default';
interface LoadingProps {
    size?: LoadingSize;
    staticPosition?: boolean;
}

const loadingSizes: Record<LoadingSize, number> = {
    small: 50,
    default: 150,
};

const LoadingWrapper = styled.div<LoadingProps>`
    position: ${({ staticPosition = false }) => (staticPosition ? 'static' : 'sticky')};
    top: 0;
    min-height: ${({ size = 'default' }) => loadingSizes[size]}px;

    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
    align-items: center;
    animation: ${loadingFadeIn} 1s;
`;

export function Loading(props: LoadingProps) {
    useDeferNavComplete();

    return (
        <LoadingWrapper {...props} data-testid="loading">
            <Spinner size="large" />
        </LoadingWrapper>
    );
}
