import { Dialog, useDialogStore } from '@ariakit/react';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Box } from '../Box';
import { Card } from '../Card';
import { Backdrop, CloseButton } from '../Dialog';

const OverlayCard = styled(Card).attrs(() => ({ appearance: 'elevated' }))<{
    children: React.ReactNode;
}>`
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    flex: 1;
    position: relative;

    &:focus {
        outline: none;
    }
`;

export interface OverlayProps {
    'aria-label': string;
    children: React.ReactNode;
    /**
     * Callback fired when the overlay is attempting to be closed.
     */
    onClose: () => void;
    /**
     * Dictates if the overlay is shown.
     */
    visible: boolean;
}

/**
 * An accessible modal-like component that follows the WAI-ARIA Dialog (Modal) pattern.
 *
 * Renders a near viewport sized container for housing content.
 */
export function Overlay({ 'aria-label': ariaLabel, children, onClose, visible }: OverlayProps) {
    const dialogRef = useRef<HTMLDivElement>(null);
    const dialogProps = useDialogStore({
        open: visible,
        setOpen(open) {
            if (!open) {
                onClose();
            }
        },
    });

    useEffect(() => {
        if (visible) {
            if (dialogRef?.current) {
                dialogRef.current.focus({
                    preventScroll: true,
                });
            }
        }
    }, [visible]);

    return (
        <Dialog
            ref={dialogRef}
            render={(props) => (
                <Backdrop visible={visible} paddingTop="3bu">
                    <Box display="flex" minHeight="100%" width="100%">
                        <OverlayCard {...props}>{props.children}</OverlayCard>
                    </Box>
                </Backdrop>
            )}
            aria-label={ariaLabel}
            store={dialogProps}
            backdrop={false}
            autoFocusOnShow={false}
        >
            {children}
            <CloseButton store={dialogProps} />
        </Dialog>
    );
}
