/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconLargeBullet1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M7.626 7.514c6.065-6.273 13.941-2.813 16.899.13s5.996 10.811.131 16.708c-5.865 5.897-13.642 3.645-17.048.244s-6.047-10.809.018-17.082z"
            fill="currentColor"
        />
    );
}
