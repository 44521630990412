/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconGplus1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M2.599.307C3.974.382 27.305-.107 28.926.022s2.802 1.154 2.86 2.982c.058 1.828.366 23.37.123 25.443s-1.304 3.075-3.403 3.029c-2.099-.046-21.426.429-24.102.521S.788 29.938.802 28.73C.817 27.521.059 5.585.003 3.992S1.225.231 2.6.307zm9.537 14.988v2.618h4.331c-.175 1.124-1.309 3.295-4.331 3.295-2.607 0-4.735-2.16-4.735-4.822s2.127-4.822 4.735-4.822c1.484 0 2.476.633 3.044 1.178l2.073-1.996c-1.331-1.244-3.055-1.996-5.116-1.996-4.222 0-7.636 3.415-7.636 7.636s3.415 7.636 7.636 7.636c4.407 0 7.331-3.098 7.331-7.462 0-.502-.055-.884-.12-1.265h-7.211zm16.364 0h-2.182v-2.182h-2.182v2.182h-2.182v2.182h2.182v2.182h2.182v-2.182H28.5v-2.182z"
            fill="currentColor"
        />
    );
}
