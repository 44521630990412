/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconAdmin1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M14.767-.079c-1.722 0-2.05-.469-2.952 1.799S6.731 14.628 6.157 15.801c-.574 1.173-.492 1.721.656 1.643s4.752-.156 5.576-.156c.823 0 1.807-.548.987.782S7.472 29.257 6.98 30.352c-.492 1.095-.492 2.196 1.558.469S24.441 13.762 25.429 12.51c.987-1.252-.078-1.255-1.718-1.333s-5.166-.232-6.314-.082c-1.148.151-.41-.788.902-2.274s5.49-6.806 6.396-7.745c.905-.939.823-1.486-1.718-1.33s-6.488.174-8.21.174z"
            fill="currentColor"
        />
    );
}
