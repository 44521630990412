import React from 'react';
import { UIProvider } from '@99designs/emu';
import { formatMessage } from '@99designs/frontend-components/i18n';
import MicrofrontendBugsnagClient from '../MicrofrontendBugsnagClient';

/**
 * Common functionality required in all microfrontends, including Bugsnag and React contexts.
 * Feel free to add more as necessary.
 */
export function MicrofrontendWrapper({ children }: { children: React.ReactNode }) {
    return (
        <MicrofrontendBugsnagClient>
            <UIProvider
                translationStrings={{
                    Alert: {
                        Dismiss: __('Dismiss'),
                    },
                    ButtonGroup: {
                        Menu: __('More options'),
                    },
                    Modal: {
                        Close: __('Close'),
                    },
                    Rating: {
                        Legend: __('Rating'),
                        ReadOnlyLabel: (value: number) =>
                            formatMessage(__('{value} out of 5 stars'), {
                                value,
                            }),
                        Value: (value: number) =>
                            value === 1
                                ? __('1 star')
                                : formatMessage(__('{value} stars'), {
                                      value,
                                  }),
                    },
                    Select: {
                        ClearAll: __('Clear all'),
                        Open: __('Open'),
                        Remove: __('Remove'),
                    },
                    Pagination: {
                        Next: __('Next'),
                        Previous: __('Previous'),
                    },
                }}
            >
                {children}
            </UIProvider>
        </MicrofrontendBugsnagClient>
    );
}
