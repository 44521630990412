/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconUser1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M4.693 32.514C2.986 32.514 2 32.439 2 30.777s1.16-4.258 3.09-4.735c1.261-.312 2.653-.557 4.169-1.149s1.738-1.181 1.738-1.51c0-.95-.073-1.4-1.718-2.378s-1.373-3.602-1.373-4.046c0-.443-1.95-.904-1.95-2.541s1.204-1.263 1.204-2.247-.841-1.469-.841-3.276 1.23-3.979 2.385-4.407c1.155-.428.626.695.777-.926.005-.054-.493.871.028-.155S13.951 2.279 17.05.906c3.205-1.42 3.981 1.431 4.818 2.069s-.484-.042 1.421 1.085c1.906 1.127 1.254 1.819 1.254 3.724s-1.036 4.767-1.036 4.767 1.312.527 1.036 2.245-1.479 1.534-1.479 2.391c0 .521-.286 2.79-1.298 3.452s-1.473 1.359-1.473 2.186c0 2.106.514 1.624 1.473 2.068s3.312.707 5.036 1.149c2.313.593 3.197 5.857 3.197 5.961 0 .354-3.131.511-4.385.511H4.692z"
            fill="currentColor"
        />
    );
}
