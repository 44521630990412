/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconVisible1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M15.991 27.846c9.35 0 14.687-8.076 15.348-9.153s.661-2.096-.047-2.746c-.708-.65-1.677-1.565-1.438-2.642s.708-1.505.189-2.151c-.519-.646-.706.052-1.511.482s-.969.161-2.055-.754c-1.086-.915-1.44-1.292-1.392-2.207s.378-1.615-.189-1.884c-.567-.269-.897.431-1.369.969s-.638.538-1.913.215c-1.275-.323-2.101-.323-2.195-1.453s.047-2.046-.661-2.207c-.708-.162-.85.808-1.228 1.669s-.24 1.292-1.94 1.238c-1.7-.054-1.696-.054-1.932-.861s-.663-1.777-1.324-1.669c-.661.108-.519.969-.472 1.992s-.525.915-1.704 1.38c-1.18.465-1.274.662-1.932-.411s-.67-1.292-1.231-1.076c-.561.215-.329 1.02-.235 2.153s.28.808-1.415 2.252c-1.696 1.444-1.371 1.41-2.362.602s-1.062-1.045-1.44-.523c-.378.523.118 1.115.354 2.192s.052 1.19-.708 2.692c-.76 1.502-1.185 1.233-.099 2.795s5.555 9.108 14.905 9.108zm-.028-16.894c3.684-.163 7.141 2.046 7.141 6.134s-2.607 7.649-7.019 7.644c-4.411-.005-7.16-3.279-7.162-7.495s3.356-6.12 7.04-6.283zm-.332 1.958c2.71-.185 3.367.569 2.879.731s-2.872.323-2.709 2.207c.163 1.884 1.463 2.315 2.493 2.315s1.571-1.131 1.734-1.615c.163-.485.704-.269.65.969s-1.03 4.899-4.443 4.684c-3.414-.215-4.768-2.315-4.877-4.2s1.563-4.907 4.273-5.092z"
            fill="currentColor"
        />
    );
}
