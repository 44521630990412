import styled from 'styled-components';
import { mediaQuery } from '@99designs/emu/css';

export const ItemList = styled.div`
    display: table;

    ${mediaQuery.only('small')} {
        display: block;
    }
`;
