/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconDuplicate1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M17.64.101c-.921.06-8.151-.183-8.151-.183S8.231.247 8.231 1.631v23.652s1.038 1.259 2.577 1.259c1.538 0 16.994-.256 16.994-.256s1.655.136 1.655-1.506V12.503s.305-1.203-.987-1.203h-8.448s-1.58.126-1.58-1.825V1.438S18.561.04 17.639.1zm3.839 4.839v2.416s0 1.03.843 1.03h6.406s.45-.461-.337-1.092-6.35-6.435-6.35-6.435-.618-.517-.618 1.322.056 2.758.056 2.758zM3.006 2.708s-.768.551-.768 1.2v25.981s.48 2.012 1.605 2.012h20.344s1.591.181 1.591-.611l.002-1.974s.148-.503-.916-.503H7.486s-2.311 0-2.311-1.223V3.614s-.258-.904-.75-.904H3.006z"
            fill="currentColor"
        />
    );
}
