import { useCallback, useState } from 'react';

export interface ToggleFunctions {
    on: () => void;
    off: () => void;
    toggle: () => void;
}

export function useToggle(defaultToggle: boolean): [boolean, ToggleFunctions] {
    const [isOn, setToggle] = useState(defaultToggle);

    const toggle = useCallback(() => setToggle((status) => !status), []);
    const on = useCallback(() => setToggle(true), []);
    const off = useCallback(() => setToggle(false), []);

    return [isOn, { on, off, toggle }];
}
