/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconView1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M14.77 10.824c-2.3.632-3.68 3.371-3.581 5.139.109 1.948 1.475 4.119 4.917 4.341s4.425-3.562 4.48-4.842c.016-.382-.021-.67-.085-.872a4.125 4.125 0 00-5.73-3.766z"
            fill="currentColor"
        />,
        <path
            d="M31.452 14.661c.722.674.722 1.731.048 2.848s-6.112 9.492-15.641 9.492c-8.957 0-13.519-6.915-14.954-9.09-.092-.139-.17-.258-.237-.355-.859-1.257-.79-1.37-.354-2.086.126-.207.282-.463.455-.812.774-1.558 4.989-9.592 15.235-9.657 8.828-.055 13.376 6.657 14.927 8.946.249.367.421.621.521.714zm-15.491 8.257c4.827.006 7.679-3.676 7.679-7.902s-3.783-6.509-7.813-6.341c-4.031.169-7.705 2.136-7.702 6.495s3.01 7.743 7.837 7.748z"
            fill="currentColor"
        />
    );
}
