/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconRss1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M2.599.307C3.974.382 27.305-.107 28.926.022s2.802 1.154 2.86 2.982c.058 1.828.366 23.37.123 25.443s-1.304 3.075-3.403 3.029c-2.099-.046-21.426.429-24.102.521S.788 29.938.802 28.73C.817 27.521.059 5.585.003 3.992S1.225.231 2.6.307zm9.299 22.615c0-.642-.227-1.189-.681-1.643s-1.002-.681-1.643-.681c-.642 0-1.189.227-1.643.681s-.681 1.002-.681 1.643c0 .642.227 1.189.681 1.643s1.002.681 1.643.681c.642 0 1.189-.227 1.643-.681s.681-1.002.681-1.643zm6.374 1.707c-.157-2.808-1.229-5.205-3.214-7.191s-4.382-3.057-7.191-3.214c-.169-.012-.315.042-.436.163s-.182.26-.182.418v2.324c0 .157.051.291.154.399s.233.169.39.182c1.864.133 3.462.866 4.794 2.197s2.064 2.929 2.197 4.794a.576.576 0 00.581.544h2.324c.157 0 .297-.061.418-.182s.176-.266.163-.436zm6.972.018c-.061-1.864-.399-3.665-1.017-5.402s-1.462-3.311-2.533-4.721c-1.071-1.41-2.312-2.651-3.722-3.722s-2.984-1.916-4.721-2.533a17.78 17.78 0 00-5.402-1.017.488.488 0 00-.418.163.574.574 0 00-.182.418v2.324c0 .157.054.291.163.399s.242.169.399.182c2.469.085 4.757.76 6.864 2.025s3.792 2.951 5.057 5.057a14.057 14.057 0 012.025 6.864c.012.157.073.291.182.399s.242.163.399.163h2.324a.576.576 0 00.418-.182.428.428 0 00.163-.418z"
            fill="currentColor"
        />
    );
}
