/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconUp4(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M4.872 18.381c-.329.103-3.029-2.671-2.919-3.085.094-.355 10.255-10.158 13.401-12.938.521-.461.503-.467.815-.467s.373-.075.74.255c2.703 2.425 12.834 12.586 13.027 13.042.219.517-2.366 2.878-2.805 2.982-.416.098-6.389-6.221-8.695-8.468-.033 5.757-.115 18.554-.115 19.411 0 1.069-.514.692-2.506.757s-1.864-.252-1.864-.566c0-.265.091-14.701.12-19.561-1.317 1.26-8.891 8.54-9.2 8.637z"
            fill="currentColor"
        />
    );
}
