import React, { useContext, useRef, useState } from 'react';
import { ApolloError } from '@apollo/client';
// eslint-disable-next-line no-restricted-imports
import { Popper } from '@material-ui/core';
import styled from 'styled-components';
import {
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    Heading,
    IconButton,
    Input,
    Stack,
    useFloatingAlert,
} from '@99designs/emu';
import { spaces } from '@99designs/emu/settings';
import ClickAwayListener from '@99designs/frontend-components/components/ClickAwayListener';
import { formatMessage } from '@99designs/frontend-components/i18n';
import { DismissJobReasonInput, DismissJobReasonType } from '@99designs/graph-utils/types';
import { IconTrash2, IconX1 } from '@99designs/icons';
import { Partner, PartnerContext } from '../Briefs';
import { PopperCard } from '../PopperCard';
import { useDismissJobMutation } from './DismissJobButton.generated';

interface DismissJobButtonProps {
    jobId: string;
    disabled?: boolean;
    iconOnly?: boolean;
    onCompleted?: () => void;
    onError?: (error: ApolloError) => void;
}

function buildDismissOptions(partner?: Partner | null) {
    const dismissOptionsMap: Record<DismissJobReasonType, string> = {
        LOW_BUDGET: __('Out of scope for budget'),
        REQUIREMENTS_UNCLEAR: __('Requirements are unclear'),
        TOO_BUSY: __('Not available right now'),
        INCOMPATIBLE_CONTENT: __("I don't work in this industry or subject matter"),
        INCOMPATIBLE_STYLE: __('Not my expertise or style'),
        UNSUPPORTED_FEATURE: formatMessage(__("{platform} doesn't support requested features"), {
            platform: partner ?? 'platform',
        }),
        OTHER: __('Other'),
    };

    //Convert DISMISS_OPTIONS_MAP to array but exclude "other" as this is a separate field
    let dismissOptions = Object.entries(dismissOptionsMap)
        .map(([value, label]) => ({ value, label }))
        .filter(({ value }) => value !== 'OTHER');

    if (!partner) {
        dismissOptions = dismissOptions.filter(({ value }) => value !== 'UNSUPPORTED_FEATURE');
    }

    return dismissOptions;
}

const noop = () => {
    // do nothing
};

const DismissButtonWrapper = styled.span`
    position: absolute;
    top: ${spaces['1bu']};
    right: ${spaces['1bu']};
`;

const SubmitButtonWrapper = styled(Box)`
    column-gap: ${spaces['0.5bu']};
`;

export function DismissJobButton({
    jobId,
    disabled = false,
    iconOnly = false,
    onCompleted = noop,
    onError = noop,
}: DismissJobButtonProps) {
    const [popupOpen, setPopupOpen] = useState(false);
    const [options, setOptions] = useState<DismissJobReasonType[]>([]);
    const [showOther, setShowOther] = useState(false);
    const [other, setOther] = useState('');
    const [focus, setFocus] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const dismissJobPopupRef = useRef<HTMLDivElement>(null);
    const [arrow, setArrow] = useState<HTMLSpanElement | null>(null);

    const floatingAlert = useFloatingAlert();
    const { partner } = useContext(PartnerContext);
    const dismissOptions = buildDismissOptions(partner);

    function getReasons(): DismissJobReasonInput[] {
        const dismissJobReasons = options.map(
            (value): DismissJobReasonInput => ({ reason: value })
        );
        if (other !== '') {
            dismissJobReasons.push({ reason: 'OTHER', comment: other });
        }
        return dismissJobReasons;
    }

    const [dismissJob, { loading }] = useDismissJobMutation({
        async onCompleted() {
            await onCompleted();
            setPopupOpen(false);
            floatingAlert({
                type: 'success',
                message: __("Done. We won't show you this job anymore."),
            });
        },
        async onError(error: ApolloError) {
            await onError(error);
            setPopupOpen(false);
            floatingAlert({
                type: 'error',
                message: __('Sorry, something went wrong. Please refresh the page and try again.'),
            });
        },
    });

    const handleToggle = () => setPopupOpen((prevPopupOpen) => !prevPopupOpen);

    const handleClose = () => setPopupOpen(false);

    const handleArrowRef = (node: HTMLSpanElement | null) => setArrow(node);

    async function handleConfirm() {
        await dismissJob({
            variables: { input: { id: jobId, reasons: getReasons() } },
        });
    }

    async function handleSkip() {
        await dismissJob({ variables: { input: { id: jobId, reasons: [] } } });
    }

    return (
        <>
            {iconOnly && (
                <IconButton
                    data-testid="dismiss-job-button"
                    ref={buttonRef}
                    onClick={handleToggle}
                    appearance="secondary"
                    disabled={disabled}
                    aria-label={__('Not interested')}
                    type="submit"
                    icon={IconTrash2}
                />
            )}
            {!iconOnly && (
                <Button
                    data-testid="dismiss-job-button"
                    ref={buttonRef}
                    onClick={handleToggle}
                    appearance="secondary"
                    disabled={disabled}
                    type="submit"
                >
                    {__('Not interested')}
                </Button>
            )}
            <Popper
                open={popupOpen}
                anchorEl={buttonRef.current}
                modifiers={{
                    flip: { enabled: true },
                    offset: { enabled: true, offset: '0, 10px' },
                    arrow: { enabled: true, element: arrow },
                }}
                style={{ zIndex: 99 }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <PopperCard triggerEl={buttonRef} arrowRef={handleArrowRef}>
                        <Box
                            maxWidth="520px"
                            ref={dismissJobPopupRef}
                            padding="2bu"
                            position="relative"
                        >
                            <DismissButtonWrapper>
                                <IconButton
                                    appearance="transparent"
                                    aria-label={__('Dismiss')}
                                    noTooltip={true}
                                    icon={IconX1}
                                    onClick={handleToggle}
                                    size="medium"
                                />
                            </DismissButtonWrapper>
                            <form>
                                <Stack space="1bu">
                                    <Heading accessibilityLevel={4} fontFamily="heading">
                                        {__(
                                            "Please let us know why you're not interested in this project"
                                        )}
                                    </Heading>
                                    <CheckboxGroup
                                        id="dismiss-options"
                                        label=""
                                        name="dismiss-options"
                                        options={dismissOptions}
                                        value={options}
                                        onChange={(items) => {
                                            setOptions(items as DismissJobReasonType[]);
                                        }}
                                    />
                                    <Checkbox
                                        id="dismiss-option-other"
                                        label={__('Other...')}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setShowOther(e.target.checked);
                                            setFocus(!focus);
                                        }}
                                    />

                                    {showOther && (
                                        <Input
                                            label={__(
                                                'Help us understand why this isn’t a good fit'
                                            )}
                                            type="text"
                                            id="other"
                                            value={other}
                                            name="other"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setOther(e.target.value)
                                            }
                                            autoFocus={focus}
                                        />
                                    )}

                                    <SubmitButtonWrapper
                                        display="flex"
                                        justifyContent="flex-start"
                                        paddingTop="1bu"
                                        width={['100%', '240px']}
                                    >
                                        <Button
                                            data-testid="confirm-dismiss-button"
                                            type="button"
                                            loading={loading}
                                            disabled={options.length <= 0 && !showOther}
                                            onClick={handleConfirm}
                                        >
                                            {__('Confirm')}
                                        </Button>
                                        <Button
                                            appearance="secondary"
                                            type="button"
                                            disabled={loading}
                                            onClick={handleSkip}
                                        >
                                            {__('Skip')}
                                        </Button>
                                    </SubmitButtonWrapper>
                                </Stack>
                            </form>
                        </Box>
                    </PopperCard>
                </ClickAwayListener>
            </Popper>
        </>
    );
}
