/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconStatistics1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M3.972 31.665c1.108.06 1.385.175 2.14-.059s.71-1.652.71-2.057-.264-15.253-.173-16.495-.821-1.579-1.354-1.637c-.533-.058-1.423-.236-2.046-.118s-1.364 1.102-1.185 2.093c.179.991.226 15.284.316 16.272s.484 1.941 1.592 2.001zm7.682.341c-1.246-.052-1.134-1.375-1.134-2.346s-.001-22.744.162-23.891c.163-1.147-.175-1.35.671-1.619s2.654-.324 3.051 0c.397.324.282 1.216.282 2.025s.125 22.251.045 23.155c-.08.904-.05 2.715-.878 2.64s-.953.087-2.199.036zm7.484-.085c.59.094 1.798.09 2.28.041s.632-.242.709-.913c.077-.671.254-14.407.133-14.853s-.508-.924-.909-.924-1.538.049-1.978 0-1.119-.055-1.119 1.036.085 13.721.085 14.308.209 1.21.799 1.304zm7.735-.068c.59.18 1.798.172 2.28.079s.632-.462.709-1.744c.077-1.282.254-27.524.133-28.376S29.487.048 29.086.048s-1.538.093-1.978 0-1.119-.105-1.119 1.979.085 26.214.085 27.335.209 2.312.799 2.492z"
            fill="currentColor"
        />
    );
}
