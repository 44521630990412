import styled, { css } from 'styled-components';
import { breakpoints, colors } from '@99designs/emu/settings';
import {
    BaseFullscreenFrameProps,
    fullscreenFrameSidebarWidth,
    fullscreenTransition,
} from './BaseFullscreenFrameProps';

type FullscreenFrameSidebarProps = Omit<BaseFullscreenFrameProps, 'scrollable'>;

const FullscreenFrameSidebar = styled.div<FullscreenFrameSidebarProps>`
    position: relative;
    background: ${colors.white};
    border-top: 1px solid ${colors.typography.contentBorder};

    ${({ isAnimatable = false }) =>
        isAnimatable &&
        css`
            transition: transform ${fullscreenTransition};
        `};

    @media (min-width: ${breakpoints.normal[0]}px) and (max-width: ${breakpoints.normal[1]}px),
        (min-width: ${breakpoints.large[0]}px) {
        position: absolute;
        top: 0;
        right: 0;
        width: ${fullscreenFrameSidebarWidth};
        height: 100%;
        border-top: none;
        box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);

        ${({ isSidebarHidden = false }) =>
            isSidebarHidden &&
            css`
                box-shadow: none;
                transform: translateX(100%);
            `};
    }

    ${({ isSidebarHidden = false }) =>
        isSidebarHidden &&
        css`
            box-shadow: none;
        `};
`;
export default FullscreenFrameSidebar;
