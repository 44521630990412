import styled from 'styled-components';

export type UnstyledButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const UnstyledButton = styled.button`
    appearance: none;
    background: none;
    border: 0;
    display: flex;
    font: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
`;
