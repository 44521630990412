import React, { useEffect, useState } from 'react';

let instanceCount = 0;
let oldDocumentBodyStyleOverflow: string;

function incrementNumberOfOverlays() {
    instanceCount++;
}
function decrementNumberOfOverlays() {
    instanceCount--;
}
function toggleFixedViewport() {
    const fixedViewportActive = instanceCount > 0;
    const body = document.querySelector('body');

    if (body) {
        if (fixedViewportActive) {
            oldDocumentBodyStyleOverflow = document.body.style.overflow;
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = oldDocumentBodyStyleOverflow;
        }
    }
}

interface FixedViewportElementProps {
    children: React.ReactNode;
}
function FixedViewportElement({ children }: FixedViewportElementProps) {
    const [scrollPosition, setScrollPosition] = useState<number | null>(null);

    useEffect(() => {
        setScrollPosition(window.pageYOffset);
        incrementNumberOfOverlays();
        toggleFixedViewport();

        return () => {
            decrementNumberOfOverlays();
            toggleFixedViewport();

            if (scrollPosition) {
                window.scrollTo(0, scrollPosition);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
}
export default FixedViewportElement;
