import React from 'react';
import styled, { css } from 'styled-components';
import { IconMore1 } from '@99designs/icons';
import { useTranslations } from '../../Context';
import { DistributiveOmit } from '../../utils/helperTypes';
import { Button, ButtonComponent, ButtonProps } from '../Button';
import { IconButton, IconButtonComponent, IconButtonProps } from '../IconButton';
import { Menu, MenuItem, MenuItemProps } from '../Menu';

type ContentButtonGroupType = DistributiveOmit<ButtonProps, 'appearance' | 'children' | 'size'> & {
    children?: never;
    content: ButtonProps['children'];
    ref?: React.Ref<HTMLButtonElement> | React.Ref<HTMLAnchorElement>;
};

type IconButtonGroupType = DistributiveOmit<IconButtonProps, 'appearance' | 'size'> & {
    content?: never;
    ref?: React.Ref<HTMLButtonElement> | React.Ref<HTMLAnchorElement>;
};

type ButtonGroupType = ContentButtonGroupType | IconButtonGroupType;

export interface ButtonGroupProps {
    appearance?: ButtonProps['appearance'];
    /**
     * Array of `ButtonProps` to be spread into every child `Button`. `size` is always `medium`. `content` is used instead of `children`.
     */
    buttons: ButtonGroupType[];
    menuItems?: (MenuItemProps & {
        children?: never;
        content: MenuItemProps['children'];
    })[];
}

// We use a custom space as our lowest spacing token is too large
// This also means we can't use the `Inline` component
const SPACE = '1px';

const Container = styled.div.attrs<{ className?: string }>({ className: 'emu' })`
    display: inline-flex;
    flex-wrap: none;
    margin-top: -${SPACE};
    margin-left: -${SPACE};
`;

function isIconOnlyButton(props: ButtonGroupType): props is IconButtonGroupType {
    return !('content' in props);
}

export function ButtonGroup({ appearance, buttons, menuItems }: ButtonGroupProps) {
    const translations = useTranslations();
    const hasMenuItems = !!menuItems?.length;
    const buttonLength = buttons.length + (hasMenuItems ? 1 : 0);

    return (
        <Container>
            {buttons.map((buttonProps, index) => {
                let position: PositionStyles['position'] = 'center';
                if (index === 0) {
                    position = 'first';
                } else if (index === buttonLength - 1) {
                    position = 'last';
                }

                const props = {
                    appearance,
                    position,
                };

                if (isIconOnlyButton(buttonProps)) {
                    return (
                        <IconButtonWithPosition
                            key={index}
                            {...props}
                            {...buttonProps}
                            size="medium"
                        />
                    );
                }

                return (
                    <ButtonWithPosition
                        key={index}
                        {...props}
                        {...buttonProps}
                        size="medium"
                        children={buttonProps.content}
                    />
                );
            })}
            {hasMenuItems && (
                <Menu
                    disclosure={
                        <IconButtonWithPosition
                            aria-label={translations.ButtonGroup.Menu}
                            appearance={appearance}
                            size="medium"
                            position="last"
                            icon={IconMore1}
                            noTooltip
                        />
                    }
                >
                    {menuItems.map(({ content, ...menuButtonProps }, index) => (
                        <MenuItem key={index} {...menuButtonProps}>
                            {content}
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </Container>
    );
}

interface PositionStyles {
    appearance: ButtonGroupProps['appearance'] | 'transparent';
    position: 'first' | 'center' | 'last';
}

function positionStyles(props: PositionStyles) {
    const { appearance, position } = props;

    const positionStyles: Record<typeof position, ReturnType<typeof css>> = {
        first: css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        `,
        center: css`
            border-radius: 0;
        `,
        last: css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        `,
    };

    return css`
        margin-top: ${SPACE};
        margin-left: ${SPACE};
        ${positionStyles[position]};
        /* Remove spacing on tertiary appearance */
        ${appearance === 'tertiary' &&
        position !== 'last' &&
        css`
            border-right: none;
            margin-right: -${SPACE};
        `}
    `;
}

const ButtonWithPosition = styled<ButtonComponent<PositionStyles>>(Button)(positionStyles);
const IconButtonWithPosition =
    styled<IconButtonComponent<PositionStyles>>(IconButton)(positionStyles);
