import { StandardLinkGenerator } from '@99designs/ninedown/dist/LinkGenerator';
import basicLinkify from '@99designs/ninedown/dist/Linkify';
import format from '@99designs/ninedown/dist/Format';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import sanitize from '@99designs/ninedown/dist/Sanitize';

export interface FormatAndLinkifyProps {
    rawHtml: string; // content to be parsed
    className?: string; // class name added to generated content container block
    appendElement?: JSX.Element; // element appended to content, internal to container block
    linkReplaceRegexp?: RegExp; // regex to match for additional link parsing
    linkReplaceCallback?: LinkReplaceFunc; // callback to use for additional link parsing matched by linkReplaceRegexp
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LinkReplaceFunc = (match: string, ...args: any[]) => string;

export const FormatAndLinkify = (props: FormatAndLinkifyProps) => {
    const { rawHtml, appendElement = null, linkReplaceRegexp, linkReplaceCallback } = props;

    let appendString = '';
    if (appendElement) {
        appendString = ReactDOMServer.renderToStaticMarkup(appendElement);
    }

    const createMarkUp = (input: string) => {
        // FIXME this doesn't work for SSR, because you can't use window on the server
        const formattedInput = fomatInput(input, appendString, window.location.hostname);

        if (linkReplaceRegexp && linkReplaceCallback) {
            return {
                __html: linkify(formattedInput, linkReplaceRegexp, linkReplaceCallback),
            };
        }
        return { __html: formattedInput };
    };

    return <div className={props.className} dangerouslySetInnerHTML={createMarkUp(rawHtml)} />;
};

// NB: appendString will not be sanitized in order to render html tags
const fomatInput = (input: string, appendString: string, domain: string): string => {
    return basicLinkify(format(sanitize(input) + appendString), StandardLinkGenerator(domain));
};

// copied from 99designs/ninedown Linkify
// TODO make this method generic in 99designs/ninedown and expose it?
const chunkDelim = /(<.+?>)/i;
const ignoreTags = /<\/?\s*(head|link|a|script|style|code|pre|select|textarea|button)([^>]*)\s*>/i;
const closeTags = /^<\/\s*([^>\s]*)\s*>$/i;

const linkify = (input: string, regexp: RegExp, linkReplaceCallBack: LinkReplaceFunc): string => {
    let out = '';
    let openTag = '';
    const chunks = input.split(chunkDelim);

    chunks.forEach((chunk, index) => {
        if (index % 2 === 0) {
            const match = chunk.match(regexp);
            out +=
                openTag !== '' || match == null
                    ? chunk
                    : chunk.replace(regexp, linkReplaceCallBack);
        } else {
            let tag = chunk.match(ignoreTags);
            if (tag !== null) {
                if (openTag === '') {
                    openTag = tag[2].trim() === '/' ? '' : tag[1];
                } else {
                    tag = chunk.match(closeTags);
                    if (tag != null) {
                        openTag = openTag === tag[1].trim() ? '' : openTag;
                    }
                }
            }
            out += chunk;
        }
    });
    return out;
};
