import styled, { css } from 'styled-components';
import { Box } from '../Box';

export const Backdrop = styled(Box)<{ visible: boolean }>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(118deg, rgba(74, 9, 0, 0.6) 0%, rgba(2, 0, 77, 0.6) 100%);
    overflow-y: auto;
    z-index: 999;
    ${({ visible }) =>
        visible
            ? css`
                  display: block;
              `
            : css`
                  display: none;
              `}
`;
