/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconDesigns1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M4.142 6.121c.159 4.554.621 17.932.611 18.782-.012.987.765 2.744 2.978 2.669s18.196-.463 19.932-.426c.25.005.483-.007.698-.037-.129 1.461-.921 2.241-2.518 2.372-1.731.142-17.585 2.178-19.778 2.482s-3.152-1.364-3.244-2.347C2.729 28.633.21 10.873.025 9.584s.68-3.16 1.817-3.216c.213-.011 1.058-.103 2.3-.246zM7.686.251C8.823.313 28.117-.087 29.457.018s2.317.943 2.365 2.437c.048 1.494.303 19.094.102 20.788s-1.078 2.512-2.814 2.475c-1.736-.038-17.719.351-19.932.426S6.187 24.462 6.2 23.475c.012-.987-.614-18.91-.661-20.211S6.55.191 7.687.253zm3.041 4.653c.027.375.395 5.539.402 5.824s.25.784.89.741c.64-.043 5.321-.431 5.824-.438s.814-.135.854-.626c.041-.491-.338-5.561-.367-5.991s-.321-.662-.71-.68c-.389-.017-5.97.346-6.3.339s-.62.456-.593.831zM24.89 9.45c-2.247.161-3.886 1.073-3.763 4.019s1.779 3.889 4.136 3.889 3.891-1.089 3.753-3.774-1.88-4.295-4.126-4.135zm-9.065 4.667c-.307 0-.41.049-.614.396s-3.124 5.9-3.226 6.198c-.102.298-.362.786.254.736s7.334.26 7.743.26c.409 0 .821-.294.463-.688s-4.312-6.903-4.619-6.903z"
            fill="currentColor"
        />
    );
}
