import ClickAwayListener from '@99designs/frontend-components/components/ClickAwayListener';
// eslint-disable-next-line no-restricted-imports
import Fade from '@material-ui/core/Fade';
// eslint-disable-next-line no-restricted-imports
import Popper from '@material-ui/core/Popper';
import clsx from 'clsx';
import { PopperCard } from '../../PopperCard';
import React, { SyntheticEvent, useRef, useState } from 'react';

export interface StyledSelectProps {
    label: React.ReactNode;
    menu: ({ onClose }: { onClose: (event: SyntheticEvent) => void }) => React.ReactNode;
}

export function StyledSelect({ label, menu }: StyledSelectProps) {
    const triggerEl = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);
    const [arrow, setArrow] = useState<HTMLSpanElement | null>(null);

    const handleToggle = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpen(!open);
    };

    const handleClose = (event: SyntheticEvent): void => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (triggerEl.current && triggerEl.current.contains(event.target as any)) {
            return;
        }

        setOpen(false);
    };

    const handleArrowRef = (node: HTMLSpanElement | null) => {
        setArrow(node);
    };

    return (
        <div
            className={clsx(
                'styled-select',
                'styled-select--detached-dropdown',
                'styled-select--fluid'
            )}
        >
            <button
                ref={triggerEl}
                aria-haspopup="true"
                aria-owns="styled-select"
                onClick={handleToggle}
                className="styled-select__trigger"
            >
                <div className="styled-select__trigger__inner">
                    <div className="styled-select__trigger__label">
                        <span className="styled-select__trigger__label__text">{label}</span>
                    </div>
                </div>
            </button>
            <Popper
                open={open}
                anchorEl={triggerEl.current}
                transition
                placement="bottom"
                id="styled-select"
                modifiers={{
                    flip: { enabled: false },
                    offset: { enabled: true, offset: '0, 10px' },
                    arrow: { enabled: true, element: arrow },
                }}
                style={{ zIndex: 99 }}
            >
                {({ TransitionProps }) => {
                    return (
                        <ClickAwayListener onClickAway={handleClose}>
                            <Fade {...TransitionProps}>
                                <PopperCard triggerEl={triggerEl} arrowRef={handleArrowRef}>
                                    {menu({ onClose: handleClose })}
                                </PopperCard>
                            </Fade>
                        </ClickAwayListener>
                    );
                }}
            </Popper>
        </div>
    );
}
