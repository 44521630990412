import {
    Tooltip as BaseTooltip,
    TooltipArrow as BaseTooltipArrow,
    TooltipAnchor,
    TooltipStoreProps,
    useTooltipStore,
} from '@ariakit/react';
import React from 'react';
import styled from 'styled-components';
import { borders, boxShadows, colors, spaces, typography } from '../../settings';

export interface TooltipProps extends TooltipStoreProps {
    children: React.ReactElement & React.RefAttributes<HTMLDivElement>;
    /**
     * Amount in ms before showing the tooltip. The hide is unaffected.
     */
    delay?: number;
    /**
     * Short, concise, non-critical, non-interactive information.
     * Although a `ReactNode` is accepted, the content should effectively be a short string.
     */
    title: React.ReactNode;
}

const Arrow = styled(BaseTooltipArrow)`
    background-color: transparent;
    line-height: normal;

    svg {
        vertical-align: baseline;
    }
    .stroke {
        fill: transparent;
    }
    .fill {
        fill: ${colors.grey900};
    }
`;

const Tip = styled(BaseTooltip).attrs<{ className?: string }>({ className: 'emu' })`
    background-color: ${colors.grey900};
    /* Adding a border solves weird gaps with the tooltip arrow */
    border: 1px solid ${colors.grey900};
    box-shadow: ${boxShadows.low};
    border-radius: ${borders.borderRadiuses.medium}px;
    color: ${colors.white};
    font-size: ${typography.typeScale.size2}px;
    padding: 6px ${spaces['0.75bu']} ${spaces['0.5bu']};
    max-width: 240px;
    z-index: 99;
`;

/**
 * Adds a tooltip to display additional information when the root element is hovered/focused
 * `children` is cloned with additional aria props and a ref to properly tie it
 * to the tooltip.
 * Requires `children` to accept a `ref`
 */
export function Tooltip({ children, delay, title, ...props }: TooltipProps) {
    const tooltip = useTooltipStore({ ...props, hideTimeout: 0, showTimeout: delay || 0 });

    return (
        <>
            <TooltipAnchor
                store={tooltip}
                ref={children.ref}
                render={(referenceProps) => React.cloneElement(children, referenceProps)}
            />
            <Tip store={tooltip}>
                <Arrow store={tooltip} />
                {title}
            </Tip>
        </>
    );
}
