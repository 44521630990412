/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconUndo1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M1.463 21.821C2.464 24.58 5.904 32 16.581 32s16.1-8.757 15.351-16.554C31.183 7.649 24.939 0 16.697 0S5.208 4.515 5.208 4.515 1.212.377.587 1.129-.287 11.914.462 11.914s10.561.752 10.561 0-3.318-4.389-3.318-4.389S11.329 4.08 16 4.08s10.936 2.818 12.185 11.471c1.249 8.653-6.667 12.797-11.912 12.797S6.71 25.015 5.207 21.086c-1.503-3.928-4.745-2.024-3.745.735z"
            fill="currentColor"
        />
    );
}
