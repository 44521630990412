/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconShareEmail1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M2.599.307C3.974.382 27.305-.107 28.926.022s2.802 1.154 2.86 2.982c.058 1.828.366 23.37.123 25.443s-1.303 3.075-3.403 3.029c-2.099-.046-21.427.429-24.102.521S.788 29.938.802 28.73C.817 27.521.059 5.585.003 3.992S1.225.231 2.6.307zm6 9.248c-.698.029-1.217.069-.775.566s6.923 5.752 7.341 6.207c.417.456.884.994 1.793.079s6.651-5.494 7.143-5.959c.492-.465.791-1.014-.282-.943s-14.523.02-15.22.049zm-.943 2.252c-.368-.373-.53-.45-.628.291s.093 7.612.059 8.125c-.034.513-.007.416.553-.032s3.757-3.455 4.147-3.943c.39-.489 1.081-.624.19-1.202s-3.953-2.866-4.321-3.239zm6.304 5.241c-.334-.364-.566-.703-.991-.221s-4.355 4.168-4.685 4.571c-.33.402-.635.846.029.808s15.09.025 15.711.011c.621-.014.948-.278.233-.706s-3.766-3.546-4.227-3.978c-.46-.432-.911-1.418-1.476-.694s-1.671 1.31-1.992 1.679c-.321.37-.569.48-1.138-.084s-1.131-1.022-1.464-1.387zm6.797-2.009c-.689.519-.888.711-.384 1.119s3.85 3.637 4.217 4.01c.368.373.925 1.248.91.513s.011-8.385-.025-8.777c-.036-.392-.146-.386-.698.029s-3.331 2.587-4.02 3.106z"
            fill="currentColor"
        />
    );
}
