/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconGrid1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M.658.251C1.001.27 6.832.149 7.237.18s.7.285.715.737c.014.452.091 5.776.031 6.289s-.326.76-.85.749c-.525-.011-5.354.106-6.023.129s-.904-.509-.9-.808c.004-.299-.186-5.72-.2-6.114S.315.233.659.251zm0 23.925c.343.019 6.174-.102 6.579-.07s.7.285.715.737c.014.452.091 5.776.031 6.289s-.326.76-.85.749c-.525-.011-5.354.106-6.023.129s-.904-.509-.9-.808c.004-.299-.186-5.72-.2-6.114s.305-.93.649-.911zM19.35.085c-.343.019-6.174-.102-6.579-.07s-.7.285-.715.737c-.014.452-.091 5.776-.031 6.289s.326.76.85.749c.525-.011 5.354.106 6.023.129s.904-.509.9-.808c-.004-.299.186-5.72.2-6.114s-.305-.929-.649-.911zm0 24.091c-.343.019-6.174-.102-6.579-.07s-.7.285-.715.737c-.014.452-.091 5.776-.031 6.289s.326.76.85.749c.525-.011 5.354.106 6.023.129s.904-.509.9-.808c-.004-.299.186-5.72.2-6.114s-.305-.93-.649-.911zM7.356 11.948c-.343.019-6.174-.102-6.579-.07s-.7.285-.715.737c-.014.452-.091 5.776-.031 6.289s.326.76.85.749c.525-.011 5.354.106 6.023.129s.904-.509.9-.808c-.004-.299.186-5.72.2-6.114s-.305-.929-.649-.911zm5.297.166c.343.019 6.174-.102 6.579-.07s.7.285.715.737c.014.452.091 5.776.031 6.289s-.326.76-.85.749c-.525-.011-5.354.106-6.023.129s-.904-.509-.9-.808c.004-.299-.186-5.72-.2-6.114s.305-.929.649-.911zm18.413-3.965c-.341-.043-6.166-.329-6.568-.389s-.679-.333-.661-.785c.017-.452.312-5.769.408-6.275s.378-.735.901-.688c.523.048 5.349.268 6.017.292s.866.571.841.868c-.025.298-.214 5.719-.227 6.113s-.37.906-.711.863zm.279 23.784c-.343-.019-6.174.102-6.579.071s-.7-.285-.715-.737c-.014-.452-.091-5.776-.031-6.289s.326-.76.85-.749c.525.011 5.354-.106 6.023-.129s.904.509.9.808c-.004.299.186 5.72.2 6.114s-.305.93-.649.911zm0-12.144c-.343-.019-6.174.102-6.579.07s-.7-.285-.715-.737c-.014-.452-.091-5.776-.031-6.289s.326-.76.85-.749c.525.011 5.354-.106 6.023-.129s.904.509.9.807c-.004.299.186 5.72.2 6.114s-.305.93-.649.911z"
            fill="currentColor"
        />
    );
}
