import React from 'react';
import styled from 'styled-components';
import { breakpoints, typography } from '@99designs/emu/settings';

export interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
    children?: React.ReactNode;
}

/**
 * @deprecated replaced by `FormLabel` in `@99designs/emu`
 */
export const FormLabel = styled.label`
    display: inline-block;
    font-weight: ${typography.fontWeights.bold};
    font-size: ${typography.typeScale.size3}px;
    line-height: ${typography.lineHeights.h6};

    @media (min-width: ${breakpoints.small[0]}px) and (max-width: ${breakpoints.medium[1]}px) {
        font-size: ${typography.typeScale.size2}px;
    }
`;
