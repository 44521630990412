/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconNotFastTrack1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M12.338 23.694c.162 1.438.662 2.462 2.125 1.825 4.244-1.85 14.7-6.206 16.238-7.231s1.531-2.825-.769-4.106c-1.119-.625-3.244-1.819-5.744-3.169l-11.85 12.681zM19.606 8.606C18.3 7.944 17 7.306 15.768 6.737c-3.475-1.606-3.825-1.75-3.825 4.619-1.206-.344-8.369-4.281-10.394-5.3C-.07 5.587-.232 6.025-.232 9.3v15.1c0 1.281.175 2.619 2.306 1.794.675-.35 1.438-.75 2.237-1.181L19.605 8.607zM27.938.625l-26.5 28.206 2.625 2.794 26.5-28.206L27.938.625z"
            fill="currentColor"
        />
    );
}
