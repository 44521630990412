/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconFinalist1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M20.511 27.383c1.739 1.271 4.12 2.945 4.268 2.629s.139-4.803.148-6.422c.04-7.136 0-19.841 0-19.841s0-1.77-1.625-1.77c-.691 0-2.681-.376-5.325-.407-3.569-.041-7.967.26-8.562.26-1.034 0-2.329.465-2.079 2.248.138.983.041 6.833 0 13.617-.033 5.523-.304 11.857 0 12.316s3.446-2.016 4.725-2.928c1.279-.912 4.05-2.509 4.05-2.509s2.66 1.537 4.399 2.809z"
            fill="currentColor"
        />
    );
}
