/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconPin1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M14.958 30.933c-.518-.555-8.991-10.056-10.249-13.724-1.528-4.454-.755-10.905 4.007-15.112s12.329-.99 14.09.247c1.761 1.237 6.05 6.929 5.043 12.125-.842 4.349-9.879 15.82-10.358 16.437s-.708 1.093-1.186 1.093-.829-.511-1.347-1.067zm.876-13.448c2.824-.052 6.473-3.588 5.655-6.125s-1.88-6.074-6.413-5.318c-4.533.756-5.036 2.832-5.036 5.812s2.972 5.683 5.795 5.63z"
            fill="currentColor"
        />
    );
}
