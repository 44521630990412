/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconDownload1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M28.646 13.44c-.413 0-.644-.043-.644.561l.018 13.612c0 .254.075.318-.487.318s-22.578.117-23 .117c-.422 0-.606.1-.606-.602s.05-12.987.05-13.546c0-.558-.111-.589-.618-.558s-2.493.023-2.926.023c-.433 0-.433.187-.433.718L.011 31.38c0 .444.004.561.458.561l31.002.04c.379 0 .529.037.529-.527l-.022-17.41c0-.521-.065-.649-.474-.649s-2.858.046-2.858.046zm-13.141 6.837c.258.36.358.738 1.005.109s7.732-8.145 8.055-8.492c.323-.347.277-.736-.239-.989s-1.146-.994-1.463-1.268c-.318-.275-.565-.485-1.187.021s-3.028 2.997-3.373 3.29c-.345.293-.41.235-.41-.495S17.825 1.384 17.825.942c0-.442.223-.962-.429-.962h-3.093c-.423 0-.299.133-.299.869s-.133 11.141-.133 11.711c0 .57-.261.464-.641.149s-2.908-3.056-3.139-3.2c-.231-.144-.453-.242-.812.027s-1.836 1.58-2.072 1.894c-.236.314-.386.362.217.956s7.823 7.53 8.081 7.89z"
            fill="currentColor"
        />
    );
}
