import * as CSS from 'csstype';
import styled, { css, keyframes } from 'styled-components';
import { colors } from '@99designs/emu/settings';

export interface AnnotationMarkerProps {
    focused?: boolean;
    sizePx?: number;
    innerSizePx?: number;
    inline?: boolean;
}

function marker(size: number, color: CSS.Property.Color, inline: boolean) {
    return css`
        ${!inline
            ? css`
                  position: absolute;
                  margin-left: ${-1 * (size / 2)}px;
                  margin-top: ${-1 * (size / 2)}px;
              `
            : ''}
        border-radius: 999px;
        width: ${size}px;
        height: ${size}px;
        background: ${color};
        border: solid 2px white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        padding: ${size / 4}px;
    `;
}

const pulse = keyframes`
    0% {
        transform: scale3d(0.95, 0.95, 0.95);
    }

    50% {
        transform: scale3d(1.2, 1.2, 1.2);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
`;

const defaultSizePx = 20;
const defaultInnerSizePx = 10;

export const AnnotationMarker = styled.button<AnnotationMarkerProps>`
    ${({
        sizePx = defaultSizePx,
        innerSizePx = defaultInnerSizePx,
        inline = false,
        focused,
    }) => css`
        ${marker(sizePx, colors.semantic.callout, inline)}

        transition: width 0.1s, height 0.1s, margin 0.1s;
        z-index: 1;

        &:active {
            transform: scale3d(0.95, 0.95, 0.95);
        }

        &:hover {
            border-color: ${colors.sandyButt};
        }

        &:focus {
            outline: none;
        }

        &:before {
            transition: 0.2s height, 0.2s width;
            content: ' ';
            background: white;
            border-radius: 999px;
            width: ${innerSizePx}px;
            height: ${innerSizePx}px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: ${-1 * (innerSizePx / 2)}px;
            margin-top: ${-1 * (innerSizePx / 2)}px;
            visibility: hidden;
        }

        ${focused
            ? css`
                  animation-name: ${pulse};
                  animation-duration: 0.3s;

                  ${marker(sizePx + 10, colors.semantic.callout, inline)}

                  &:before {
                      visibility: visible;
                  }
              `
            : ''}
    `}
`;
