/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconInfo1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M15.886.011C24.199-.33 32 7.447 32 16.003s-5.883 16.009-15.838 15.998C6.208 31.99.005 25.138 0 16.315S7.573.353 15.886.012zm.209 13.76c-1.127 0-2.277 0-2.071 3.354s-.02 6.8.348 7.572c.368.771 3.498 1.012 3.754-.186s.099-6.458.099-7.386c0-.928.737-3.644-2.13-3.354zm-.023-6.521c-3.065-.006-3.753 4.423-.061 4.38s3.126-4.374.061-4.38z"
            fill="currentColor"
        />
    );
}
