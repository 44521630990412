import React from 'react';
import styled, { css } from 'styled-components';
import { IconStar2 } from '@99designs/icons';
import { colors } from '../../settings';
import { StarSize, starSizes } from './sizes';

export interface StarIconProps {
    size: StarSize;
}

const Wrapper = styled.span<{ size: StarIconProps['size'] }>`
    display: block;
    transition: color 150ms, transform 150ms;

    ${({ size }) => css`
        padding: ${starSizes[size].padding};
    `}
`;

const Icon = styled(IconStar2)<StarIconProps>`
    display: block;
    color: currentColor;
    stroke: ${colors.grey800};

    ${({ size }) => css`
        width: ${starSizes[size].icon};
        height: ${starSizes[size].icon};
    `}
`;

export function StarIcon({ size }: StarIconProps) {
    return (
        <Wrapper aria-hidden="true" size={size}>
            <Icon size={size} />
        </Wrapper>
    );
}
