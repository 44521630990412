import React, { ElementType } from 'react';
import { colors } from '../../settings';
import { Text } from './Text';

export interface AttributionTextProps {
    as?: ElementType<React.HTMLAttributes<HTMLElement>>;
    children: React.ReactNode;
}

/**
 * @deprecated replaced by `Attribution` and `AttributionLink` in `@99designs/emu`
 */
export function AttributionText(props: AttributionTextProps) {
    return (
        <Text
            {...props}
            color={colors.typography.attribution}
            fontFamily="attribution"
            size="size2"
            italic
        />
    );
}
