/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconServer1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M29.122 13.921c-.258-.202-.069-.09-.589-1.651s-2.347-3.855-3.482-4.334c-1.135-.48-1.612-2.142-.903-3.419s.638-1.706-1.062-1.61c-1.699.096-3.736 3.489-3.736 3.489s-5.06-.819-8.969.205c-3.91 1.024-5.613 4.461-6.659 5.837-1.653-.836-.704-2.739-.542-3.352.396-1.328-1.776-2.033-2.356-.511-.544.978-.975 2.836-.406 4.162s1.072 1.74 2.648 3.053c-.816 6.653 4.049 8.366 4.049 8.94s-.657 2.388-.599 2.769c.058.381 3.098 1.691 3.604 1.335s1.346-2.586 1.854-2.81c.508-.224 7.066.053 7.613.169s.697 2.562 1.373 2.562c.675 0 3.661-.598 3.872-1.319s-.232-1.573-.298-2.564c1.53-.754 3.912-3.87 4.183-4.157s1.576-.287 2.503-.287.93-.562.93-1.347-.172-3.106-.172-4.142c0-1.036-.269-.853-.753-.947s-1.845.131-2.103-.07zM23.36 12.39c2.048 0 1.89 2.414.079 2.498s-2.127-2.498-.079-2.498z"
            fill="currentColor"
        />
    );
}
