/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconSelect1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M31.78 3l.002-.11A2.89 2.89 0 0028.932 0h-.002C27.3-.11 4 .38 2.6.31S0 2.4 0 4s.82 23.52.8 24.73a3.31 3.31 0 003.613 3.269L4.4 32c2.68-.09 22-.57 24.1-.52s3.16-1 3.41-3S31.84 4.83 31.78 3zM13.71 23.18a.84.84 0 01-1.073.108l.003.002C12.48 23.16 6 16.85 6 16.74a13.477 13.477 0 012.265-2.155l.035-.025c.25 0 3.95 3.92 4.28 4.23s.31.72.84.23 10-10.27 10.21-10.27 2.22 1.8 2.13 2.24-11.63 11.8-12.05 12.19z"
            fill="currentColor"
        />
    );
}
