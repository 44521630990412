import React from 'react';
import styled from 'styled-components';
import { colors } from '../../settings';
import { Box } from '../Box';
import { SingleSelectProps } from '../Combobox/types';
import { FieldFocusStyling, FieldPadding, FieldPlaceholderStyling, FieldStyling } from '../Field';
import { ChevronIcon, chevronSize } from '../Form/ChevronIcon';

export type BaseSelectProps = SingleSelectProps;

// TODO - left padding doesn't line up due to native <select> element
const Select = styled.select`
    appearance: none;
    ${FieldStyling}
    cursor: pointer;
    padding-right: calc(${FieldPadding.x} + ${chevronSize});
    text-overflow: ellipsis;

    ${({ value }) => !value && FieldPlaceholderStyling}

    &:focus {
        ${FieldFocusStyling}
    }
`;

const ChevronBox = styled(Box)`
    top: 0;
    bottom: 0;
    right: ${FieldPadding.x};
    pointer-events: none;
`;

const NativeOption = styled.option<{ isPlaceholder?: boolean }>`
    ${({ isPlaceholder }) => isPlaceholder && FieldPlaceholderStyling};

    color: ${({ disabled }) => (disabled ? colors.grey400 : colors.grey800)};
`;

export const BaseSelect = React.forwardRef<HTMLSelectElement, BaseSelectProps>(function (
    { disabled, onChange, options, placeholder, value, ...rest },
    ref
) {
    function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const selectedOption = options.find((option) => option.value === e.target.value);

        if (!selectedOption) {
            return;
        }

        onChange(selectedOption);
    }

    return (
        <Box className="emu" position="relative">
            <Select
                ref={ref}
                disabled={disabled}
                onChange={handleChange}
                value={value?.value || ''}
                {...rest}
            >
                {placeholder && (
                    <NativeOption value="" disabled isPlaceholder>
                        {placeholder}
                    </NativeOption>
                )}
                {options.map(({ count, label, value, ...rest }) => (
                    <NativeOption key={label} value={value} {...rest}>
                        {label}
                        {typeof count !== 'undefined' ? ` (${count})` : undefined}
                    </NativeOption>
                ))}
            </Select>
            <ChevronBox display="flex" alignItems="center" height="100%" position="absolute">
                <ChevronIcon isDisabled={disabled} />
            </ChevronBox>
        </Box>
    );
});
