import React from 'react';
import styled, { css } from 'styled-components';
import { BehaviourReactRouterLink, Link, LinkProps, LinkWithRoutingProps } from '@99designs/emu';
import { breakpoints, colors, spaces, typography } from '@99designs/emu/settings';
import { NotificationPill } from './NotificationPill';

const StyledNav = styled.nav`
    margin: 0;
    padding: 0;
`;

const TabNavItemStyles = ({ first, last }: TabNavItemLinkProps) => css`
    /* reset <button> */
    appearance: none;
    -webkit-appearance: none;
    border: none;
    background: transparent;

    display: inline-block;
    margin: ${spaces['1bu']} 0 ${spaces['1bu']} ${spaces['2bu']};
    padding-bottom: ${spaces['0.75bu']};
    text-align: center;
    font-size: ${typography.typeScale.size4}px;
    float: left;

    @media (min-width: ${breakpoints.small[0]}px) and (max-width: ${breakpoints.small[1]}px) {
        min-width: ${spaces['3bu']};
        font-size: ${typography.typeScale.size3}px;
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        outline: none;
    }

    ${first &&
    css`
        margin-left: 0;
    `}
    ${last &&
    css`
        margin-right: 0;
    `}
`;

interface TabNavItemTitleProps {
    active?: boolean;
}

const TabNavItemTitle = styled.span<TabNavItemTitleProps>`
    border-bottom: 2px solid transparent;
    padding-bottom: 6px; /* Magic number for spacing border exactly 10px from baseline */
    font-family: ${typography.fontFamilies.heading};
    text-decoration: none;
    color: ${colors.grey800};

    ${({ active = false }) =>
        active &&
        css`
            border-color: ${colors.grey800};
        `}

    &:hover,
    &:focus {
        color: ${colors.grey800};
        border-bottom-color: #888888;
        text-decoration: none;
    }
`;

const TabNavItemNotifications = styled.span`
    margin-left: ${spaces['0.75bu']};
`;

interface TabNavProps {
    children?: React.ReactNode;
}
/**
 * @deprecated replaced by `Tabs` in `@99designs/emu`
 */
export function TabNav({ children }: TabNavProps) {
    return <StyledNav data-testid="TabNav">{children}</StyledNav>;
}

interface TabNavItemLinkProps {
    first?: boolean;
    last?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TabNavItemLink = styled(({ first, last, ...props }: TabNavItemLinkProps & LinkProps) => (
    <Link {...props} />
))<TabNavItemLinkProps>`
    ${({ first = false, last = false }: TabNavItemLinkProps) => TabNavItemStyles({ first, last })};
`;

const TabNavItemRouterLink = styled(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ first, last, ...props }: TabNavItemLinkProps & LinkWithRoutingProps) => (
        <Link behaviour={BehaviourReactRouterLink} {...props} />
    )
)<TabNavItemLinkProps>`
    ${({ first = false, last = false }: TabNavItemLinkProps) => TabNavItemStyles({ first, last })};
`;

interface TabNavItemProps extends TabNavItemLinkProps, TabNavItemTitleProps {
    path: string;
    title: string;
    count?: number;
}

type TabNavItemContentProps = Omit<TabNavItemProps, 'path'>;

function TabNavItemContent({ active, title, count = 0 }: TabNavItemContentProps) {
    return (
        <>
            <TabNavItemTitle active={active}>{title}</TabNavItemTitle>
            {count > 0 && (
                <TabNavItemNotifications>
                    <NotificationPill>{count > 99 ? '99+' : count}</NotificationPill>
                </TabNavItemNotifications>
            )}
        </>
    );
}

export function TabNavItem({ path, title, count = 0, active, first, last }: TabNavItemProps) {
    return (
        <TabNavItemLink href={path} first={first} last={last} data-testid="TabNavItem">
            <TabNavItemContent active={active} title={title} count={count} />
        </TabNavItemLink>
    );
}

export function TabNavRouterItem({ path, title, count = 0, active, first, last }: TabNavItemProps) {
    return (
        <TabNavItemRouterLink href={path} first={first} last={last} data-testid="TabNavItem">
            <TabNavItemContent active={active} title={title} count={count} />
        </TabNavItemRouterLink>
    );
}
