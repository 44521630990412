import React from 'react';
import { useTranslations } from '../../Context';
import { ChevronIcon } from '../Form/ChevronIcon';
import { UnstyledButton, UnstyledButtonProps } from '../UnstyledButton';

interface ChevronProps extends UnstyledButtonProps {
    rotate?: boolean;
}

export const Chevron = React.forwardRef<HTMLButtonElement, ChevronProps>(function (
    { disabled, rotate, ...rest },
    ref
) {
    const translations = useTranslations();

    return (
        <UnstyledButton
            ref={ref}
            aria-label={translations.Select.Open}
            disabled={disabled}
            {...rest}
        >
            <ChevronIcon isDisabled={disabled} isRotated={rotate} />
        </UnstyledButton>
    );
});
