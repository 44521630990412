/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconProfile1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 37 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M30.343 31.6c-.457-1.029-1.086-2-1.943-2.229-1.543-.4-3.6-.629-4.457-.971-.857-.4-1.314.057-1.314-1.771 0-.743.4-1.314 1.314-1.886s1.143-2.514 1.143-2.971c0-.743 1.086-.571 1.314-2.057s-.914-1.943-.914-1.943.914-2.457.914-4.114.571-2.229-1.086-3.2-.514-.4-1.257-.914c-.743-.571-1.429-3.029-4.229-1.771-2.743 1.2-6.171 1.257-6.629 2.171s0 .114 0 .114c-.114 1.429.343.457-.686.8s-2.114 2.229-2.114 3.829.743 2 .743 2.857c0 .857-1.086.514-1.086 1.943s1.714 1.829 1.714 2.229c0 .4-.229 2.629 1.2 3.486s1.486 1.257 1.486 2.057c0 .286-.171.8-1.543 1.314-1.314.514-2.571.743-3.657.971-1.2.286-2.057 1.486-2.457 2.629a12.587 12.587 0 01-1.314-1.143c-5.2-5.086-9.2-16.229 0-25.6 9.257-9.429 21.257-4.229 25.771.171s9.143 16.229.171 25.029c-.343.229-.743.629-1.086.971z"
            fill="currentColor"
        />
    );
}
