/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconSpinner1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M32 16c0-8.875-7.2-16-16.031-16C8.281 0 1.744 5.581.3 12.938c-.325 1.644.956 3.169 2.638 3.15h.019a2.658 2.658 0 002.575-2.15c.981-4.956 5.338-8.625 10.563-8.625 5.95 0 10.581 4.688 10.581 10.625 0 5.206-3.663 9.55-8.531 10.537a2.666 2.666 0 00-2.144 2.575c-.019 1.681 1.512 2.969 3.163 2.637 7.35-1.481 12.837-7.963 12.837-15.688z"
            fill="currentColor"
        />
    );
}
