export enum size {
    ExtraExtraLarge = 'extra-extra-large',
    ExtraLarge = 'extra-large',
    Large = 'large',
    Medium = 'medium',
    Small = 'small',
    ExtraSmall = 'extra-small',
}

interface AvatarSizing {
    avatarSize: number;
    indicatorSize: number;
    indicatorPositioning: number;
}

export const sizes: { [key in size]: AvatarSizing } = {
    [size.ExtraExtraLarge]: {
        avatarSize: 160,
        indicatorSize: 20,
        indicatorPositioning: 14,
    },
    [size.ExtraLarge]: {
        avatarSize: 128,
        indicatorSize: 20,
        indicatorPositioning: 9,
    },
    [size.Large]: {
        avatarSize: 96,
        indicatorSize: 17,
        indicatorPositioning: 6,
    },
    [size.Medium]: {
        avatarSize: 64,
        indicatorSize: 16,
        indicatorPositioning: 2,
    },
    [size.Small]: {
        avatarSize: 48,
        indicatorSize: 15,
        indicatorPositioning: 0,
    },
    [size.ExtraSmall]: {
        avatarSize: 32,
        indicatorSize: 8,
        indicatorPositioning: 0,
    },
};
