import React from 'react';
import { FormattedRelativeTime } from 'react-intl';
import { DateTime, Duration } from 'luxon';
import styled from 'styled-components';
import { Tooltip } from '@99designs/emu';
import { formatMessage } from '@99designs/frontend-components/i18n';
import selectValueAndUnit from '@99designs/frontend-components/i18n/selectValueAndUnit';
import { useFormatDate } from '../useFormatDate';

export interface RelativeTimeDisplayProps {
    timestamp: string;
    style?: 'default' | 'short';
}

const StyledRelativeTimeDisplay = styled.span`
    text-decoration: underline dotted currentColor;
    text-underline-offset: 0.25em;
`;

// RelativeTimeDisplay will display a given timestamp relative to the end-user, e.g. 2 hours ago.
// A tooltip around this relative time display is also output.
// An optional 'style' parameter can be provided:
//   - 'default' style uses the FormattedRelative component from react-intl
//   - 'short' style uses a custom message that drops the prefix/suffix that indicates the tense of
//     the timestamp, e.g. dropping 'ago' from '2 days ago'. This style only supports future timestamps.
// Example output (default style):
//   // Onscreen   -> Tooltip
//   "a month ago" -> "1 May 2019 11:11am"
// Example output (short style):
//   // Onscreen   -> Tooltip
//   "a day" -> "1 May 2019 11:11am"
export function RelativeTimeDisplay({ timestamp, style = 'default' }: RelativeTimeDisplayProps) {
    const formatDate = useFormatDate({
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });

    // FIXME: value is actually a delta now, need to compute it.
    const durationFromNow = DateTime.fromISO(timestamp).diffNow();

    const [value, unit] = selectValueAndUnit(durationFromNow);

    return (
        <Tooltip title={formatDate(timestamp)}>
            <time dateTime={timestamp}>
                <StyledRelativeTimeDisplay>
                    {/* eslint-disable-next-line eqeqeq */}
                    {style == 'short' ? (
                        getShortDurationMessage(durationFromNow)
                    ) : (
                        <FormattedRelativeTime
                            value={Math.round(value)}
                            unit={unit}
                            numeric="auto"
                        />
                    )}
                </StyledRelativeTimeDisplay>
            </time>
        </Tooltip>
    );
}

function getShortDurationMessage(duration: Duration): string {
    const minutes = Math.round(duration.as('minutes'));
    const hours = Math.round(duration.as('hours'));
    const days = Math.round(duration.as('days'));

    if (minutes <= 0) {
        return formatMessage(__('a few seconds'));
    }
    if (minutes <= 1) {
        return formatMessage(__('a minute'));
    }
    // eslint-disable-next-line eqeqeq
    if (minutes > 1 && hours == 0) {
        return formatMessage(__('{minutes} minutes'), { minutes: minutes });
    }
    if (hours <= 1) {
        return formatMessage(__('an hour'));
    }
    // eslint-disable-next-line eqeqeq
    if (hours > 1 && days == 0) {
        return formatMessage(__('{hours} hours'), { hours: hours });
    }
    if (days <= 1) {
        return formatMessage(__('a day'));
    }
    return formatMessage(__('{days} days'), { days: days });
}
