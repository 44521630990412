import React from 'react';
import ReactDOM from 'react-dom/client';
// eslint-disable-next-line no-restricted-imports
import { ThemeProvider } from '@material-ui/styles';
import materialTheme from '@99designs/apps/spa/materialTheme';
import AttributionPopup from '@99designs/apps/spa/microfrontend/attribution-popup/AttributionPopup';
import { MicrofrontendWrapper } from '@99designs/microfrontends';
import { trackEvent } from '@99designs/tracking';

declare global {
    interface Window {
        mountPopupCard: (attributionElements: HTMLElement[], pageType?: string) => void;
        unmountPopupCard: (attributionElements: HTMLElement[]) => void;
    }
}

window.mountPopupCard = (attributionElements: HTMLElement[], pageType?: string) => {
    attributionElements.forEach((attributionElement) => {
        const popup = attributionElement.querySelector('[data-popup]') as HTMLElement;
        const triggerElement = attributionElement.querySelector('[data-popup-trigger]');
        const designerId = attributionElement.getAttribute('data-designer-id');
        const designerName = attributionElement.getAttribute('data-designer-name');

        const onOpen = () => {
            trackEvent('Viewed Design Attribution Popup', {
                page: pageType,
                designer_id: designerId,
            });
        };

        if (!designerId || !designerName) return;

        const root = ReactDOM.createRoot(popup);
        root.render(
            <MicrofrontendWrapper>
                <ThemeProvider theme={materialTheme}>
                    <AttributionPopup
                        designerId={designerId}
                        designerName={designerName}
                        attributionElement={triggerElement}
                        onOpen={onOpen}
                    />
                </ThemeProvider>
            </MicrofrontendWrapper>
        );
    });
};

window.unmountPopupCard = (attributionElements: HTMLElement[]) => {
    attributionElements.forEach((attributionElement) => {
        const popup = ReactDOM.createRoot(
            attributionElement.querySelector('[data-popup]') as HTMLElement
        );
        if (popup) {
            popup.unmount();
        }
    });
};

export {};
