/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconHome1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M2.913 31.968s7.906-.156 8.251-.192.275-.235.204-.919c-.072-.684-.013-4.619-.063-7.284s3.029-4.022 4.52-3.957c1.491.064 4.638.488 4.792 3.345s.029 8.004.029 8.004-.235 1.019.365 1.032 7.812-.027 8.248-.081c.435-.053.435-.475.435-.876s.308-18.044.308-18.569c0-.526-13.364-12.126-13.618-12.282s-.6-.401-1.146.16C14.692.91 2.149 13.054 2.047 13.348s-.002 17.805-.002 18.148c0 .343.869.472.869.472z"
            fill="currentColor"
        />
    );
}
