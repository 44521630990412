/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconTwitter1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M31.988 5.078a13.095 13.095 0 01-3.77 1.036 6.584 6.584 0 002.886-3.632c-1.27.754-2.676 1.3-4.168 1.594a6.567 6.567 0 00-11.186 5.986 18.641 18.641 0 01-13.534-6.86 6.568 6.568 0 00-.886 3.302c0 2.278 1.52 4.286 3.282 5.464C3.536 11.934 2 11.636 2 11.144v.086c0 3.18 1.9 5.832 4.902 6.438-.55.148-1.31.23-1.908.23a7.29 7.29 0 01-1.328-.122c.836 2.61 3.216 4.508 6.088 4.562a13.254 13.254 0 01-8.176 2.808c-.532 0-1.064-.032-1.578-.09a18.587 18.587 0 0010.058 2.95c12.08 0 18.676-10.004 18.676-18.68 0-.286-.008-.57-.022-.852a13.223 13.223 0 003.276-3.396z"
            fill="currentColor"
        />
    );
}
