import React from 'react';
import { colors } from '../../settings';
import { Text } from '../Text';

export interface FormErrorProps {
    children: React.ReactNode;
    id: string;
}

/*
 * Displays an error regarding a form component.
 * Can be used internally by form components, or used directly when needed.
 * If used directly, remember to set `aria-describedby` on the linked <input>
 * for accessibility.
 */
export function FormError({ children, id }: FormErrorProps) {
    return (
        <Text role="alert" id={id} color={colors.semantic.error}>
            {children}
        </Text>
    );
}
