import React, { useContext } from 'react';

export type StaticRouterContext = {
    statusCode: number;
};

const staticRouterContext = React.createContext({ statusCode: 200 });

export const StaticRouterContextProvider = staticRouterContext.Provider;

export function useRouterContext(): StaticRouterContext {
    const staticContext = useContext(staticRouterContext);

    if (staticContext === undefined) {
        throw new Error('useStaticRouterContext must be used within a StaticRouterContextProvider');
    }

    return staticContext;
}
