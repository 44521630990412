import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { ResponsiveProp, resolveResponsiveProp } from '../../lib/responsiveProps';
import { Space, borders, boxShadows, colors, spaces } from '../../settings';

export interface CardProps {
    appearance?: 'outlined' | 'filled' | 'elevated';
    as?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
    children: React.ReactNode;
    className?: string;
    padding?: ResponsiveProp<Space>;
}

const StyledCard = styled.div.attrs<{ className?: string }>({ className: 'emu' })<{
    appearance: CardProps['appearance'];
    padding: Exclude<CardProps['padding'], undefined>;
}>`
    border-radius: ${borders.borderRadiuses.medium}px;
    ${({ padding }) => css`
        ${resolveResponsiveProp(padding, 'padding', spaces.sizes)}
    `}

    ${({ appearance }) => {
        switch (appearance) {
            case 'outlined':
                return css`
                    /* Use box-shadow to not change dimensions */
                    box-shadow: inset 0 0 0 1px ${colors.grey300};
                `;

            case 'filled':
                return css`
                    background-color: ${colors.grey050};
                `;

            case 'elevated':
                return css`
                    background-color: ${colors.white};
                    box-shadow: ${boxShadows.low};
                `;

            default:
                return;
        }
    }}
`;

const defaultPadding = spaces[spaces.semantic.cardPadding] as Space;

/**
 * A stylised content container.
 */
export const Card = forwardRef<HTMLDivElement, CardProps>(function (
    { appearance = 'outlined', padding = defaultPadding, ...rest },
    ref
) {
    return <StyledCard ref={ref} appearance={appearance} padding={padding} {...rest} />;
});
