/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconAt1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M15.932 8.066c-5.405 0-7.858 4.612-7.858 7.766s2.383 8.267 7.981 8.267 4.464-2.393 5.282-2.393.072 2.163 4.656 2.393c3.151 0 6.05-1.114 6.006-7.41C31.999 2.479 21.267 0 15.852 0S-.506 3.728.018 15.704C.542 27.68 8.609 32 16.075 32s8.805-3.121 7.7-4.791c-1.106-1.67-3.423 1.061-7.989.919-9.43-.312-11.675-7.725-11.675-12.201S6.719 3.728 16.323 3.728s11.692 9.852 11.692 12.874-1.229 3.468-2.021 3.468c-.792 0-2.049-.638-2.049-2.02s-.046-6.557-.046-7.508c0-.951-1.909-.06-2.63-.76S19.3 8.306 15.933 8.066zm.203 4.207c5.076 0 5.076 7.792 0 7.792s-5.076-7.792 0-7.792z"
            fill="currentColor"
        />
    );
}
