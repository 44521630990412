import * as CSS from 'csstype';
import styled, { css } from 'styled-components';
import { joinAndFilter } from '../../lib/joinAndFilter';
import { ResponsiveProp, resolveResponsiveProp } from '../../lib/responsiveProps';
import { Space, colors, spaces } from '../../settings';

type TLengthSystem = string | number | 0;

export interface BoxProps {
    // Display
    display?: ResponsiveProp<CSS.Property.Display>;

    width?: ResponsiveProp<CSS.Property.Width<string>>;
    height?: ResponsiveProp<CSS.Property.Height<string>>;
    minWidth?: ResponsiveProp<CSS.Property.MinWidth<string>>;
    minHeight?: ResponsiveProp<CSS.Property.MinHeight<string>>;
    maxWidth?: ResponsiveProp<CSS.Property.MaxWidth<string>>;
    maxHeight?: ResponsiveProp<CSS.Property.MaxHeight<string>>;

    position?: CSS.Property.Position;

    whiteSpace?: CSS.Property.WhiteSpace;

    verticalAlign?: CSS.Property.VerticalAlign;

    zIndex?: CSS.Property.ZIndex;

    // Spaces
    margin?: ResponsiveProp<Space>;
    marginX?: ResponsiveProp<Space>;
    marginY?: ResponsiveProp<Space>;
    marginBottom?: ResponsiveProp<Space>;
    marginTop?: ResponsiveProp<Space>;
    marginLeft?: ResponsiveProp<Space>;
    marginRight?: ResponsiveProp<Space>;

    padding?: ResponsiveProp<Space>;
    paddingX?: ResponsiveProp<Space>;
    paddingY?: ResponsiveProp<Space>;
    paddingBottom?: ResponsiveProp<Space>;
    paddingTop?: ResponsiveProp<Space>;
    paddingLeft?: ResponsiveProp<Space>;
    paddingRight?: ResponsiveProp<Space>;

    // Flexbox
    flexDirection?: ResponsiveProp<CSS.Property.FlexDirection>;
    flexWrap?: CSS.Property.FlexWrap;
    justifyContent?: ResponsiveProp<CSS.Property.JustifyContent>;
    alignItems?: ResponsiveProp<CSS.Property.AlignItems>;
    alignContent?: CSS.Property.AlignContent;
    order?: CSS.Property.Order;
    flex?: CSS.Property.Flex<TLengthSystem>;
    flexGrow?: CSS.Property.FlexGrow;
    flexShrink?: CSS.Property.FlexShrink;
    alignSelf?: CSS.Property.AlignSelf;

    // Color
    background?: CSS.Property.Background<string> | colors.Color;
    color?: CSS.Property.Color | colors.Color;

    // Positioning
    top?: ResponsiveProp<Space>;
    bottom?: ResponsiveProp<Space>;
    left?: ResponsiveProp<Space>;
    right?: ResponsiveProp<Space>;
}

const displayStyle = (display: ResponsiveProp<CSS.Property.Display>) =>
    css`
        ${resolveResponsiveProp(display, 'display')}
    `;

const widthStyle = (width: ResponsiveProp<CSS.Property.Width<string>>) =>
    css`
        ${resolveResponsiveProp(width, 'width')}
    `;

const heightStyle = (height: ResponsiveProp<CSS.Property.Height<string>>) =>
    css`
        ${resolveResponsiveProp(height, 'height')}
    `;

const minWidthStyle = (minWidth: ResponsiveProp<CSS.Property.MinWidth<string>>) =>
    css`
        ${resolveResponsiveProp(minWidth, 'min-width')}
    `;

const minHeightStyle = (minHeight: ResponsiveProp<CSS.Property.MinHeight<string>>) =>
    css`
        ${resolveResponsiveProp(minHeight, 'min-height')}
    `;

const maxWidthStyle = (maxWidth: ResponsiveProp<CSS.Property.MaxWidth<string>>) =>
    css`
        ${resolveResponsiveProp(maxWidth, 'max-width')}
    `;

const maxHeightStyle = (maxHeight: ResponsiveProp<CSS.Property.MaxHeight<string>>) =>
    css`
        ${resolveResponsiveProp(maxHeight, 'max-height')}
    `;

const positionStyle = (position: CSS.Property.Position) =>
    css`
        position: ${position};
    `;

const whiteSpaceStyle = (whiteSpace: CSS.Property.WhiteSpace) =>
    css`
        white-space: ${whiteSpace};
    `;

const verticalAlignStyle = (verticalAlign: CSS.Property.VerticalAlign) =>
    css`
        vertical-align: ${verticalAlign};
    `;

const zIndexStyle = (zIndex: CSS.Property.ZIndex) =>
    css`
        z-index: ${zIndex};
    `;

const flexDirectionStyle = (flexDirection: ResponsiveProp<CSS.Property.FlexDirection>) =>
    css`
        ${resolveResponsiveProp(flexDirection, 'flex-direction')}
    `;

const flexWrapStyle = (flexWrap: CSS.Property.FlexWrap) => css`
    flex-wrap: ${flexWrap};
`;

const justifyContentStyle = (justifyContent: ResponsiveProp<CSS.Property.JustifyContent>) => css`
    ${resolveResponsiveProp(justifyContent, 'justify-content')}
`;

const alignItemsStyle = (alignItems: ResponsiveProp<CSS.Property.AlignItems>) => css`
    ${resolveResponsiveProp(alignItems, 'align-items')};
`;

const alignContentStyle = (alignContent: CSS.Property.AlignContent) => css`
    align-content: ${alignContent};
`;

const orderStyle = (order: CSS.Property.Order) => css`
    order: ${order};
`;

const flexStyle = (flex: CSS.Property.Flex<TLengthSystem>) => css`
    flex: ${flex};
`;

const flexGrowStyle = (flexGrow: CSS.Property.FlexGrow) => css`
    flex-grow: ${flexGrow};
`;

const flexShrinkStyle = (flexShrink: CSS.Property.FlexShrink) => css`
    flex-shrink: ${flexShrink};
`;

const alignSelfStyle = (alignSelf: CSS.Property.AlignSelf) => css`
    align-self: ${alignSelf};
`;

const backgroundStyle = (background: CSS.Property.Background<string> | colors.Color) => css`
    background: ${colors[background as colors.Color] || background};
`;

const colorStyle = (color: CSS.Property.Color | colors.Color) => css`
    color: ${colors[color as colors.Color] || color};
`;

const topStyle = (top: ResponsiveProp<Space>) => css`
    ${resolveResponsiveProp(top, 'top', spaces.sizes)};
`;

const rightStyle = (right: ResponsiveProp<Space>) => css`
    ${resolveResponsiveProp(right, 'right', spaces.sizes)};
`;

const leftStyle = (left: ResponsiveProp<Space>) => css`
    ${resolveResponsiveProp(left, 'left', spaces.sizes)};
`;

const bottomStyle = (bottom: ResponsiveProp<Space>) => css`
    ${resolveResponsiveProp(bottom, 'bottom', spaces.sizes)};
`;

/**
 * A wrapper component for easily applying common CSS styling.
 *
 * Can also be used as an empty component to add spacing.
 */
export const Box = styled.div<BoxProps>`
    ${({ display }) => display && displayStyle(display)}

    ${({ width }) => width && widthStyle(width)}
    ${({ height }) => height && heightStyle(height)}
    ${({ minWidth }) => minWidth && minWidthStyle(minWidth)}
    ${({ minHeight }) => minHeight && minHeightStyle(minHeight)}
    ${({ maxWidth }) => maxWidth && maxWidthStyle(maxWidth)}
    ${({ maxHeight }) => maxHeight && maxHeightStyle(maxHeight)}

    ${({ position }) => position && positionStyle(position)}

    ${({ whiteSpace }) => whiteSpace && whiteSpaceStyle(whiteSpace)}

    ${({ verticalAlign }) => verticalAlign && verticalAlignStyle(verticalAlign)}

    ${({ zIndex }) => zIndex && zIndexStyle(zIndex)}

    ${({ margin, marginX, marginY, marginBottom, marginTop, marginLeft, marginRight }) => {
        const resolvedMarginTop = marginTop || marginY || margin;
        const resolvedMarginBottom = marginBottom || marginY || margin;
        const resolvedMarginLeft = marginLeft || marginX || margin;
        const resolvedMarginRight = marginRight || marginX || margin;

        return joinAndFilter(
            resolvedMarginTop &&
                resolveResponsiveProp(resolvedMarginTop, 'margin-top', spaces.sizes),
            resolvedMarginBottom &&
                resolveResponsiveProp(resolvedMarginBottom, 'margin-bottom', spaces.sizes),
            resolvedMarginLeft &&
                resolveResponsiveProp(resolvedMarginLeft, 'margin-left', spaces.sizes),
            resolvedMarginRight &&
                resolveResponsiveProp(resolvedMarginRight, 'margin-right', spaces.sizes)
        );
    }}

    ${({ padding, paddingX, paddingY, paddingBottom, paddingTop, paddingLeft, paddingRight }) => {
        const resolvedPaddingTop = paddingTop || paddingY || padding;
        const resolvedPaddingBottom = paddingBottom || paddingY || padding;
        const resolvedPaddingLeft = paddingLeft || paddingX || padding;
        const resolvedPaddingRight = paddingRight || paddingX || padding;

        return joinAndFilter(
            resolvedPaddingTop &&
                resolveResponsiveProp(resolvedPaddingTop, 'padding-top', spaces.sizes),
            resolvedPaddingBottom &&
                resolveResponsiveProp(resolvedPaddingBottom, 'padding-bottom', spaces.sizes),
            resolvedPaddingLeft &&
                resolveResponsiveProp(resolvedPaddingLeft, 'padding-left', spaces.sizes),
            resolvedPaddingRight &&
                resolveResponsiveProp(resolvedPaddingRight, 'padding-right', spaces.sizes)
        );
    }}

    ${({ flexDirection }) => flexDirection && flexDirectionStyle(flexDirection)}
    ${({ flexWrap }) => flexWrap && flexWrapStyle(flexWrap)}
    ${({ justifyContent }) => justifyContent && justifyContentStyle(justifyContent)}
    ${({ alignItems }) => alignItems && alignItemsStyle(alignItems)}
    ${({ alignContent }) => alignContent && alignContentStyle(alignContent)}
    ${({ order }) => order && orderStyle(order)}
    ${({ flex }) => flex && flexStyle(flex)}
    ${({ flexGrow }) => flexGrow != null && flexGrowStyle(flexGrow)}
    ${({ flexShrink }) => flexShrink != null && flexShrinkStyle(flexShrink)}
    ${({ alignSelf }) => alignSelf && alignSelfStyle(alignSelf)}
    ${({ background }) => background && backgroundStyle(background)}
    ${({ color }) => color && colorStyle(color)}
    ${({ top }) => top && topStyle(top)}
    ${({ right }) => right && rightStyle(right)}
    ${({ left }) => left && leftStyle(left)}
    ${({ bottom }) => bottom && bottomStyle(bottom)}
`;
