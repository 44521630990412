/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconLock1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M7.489 13.274c-.013-1.652-.021-3.036.019-4.396.066-2.263 2.842-6.914 8.268-6.851s8.197 4.274 8.328 6.537c.131 2.263.383 3.897.32 4.463a2.377 2.377 0 00-.007.084c.474.008.839.021 1.065.039 1.97.162 2.292-.283 2.292 1.812s.171 11.911.037 13.226c-.134 1.315.408 1.839-2.484 1.839s-16.484-.009-18.798 0c-2.314.009-2.458-1.038-2.603-3.134s0-9.166 0-11.261c0-2.095.428-1.964 3.32-2.357h.243zm3.057-.029c3.176-.042 7.456-.116 10.664-.138a6.874 6.874 0 01-.006-.331c0-.943.189-7.414-5.237-7.477s-5.428 4.958-5.423 7.032l.002.914zm5.408 4.594c-.776-.005-1.664.7-1.838 1.771s.261 2.984.261 4.438c0 1.454.869 1.836 1.998 1.836s1.738-.842 1.738-1.836c0-.995-.087-2.602-.08-4.132s-1.303-2.072-2.079-2.077z"
            fill="currentColor"
        />
    );
}
