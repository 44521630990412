import React, { TextareaHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { Stack } from '@99designs/emu';
import { colors } from '@99designs/emu/settings';
import { ErrorText } from '../ErrorText';

const Textarea = styled.textarea<{ error: boolean }>`
    resize: vertical;
    height: auto;
    ${({ error }) =>
        error &&
        css`
            border-color: ${colors.semantic.error};
            &:focus {
                border-color: ${colors.semantic.error};
            }
        `}
`;

export interface TextAreaFieldProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    error?: string;
}

/**
 * @deprecated replaced by `Textarea` in `@99designs/emu`
 */
export function TextareaField({ error, ...props }: TextAreaFieldProps) {
    return (
        <Stack space="0.25bu">
            <Textarea {...props} className="input-textarea" error={!!error} />
            {error && <ErrorText text={error} />}
        </Stack>
    );
}
