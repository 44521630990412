import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { useCombobox, useMultipleSelection } from 'downshift';
import styled from 'styled-components';
import { useTranslations } from '../../../Context';
import { borders, boxShadows, colors, spaces } from '../../../settings';
import { Box } from '../../Box';
import { Inline } from '../../Inline';
import { BaseInput } from '../../Input/BaseInput';
import { Tag } from '../../Tag';
import { IconButton as TagIconButton } from '../../Tag/DeleteIcon';
import { Chevron } from '../Chevron';
import { Menu } from '../Menu';
import { Option, OptionType } from '../Option';
import { MultiSelectProps } from '../types';
import { Clear } from './Clear';

const optionToString = (option?: OptionType | null) => (option ? option.label : '');

/**
 * This adds focus styling to the Tag, that is visually applied to the delete button.
 * This is unique to MultiSelect, as it allows the tag itself to be focusable, rather
 * than the button.
 */
const MultiSelectTag = styled(Tag)`
    &:focus {
        ${TagIconButton} {
            background-color: ${colors.grey400};
        }
    }
    &:focus:not(:focus-visible) {
        ${TagIconButton} {
            background-color: unset;
        }
    }
    &:focus-visible {
        ${TagIconButton} {
            box-shadow: ${boxShadows.focusRing};
            border-radius: ${borders.borderRadiuses.small}px;
            /* This is used to unset the existing :focus styles */
            background-color: unset;
        }
    }
    &:active {
        ${TagIconButton} {
            background-color: ${colors.grey300};
        }
    }
`;

export const MultiSelect = React.forwardRef<HTMLInputElement, MultiSelectProps>(function (
    {
        'aria-describedby': ariaDescribedBy,
        disabled,
        error,
        id,
        menuIsOpen,
        onChange,
        options,
        placeholder,
        value,
        name,
        ...rest
    },
    ref
) {
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, spaces.px['0.75bu']],
                },
            },
        ],
    });
    const translations = useTranslations();
    const [inputValue, setInputValue] = useState('');

    const {
        addSelectedItem,
        getDropdownProps,
        getSelectedItemProps,
        removeSelectedItem,
        selectedItems,
        setActiveIndex,
        setSelectedItems,
    } = useMultipleSelection<OptionType>({
        itemToString: optionToString,
        onSelectedItemsChange: ({ selectedItems }) => {
            if (typeof selectedItems === 'undefined') {
                return;
            }

            onChange?.(selectedItems);
        },
        selectedItems: value,
        stateReducer: (_state, { changes, type }) => {
            switch (type) {
                // When clicking on a selected item, we don't want to keep the item focused
                // By setting activeIndex to -1, downshift focus on the dropdown which opens it
                case useMultipleSelection.stateChangeTypes.SelectedItemClick:
                    return { ...changes, activeIndex: -1 };
                default:
                    return changes;
            }
        },
    });

    const getFilteredOptions = () =>
        options.filter(
            (option) =>
                selectedItems.indexOf(option) < 0 &&
                optionToString(option)
                    .toLowerCase()
                    .includes(inputValue?.toLowerCase() || '')
        );

    const {
        getComboboxProps,
        getMenuProps,
        getInputProps,
        getItemProps,
        getToggleButtonProps,
        highlightedIndex,
        isOpen,
        openMenu,
        setHighlightedIndex,
    } = useCombobox<OptionType>({
        id,
        inputId: id,
        inputValue,
        ...(typeof menuIsOpen !== 'undefined' && { isOpen: menuIsOpen }),
        items: getFilteredOptions(),
        itemToString: optionToString,
        onStateChange: ({ type, selectedItem }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    if (selectedItem) {
                        setInputValue('');
                        addSelectedItem(selectedItem);
                        setHighlightedIndex(0);
                    }
                    break;
                default:
                    break;
            }
        },
        selectedItem: null,
        stateReducer: (state, { changes, type }) => {
            switch (type) {
                // Don't select items on blur
                case useCombobox.stateChangeTypes.InputBlur:
                    setInputValue('');

                    return {
                        ...changes,
                        selectedItem: state.selectedItem,
                    };

                // Keep the menu open after selection
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        isOpen: true,
                    };

                default:
                    return changes;
            }
        },
    });

    const hasSelectedItems = !!selectedItems?.length;

    let labelProps = {};
    if ('aria-label' in rest) {
        labelProps = {
            'aria-label': rest['aria-label'],
            'aria-labelledby': null,
        };
    } else if ('aria-labelledby' in rest) {
        labelProps = {
            'aria-labelledby': rest['aria-labelledby'],
        };
    }

    return (
        <Box position="relative">
            <Box {...getComboboxProps({ ref: setReferenceElement })}>
                <BaseInput
                    {...getInputProps({
                        disabled,
                        onClick: () => {
                            if (!isOpen) {
                                openMenu();
                            }
                        },
                        onChange: (e) => {
                            setInputValue(e.currentTarget.value);
                        },
                        onFocus: () => {
                            if (!isOpen) {
                                openMenu();
                            }
                        },
                        ref,
                        ...getDropdownProps({ ref }),
                        ...labelProps,
                    })}
                    aria-describedby={ariaDescribedBy}
                    disabled={disabled}
                    error={error}
                    placeholder={!hasSelectedItems && placeholder}
                    name={name}
                    prefix={
                        hasSelectedItems && (
                            <TagContainer>
                                <Inline space="0.25bu">
                                    {selectedItems.map((selectedOption, index) => (
                                        <MultiSelectTag
                                            // This requires index to properly track items on delete
                                            key={`${id}-tag-${index}`}
                                            {...getSelectedItemProps({
                                                onFocus: () => setActiveIndex(index),
                                                selectedItem: selectedOption,
                                                index,
                                            })}
                                            appearance="filled"
                                            deleteIconProps={{
                                                onDelete: (e) => {
                                                    e.stopPropagation();
                                                    removeSelectedItem(selectedOption);
                                                },
                                                ariaLabel: translations.Select.Remove,
                                                focusable: false,
                                                size: 'small',
                                            }}
                                        >
                                            {optionToString(selectedOption)}
                                        </MultiSelectTag>
                                    ))}
                                </Inline>
                            </TagContainer>
                        )
                    }
                    suffix={
                        <Inline space="1bu" noWrap>
                            {hasSelectedItems && (
                                <Clear disabled={disabled} onClick={() => setSelectedItems([])} />
                            )}
                            <Chevron
                                {...getToggleButtonProps({ disabled })}
                                rotate={isOpen}
                                type="button"
                            />
                        </Inline>
                    }
                    wrap
                />
            </Box>
            <Menu
                style={styles['popper']}
                isOpen={isOpen}
                {...getMenuProps({ ref: setPopperElement, ...labelProps })}
                {...attributes['popper']}
            >
                {isOpen &&
                    getFilteredOptions().map((option, index) => {
                        return (
                            <Option
                                key={option.value}
                                highlighted={highlightedIndex === index}
                                {...getItemProps({
                                    disabled: option.disabled,
                                    item: option,
                                    index,
                                })}
                                {...option}
                            />
                        );
                    })}
            </Menu>
        </Box>
    );
});

const TagContainer = styled.div`
    margin: -${spaces['0.25bu']} 0;
`;
