import React, { useRef } from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import styled, { css } from 'styled-components';
import { Inline } from '../Inline';
import { Tab, focusStylePadding } from './Tab';
import { useScrollOverlay } from './useScrollOverlay';

export interface TabsProps {
    /**
     * Required due to being a landmark region
     */
    'aria-label': string;
    children: React.ReactNode;
}

const ScrollableNav = styled.nav.attrs<{ className?: string }>({ className: 'emu' })<{
    hasScrollOverlay?: boolean;
}>`
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    padding: 6px 0px;
    position: relative;

    // This negative margin is to balance the padding on the
    // first li element for the focus-visible styles
    margin-left: -${focusStylePadding}px;

    ${({ hasScrollOverlay }) =>
        hasScrollOverlay &&
        css`
            mask-image: linear-gradient(to right, #000, #000 calc(100% - 80px), transparent);
        `}
`;

const ResetUl = styled.ul`
    list-style: none;
    padding: 0;
`;

const ResetLi = styled.li`
    text-indent: 0;
    list-style-type: none;

    &:first-of-type {
        padding-left: ${focusStylePadding}px;
    }
    &:last-of-type {
        padding-right: ${focusStylePadding}px;
    }
`;

/**
 * Renders a `<nav>` containing multiple <a> elements to be used for navigation.
 * Direct children should be only `Tab` elements.
 * One `Tab` element should be `active` at a time.
 *
 * Not intended to follow the WAI-ARIA Tabs pattern.
 */
export function Tabs({ 'aria-label': ariaLabel, children }: TabsProps) {
    const navRef = useRef<HTMLElement>(null);
    const hasScrollOverlay = useScrollOverlay<HTMLElement>(navRef);
    const items = flattenChildren(children);

    if (!items.every((item) => typeof item === 'object' && item.type === Tab)) {
        console.error('All child nodes within a Tabs component must be a Tab');
    }

    return (
        <ScrollableNav ref={navRef} aria-label={ariaLabel} hasScrollOverlay={hasScrollOverlay}>
            <Inline as={{ root: ResetUl, child: ResetLi }} space="2bu" noWrap>
                {React.Children.map(items, (child) => {
                    if (React.isValidElement(child)) {
                        // TODO: this shouldn't require the use of `as`
                        return React.cloneElement(child as React.ReactElement, { navRef });
                    }

                    return;
                })}
            </Inline>
        </ScrollableNav>
    );
}
