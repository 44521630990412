import React from 'react';
import { FormattedNumber } from 'react-intl';
import { getCurrencyAsFractionalAmount } from './currencyHelpers';

interface PropsType {
    currency: string;
    value: number;
    hideDecimal?: boolean;
}

export function Currency({ value, currency, hideDecimal = false }: PropsType) {
    return (
        <FormattedNumber
            value={getCurrencyAsFractionalAmount(value, currency)}
            currency={currency}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            minimumFractionDigits={hideDecimal ? 0 : undefined}
            maximumFractionDigits={hideDecimal ? 0 : undefined}
        />
    );
}
