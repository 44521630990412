import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { VisuallyHidden } from '@99designs/emu';
import { formatMessage } from '@99designs/frontend-components/i18n';
import { IconLeft1, IconRight1 } from '@99designs/icons';
import paginate from './paginate';

export interface PaginationProps {
    currentPage: number;
    totalRecords: number;
    recordsPerPage?: number;
    maxPages?: number;
}

/**
 * @deprecated replaced by `Pagination` in `@99designs/emu`
 */
export function Pagination({
    currentPage,
    totalRecords,
    maxPages = 5,
    recordsPerPage = 10,
}: PaginationProps) {
    const paginateResult = paginate({
        currentPage,
        totalRecords,
        maxPages,
        recordsPerPage,
    });
    if (paginateResult === null) {
        return null;
    }
    const {
        totalPages,
        pageNumbers,
        startPage,
        endPage,
        hasFirstPage,
        hasSecondPage,
        hasSecondLastPage,
        hasLastPage,
    } = paginateResult;

    return (
        <div className="pagination pagination--with-half-2bu-padding" data-testid="Pagination">
            <PrevButton page={currentPage - 1} isDisabled={currentPage <= 1} />
            <span className="pagination__item pagination__summary" data-testid="PaginationSummary">
                {formatMessage(__('{currentPage} of {totalPages}'), {
                    currentPage,
                    totalPages,
                })}
            </span>

            {!hasFirstPage ? (
                <PaginationNumber
                    pageNumber={1}
                    isActive={1 === currentPage}
                    isFirstPage
                    isLastPage={false}
                />
            ) : null}
            {startPage === 3 ? (
                <PaginationNumber
                    pageNumber={2}
                    isActive={2 === currentPage}
                    isFirstPage={false}
                    isLastPage={false}
                />
            ) : !hasSecondPage ? (
                <PaginationPlaceholder />
            ) : null}
            {pageNumbers.map((n) => (
                <PaginationNumber
                    key={n}
                    pageNumber={n}
                    isActive={n === currentPage}
                    isFirstPage={n === 1}
                    isLastPage={n === totalPages}
                />
            ))}
            {endPage === totalPages - 2 ? (
                <PaginationNumber
                    pageNumber={totalPages - 1}
                    isActive={totalPages - 1 === currentPage}
                    isFirstPage={false}
                    isLastPage={false}
                />
            ) : !hasSecondLastPage ? (
                <PaginationPlaceholder />
            ) : null}
            {!hasLastPage ? (
                <PaginationNumber
                    pageNumber={totalPages}
                    isActive={totalPages === currentPage}
                    isFirstPage={false}
                    isLastPage
                />
            ) : null}

            <NextButton page={currentPage + 1} isDisabled={currentPage === totalPages} />
        </div>
    );
}

const PaginationPlaceholder = () => {
    return <span className="pagination__item pagination__placeholder">…</span>;
};

interface PaginationNumberProps {
    pageNumber: number;
    isActive: boolean;
    isFirstPage: boolean;
    isLastPage: boolean;
}
const PaginationNumber = ({
    pageNumber,
    isActive,
    isFirstPage,
    isLastPage,
}: PaginationNumberProps) => {
    return (
        <span
            className={classNames('pagination__item pagination__number', {
                'pagination__item--first': isFirstPage,
                'pagination__item--last': isLastPage,
            })}
            data-active={isActive || null}
        >
            <NavLink
                to={{ search: `?page=${pageNumber}` }}
                className="pagination__title"
                data-testid="PaginationPageLink"
            >
                {pageNumber}
            </NavLink>
        </span>
    );
};

export interface PaginationButtonBaseProps {
    isDisabled?: boolean;
    page: number;
    children?: React.ReactNode;
}
export interface PaginationButtonProps extends PaginationButtonBaseProps {
    ariaLabel: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PrevButtonProps extends PaginationButtonBaseProps {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NextButtonProps extends PaginationButtonBaseProps {}

const PaginationButton = ({ ariaLabel, isDisabled, page, children }: PaginationButtonProps) => {
    return (
        <span className="pagination__item">
            <NavLink
                className="pagination__button"
                to={isDisabled ? '#' : { search: `?page=${page}` }}
                onClick={(e) => {
                    if (isDisabled) e.preventDefault();
                    window.scrollTo(0, 0);
                }}
                data-disabled={isDisabled ? true : undefined}
                data-button-disabled={isDisabled ? true : undefined}
                aria-label={ariaLabel}
            >
                <span className="pagination__button__inner">
                    <span className="pagination__button__icon">{children}</span>
                    <VisuallyHidden>{ariaLabel}</VisuallyHidden>
                </span>
            </NavLink>
        </span>
    );
};

const PrevButton = ({ isDisabled, page }: PrevButtonProps) => {
    return (
        <PaginationButton
            page={page}
            isDisabled={isDisabled}
            ariaLabel={__('Previous (left arrow)')}
        >
            <IconLeft1 />
        </PaginationButton>
    );
};

const NextButton = ({ isDisabled, page }: NextButtonProps) => {
    return (
        <PaginationButton page={page} isDisabled={isDisabled} ariaLabel={__('Next (right arrow)')}>
            <IconRight1 />
        </PaginationButton>
    );
};
