/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconAlarm1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M5.874 27.103s-1.138 4.49.181 4.723c1.73.305 2.574-1.07 3.482-2.037 2.004 1.151 4.101 1.856 6.745 1.897 3.132.049 5.821-.818 7.973-2.28.907.963 2.197 2.031 3.204 2.031 1.394 0 1.211-3.063-.056-5.272 2.018-2.433 2.677-4.76 2.709-7.941.069-6.948-4.001-13.397-11.93-13.7-.29-.449-.487-2.175.222-2.175s1.897.094 1.897-1.156c0-1.249-.634-1.326-2.072-1.326s-2.362.136-4.059.136c-1.696 0-2.089.068-2.089 1.156s1.263 1.042 2.038 1.107c.775.065.39 2.534-.029 2.305-7.035.381-11.946 7.181-11.946 13.535 0 3.342 3.035 8.313 3.732 8.995zm10.336-9.869c.931-.822 1.884-1.826 2.758-2.452.859-.615 1.124-1.386 2.246-.217s.348 1.309-.266 2.029c-.815.956-2.31 1.96-3.5 2.904l-.103.079a.792.792 0 01-.152.249c-.185.207-.454.275-.784.236-.828.241-1.461-.091-1.604-.655-2.282-1.356-5.263-4.336-5.656-4.625-.574-.423.731-2.844 2.065-1.755.826.674 3.445 2.527 4.996 4.207zM26.891 5.786c-3.361-3.072-5.521-2.26-4.456-3.251s5.235-2.308 7.517-.207 2.648 4.747 1.14 7.606c-1.489 1.738-.841-1.076-4.202-4.148zm-21.782 0c3.361-3.072 5.521-2.26 4.456-3.251S4.33.227 2.047 2.328C-.235 4.429-.601 7.075.907 9.934c1.489 1.738.841-1.076 4.202-4.148z"
            fill="currentColor"
        />
    );
}
