import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import Bugsnag from '@bugsnag/js';

// TODO - this shouldn't be hard-coded to a specific apiKey
// Instead, we need to figure out a solution to pass in an apiKey while keeping
// the functionality consistent across various microfrontends.
// There's some difficulty in doing so, due to Bugsnag needing to initialise outside of
// React, which makes passing information more difficult.

declare const __BUGSNAG_API_KEY__: string;
declare const __APP_VERSION__: string;
declare const __BUGSNAG_RELEASE_STAGE__: string;

// We plan to tackle this as we evolve the microfrontends app
Bugsnag.start({
    apiKey: __BUGSNAG_API_KEY__ || '',
    appVersion: __APP_VERSION__,
    enabledReleaseStages: ['production'],
    releaseStage: __BUGSNAG_RELEASE_STAGE__,
    plugins: [new BugsnagPluginReact(React)],
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const BugsnagErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary();

export default function MicrofrontendBugsnagClient({ children }: { children: React.ReactNode }) {
    return <BugsnagErrorBoundary>{children}</BugsnagErrorBoundary>;
}
