import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { colors, typography } from '../../settings';

const spinnerSpin = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(720deg);
    }
`;

type SpinnerSize = 'small' | 'medium' | 'large' | 'extra-large' | 'default';

export const spinnerSizes: Record<SpinnerSize, { size: number; stroke: number }> = {
    small: {
        size: typography.typeScale.size2,
        stroke: 1,
    },
    default: {
        size: typography.typeScale.size3,
        stroke: 1,
    },
    medium: {
        size: typography.typeScale.size5,
        stroke: 2,
    },
    large: {
        size: typography.typeScale.size6,
        stroke: 2,
    },
    'extra-large': {
        size: typography.typeScale.size9,
        stroke: 3,
    },
};

export interface SpinnerProps {
    color?: colors.Color;
    inline?: boolean;
    size?: SpinnerSize;
}

function SVG(props: SpinnerProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                strokeLinecap="round"
                vectorEffect="non-scaling-stroke"
                d="M21.333 7.19a10.5 10.5 0 0 0-18.657-.018"
            />
        </svg>
    );
}

export const Spinner = styled(SVG)
    .attrs<{ className?: string }>({
        className: 'emu',
    })
    .withConfig({
        shouldForwardProp: (prop, defaultValidatorFn) =>
            !['color', 'inline', 'size'].includes(prop) && defaultValidatorFn(prop),
    })<SpinnerProps>`
    animation: ${spinnerSpin} 1.7s infinite cubic-bezier(0.65, 0.25, 0.39, 0.8);
    stroke: ${({ color = 'grey600' }) => colors.colors[color]};
    display: ${({ inline = false }) => (inline ? 'inline' : 'block')};

    ${({ size = 'default' }) => css`
        height: ${spinnerSizes[size].size}px;
        width: ${spinnerSizes[size].size}px;
        stroke-width: ${spinnerSizes[size].stroke}px;
    `}
`;
