/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconRss2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M1.001 17.021C.451 16.981 0 16.541 0 15.98v-4.103c0-.56.45-1.011 1.001-.981 5.433.26 10.326 2.562 13.929 6.174s5.914 8.505 6.174 13.929c.03.55-.42 1.001-.981 1.001H15.99c-.55 0-1.001-.45-1.041-1.001-.24-3.642-1.761-7.034-4.353-9.616A14.908 14.908 0 001 17.02zM0 5.093V.99C0 .43.46-.011 1.011-.001c8.425.27 16.03 3.802 21.613 9.386C28.197 14.958 31.74 22.573 32 30.998a.975.975 0 01-.981 1.001h-4.133c-.55 0-1.001-.45-1.021-1.001C25.355 17.51 14.478 6.643 1.01 6.113c-.55-.02-1.011-.46-1.011-1.021zM4.625 31.75a4.375 4.375 0 100-8.75 4.375 4.375 0 000 8.75z"
            fill="currentColor"
        />
    );
}
