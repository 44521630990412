import styled, { css } from 'styled-components';
import { mediaQuery } from '@99designs/emu/css';
import { spaces } from '@99designs/emu/settings';

export const FlexForm = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;

    ${mediaQuery.between('small', 'medium')} {
        align-items: flex-start;
        padding-bottom: ${spaces['1bu']};
        padding-top: ${spaces['1bu']};
    }
`;

export const FlexFormField = styled.div<{ isAfterAction?: boolean }>`
    flex-grow: 10;

    ${({ isAfterAction }) =>
        isAfterAction &&
        css`
            padding-left: ${spaces['1bu']};
        `}
`;

export const FlexFormAction = styled.div<{ isFirst?: boolean; isAlignLeft?: boolean }>`
    align-items: baseline;
    display: block;
    padding-left: ${spaces['0.75bu']};
    text-align: right;
    white-space: nowrap;
    width: auto;
    margin-bottom: ${spaces['0.5bu']};

    ${({ isFirst }) =>
        isFirst &&
        css`
            padding-left: 0;
        `}

    ${({ isAlignLeft }) =>
        isAlignLeft &&
        css`
            align-self: flex-end;
        `}
`;
