import { useEffect, useRef } from 'react';
import { trackLink } from './SegmentWrapper/trackLink';

export interface TrackEvent {
    event: string;
    meta?: Record<string, unknown>;
}

/*
 * This hook is primarily designed to be used with static trackEvent.meta.
 * However, there is an option to use this hook with dynamic trackEvent.meta data.
 * In this case, `key` should be provided to the component to avoid multiple events from firing
 * By providing the `key`, we make sure that the component is recreated and destroys previously
 * attached events (see code example below).
 *
 * For more information regarding using deps, see https://reactjs.org/docs/hooks-effect.html
 *
 * Code example:
 * const [value, setValue] = useState(1);
 * const myRef = useTrackLink({
 *   event: 'My event name',
 *   meta: { value }
 * }, [value])
 *
 * <MyLinkComponent
 *   ref={myRef}
 *   key={value}
 *   onChange={() => setValue(value + 1)}
 * />
 */
export const useTrackLink = (trackEvent?: TrackEvent, deps: unknown[] = []) => {
    const linkRef = useRef(null);
    useEffect(() => {
        if (trackEvent) {
            trackLink(linkRef.current, trackEvent.event, trackEvent.meta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
    return linkRef;
};
