import { Command as BaseClickable } from '@ariakit/react';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { CustomLinkComponentProps } from './types';

export type BehaviourReactRouterLinkProps = CustomLinkComponentProps;

export const BehaviourReactRouterLink = React.forwardRef<
    HTMLAnchorElement,
    BehaviourReactRouterLinkProps
>(function ({ href, ...props }, ref) {
    return <BaseClickable ref={ref} as={ReactRouterLink} to={href} {...props} />;
});
