/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconLinkedin3(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M30 0H2C.894 0 0 .894 0 2v28c0 1.106.894 2 2 2h28c1.106 0 2-.894 2-2V2c0-1.106-.894-2-2-2zM10 24H6V10h4v14zM8 8c-1.106 0-2-.894-2-2s.894-2 2-2 2 .894 2 2-.894 2-2 2zm18 16h-4v-6c0-1.878-.5-4-2.5-4-2.25 0-3.5 2-3.5 4v6h-3.968s.094-14 .032-14H16v3c.61-1.342 1.876-2.624 3.708-2.876C23.59 9.596 26 11.648 26 15.948V24z"
            fill="currentColor"
        />
    );
}
