import React from 'react';
import { css } from 'styled-components';
import { borders, colors, typography } from '../../settings';
import {
    FormDescription,
    FormDescriptionProps,
    FormError,
    FormErrorProps,
    FormHelperText,
    FormHelperTextProps,
    FormLabel,
} from '../Form';
import { Stack } from '../Stack';
import { getFormIDs } from './getFormIDs';
import { FieldLabelType } from './types';

export const FieldPadding = { x: '12px', y: '9px' };

export const FieldFocusStyling = css`
    border-color: ${colors.grey900};
    outline: none;
`;

export const FieldPlaceholderStyling = css<{ disabled?: boolean }>`
    color: ${({ disabled }) => (disabled ? colors.grey400 : colors.grey600)};
    opacity: 1;
`;

export const FieldStyling = css<{ disabled?: boolean; error?: boolean }>`
    background-color: ${colors.white};
    border: ${borders.textInput};
    border-radius: ${borders.borderRadiuses.medium}px;
    color: ${colors.grey800};
    line-height: 1.5;
    font-size: ${typography.typeScale.size3}px;
    padding: ${FieldPadding.y} ${FieldPadding.x};
    width: 100%;

    ${({ disabled }) =>
        disabled &&
        css`
            background-color: ${colors.grey100};
            border-color: ${colors.grey200};
            color: ${colors.grey400};
            pointer-events: none;
        `}

    ${({ error }) =>
        error &&
        css`
            border-color: ${colors.semantic.error};
        `}
`;

interface BaseFieldProps {
    /**
     * Adds additional context above the field
     */
    description?: FormDescriptionProps['children'];
    disabled?: boolean;
    /**
     * Adds error styling to the input when truthy, and displays a message underneath the input.
     * The focus outline will take priority over this styling. The error state (and string) should
     * be removed when the data inside the input is changed.
     */
    error?: FormErrorProps['children'];
    /**
     * Adds additional context below the field group. Not shown when an error is present
     */
    helperText?: FormHelperTextProps['children'];
    id: string;
    required?: boolean;
}

export type FieldProps = BaseFieldProps & FieldLabelType;

type PrivateFieldProps = BaseFieldProps & {
    children: React.ReactNode;
    label?: string;
};

export function Field({
    children,
    description,
    disabled,
    error,
    helperText,
    id,
    label,
    required,
}: PrivateFieldProps) {
    const { ariaDescribedBy, descriptionId, errorId, helperTextId, labelId, showHelperText } =
        getFormIDs({
            description,
            error,
            helperText,
            id,
        });

    return (
        <div className="emu">
            <Stack space="0.75bu">
                {label && (
                    <FormLabel id={labelId} htmlFor={id} required={required} disabled={disabled}>
                        {label}
                    </FormLabel>
                )}

                <Stack space="0.5bu">
                    {!!description && (
                        <FormDescription id={descriptionId} disabled={disabled}>
                            {description}
                        </FormDescription>
                    )}

                    {React.Children.map(children, (child) => {
                        if (React.isValidElement(child)) {
                            // TODO: this shouldn't require the use of `as`
                            return React.cloneElement(child as React.ReactElement, {
                                'aria-describedby': ariaDescribedBy,
                            });
                        }

                        return;
                    })}

                    {!!error && <FormError id={errorId}>{error}</FormError>}

                    {showHelperText && (
                        <FormHelperText id={helperTextId} disabled={disabled}>
                            {helperText}
                        </FormHelperText>
                    )}
                </Stack>
            </Stack>
        </div>
    );
}
