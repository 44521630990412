/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconSettings1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M16.143 32c2.768 0 2.832-.109 2.832-1.926s-.815-1.82 1.818-2.975 1.895-.518 2.784.091c1.119.766 1.479 2.369 3.827-.066 2.436-2.525.726-2.452-.204-3.885-.461-.709-1.175-.883-.133-3.099s1.455-1.406 2.345-1.612c1.323-.307 2.586.914 2.586-2.522s-.938-2.897-2.836-2.897c-.888 0-1.396.633-2.371-1.648s-.07-2.313.526-2.885c1.118-1.074 2.684-1.105.394-3.794s-2.797-1.852-4.286-.384c-.74.73-.819 1.769-2.981 1.027s-1.535-1.541-1.535-2.474c0-1.854.571-2.952-2.764-2.952s-2.74 1.292-2.74 2.952c0 .836.579 1.688-1.559 2.428s-1.997-.111-3.036-.742c-2.094-1.273-1.437-2.768-4.434.044s-1.265 2.755.157 3.952c.801.674 1.821.298.843 2.828s-1.361 1.685-2.41 1.685c-2.079 0-2.969-1.209-2.969 2.852s.847 2.924 2.958 2.924c1.052 0 1.528-.461 2.421 1.652s-.247 2.138-1.08 2.978c-1.661 1.674-1.431 1.761.388 3.572s2.36 1.65 3.777.5c.798-.648.761-2.03 3.386-.75s1.749 1.798 1.559 3.2c.056 1.681-.033 1.926 2.735 1.926zm4.907-21.068c1.793 1.762 3.634 6.474.079 10.004s-8.269 2.183-10.334.146c-2.064-2.037-3.665-6.472.011-10.228s8.451-1.684 10.244.078z"
            fill="currentColor"
        />
    );
}
