import React from 'react';
import { Text } from '@99designs/emu';
import { FormattedMessage } from '@99designs/frontend-components/i18n';
import { linkTo } from '@99designs/routing';
import { DisplayName } from '../DisplayName';

interface AuthorDisplayProps {
    user: {
        displayName: string;
        id: string;
    };
}

function AuthorDisplay({ user }: AuthorDisplayProps) {
    return (
        <Text fontFamily="attribution" size="size2" italic>
            <FormattedMessage
                message={__('by {user}')}
                values={{
                    user: (
                        <DisplayName
                            displayName={user.displayName}
                            profileUrl={linkTo.userProfile(user.id)}
                        />
                    ),
                }}
            />
        </Text>
    );
}

export default AuthorDisplay;
