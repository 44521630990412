/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconAgency1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M32 6H22V2c0-1.106-.894-2-2-2H2C.894 0 0 .894 0 2v28c0 1.106.894 2 2 2h30c1.106 0 2-.894 2-2V8c0-1.106-.894-2-2-2zM18 8v20H4V4h14v4zm12 20h-8V10h8v18zM6 6h4v4H6zm6 0h4v4h-4zm-6 6h4v4H6zm6 0h4v4h-4zm-6 6h4v4H6zm6 0h4v4h-4zm12-6h4v4h-4zm0 6h4v4h-4z"
            fill="currentColor"
        />
    );
}
