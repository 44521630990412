/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconPinterest1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M2.599.307C3.974.382 27.305-.107 28.926.022s2.802 1.154 2.86 2.982c.058 1.828.366 23.37.123 25.443s-1.304 3.075-3.403 3.029c-2.099-.046-21.426.429-24.102.521S.788 29.938.802 28.73C.817 27.521.059 5.585.003 3.992S1.225.231 2.6.307zm9.844 27.465c.176-.325 1.309-2.272 1.602-3.397.157-.606.807-3.078.807-3.078.422.805 1.656 1.487 2.968 1.487 3.906 0 6.72-3.592 6.72-8.055 0-4.278-3.491-7.479-7.983-7.479-5.588 0-8.556 3.751-8.556 7.836 0 1.899 1.011 4.264 2.629 5.017.245.114.376.064.433-.173.043-.18.261-1.06.36-1.469a.387.387 0 00-.09-.371c-.535-.649-.964-1.842-.964-2.955 0-2.856 2.163-5.62 5.847-5.62 3.181 0 5.409 2.168 5.409 5.268 0 3.503-1.769 5.93-4.071 5.93-1.271 0-2.223-1.051-1.918-2.34.365-1.54 1.073-3.201 1.073-4.312 0-.995-.534-1.824-1.638-1.824-1.3 0-2.343 1.344-2.343 3.145 0 1.147.387 1.922.387 1.922s-1.283 5.426-1.518 6.437c-.26 1.119-.117 2.597-.092 3.628 0 0 .037 1.303.228 1.335s.614-.763.614-.763l.098-.169z"
            fill="currentColor"
        />
    );
}
