import styled, { css, keyframes } from 'styled-components';
import { colors } from '@99designs/emu/settings';

const ddoSketchFadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;
const ddoSketchSaved = keyframes`
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

interface FullscreenHeaderItemProps {
    designDetails?: boolean;
    first?: boolean;
    last?: boolean;
    status?: boolean;
    saved?: boolean;
}

const FullscreenHeaderItem = styled.div<FullscreenHeaderItemProps>`
    ${({ designDetails = false }) =>
        designDetails &&
        css`
            white-space: nowrap;
            display: inline-block;
        `}

    display: flex;
    text-align: center;
    white-space: nowrap;

    ${({ first = false }) =>
        first &&
        css`
            width: 1%;
            text-align: left;
        `}

    ${({ last = false }) =>
        last &&
        css`
            width: 1%;
            text-align: right;
        `}

    ${({ status = false, saved = false }) =>
        status &&
        css`
            color: ${colors.grey700};
            opacity: 0;
            animation-duration: ${saved ? '5s' : '2s'};
            animation-name: ${saved ? ddoSketchSaved : ddoSketchFadeIn};
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        `}
`;
export default FullscreenHeaderItem;
