import React from 'react';
import styled, { css } from 'styled-components';
import { typography } from '@99designs/emu/settings';
import { FormatAndLinkify } from '../../FormatAndLinkify';

const Wrapper = styled.div<{ size: typography.TypeScale; lineCount: number }>(
    ({ size, lineCount }) => {
        return css`
            font-size: ${typography.typeScale[size]}px;
            overflow-y: hidden;
            height: calc(
                ${typography.typeScale[size]}px * ${typography.lineHeights.root} * ${lineCount}
            );
            line-height: ${typography.lineHeights.root};

            p {
                margin: 0 auto;
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: ${lineCount};
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }
        `;
    }
);

interface Props {
    rawHtml: string;
    size?: typography.TypeScale;
    maxLineCount?: number;
}

const TextWithClampedOverflow = ({ rawHtml, size = 'size3', maxLineCount = 2 }: Props) => {
    return (
        <Wrapper size={size} lineCount={maxLineCount}>
            <FormatAndLinkify rawHtml={rawHtml} />
        </Wrapper>
    );
};

export default TextWithClampedOverflow;
