// sourced from: https://jasonwatmore.com/post/2018/08/07/javascript-pure-pagination-logic-in-vanilla-js-typescript

interface PaginateOptions {
    currentPage: number;
    totalRecords: number;
    recordsPerPage: number;
    maxPages: number;
}
interface PaginateResult {
    currentPage: number;
    totalPages: number;
    pageNumbers: number[];
    startPage: number;
    endPage: number;

    hasFirstPage: boolean;
    hasSecondPage: boolean;
    hasSecondLastPage: boolean;
    hasLastPage: boolean;
}

function paginate({
    totalRecords,
    currentPage,
    recordsPerPage,
    maxPages,
}: PaginateOptions): PaginateResult | null {
    const totalPages = Math.max(1, Math.ceil(totalRecords / Math.max(1, recordsPerPage || 0)));
    if (totalPages === 1) {
        return null;
    }

    // ensure current page is not out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage: number;
    let endPage: number;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    const pageNumbers = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);

    const hasFirstPage = pageNumbers.includes(1);
    const hasSecondPage = pageNumbers.includes(2);
    const hasSecondLastPage = pageNumbers.includes(totalPages - 1);
    const hasLastPage = pageNumbers.includes(totalPages);

    return {
        currentPage,
        totalPages,
        pageNumbers,
        startPage,
        endPage,
        hasFirstPage,
        hasSecondPage,
        hasSecondLastPage,
        hasLastPage,
    };
}

export default paginate;
