import styled, { css } from 'styled-components';
import { breakpoints } from '@99designs/emu/settings';
import {
    BaseFullscreenFrameProps,
    fullscreenFrameSidebarWidth,
    fullscreenTransition,
} from './BaseFullscreenFrameProps';

const FullscreenFrameMain = styled.div<BaseFullscreenFrameProps>`
    position: relative;

    ${({ isAnimatable = false }) =>
        isAnimatable &&
        css`
            transition: right ${fullscreenTransition};
        `};

    @media (min-width: ${breakpoints.normal[0]}px) and (max-width: ${breakpoints.normal[1]}px),
        (min-width: ${breakpoints.large[0]}px) {
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        right: ${({ isSidebarHidden = false }) =>
            isSidebarHidden ? '0' : fullscreenFrameSidebarWidth};
        height: 100%;
    }

    ${({ scrollable = false }) =>
        scrollable &&
        css`
            overflow: auto !important;
        `}
`;
export default FullscreenFrameMain;
