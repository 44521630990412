/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconGlobe1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M15.601 0c8.998 0 16.555 7.994 16.555 16.547S25.954 32 16.573 32 .491 25.733.167 16.512 6.603 0 15.6 0zm3.934 4.176s-3.107-.308-1.151 1.146c0 0 3.472 2.321 1.617 5.84s-4.268.547-5.416 2.589c-1.149 2.042.127 3.562 1.762 3.608s4.858-.149 6.105 2.557c1.247 2.707-.046 4.57-.853 5.88s-.298 2.71 1.157 1.264c1.455-1.445 3.456-2.395 5.034-6.244.998-2.433.855-7.856 0-9.37-1.93-3.418-5.899-7.066-8.254-7.27zM5.896 9.044c-2.465 3.653-2.402 9.171-.281 13.439s7.097 5.437 8.599 5.918c1.502.481 1.762.346 1.964-.72s-.663-2.401-.663-4.492-1.944-4.769-3.538-6.214-3.489-2.88-3.874-6.64c-.385-3.761-1.383-2.664-2.207-1.29z"
            fill="currentColor"
        />
    );
}
