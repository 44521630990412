import React, { useEffect } from 'react';
import { LinkProps } from '@99designs/emu';
import { TrackedLinkProps } from '@99designs/tracking';
import { User } from '../types';
import { CircumventionWarningMessage } from './CircumventionWarningMessage';
import useTrackedTriggers from './useTrackedTriggers';

export interface CircumventionWarningProps {
    user: User;
    message: string;
    triggers: string[];
    location: string;
    trackEvent: (name: string, properties?: Record<string, unknown>) => void;
    TrackedLink: React.ComponentType<TrackedLinkProps & LinkProps>;
}

export function CircumventionWarning({
    user,
    message,
    triggers,
    location,
    trackEvent,
    TrackedLink,
}: CircumventionWarningProps) {
    const { hasNewTrigger, setTrackedTriggers } = useTrackedTriggers(triggers);

    useEffect(() => {
        if (hasNewTrigger) {
            trackEvent('Circumvention Warning Message Triggered', {
                location,
                user,
                message,
                triggers,
            });
            setTrackedTriggers(triggers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasNewTrigger]);

    return (
        <CircumventionWarningMessage
            event={{
                event: 'Clicked on circumvention article from private messages', // Note: this is used for all locations.
                meta: { user, location },
            }}
            TrackedLink={TrackedLink}
        />
    );
}
