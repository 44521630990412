/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconExperiment1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M10.271 8.428c.309.336.919-.052.979 1.172s.03 1.451.091 1.897c.061.446-.611 1.114-2.53 2.062s-5.058 4.987-4.814 8.525c.244 3.538 2.498 7.801 5.911 8.999s10.964.585 12.488.028c1.523-.557 5.85-4.932 5.576-9.417s-4.083-7.967-6.703-9.081c-1.066-.362-.945-1.059-.975-1.616s0-1.033 0-1.423c0-.39-.03-1.003.548-1.142s.579-1.087.579-1.449c0-.362-1.951-.221-5.638-.195s-5.691-.28-5.691.138c0 .418-.131 1.166.178 1.502zm3.863.444c.935.091 2.948-.051 3.624 0s.75.425.75 1.094-.241 2.808.125 3.031 5.368 2.792 6.19 5.133c.406 1.156-.326 2.492-1.345 2.401-1.044-.093-2.393-1.629-3.501-1.601-2.189.056-2.213 2.486-4.5 2.569s-2.08-2.479-4-2.563c-1.919-.084-2.719 1.813-4.5 1.719s.28-4.5 5.345-7.065c1.396-.841.899-.867.844-3.813-.091-.641.034-.997.969-.906zm-1.66-8.939c-.959.07-1.65-.07-1.573 1.403s.454 2.526.807 3.332c.354.807.726.599 3.683.561s4.45-.173 4.565-.912c.115-.739.767-2.528.767-3.335s-.152-1.123-2.378-1.193c-2.227-.07-4.912.073-5.871.143z"
            fill="currentColor"
        />
    );
}
