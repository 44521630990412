import { DialogDismiss, DialogStore } from '@ariakit/react';
import React from 'react';
import { IconX1 } from '@99designs/icons';
import { useTranslations } from '../../Context';
import { spaces } from '../../settings';
import { Bleed } from '../Bleed';
import { Box } from '../Box';
import { IconButton } from '../IconButton';

export function CloseButton({ store }: { store: DialogStore }) {
    const translations = useTranslations();

    return (
        <Box
            position="absolute"
            top={spaces.semantic.cardPadding}
            right={spaces.semantic.cardPadding}
            zIndex={999}
        >
            <Bleed spaceTop="0.5bu" spaceRight="0.5bu">
                <DialogDismiss
                    store={store}
                    render={
                        <IconButton
                            aria-label={translations.Modal.Close}
                            appearance="transparent"
                            onClick={store.hide}
                            icon={IconX1}
                            noTooltip
                        />
                    }
                />
            </Bleed>
        </Box>
    );
}
