/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type DismissJobMutationVariables = Types.Exact<{
    input: Types.DismissJobInput;
}>;

export type DismissJobMutation = {
    __typename: 'Mutation';
    dismissJob: { __typename: 'JobForDesigner'; job: { __typename: 'JobEntity'; id: string } };
};

export const DismissJobDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'DismissJob' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'DismissJobInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dismissJob' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'job' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export type DismissJobMutationFn = Apollo.MutationFunction<
    DismissJobMutation,
    DismissJobMutationVariables
>;

/**
 * __useDismissJobMutation__
 *
 * To run a mutation, you first call `useDismissJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissJobMutation, { data, loading, error }] = useDismissJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDismissJobMutation(
    baseOptions?: Apollo.MutationHookOptions<DismissJobMutation, DismissJobMutationVariables>
) {
    return Apollo.useMutation<DismissJobMutation, DismissJobMutationVariables>(
        DismissJobDocument,
        baseOptions
    );
}
export type DismissJobMutationHookResult = ReturnType<typeof useDismissJobMutation>;
export type DismissJobMutationResult = Apollo.MutationResult<DismissJobMutation>;
export type DismissJobMutationOptions = Apollo.BaseMutationOptions<
    DismissJobMutation,
    DismissJobMutationVariables
>;
