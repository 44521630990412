/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconFastTrack1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M14.464 25.516c4.243-1.848 14.701-6.205 16.234-7.232s1.534-2.823-.767-4.107c-2.301-1.283-8.89-5.004-14.164-7.443-3.474-1.607-3.822-1.75-3.822 4.62-1.204-.344-8.366-4.281-10.396-5.3C-.072 5.587-.233 6.02-.233 9.301v15.098c0 1.283.172 2.617 2.308 1.793 3.339-1.715 8.737-4.788 10.218-5.771-.02 2.377-.348 6.193 2.171 5.096z"
            fill="currentColor"
        />
    );
}
