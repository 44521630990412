import React, { Children, ReactNode } from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import styled from 'styled-components';
import { ResponsiveProp } from '../../lib/responsiveProps';
import { Box } from '../Box';
import type { Space } from '../../settings';

export interface StackProps {
    children: ReactNode;
    space: ResponsiveProp<Space>;
}

const BoxItem = styled(Box)`
    :last-child {
        padding-bottom: 0;
    }
`;

/**
 * Adds spacing between vertically placed elements.
 */
export function Stack({ children, space }: StackProps) {
    const stackItems = flattenChildren(children);

    return (
        <Box>
            {Children.map(stackItems, (child) =>
                child !== null && child !== undefined ? (
                    <BoxItem paddingBottom={space}>{child}</BoxItem>
                ) : null
            )}
        </Box>
    );
}
