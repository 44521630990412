import React from 'react';
import styled, { css } from 'styled-components';
import { IconTick1 } from '@99designs/icons';
import { colors, spaces } from '../../settings';
import { Box } from '../Box';
import { Text } from '../Text';

export interface OptionType {
    count?: number;
    disabled?: boolean;
    label: string;
    value: string;
}

interface OptionProps extends OptionType {
    selected?: boolean;
}

const BaseOption = styled(Box)<{
    disabled?: OptionProps['disabled'];
    highlighted?: boolean;
    selected?: OptionProps['selected'];
}>`
    &:first-of-type {
        margin-top: ${spaces['0.75bu']};
    }
    &:last-of-type {
        margin-bottom: ${spaces['0.75bu']};
    }

    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    padding: ${spaces['0.75bu']} ${spaces['1bu']};

    ${({ highlighted }) =>
        highlighted &&
        css`
            background-color: ${colors.grey100};
        `}
    ${({ selected }) =>
        selected &&
        css`
            background-color: ${colors.grey300};
        `}
`;

export const Option = React.forwardRef<HTMLLIElement, OptionProps>(function (
    { count, disabled, label, selected, ...rest },
    ref
) {
    return (
        <BaseOption
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref={ref as any}
            as="li"
            display="flex"
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="space-between"
            disabled={disabled}
            selected={selected}
            {...rest}
        >
            <Text color={disabled ? 'grey400' : undefined} truncate>
                {label}

                {typeof count !== 'undefined' && (
                    <Box as="span" marginLeft="0.25bu">
                        <Text color="grey700" size="size1">
                            ({count})
                        </Text>
                    </Box>
                )}
            </Text>
            {selected && (
                <Box display="flex" alignItems="center" marginLeft="0.25bu">
                    <IconTick1 color={colors.grey800} />
                </Box>
            )}
        </BaseOption>
    );
});
