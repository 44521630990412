import { FormatAndLinkify, FormatAndLinkifyProps } from './FormatAndLinkify';
import {
    HandoverMessageFormatAndLinkify,
    HandoverMessageFormatAndLinkifyProps,
} from './HandoverMessageFormatAndLinkify/HandoverMessageFormatAndLinkify';
import {
    ProjectMessageFormatAndLinkify,
    ProjectMessageFormatAndLinkifyProps,
} from './ProjectMessageFormatAndLinkify/ProjectMessageFormatAndLinkify';

type Formatters =
    | FormatAndLinkifyProps
    | HandoverMessageFormatAndLinkifyProps
    | ProjectMessageFormatAndLinkifyProps;

export function getFormatterByChatType(chatType: string): React.FunctionComponent<Formatters> {
    // eslint-disable-next-line eqeqeq
    if (chatType == 'handover') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return HandoverMessageFormatAndLinkify;
    }

    // eslint-disable-next-line eqeqeq
    if (chatType == 'projects') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return ProjectMessageFormatAndLinkify;
    }

    return FormatAndLinkify;
}
