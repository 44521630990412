import React from 'react';
import styled from 'styled-components';
import { Box, LinkProps } from '@99designs/emu';
import { colors } from '@99designs/emu/settings';
import { FormattedReactMessage } from '@99designs/frontend-components/i18n';
import { IconExclamationPoint1 } from '@99designs/icons';
import { linkTo } from '@99designs/routing';
import { TrackedLinkProps } from '@99designs/tracking';

const CircumventionWarningMessageWrapper = styled(Box)`
    width: 100%;
    color: ${colors.semantic.caution};
`;
export interface CircumventionWarningMessageProps {
    event: TrackedLinkProps['event'];
    TrackedLink: React.ComponentType<TrackedLinkProps & LinkProps>;
}

export function CircumventionWarningMessage({
    event,
    TrackedLink,
}: CircumventionWarningMessageProps) {
    return (
        <CircumventionWarningMessageWrapper
            display="flex"
            alignItems="center"
            marginBottom="0.5bu"
            data-testid="circumvention-warning"
        >
            <IconExclamationPoint1 offsetRight minWidth="1em" />
            <div>
                <FormattedReactMessage
                    message={__(
                        '<strong>Stay safe:</strong> always message and pay through 99designs. <TrackedLink>Read more</TrackedLink>.'
                    )}
                    replacements={{
                        strong: <strong />,
                        TrackedLink: (
                            <TrackedLink
                                event={event}
                                color="berlinThing"
                                href={linkTo.supportUrl('360001346686', 'articles', __LANG__)}
                                style={{ whiteSpace: 'nowrap' }}
                            />
                        ),
                    }}
                />
            </div>
        </CircumventionWarningMessageWrapper>
    );
}
