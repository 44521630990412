import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ResponsiveProp, resolveResponsiveProp } from '../../lib/responsiveProps';
import { Space, spaces } from '../../settings';

const Grid = styled.div<{
    columns: ResponsiveProp<number>;
    space: ResponsiveProp<Space>;
}>`
    display: grid;

    ${({ columns }) =>
        resolveResponsiveProp(
            columns,
            'grid-template-columns',
            (val) => `repeat(${val}, minmax(0, 1fr));`
        )}
    ${({ space }) => resolveResponsiveProp(space, 'grid-gap', spaces.sizes)}
`;

export const MatrixItem = styled.div`
    grid-column: auto;
`;

export const MatrixRow = styled.div`
    grid-column: 1 / -1;
`;

type MatrixType = 'mobile-two-column' | 'two-column' | 'default';
const MatrixMap: Record<MatrixType, ResponsiveProp<number>> = {
    'mobile-two-column': [2, 2, 3, 4],
    'two-column': [1, 2, 3, 2],
    default: [1, 2, 3, 4],
};

export const getColumns = (type: MatrixType) => {
    return MatrixMap[type];
};

export interface MatrixProps {
    children: ReactNode;
    columns?: ResponsiveProp<number>;
    space?: ResponsiveProp<Space>;
}

export function Matrix({ children, columns = MatrixMap.default, space = '1bu' }: MatrixProps) {
    return (
        <Grid columns={columns} space={space}>
            {children}
        </Grid>
    );
}
