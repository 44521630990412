import React from 'react';
import { Link, LinkProps } from '@99designs/emu';
import { TrackEvent, useTrackLink } from './useTrackLink';

export interface TrackedLinkProps {
    event: TrackEvent;
}

export function TrackedLink({ event, ...props }: TrackedLinkProps & LinkProps) {
    const linkRef = useTrackLink(event);
    return <Link ref={linkRef} {...props} />;
}
