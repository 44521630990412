/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function MarketingWebsiteRedesign(props: Props) {
    const newProps = { ...props, viewBox: '0 0 48 48' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            fill="currentColor"
            d="M41.445 10.373c-.48-2.396-1.695-3.248-4.155-3.229H16.849c-1.616 0-3.232-.06-4.841 0-3.615.176-5.715 2.4-5.734 6v9.375h.034c0 3.225-.026 6.45 0 9.675 0 1.875 1.057 3.225 2.707 3.536a14.712 14.712 0 002.668.149l-.024.001h8.194c.06 0 .165.079.165.116a42.56 42.56 0 01-.128 2.085.443.443 0 01-.263.314l-.003.001a1.22 1.22 0 00-1.079 1.36l-.001-.006v.005a1.125 1.125 0 001.231 1.12h-.004 5.917l.059.001c.666 0 1.264-.289 1.676-.749l.002-.002c1.084-1.166 1.024-2.565.829-3.997 0-.053.101-.176.157-.176h3.915a34.694 34.694 0 004.334-.15l-.141.011c3.034-.431 4.969-2.767 5.014-5.824.023-1.65.086-3.3.124-4.946.034-1.474.086-2.951.075-4.425 0-2.063-.071-4.125-.12-6.188-.034-1.376.098-2.756-.165-4.057zM25.826 38.569c0 .071-.214.158-.334.161h-4.08c-.086 0-.244-.24-.225-.341.086-.428.217-.848.345-1.271a.171.171 0 01.13-.09h.001q2.104 0 4.204.026c.041 0 .124.056.124.079-.049.476-.086.96-.165 1.436zm9.375-4.414c-.566.172-1.256-.064-1.875-.079-3.281-.064-6.563-.127-9.844-.165-2.625-.03-5.25 0-7.875-.03-1.901 0-3.803-.105-5.707-.094-1.076 0-1.395-.75-1.688-1.5a4.82 4.82 0 01-.304-1.639v-.003c-.026-2.974 0-5.944 0-8.917v-8.67c0-2.678 1.294-4.088 3.967-4.249 1.219-.071 2.449-.056 3.671-.06H35.44c1.624 0 2.25.555 2.28 2.175.056 3.45.041 6.904.079 10.354.037 2.981.124 5.963.161 8.944.026 1.631-1.189 3.45-2.741 3.934z"
        />,
        <path
            fill="currentColor"
            d="M33.851 10.357c-1.913-.034-3.825-.071-5.738-.094-2.711-.03-5.422-.026-8.137-.071-1.144-.022-2.288-.214-3.424-.184-1.673.049-3.341.266-5.014.337h-.023a2.842 2.842 0 00-2.841 2.898v-.003 13.5c0 1.939 1.151 3.09 3.094 3.09h21.904c1.822 0 3-1.196 3-3V13.225l.001-.068a2.8 2.8 0 00-2.801-2.801h-.021.001zm1.541 16.482l.002.07c0 .368-.176.694-.449.9l-.003.002a.978.978 0 01-.566.18h-2.25a.113.113 0 00.03-.052v-.001-1.534-1.44-1.71l-.023-1.125v-1.016l.001-.023a.25.25 0 00-.248-.251 6.575 6.575 0 00-.636-.03h-.041.002-4.702c-.533 0-1.065 0-1.598.026s-.994.049-1.5.067a2.679 2.679 0 01-.685-.002l.013.001c-.289-.056-.375-.034-.345.259v5.044c0 .446-.034.892-.049 1.339v.045c-2.28-.019-4.568.112-6.848.068a14.94 14.94 0 01-3.026-.547l.105.026c-.791-.18-1.17-.836-1.192-1.875-.049-2.25-.221-4.523-.124-6.776a55.78 55.78 0 01.792-6.114l-.053.343c.109-.362.274-.677.489-.951l-.005.006.03-.053c.034-.056.165-.082.251-.082h8.179v.038h8.04c1.466 0 2.936.041 4.406.071a3.61 3.61 0 011.112.068l-.024-.004a1.345 1.345 0 01.918 1.373v-.004q-.004 6.836-.004 13.665zM22.976 31.241c-.836-.034-1.095.203-1.091.81s.135 1.001.668 1.065a1.043 1.043 0 001.124-.906l.001-.005c.008-.491-.112-.941-.701-.964z"
        />,
        <path
            fill="currentColor"
            d="M17.625 20.696a.552.552 0 00.374-.66l.001.004a4.63 4.63 0 00-.175-.625l.01.032c-.259-.712-.521-1.421-.78-2.134-.375-1.091-.773-2.186-1.099-3.3a.595.595 0 00-.457-.472l-.004-.001a.68.68 0 00-.719.257l-.001.002a1.787 1.787 0 00-.244.493l-.004.013a177.005 177.005 0 00-1.076 3.645c-.18.63-.454 1.249-.352 1.931v.54c0 .244.143.375.375.322a.575.575 0 00.318-.179v-.001a2.15 2.15 0 00.332-.567l.005-.015c.082-.24.164-.545.227-.856l.009-.052a.34.34 0 01.272-.303h.002c.124-.03.236-.098.375-.128l.143-.034c.179-.055.393-.1.612-.126l.018-.002h.056c.307 0 .431.105.536.416.139.424.285.84.431 1.26.037.114.073.207.115.297l-.007-.016c.032.057.07.105.116.146h.001a.581.581 0 00.593.108l-.004.001zm-2.73-3.281c.139-.547.266-1.072.398-1.594h.082c.03.124.056.247.086.375.075.33.15.66.229.986.023.075 0 .124-.071.135zM23.704 16.672c-.375.023-.773.041-1.155.071-.041 0-.109.056-.113.09v.806c0 .097.041.139.143.142l1.523.056 1.976.064c.409 0 .818 0 1.226.019h1.211l2.884.03h.491c.105 0 .15-.067.146-.18v-.855c0-.285-.064-.337-.311-.334h-1.898c-.907 0-1.815 0-2.723.026h-.105c-1.102.015-2.197.041-3.296.064zM22.628 14.31c-.071 0-.169-.023-.172.105v.87c0 .139.067.169.184.169h.776l2.917.034h5.479c.068 0 .188-.064.188-.101v-.945c0-.214-.041-.232-.255-.229l-2.625.023h-3.649l-1.627.026zM17.929 15.457a.405.405 0 00.375-.475v-.294a.329.329 0 00-.141-.258l-.001-.001a.577.577 0 00-.72.027l.001-.001a.574.574 0 00.225 1.001l.004.001a.524.524 0 00.262-.001l-.004.001z"
        />
    );
}
