import React from 'react';
import { Box } from '@99designs/emu';

export interface InfoBoxContentProps {
    children: React.ReactNode;
}

export function InfoBoxContent({ children }: InfoBoxContentProps) {
    return <Box maxWidth="55em">{children}</Box>;
}
