import React from 'react';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import clsx from 'clsx';
import styled, { css } from 'styled-components';
import { colors } from '@99designs/emu/settings';
import { useContextAwareRef } from '@99designs/messaging';

export interface AutosizeTextAreaProps extends TextareaAutosizeProps {
    onHeightChange?: (height: number) => void;
    error?: boolean;
}

const BaseAutosizeTextArea = ({
    className,
    style,
    onFocus,
    value = '',
    minRows = 1,
    onHeightChange,
    error: _errorIgnored,
    ...htmlTextAreaElementProps
}: AutosizeTextAreaProps) => {
    const inputRef = useContextAwareRef();

    const text = value as string;
    const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        if (onFocus) onFocus(e);

        if (inputRef.current && text.length > 0) {
            inputRef.current.setSelectionRange(text.length, text.length);
        }
    };

    return (
        <TextareaAutosize
            data-testid="textarea"
            className={clsx('input-text', 'input-width--max', className)}
            ref={inputRef}
            onFocus={handleFocus}
            style={{ resize: 'vertical', ...style }}
            minRows={minRows}
            value={text}
            onHeightChange={onHeightChange}
            {...htmlTextAreaElementProps}
        />
    );
};

/**
 * @deprecated replaced by `Textarea` in `@99designs/emu`
 */
export const AutosizeTextArea = styled(BaseAutosizeTextArea)`
    ${({ error }) =>
        error
            ? css`
                  border-color: ${colors.semantic.error};
                  &:focus {
                      border-color: ${colors.semantic.error};
                  }
              `
            : ''}
`;
