import React, { Children, ElementType, ReactNode } from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import styled from 'styled-components';
import { joinAndFilter } from '../../lib/joinAndFilter';
import { ResponsiveProp, resolveResponsiveProp } from '../../lib/responsiveProps';
import { Space, spaces } from '../../settings';
import { Box } from '../Box';

interface AsObject {
    root: ElementType<React.HTMLAttributes<HTMLElement>>;
    child: ElementType<React.HTMLAttributes<HTMLElement>>;
}

export interface InlineProps {
    /**
     * Changes the element for the bounding box and the individual wrapping boxes
     * If an object is used, `as.root` will be used for the bouding box and `as.child` will be used for the individual wrapping boxes
     * If a non-object value is used, it will be applied to both bounding box and the individual wrapping boxes
     */
    as?: ElementType<React.HTMLAttributes<HTMLElement>> | AsObject;
    children: ReactNode;
    noWrap?: boolean;
    space: ResponsiveProp<Space>;
}

const NegativeBox = styled.div<InlineProps>`
    display: inline-flex;
    align-items: center;
    flex-wrap: ${({ noWrap = false }) => (noWrap ? 'nowrap' : 'wrap')};

    ${({ space }) => {
        return joinAndFilter(
            resolveResponsiveProp(
                space,
                'margin-top',
                (value) => `-${spaces.sizes[value as Space]}`
            ),
            resolveResponsiveProp(
                space,
                'margin-left',
                (value) => `-${spaces.sizes[value as Space]}`
            )
        );
    }}
`;

/**
 * Adds spacing between horizontally placed elements.
 */
export function Inline({ as = 'div', children, noWrap, space, ...rest }: InlineProps) {
    const stackItems = flattenChildren(children);
    const normalisedAs = normaliseAs(as);

    return (
        <NegativeBox as={normalisedAs.root} noWrap={noWrap} space={space} {...rest}>
            {Children.map(stackItems, (child) =>
                child !== null && child !== undefined ? (
                    <Box as={normalisedAs.child} marginTop={space} marginLeft={space}>
                        {child}
                    </Box>
                ) : null
            )}
        </NegativeBox>
    );
}

const normaliseAs = (as: NonNullable<InlineProps['as']>): AsObject => {
    if (typeof as !== 'object') {
        return {
            root: as,
            child: as,
        };
    }

    return as;
};
