/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconSketch1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M9.902 24.254c.663-.645 11.465-11.42 12.192-12.224s1.029-1.494.461-2.22c-.568-.726-2.704-3.099-3.312-3.801s-1.609-.042-2.03.577c-.421.619-11.497 11.122-12.105 11.843s-1.629 2.033-1.629 3.829c0 1.795-.424 2.443.05 2.91s1.087.207 2.994.207c1.907 0 2.717-.474 3.38-1.12zM25.684 8.085c.805-.872 1.724-1.679 2.346-2.446s.545-1.759-1.17-3.729C25.145-.06 24.055.205 23.366.788s-1.836 1.457-2.618 2.199c-.782.741-.343 1.304.091 1.715s2.293 2.66 2.971 3.237c.678.578 1.07 1.018 1.874.146zM5.091 28.344c.766 0 21.42-.001 22.326.126s1.065-.136 1.277.521c.212.657.256 2.06 0 2.368s-.959.219-1.598.219c-.639 0-21.031.097-21.745.035s-2.142-.039-2.083-.681c.059-.642-.069-.739-.028-1.706s1.085-.88 1.851-.88z"
            fill="currentColor"
        />
    );
}
