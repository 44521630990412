/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconEliminate1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M2.551.316C1.176.241-.102 2.408-.046 4.001S.768 27.53.753 28.739c-.015 1.209.926 3.359 3.602 3.267s22.003-.567 24.102-.521c2.099.046 3.16-.956 3.403-3.029s-.065-23.615-.123-25.443C31.679 1.185 30.498.16 28.877.031S3.925.392 2.55.316zm5.151 9.207c0-.311 1.429-1.821 1.742-1.697s5.075 5 5.514 5.373c.439.373.94.95 1.754.204s5.497-5.826 5.747-5.639c.251.186 1.622 1.59 1.685 1.777s-4.933 5.077-5.434 5.574c-.501.497-.837 1.054-.085 1.738s5.511 5.222 5.574 5.533c.063.311-1.412 1.714-1.726 1.714s-5.576-5.124-5.827-5.435c-.251-.311-.994-.646-1.629-.018s-5.258 5.409-5.516 5.595c-.258.186-1.858-1.295-1.796-1.67s4.745-4.976 5.372-5.598c.627-.622.501-1.474 0-1.909S7.701 9.833 7.701 9.522z"
            fill="currentColor"
        />
    );
}
