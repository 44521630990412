/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconMinus2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M.075 14.075c.469-.206 31.004-.483 31.404-.213s.324 3.913-.012 4.254c-.336.341-31.108.55-31.441.212s-.42-4.047.049-4.253z"
            fill="currentColor"
        />
    );
}
