/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconNinetyNine1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M12.48 16.839a4.388 4.388 0 001.636 1.664 4.337 4.337 0 001.882.576l-1.912 3.332h2.863l2.281-3.973c.019.574.12 1.104.302 1.59.204.542.491 1.01.86 1.404s.807.701 1.314.923a4.114 4.114 0 001.669.332c.573 0 1.055-.091 1.446-.274s.752-.479 1.083-.89v.881h2.347V10.503h-2.48v4.288c-.341-.333-.705-.579-1.09-.741s-.838-.241-1.356-.241a3.936 3.936 0 00-2.615.955 4.428 4.428 0 00-2.198-3.921 4.381 4.381 0 00-6.005 1.618l-.026.046a4.395 4.395 0 00-1.636-1.664c-2.103-1.221-4.792-.497-6.005 1.618s-.494 4.82 1.609 6.041a4.337 4.337 0 001.882.576L6.419 22.41h2.863l3.173-5.527.026-.045zM2.599.307C3.974.382 27.305-.107 28.926.022s2.802 1.154 2.86 2.982c.058 1.828.366 23.37.123 25.443s-1.304 3.075-3.403 3.029c-2.099-.046-21.426.429-24.102.521S.788 29.938.802 28.73C.817 27.521.059 5.585.003 3.992S1.225.231 2.6.307zm22.709 19.454c-.396.399-.903.599-1.52.599-.297 0-.576-.053-.834-.158s-.479-.252-.661-.441c-.182-.188-.325-.412-.43-.673s-.157-.546-.157-.856c0-.299.052-.579.157-.84s.251-.482.438-.665c.187-.183.41-.327.669-.432s.543-.158.851-.158c.297 0 .573.053.827.158s.474.252.661.441c.188.188.334.407.438.656s.157.524.157.823c0 .632-.199 1.148-.596 1.546zm-10.733-6.098c.555-.966 1.783-1.297 2.743-.739s1.289 1.793.735 2.759c-.555.966-1.783 1.297-2.743.74s-1.29-1.793-.735-2.759zm-7.667 0c.555-.966 1.783-1.297 2.743-.739s1.289 1.793.735 2.759c-.555.966-1.783 1.297-2.743.74s-1.29-1.793-.735-2.759z"
            fill="currentColor"
        />
    );
}
