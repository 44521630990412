import { page } from './SegmentWrapper';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

let pending = 0;
let isLoading = true;

// Should be included once in the top level router to track when users start navigating to a new page.
export function useNavigationTracking() {
    const location = useLocation();

    useEffect(() => recordNavigationStart, [location]);
}

// Should be called to defer our page completion until this component has been *removed* from the dom.
// It is intended that this is called from components like loading spinners to delay completion until
// they are no longer present.
export function useDeferNavComplete() {
    useEffect(() => startTracking('useDeferNavComplete'), []);
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
let stopPageTrackingCb = () => {};

// All "pages" must contain exactly one of these. Its used to defer completion events between page
// transitions until the new page element has been painted.
export function usePageElement() {
    useEffect(() => {
        stopPageTrackingCb();

        return () => {
            stopPageTrackingCb = startTracking('page');
        };
    }, []);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function startTracking(name: string) {
    let hasFinished = false;
    pending++;

    return (skipTracking = false) => {
        if (hasFinished) {
            return;
        }
        pending--;
        hasFinished = true;

        if (pending === 0 && !skipTracking) {
            recordNavigationRenderEnd();
        }
    };
}

function recordNavigationStart() {
    isLoading = true;
    try {
        // Navigation start event
        console.info('🏃 navigationStart');

        // Segment page event
        page();
    } catch (e) {
        console.error('navigationStart error:', e);
    }
}

function recordNavigationRenderEnd() {
    if (!isLoading) {
        return false;
    }
    isLoading = false;
    try {
        console.info('🏁 navigationRenderEnd');
    } catch (e) {
        console.error('navigationRenderEnd error:', e);
    }
    return;
}
