/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconService1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M3.677 22.882c.767 0 1.491 1.153 2.318 2.118s2.359 2.168 2.81 2.978c.451.81-.433 1.36-.954 1.769s-1.838 2.008-2.563 2.008c-.725 0-2.466-1.949-3.449-2.978s-2.113-2.02-1.768-2.92c.345-.9 2.839-2.975 3.605-2.975zM20.495 5.729c.238-.332.726-.673 1.217-.538s.828.484 1.223.87c.395.386 3.256 3.2 3.724 3.761s.508 1.031.142 1.374c-.245.228-2.548 2.477-6.91 6.744a977.838 977.838 0 015.212 5.543c1.249 1.348 3.585 3.623 3.585 4.225s-.752 1.156-1.333 1.698-1.297 1.336-1.848 1.336-4.676-4.089-4.989-4.415c-.209-.217-1.736-1.706-4.582-4.466-2.606 2.652-4.269 4.139-4.99 4.458-1.081.48-2.474-1.374-3.287-2.31s-2.202-2.02-2.202-2.883 1.353-1.713 1.968-2.377c.41-.443 1.228-1.263 2.453-2.461-1.575-1.565-2.485-2.461-2.731-2.688-.369-.341-.574-.884-.152-1.339s1.062-1.126 1.823-1.853c.761-.727 1.583-1.756 2.26-1.756.451 0 1.564 1.007 3.339 3.02 3.893-3.74 5.919-5.721 6.078-5.942zM.107 0c1.308 1.652 2.371 2.583 3.19 2.796 1.004.26 2.446-1.026 3.621-1.113 3.919-.291 3.174 4.627 3.174 4.861s-1.581 1.729-2.301 2.32c-.721.592-1.307 1.481-2.503 2.458C.484 10.287.32 7.655.107 5.805-.035 4.572-.035 2.637.107 0zm31.771 0c.557 0-.944 3.048-1.587 5.091s-.981 3.953-1.726 3.801c-.744-.152-1.202-.932-2.226-2.013-1.381-1.459-2.88-2.759-3.274-3.359-.257-.392.195-.851.841-1.104C26.088 1.56 31.254.002 31.877.002z"
            fill="currentColor"
        />
    );
}
