import React from 'react';
import styled from 'styled-components';
import { Box, Heading } from '@99designs/emu';
import { mediaQuery } from '@99designs/emu/css';
import { colors, spaces, typography } from '@99designs/emu/settings';

export interface HeadingWithBodyProps {
    heading: string;
    body?: string;
}

export const BodyText = styled.p`
    text-align: center;
    color: ${colors.grey700};
    margin-bottom: 0;
    font-size: ${typography.typeScale.size2}px;

    ${mediaQuery.between('small', 'medium')} {
        font-size: ${typography.fontSizes.paragraphs.small}px;
        line-height: ${typography.lineHeights.medium};
    }
`;

const StyledHeading = styled(Heading)`
    text-align: center;
    padding-bottom: ${spaces['0.25bu']};
`;

export function HeadingWithBody({ heading, body }: HeadingWithBodyProps) {
    return (
        <Box
            display="flex"
            paddingX="1.5bu"
            paddingY={['1.5bu', '3bu']}
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            height="inherit"
        >
            <StyledHeading appearanceLevel={4} accessibilityLevel={2}>
                {heading}
            </StyledHeading>
            <BodyText>{body}</BodyText>
        </Box>
    );
}
