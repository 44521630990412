import React from 'react';
import styled from 'styled-components';
import { useTranslations } from '../../Context';
import { colors } from '../../settings';
import { Box } from '../Box';
import { VisuallyHidden } from '../VisuallyHidden';
import { Star, StarProps } from './Star';

interface InteractiveRatingProps {
    name: string;
    onChange: (rating: number) => void;
    size: StarProps['size'];
    value: number | null;
}

const HoverBox = styled(Box)`
    vertical-align: middle;

    &:hover {
        color: ${colors.grey800};
    }
`;

export function InteractiveRating({
    name,
    size = 'medium',
    value,
    ...rest
}: InteractiveRatingProps) {
    const translations = useTranslations();

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        if ('onChange' in rest && rest.onChange !== undefined) {
            const { onChange } = rest;

            onChange(parseInt(e.target.value));
        }
    }

    return (
        <fieldset>
            <VisuallyHidden as="legend">{translations.Rating.Legend}</VisuallyHidden>
            <HoverBox display="inline-flex">
                {[...Array(5)].map((_, i) => {
                    const position = i + 1;
                    return (
                        <Star
                            key={position}
                            id={`${name}-${position}`}
                            checked={value === position}
                            filled={!!value && value >= position}
                            name={name}
                            onChange={handleChange}
                            size={size}
                            value={position}
                        />
                    );
                })}
            </HoverBox>
        </fieldset>
    );
}
