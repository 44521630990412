import { Command as BaseClickable } from '@ariakit/react';
import React from 'react';
import { CustomLinkComponent } from './types';

type BaseProps<S> = S & {
    children?: React.ReactNode;
    disabled?: boolean;
};

interface WithRoutingProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    behaviour?: CustomLinkComponent;
    href: string;
    openInNewTab?: boolean;
}

interface NoRoutingProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    behaviour?: never;
    href?: never;
    openInNewTab?: never;
}

export type ClickableWithRoutingProps<S> = BaseProps<S> & WithRoutingProps;
export type ClickableNoRoutingProps<S> = BaseProps<S> & NoRoutingProps;

export type ClickableProps<S> = ClickableWithRoutingProps<S> | ClickableNoRoutingProps<S>;
type PrivateClickableProps<S> = ClickableProps<S> & {
    focusable?: boolean;
};

export type ClickableComponent<S> = React.ForwardRefExoticComponent<
    React.PropsWithoutRef<ClickableProps<S>> &
        React.RefAttributes<HTMLButtonElement | HTMLAnchorElement>
>;

interface OpenInNewTabProps {
    target?: string;
    rel?: string;
}

export const Clickable = React.forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    PrivateClickableProps<unknown>
>(({ focusable = true, ...rest }, ref) => {
    if ('href' in rest && rest.href !== undefined) {
        const { behaviour, openInNewTab, target, rel, ...anchorProps } = rest;

        const getOpenInNewTabProps = (
            defaultValues: OpenInNewTabProps,
            openInNewTab?: boolean
        ): OpenInNewTabProps => {
            return openInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : defaultValues;
        };

        const openInNewTabProps = getOpenInNewTabProps({ target, rel }, openInNewTab);

        if (!behaviour) {
            return (
                <BaseClickable
                    {...anchorProps}
                    {...openInNewTabProps}
                    as="a"
                    accessibleWhenDisabled={focusable}
                    ref={ref as React.ForwardedRef<HTMLAnchorElement>}
                />
            );
        }

        const BehaviourComponent = behaviour;
        return (
            <BehaviourComponent
                ref={ref as React.ForwardedRef<HTMLAnchorElement>}
                {...anchorProps}
                {...openInNewTabProps}
                accessibleWhenDisabled={focusable}
            />
        );
    }

    const { type = 'button', ...buttonProps } = rest;

    return (
        <BaseClickable
            {...buttonProps}
            type={type}
            accessibleWhenDisabled={focusable}
            ref={ref as React.ForwardedRef<HTMLButtonElement>}
        />
    );
});
