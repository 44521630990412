import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { borders, colors } from '../../settings';

export interface ProgressBarProps {
    'aria-label'?: string;
    'aria-labelledby'?: string;
    /**
     * Number from [0,100] indicating the percentage progress that should be displayed.
     * If progress is undefined, the ProgressBar is in the indeterminate state
     */
    progress?: number;
    /**
     * Set to display a smaller (in height) progress bar.
     */
    small?: boolean;
    /**
     * The background color for the progress bar. Either a string value representing one of our color constants
     * (i.e. `"grey100"`) or a valid CSS `background` value as a string, including gradient or image values.
     */
    background?: string;
    /**
     * The foreground color for the progress bar. Either a string value representing one of our color constants
     * (i.e. `"grey100"`) or a valid CSS `background` value as a string, including gradient or image values.
     */
    color?: string;
}

const ProgressBarContainer = styled.div.attrs<{ className?: string }>({ className: 'emu' })<
    Omit<ProgressBarProps, 'progress'>
>`
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: 1;

    ${({ background }) => css`
        background: ${colors[background as colors.Color] || background};
    `}

    ${({ small }) => css`
        height: ${small ? '4px' : '8px'};
        border-radius: ${borders.borderRadiuses.small}px;
    `}
`;

function progressBarStyles({ color }: Pick<ProgressBarProps, 'color'>) {
    return css`
        background: ${colors[color as colors.Color] || color};
        border-radius: ${borders.borderRadiuses.small}px;
    `;
}

const ProgressBarFill = styled(styled.div<ProgressBarProps>`
    transition: width 0.3s ease-out;
    height: 100%;

    ${({ progress }) => css`
        width: ${progress}%;
    `}
`)(progressBarStyles);

const inProgress = keyframes`
    from {
        left: -55%;
    }
    to {
        left: 105%;
    }
`;
const ProgressBarIndeterminate = styled(styled.div`
    width: 50%;
    left: -75%;
    height: 100%;
    position: absolute;
    animation: 1.2s cubic-bezier(0.65, 0.05, 0.36, 1) 0s infinite normal none running ${inProgress};
`)(progressBarStyles);

/**
 * Displays a 100% wide horizontal bar with an inner bar used to indicate the percentage progress that a process is
 * complete.
 */
export function ProgressBar({
    progress,
    small = false,
    background = colors.grey300,
    color = colors.grey800,
    ...props
}: ProgressBarProps) {
    return (
        <ProgressBarContainer
            {...props}
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
            small={small}
            background={background}
        >
            {progress === undefined ? (
                <ProgressBarIndeterminate color={color} />
            ) : (
                <ProgressBarFill color={color} progress={progress} />
            )}
        </ProgressBarContainer>
    );
}
