import React from 'react';
import { Field, FieldProps } from '../Field';
import { BaseSelect, BaseSelectProps } from './BaseSelect';

export type SelectProps = FieldProps & Omit<BaseSelectProps, 'error' | 'menuIsOpen'>;

/**
 * A pre-made form component for select inputs.
 * Uses the browser's native select, which is preferred for short lists and forms.
 *
 * Requires one of: `label`, `aria-label`, or `aria-labelledby`.
 *
 * For longer lists or more features, @see Combobox.
 */
export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(function (
    { description, error, helperText, ...rest },
    ref
) {
    const { disabled, id, required } = rest;

    return (
        <Field
            description={description}
            disabled={disabled}
            error={error}
            helperText={helperText}
            id={id}
            label={'label' in rest ? rest.label : undefined}
            required={required}
        >
            <BaseSelect ref={ref} error={!!error} {...rest} />;
        </Field>
    );
});
