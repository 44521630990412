import React from 'react';
import { getComponentClasses } from '@99designs/frontend-components/utils/app';

export interface DesignFigureProps {
    attribution: string;
    imageSource: string;
    imageAlt?: string;
    variations?: string[];
}

export function DesignFigure({
    attribution,
    imageAlt,
    imageSource,
    variations,
}: DesignFigureProps) {
    return (
        <figure className={getComponentClasses('design-figure', variations)}>
            <div className="design-figure__image-container">
                <img
                    className="design-figure__image-container__image"
                    src={imageSource}
                    alt={imageAlt ? imageAlt : ''}
                    role={imageAlt ? 'image' : 'presentational'}
                />
            </div>
            <figcaption className="design-figure__attribution">
                <span className="attribution">{attribution}</span>
            </figcaption>
        </figure>
    );
}
