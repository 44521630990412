import { parse, ParseOptions } from 'query-string';
import { useLocation } from 'react-router-dom';

export const useUrlQuery = (
    options?: ParseOptions
): Record<string, string | string[] | null | undefined> => {
    const location = useLocation();

    return parse(location.search, options);
};
