// eslint-disable-next-line no-restricted-imports
import { createTheme } from '@material-ui/core/styles';
import { breakpoints, typography } from '@99designs/emu/settings';

// Create a theme instance.
const materialTheme = createTheme({
    typography: {
        fontFamily: typography.fontFamilies.root,
    },
    breakpoints: {
        values: {
            xs: breakpoints.small[0],
            sm: breakpoints.medium[0],
            md: breakpoints.normal[0],
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            lg: breakpoints.large[0]!,
            xl: 1920,
        },
    },
    overrides: {
        MuiTableCell: {
            root: {
                borderBottom: 'inherit',
                fontSize: 'inherit',
            },
            footer: {
                color: 'inherit',
                fontSize: 'inherit',
                lineHeight: 'inherit',
            },
        },
    },
});

export default materialTheme;
