/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconGuaranteed1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M13.531 12.944c0 .3.056.563.169.775.113.219.262.412.45.575s.406.3.65.412c.244.113.5.206.756.281v-4.162c-.6.1-1.088.325-1.463.681-.375.363-.563.838-.563 1.438zM18.113 17.762c-.206-.163-.45-.3-.719-.413-.275-.113-.55-.212-.825-.3v4.35c.313-.038.6-.113.875-.225.269-.113.506-.262.712-.45s.369-.406.488-.663c.119-.256.175-.544.175-.875 0-.325-.063-.6-.194-.837a2.183 2.183 0 00-.512-.587z"
            fill="currentColor"
        />,
        <path
            d="M27.494 17.738l4.612-1.794-4.613-1.794 3.831-3.131-4.938-.281 2.675-4.162-4.787 1.256 1.256-4.787-4.163 2.675-.281-4.938-3.131 3.831-1.8-4.612-1.794 4.612L11.23.782l-.281 4.938-4.162-2.675 1.256 4.787-4.787-1.256 2.675 4.162-4.938.281 3.831 3.131-4.612 1.794 4.612 1.794-3.831 3.137 4.938.281-2.675 4.163 4.787-1.256-1.256 4.788 4.162-2.675.281 4.938 3.131-3.831 1.794 4.613 1.794-4.613 3.131 3.831.281-4.938 4.163 2.675-1.256-4.788 4.788 1.256-2.675-4.163 4.938-.281-3.825-3.137zm-7.2 2.931a3.646 3.646 0 01-.881 1.188c-.369.331-.8.594-1.294.781s-1.012.3-1.55.337v1.35h-1.012v-1.35c-.825 0-1.594-.137-2.3-.406s-1.319-.731-1.825-1.394l1.444-1.294c.287.475.675.844 1.162 1.094.488.256.994.4 1.519.425v-4.613c-.512-.125-1-.281-1.45-.475-.456-.194-.863-.444-1.219-.744s-.637-.662-.844-1.088c-.206-.425-.306-.938-.306-1.537 0-.537.106-1.025.319-1.45.213-.431.494-.806.844-1.125s.756-.575 1.219-.769a4.611 4.611 0 011.444-.344v-1.35h1.013v1.344c.688 0 1.363.119 2.012.344.656.231 1.219.6 1.7 1.1l-1.35 1.313a3.072 3.072 0 00-2.363-1.175v4.419l-.038.019c.538.15 1.05.319 1.55.5.494.181.925.413 1.3.694s.675.637.9 1.063c.225.425.337.956.337 1.594a3.784 3.784 0 01-.331 1.55z"
            fill="currentColor"
        />
    );
}
