import React from 'react';
import styled from 'styled-components';
import { linkStyles } from '@99designs/emu';
import { FormatAndLinkify, LinkReplaceFunc } from '../FormatAndLinkify';

export interface ProductMessageFormatAndLinkifyProps {
    rawHtml: string;
    ctaLink?: string | null;
}

const Message = styled.div`
    width: 100%;
    > * {
        width: 100%;
    }
    a {
        ${linkStyles({})}
    }
`;

const LINK_REGEX = /{Link}([\w\d\s\W]+){\/Link}/g;

const linkReplaceCallback = (ctaLink?: string | null): LinkReplaceFunc => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (match: string, ...args: any[]) => {
        return `<a href="${ctaLink}" target="_blank">${args[0]}</a>`;
    };
};

const prepareRawHtml = (rawHtml: string): string =>
    rawHtml.replace('<Link>', '{Link}').replace('</Link>', '{/Link}');

export const ProductMessageFormatAndLinkify = (props: ProductMessageFormatAndLinkifyProps) => {
    const { ctaLink, rawHtml } = props;

    return (
        <Message>
            <FormatAndLinkify
                rawHtml={prepareRawHtml(rawHtml)}
                linkReplaceRegexp={LINK_REGEX}
                linkReplaceCallback={linkReplaceCallback(ctaLink)}
            />
        </Message>
    );
};
