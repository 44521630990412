import React from 'react';
import { Inline } from '@99designs/emu';

export interface IconTextPairProps {
    icon: React.ReactNode;
    children: React.ReactNode;
}

/**
 * @deprecated replaced by `Inline` in `@99designs/emu`
 */
export function IconTextPair({ icon, children }: IconTextPairProps) {
    return (
        <Inline space={'0.5bu'}>
            <div>{icon}</div>
            <div>{children}</div>
        </Inline>
    );
}
