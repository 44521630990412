/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconRight4(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M13.619 4.872c-.103-.329 2.671-3.029 3.085-2.919.355.094 10.158 10.255 12.938 13.401.46.521.467.503.467.815s.075.373-.255.74c-2.425 2.703-12.586 12.834-13.042 13.027-.517.219-2.878-2.366-2.982-2.805-.098-.416 6.221-6.389 8.468-8.695-5.757-.033-18.554-.115-19.411-.115-1.069 0-.692-.514-.757-2.506s.252-1.864.566-1.864c.265 0 14.701.091 19.561.12-1.26-1.317-8.54-8.891-8.637-9.2z"
            fill="currentColor"
        />
    );
}
