/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconUserInfo1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 37 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M22.923 20.008c4.746 0 8.799.051 9.226.102 1.013.152 1.227-.152 1.44.61.267.762.267 2.389 0 2.694-.267.356-1.067.254-1.813.254-.267 0-3.68 0-7.839.051v-.051c0-.559-.32-1.931-.747-2.999a3.416 3.416 0 00-.267-.661zM19.6 11.462c6.05 0 12.046.051 12.577.102 1.008.152 1.221-.152 1.433.61s.265 2.389 0 2.694c-.265.356-1.061.254-1.804.254-.371 0-7.429.051-13.85.051.265-.61.425-1.22.531-1.83.531-.457.902-1.118 1.114-1.88zm.038-8.481c6.025 0 11.997.051 12.53.102 1.013.152 1.226-.152 1.44.61s.267 2.389 0 2.694c-.267.356-1.066.254-1.813.254-.373 0-6.345.051-12.317.051.107-.559.16-1.067.16-1.525 0-.152 0-.305.053-.508v-.203-.254-.712c0-.204-.053-.356-.053-.508zM2.066 23.718C.795 23.718 0 23.666 0 22.422s.9-3.163 2.331-3.526c.953-.207 2.013-.415 3.178-.83s1.324-.881 1.324-1.141c0-.726-.053-1.037-1.324-1.763S4.45 12.466 4.45 12.155c0-.311-1.483-.674-1.483-1.867s.9-.933.9-1.659-.636-1.089-.636-2.437.953-2.956 1.801-3.267c.848-.311.477.518.583-.674 0-.052-.371.622 0-.104s3.39-.83 5.721-1.867c2.437-1.037 3.019 1.089 3.655 1.556s-.371-.052 1.059.83c1.43.83.953 1.348.953 2.748s-.795 3.526-.795 3.526 1.006.415.795 1.659c-.212 1.296-1.112 1.141-1.112 1.763 0 .363-.212 2.074-1.006 2.541-.742.467-1.112.985-1.112 1.607 0 1.556.371 1.193 1.112 1.556.742.311 2.49.518 3.814.83 1.748.415 2.437 4.355 2.437 4.407 0 .259-2.384.363-3.337.363-.795.052-14.46.052-15.732.052zm29.649 4.376c.846 0 2.01-.105 2.063 1.052s-.106 1.262-.053 2.051c.053.789-1.534.736-2.327.789s-23.692-.053-24.433-.053c-.74 0-1.534.105-1.798-.263s-.264-2.051 0-2.787c.264-.736.423-.473 1.428-.631 1.058-.158 24.274-.158 25.12-.158z"
            fill="currentColor"
        />
    );
}
