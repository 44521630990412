import React, { useEffect, useRef, FunctionComponent } from 'react';

interface Props {
    onClickAway: Function;
    children?: React.ReactNode;
}

const ClickAwayListener: FunctionComponent<Props> = ({ onClickAway, children }) => {
    const node = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleMouseDown = (event: MouseEvent): void => {
            if (node.current && node.current.contains(event.target as Node)) {
                return;
            }

            onClickAway(event);
        };

        document.addEventListener('mousedown', handleMouseDown);

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        };
    });

    return <div ref={node}>{children}</div>;
};

export default ClickAwayListener;
