import React from 'react';
import styled, { css } from 'styled-components';
import { mediaQuery } from '@99designs/emu/css';

const hidden = css`
    display: none !important;
`;

interface HiddenProps {
    children: React.ReactNode;
}

export const Desktop = styled.div<HiddenProps>`
    ${mediaQuery.between('small', 'medium')} {
        ${hidden}
    }
`;

export const Mobile = styled.div<HiddenProps>`
    ${mediaQuery.minimum('normal')} {
        ${hidden}
    }
`;
