import React from 'react';
import { Box } from '@99designs/emu';
import { Checkbox, CheckboxProps } from '../Checkbox';

export interface CheckboxFieldProps extends CheckboxProps {
    fieldTitle: React.ReactNode;
    subText?: string;
}

/**
 * @deprecated replaced by Checkbox in `@99designs/emu`
 */
export function CheckboxField({ fieldTitle, subText, ...checkboxProps }: CheckboxFieldProps) {
    return (
        <div>
            <Box display={'flex'} alignItems={'center'}>
                <Checkbox
                    data-testid={`checkbox-${checkboxProps.value}`}
                    id={`checkbox-${checkboxProps.value}`}
                    {...checkboxProps}
                />
                <Box marginLeft={'0.75bu'}>
                    <label htmlFor={`checkbox-${checkboxProps.value}`}>{fieldTitle}</label>
                </Box>
            </Box>
            {subText && (
                <Box marginLeft={'1.5bu'} color={'grey700'}>
                    {subText}
                </Box>
            )}
        </div>
    );
}
