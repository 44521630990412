const vistaLocaleBaseUrls: { [key: string]: string } = {
    'en-us': 'https://vista.com',
    'es-us': 'https://vista.com/us/es',
    'en-au': 'https://vista.com/au',
    'en-ca': 'https://vista.com/ca',
    'en-gb': 'https://vista.com/gb',
    'en-ie': 'https://vista.com/ie',
    'de-de': 'https://vista.com/de',
    'en-de': 'https://vista.com/de',
    'en-dk': 'https://vista.com/dk',
    'fr-fr': 'https://vista.com/fr',
    'en-fr': 'https://vista.com/fr',
    'it-it': 'https://vista.com/it',
    'en-it': 'https://vista.com/it',
    'en-no': 'https://vista.com/no',
    'nl-nl': 'https://vista.com/nl',
    'en-nl': 'https://vista.com/nl',
    'pt-br': 'https://vista.com',
    'en-br': 'https://vista.com',
    'pt-pt': 'https://vista.com/pt',
    'en-pt': 'https://vista.com/pt',
    'es-cl': 'https://vista.com/us/es',
    'en-cl': 'https://vista.com',
    'es-co': 'https://vista.com/us/es',
    'en-co': 'https://vista.com',
    'es-mx': 'https://vista.com/us/es',
    'en-mx': 'https://vista.com',
    'es-es': 'https://vista.com/es',
    'en-es': 'https://vista.com/es',
    'ja-jp': 'https://vista.com',
    'en-jp': 'https://vista.com',
    'de-ch': 'https://vista.com/ch',
    'it-ch': 'https://vista.com/ch/it',
    'fr-ch': 'https://vista.com/ch/fr',
    'en-ch': 'https://vista.com/ch',
    'en-hk': 'https://vista.com',
    'en-sg': 'https://www.vista.com/sg',
    'nl-be': 'https://vista.com/be',
    'fr-be': 'https://vista.com/be/fr',
    'en-be': 'https://vista.com/be',
    'de-at': 'https://vista.com/at',
    'en-at': 'https://vista.com/at',
};

const fallbackLocaleBaseUrl = vistaLocaleBaseUrls['en-us'];

// Usages:
//  vistaUrlByLocale("en-US", "/terms-and-conditions")
//  vistaUrlByLocale("en-us", "/terms-and-conditions")
export default function vistaUrlByLocale(locale: string, path: string): string {
    const baseUrl = vistaLocaleBaseUrls[locale.toLowerCase()] || fallbackLocaleBaseUrl;

    return `${baseUrl}${path}`;
}
