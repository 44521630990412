/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconEdit1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M3.2 22.644c-.72.74-1.25 1.644-1.25 3.773s-.291 2.813.231 3.342c.521.529 1.244.056 3.248.056s3.47-1.14 4.274-1.819c.804-.679 12.529-13.043 13.22-13.513s1.428-1.588.644-2.266c-.784-.679-3.433-3.063-4.244-3.697s-1.58-.296-2.478.515C15.947 9.846 3.92 21.904 3.2 22.644zM21.249 5.027c-.973.898-.482 1.335.163 2.092s3.155 2.833 3.614 3.317c.459.484 1.087.974 1.914.101s1.804-2.154 2.455-2.923c.651-.769.947-1.986-1.252-3.9s-3.306-2.002-4.163-1.306c-.857.695-1.758 1.721-2.731 2.619z"
            fill="currentColor"
        />
    );
}
