import React from 'react';
import styled from 'styled-components';
import { Box } from '@99designs/emu';
import { mediaQuery } from '@99designs/emu/css';
import { spaces, typography } from '@99designs/emu/settings';
import { ProductMessageFormatAndLinkify } from '../FormatAndLinkify';
import { ProductPrice, ProductPriceProps } from '../ProductPrice';

const Logo = styled.div`
    height: 46px;

    ${mediaQuery.only('small')} {
        height: 35px;
    }

    svg {
        height: 100%;
    }
`;

const StyledHeading = styled.p`
    grid-area: heading;

    font-weight: ${typography.fontWeights.medium};
    font-size: ${typography.typeScale.size5}px;
    font-family: ${typography.fontFamilies.heading};
    margin-bottom: 0;

    ${mediaQuery.only('small')} {
        font-size: ${typography.typeScale.size4}px;
    }
`;

const Description = styled.div`
    grid-area: description;

    margin: ${spaces['0.75bu']} ${spaces['1.5bu']} 0 0;
    width: 100%;

    ${mediaQuery.between('small', 'medium')} {
        margin-right: 0;
        width: 100%;
    }
    p:last-child {
        margin-bottom: 0;
    }
`;

const PriceContainer = styled.div`
    grid-area: price;
    justify-self: end;

    font-weight: ${typography.fontWeights.medium};
    font-size: ${typography.typeScale.size5}px;
    font-family: ${typography.fontFamilies.heading};
    width: 250px;

    ${mediaQuery.minimum('normal')} {
        text-align: right;
    }

    ${mediaQuery.between('small', 'medium')} {
        padding-top: ${spaces['0.5bu']};
        font-size: ${typography.typeScale.size4}px;
        width: 100%;
    }
`;

const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
        'heading heading heading price'
        'description description description price';

    ${mediaQuery.between('small', 'medium')} {
        grid-template-columns: 1fr;
        grid-template-areas:
            'heading'
            'price'
            'description';
    }
`;

interface Props extends ProductPriceProps {
    title: string;
    description: string;
    logo?: string;
}

export interface PropsWithProductSummary {
    productSummary: Props;
}

export default function ProductSummary({
    title,
    description,
    logo,
    fromPrice,
    toPrice,
    showFixedPrice,
}: Props) {
    return (
        <>
            {logo && (
                <Box marginBottom="1.5bu">
                    <Logo
                        dangerouslySetInnerHTML={{
                            __html: logo,
                        }}
                    />
                </Box>
            )}
            <Content>
                <StyledHeading>{title}</StyledHeading>
                <Description>
                    <ProductMessageFormatAndLinkify rawHtml={description} />
                </Description>
                {fromPrice && fromPrice.amountInCents > 0 && (
                    // eslint-disable-next-line jsx-a11y/aria-role
                    <PriceContainer role={'price'}>
                        <ProductPrice
                            fromPrice={fromPrice}
                            toPrice={toPrice}
                            showFixedPrice={showFixedPrice}
                        />
                    </PriceContainer>
                )}
            </Content>
        </>
    );
}

interface Package {
    title: string;
    description: string;
    amountInCents: number;
    currency: string;
    logo?: string;
}

export function packageToProductSummary(pkg: Package): Props {
    return {
        title: pkg.title,
        description: pkg.description,
        fromPrice: {
            amountInCents: pkg.amountInCents,
            currency: pkg.currency,
        },
        showFixedPrice: true,
        logo: pkg.logo,
    };
}
