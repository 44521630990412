/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconX2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M16.057.001C4.401.126-.043 9.592 0 16.023s5.323 15.938 16.435 15.976C27.547 32.037 32.048 21.76 32 16.186S27.713-.125 16.058 0zM7.89 9.879c0-.312 1.757-2.186 2.072-2.061s4.598 5.057 5.039 5.432c.441.375.694 1.062 1.512.313s5.665-5.994 5.916-5.807c.252.187 1.696 1.811 1.759 1.999s-5.098 5.242-5.602 5.742c-.503.5-.692.559.063 1.245s5.536 5.245 5.599 5.557c.063.312-1.633 1.936-1.948 1.936s-5.602-5.432-5.853-5.745c-.252-.312-.567-.687-1.136.125s-5.282 5.432-5.541 5.62c-.259.187-2.081-1.372-2.018-1.749s4.909-4.998 5.539-5.622c.629-.624.504-1.123 0-1.56s-5.4-5.112-5.4-5.424z"
            fill="currentColor"
        />
    );
}
