/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconDollar2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M15.886.011C7.573.352-.005 7.491 0 16.314S6.208 31.989 16.162 32C26.116 32.011 32 24.558 32 16.002S24.198-.331 15.886.01zm2.711 23.368a1.582 1.582 0 01-.291.19c-.446.215-.982.307-1.466.404v2.035c.001-.017.002.449 0 .432v-.384l-.004.484c-.015.077-.056.139-.126.137-.568-.013-1.131-.093-1.698-.058a.124.124 0 01-.128-.078l-.005-.484v.356c-.014.037-.013-.403 0-.365v-2.161c-.217-.008-.419-.082-.626-.163-1.767-.423-2.971-1.529-3.473-3.154a4.188 4.188 0 01-.28-1.524.219.219 0 01.032-.109.21.21 0 01.2-.157c.851-.035 1.695.068 2.546.088.098.003.16.058.19.127h.004l.001.009a.26.26 0 01.011.127c.065.808.259 1.425.765 1.8.416.308 1.088.447 1.778.447.717 0 1.295-.219 1.672-.603.311-.317.485-.748.485-1.26 0-1.057-.772-1.707-2.848-2.399-3.167-1.022-4.548-2.409-4.548-4.744 0-2.064 1.169-3.614 2.973-4.267.319-.115.66-.202 1.022-.258.058-.35.086-1.046.082-1.51.003.09.007.169.012.157V5.157l.03.484c.023-.038.061-.548.117-.541.564.068 1.113-.111 1.681-.098.128.003.175.209.134.341v2.51l.073.013c1.447.244 2.507.972 3.121 2.11.34.631.54 1.391.595 2.262l.003.031.003.031h-.009c-.015.095-.081.179-.206.176-.847-.02-1.687-.122-2.534-.089-.123.035-.288-.031-.301-.199a2.01 2.01 0 00-.188-.709 1.81 1.81 0 01-.127-.324c-.048-.076-.095-.152-.146-.227-.345-.367-.828-.585-1.434-.585-.994 0-1.643.484-1.815 1.313-.039.438.042.921.144 1.253.004.014.002.025.004.038.079.149.184.284.308.411.192.142.416.256.595.4.018.015.032.033.05.048.893.326 1.896.584 2.688 1.123.318.144.63.254.913.444 1.785.937 2.556 2.064 2.556 3.869 0 1.934-.923 3.395-2.537 4.136z"
            fill="currentColor"
        />
    );
}
