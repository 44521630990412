/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconPlatinum1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M5.244.063H16.5c2.05 0 3.738.281 5.063.838s2.375 1.263 3.144 2.125a7.28 7.28 0 011.606 2.894c.3 1.069.45 2.1.45 3.094s-.15 2.025-.45 3.094a7.254 7.254 0 01-1.606 2.894c-.769.856-1.819 1.569-3.144 2.125s-3.012.837-5.063.837H9.581v14.1H5.243v-32zm4.337 14.099h5.694c.844 0 1.681-.069 2.506-.206s1.569-.387 2.212-.744a4.081 4.081 0 001.563-1.563c.394-.675.587-1.563.587-2.644 0-1.088-.194-1.969-.587-2.644s-.913-1.2-1.563-1.556a6.677 6.677 0 00-2.212-.744 15.24 15.24 0 00-2.506-.206H9.581v10.306z"
            fill="currentColor"
        />
    );
}
