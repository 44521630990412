import styled, { css } from 'styled-components';
import { breakpoints, spaces } from '@99designs/emu/settings';

interface FullscreenHeaderProps {
    designDetails?: boolean;
    inactive?: boolean;
}

const FullscreenHeader = styled.div<FullscreenHeaderProps>`
    display: table;
    padding: 0 ${spaces['1bu']};
    pointer-events: auto;
    opacity: 1;
    transition: opacity 0.1s ease-out;

    ${({ designDetails = false }) =>
        designDetails &&
        css`
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        `}

    @media (min-width: ${breakpoints.normal[0]}px) and (max-width: ${breakpoints.normal[1]}px),
        (min-width: ${breakpoints.large[0]}px) {
        padding: 0 ${spaces['2bu']};

        ${({ inactive = false }) =>
            inactive &&
            css`
                opacity: 0;
                transition: opacity 0.1s ease-out;
                pointer-events: none;
            `}
    }
`;
export default FullscreenHeader;
