import React from 'react';
import { LabelText, LabelTextProps } from './LabelText';

export interface FormLabelProps extends LabelTextProps {
    /**
     * Links to an <input>'s `id`
     */
    htmlFor: string;
    id?: string;
}

/**
 * Renders a <label> component to be used with a linked <input>.
 * Can be used internally in pre-made form components, e.g. TextInput, or used directly
 * when needed.
 */
export function FormLabel({ htmlFor, id, ...rest }: FormLabelProps) {
    return (
        <label id={id} htmlFor={htmlFor}>
            <LabelText {...rest} />
        </label>
    );
}
