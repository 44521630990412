import styled, { css } from 'styled-components';

export const VisuallyHiddenStyles = css`
    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const VisuallyHidden = styled.span`
    ${VisuallyHiddenStyles}
`;
