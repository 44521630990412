import styled, { css } from 'styled-components';
import { colors } from '../../settings';
import { AvatarSize } from './types';

interface OnlineIndicatorProps {
    size: AvatarSize;
}

const indicatorSizes: Record<AvatarSize, { offset: string; size: string }> = {
    'extra-small': { offset: '0px', size: '8px' },
    small: { offset: '1px', size: '16px' },
    medium: { offset: '2px', size: '16px' },
    large: { offset: '7px', size: '16px' },
    'extra-large': { offset: '9px', size: '20px' },
    'extra-extra-large': { offset: '14px', size: '20px' },
};

export const OnlineIndicator = styled.div<OnlineIndicatorProps>`
    position: absolute;
    background: ${colors.forrestLyf};
    border-radius: 50%;
    border: 2px solid ${colors.white};
    ${({ size }) => css`
        right: ${indicatorSizes[size].offset};
        bottom: ${indicatorSizes[size].offset};
        width: ${indicatorSizes[size].size};
        height: ${indicatorSizes[size].size};
        border-width: ${size === 'extra-small' ? 1 : 2}px;
    `}
`;
