export type Space =
    | 'none'
    | '0.25bu'
    | '0.5bu'
    | '0.75bu'
    | '1bu'
    | '1.5bu'
    | '2bu'
    | '3bu'
    | '4bu'
    | '6bu'
    | '8bu'
    | '10bu'
    | 'auto';

const unitlessSpaces = {
    '0.25bu': 4,
    '0.5bu': 8,
    '0.75bu': 12,
    '1bu': 16,
    '1.5bu': 24,
    '2bu': 32,
    '3bu': 48,
    '4bu': 64,
    '6bu': 96,
    '8bu': 128,
    '10bu': 160,
};

// These records include units as they are used directly in Styled Components CSS
const values = {
    none: '0',
    '0.25bu': 4 + 'px',
    '0.5bu': 8 + 'px',
    '0.75bu': 12 + 'px',
    '1bu': 16 + 'px',
    '1.5bu': 24 + 'px',
    '2bu': 32 + 'px',
    '3bu': 48 + 'px',
    '4bu': 64 + 'px',
    '6bu': 96 + 'px',
    '8bu': 128 + 'px',
    '10bu': 160 + 'px',
    auto: 'auto',
};

// Experimental abstraction on top of existing units for specific space values
const semantic = {
    cardPadding: '1.5bu',
} as const;

export const spaces = {
    ...values,
    px: unitlessSpaces,
    sizes: values,
    semantic,
};
