/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconStopWatch2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M30.734 21.821C29.733 24.58 26.293 32 15.616 32S-.484 23.243.265 15.446C1.014 7.649 7.258 0 15.5 0s11.489 4.515 11.489 4.515S30.985.377 31.61 1.129s.874 10.785.125 10.785-10.561.752-10.561 0 3.318-4.389 3.318-4.389-3.624-3.445-8.295-3.445S5.261 6.898 4.012 15.551c-1.249 8.653 6.667 12.797 11.912 12.797s9.563-3.333 11.066-7.262c1.503-3.928 4.745-2.024 3.745.735zm-16.433-.999h5.624c.944 0 1.503-.95 1.503-2.078 0-1.129-.629-1.809-1.503-1.809h-4.061v-4.652c0-1.633-3.679-1.693-3.679 0v6.461c0 1.186.87 2.078 2.116 2.078z"
            fill="currentColor"
        />
    );
}
