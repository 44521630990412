import React from 'react';
import styled from 'styled-components';
import { IconX1 } from '@99designs/icons';
import { useTranslations } from '../../Context';
import { borders, boxShadows, spaces, typography } from '../../settings';
import { Bleed } from '../Bleed';
import { Box } from '../Box';
import { createButtonTransparentColorConfig } from '../Button';
import { IconButton } from '../IconButton';

export interface BaseAlertProps {
    icon?: React.ReactNode;
    className?: string;
    children: React.ReactNode;
    onDismiss?: () => void;
    dismissed?: boolean;
    role?: React.AriaRole;
}

const AlertContainer = styled.div.attrs<{ className?: string }>({ className: 'emu' })`
    display: flex;
    padding: ${spaces['1bu']} ${spaces['1.5bu']};
    border-radius: ${borders.borderRadiuses.medium}px;
    box-shadow: ${boxShadows.low};
`;

const AlertContent = styled.div`
    line-height: ${typography.lineHeights.root};
    font-size: ${typography.fontSizes.root}px;
    font-weight: ${typography.fontWeights.bold};
    margin: 0;
    flex-grow: 1;
`;

export function BaseAlert({
    className,
    children,
    icon,
    onDismiss,
    dismissed = false,
    role,
}: BaseAlertProps) {
    const translations = useTranslations();
    const hide = () => {
        if (onDismiss) {
            onDismiss();
        }
    };

    if (dismissed) return null;

    return (
        <AlertContainer className={className} data-testid={'BaseAlert'} role={role}>
            <Box marginRight="1bu">{icon}</Box>
            <AlertContent>{children}</AlertContent>
            {onDismiss && (
                <Bleed spaceY="0.75bu" spaceRight="1bu">
                    <Box marginLeft="0.75bu" display="flex" height="100%">
                        <IconButton
                            aria-label={translations.Alert.Dismiss}
                            onClick={hide}
                            icon={IconX1}
                            style={{ fontSize: typography.typeScale.size3 }}
                            appearance={createButtonTransparentColorConfig({
                                color: 'currentColor',
                                backgroundColorActive: 'transparent',
                                backgroundColorHover: 'transparent',
                            })}
                        />
                    </Box>
                </Bleed>
            )}
        </AlertContainer>
    );
}
