import React from 'react';
import styled from 'styled-components';
import { mediaQuery } from '@99designs/emu/css';
import { colors, spaces, typography } from '@99designs/emu/settings';
import { IconTick1, IconX1 } from '@99designs/icons';

interface Props {
    features: Feature[];
}

export interface Feature {
    isAvailable: boolean;
    title: string;
}
const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    ${mediaQuery.only('small')} {
        flex-direction: column;
        row-gap: ${spaces['0.75bu']};
    }
    ${mediaQuery.between('small', 'medium')} {
        margin-bottom: ${spaces['1.5bu']};
    }
`;
const List = styled.ul`
    list-style-type: none;
    display: flex;
    flex: 1;
    flex-direction: column;
    row-gap: ${spaces['0.75bu']};
    margin-bottom: 0;
`;

const ListItem = styled.li`
    font-size: ${typography.typeScale.size3}px;
    padding-left: 21px;
    padding-right: ${spaces['1.5bu']};
    width: 100%;
`;

const Icon = styled.div`
    max-width: 16px;
    position: absolute;
    margin-left: -21px;
    width: 100%;
`;

const FeatureList = ({ features }: Props) => (
    <List>
        {features.map((feature, i) => {
            return (
                <ListItem key={i}>
                    {feature.isAvailable ? (
                        <Icon>
                            <IconTick1
                                offsetRight={true}
                                color={colors.forrestLyf}
                                aria-label={__('available')}
                            />
                        </Icon>
                    ) : (
                        <Icon>
                            <IconX1
                                offsetRight={true}
                                color={colors.grey700}
                                aria-label={__('not available')}
                            />
                        </Icon>
                    )}
                    {feature.title}
                </ListItem>
            );
        })}
    </List>
);

export function FeaturesList({ features }: Props) {
    const middleIndex: number = Math.ceil(features.length / 2);
    const featuresFirstHalf: Feature[] = features.slice(0, middleIndex);
    const featuresSecondHalf: Feature[] = features.slice(middleIndex);

    return (
        <Container>
            <FeatureList features={featuresFirstHalf} />
            <FeatureList features={featuresSecondHalf} />
        </Container>
    );
}
