/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconBlind2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M31.5 17.51c.67-1.12.67-2.17-.05-2.85a6.213 6.213 0 01-.505-.685l-.015-.025a23.7 23.7 0 00-4.479-5.144l-.031-.026a32.855 32.855 0 003.428-3.711L29.9 5a25.033 25.033 0 00-2.872-2.976L27 2c-.21-.16-2.36 2-4.61 4.29-1.825-.816-3.955-1.291-6.196-1.291L15.989 5h.01-.01c-2.39 0-4.665.489-6.732 1.372l.112-.043c-2.11-2.11-4.15-4.11-4.37-4.22-.57-.21-3 2.36-3 2.89a29.041 29.041 0 003.392 3.753l.018.017a18.665 18.665 0 00-4.591 5.785l-.049.105a8.07 8.07 0 01-.48.842l.02-.032c-.43.72-.5.83.36 2.08l.24.36a23.444 23.444 0 004.578 5.303l.032.027a29.561 29.561 0 00-3.467 3.827l-.053.073c-.11.64 2.61 3.15 3 2.83.22-.15 2.21-2.18 4.31-4.33 1.913.868 4.147 1.373 6.5 1.373s4.587-.506 6.601-1.414l-.101.041a39.932 39.932 0 004.581 4.058l.099.072c.53 0 3-2.38 2.93-2.91a36.82 36.82 0 00-3.503-3.685l-.027-.025a22.992 22.992 0 005.056-5.551l.054-.089zm-20.38.13l-.38.38-1.46 1.44a8.151 8.151 0 01-1.16-4.216v-.078.004l-.001-.129c0-1.05.263-2.039.727-2.904l-.016.033c1.14 1.12 2 2 2.29 2.2.454.401.738.985.738 1.635s-.284 1.234-.736 1.633l-.002.002zM16 22.92h-.064a8.773 8.773 0 01-3.325-.651l.059.021c.86-.88 1.52-1.55 1.79-1.81.34-.38.832-.618 1.38-.618s1.04.238 1.378.616l.002.002c.11.14.83.86 1.84 1.85a8.114 8.114 0 01-3.06.59zm1.33-11.34c-1.26 1.16-2.08.44-2.78-.18l-.2-.17c-.2-.17-1-.91-1.94-1.9a11.455 11.455 0 013.438-.659l.022-.001a9.387 9.387 0 013.794.621l-.064-.022c-1.09 1.09-1.94 1.97-2.31 2.31zm5.24 7.72c-.94-.89-1.67-1.58-2-1.87-1.28-1.16-.71-2.1.14-2.95l.49-.48 1.8-1.79c.404.785.641 1.714.641 2.698l-.001.097V15v.079a8.544 8.544 0 01-1.132 4.263l.022-.042z"
            fill="currentColor"
        />
    );
}
