import React, { ElementType } from 'react';
import { typography } from '../../settings';
import { Text } from './Text';
import type { TextProps } from './Text';

export interface SubtleTextProps {
    as?: ElementType<React.HTMLAttributes<HTMLElement>>;
    children: React.ReactNode;
    size?: 'small' | 'regular';
    /**
     * SubtleText must be block displayed.
     * Truncates the text with an ellipsis after one, or a specified amount
     * of lines.
     * For multi-line truncation, the SubtleText element cannot have any bottom
     * padding.
     * May have undesired results if the SubtleText element has child elements.
     */
    truncate?: TextProps['truncate'];
}

export function SubtleText({ size = 'small', ...rest }: SubtleTextProps) {
    return <Text {...rest} color="grey700" size={typography.paragraphFontSizes[size]} />;
}
