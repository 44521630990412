import React from 'react';
import { FieldGroup, FieldGroupProps } from '../Field';
import { RadioItem, RadioItemProps } from './RadioItem';

type AllOrNone<T> = T | { [K in keyof T]?: never };

export interface RadioGroupItem {
    disabled?: RadioItemProps['disabled'];
    label: string;
    ref?: React.ForwardedRef<HTMLInputElement>;
    value: RadioItemProps['value'];
}

export type RadioGroupProps = FieldGroupProps & {
    /**
     * Used as the `name` attribute for radio buttons.
     */
    name: RadioItemProps['name'];
    /**
     * A list of options to render as radio buttons.
     */
    options: RadioGroupItem[];
} & AllOrNone<{
        onChange: RadioItemProps['onChange'];
        value: RadioItemProps['value'];
    }>;

/**
 * Creates an accessible group of vertically placed radio buttons.
 *
 * Can be controlled using `onChange` & `value` together, which appropriately
 * forwards props to individual radio buttons.
 *
 * Requires one of: `label`, `aria-label`, or `aria-labelledby`.
 *
 * If more flexibility is required, `RadioItem` can be used directly.
 */
export function RadioGroup({
    description,
    error,
    helperText,
    id,
    name,
    onChange,
    options,
    size,
    value,
    ...rest
}: RadioGroupProps) {
    return (
        <FieldGroup
            description={description}
            error={error}
            helperText={helperText}
            id={id}
            role="radiogroup"
            size={size}
            {...rest}
        >
            {options.map((option) => {
                const optionId = `${name}_${option.value}`;

                return (
                    <RadioItem
                        key={optionId}
                        id={optionId}
                        name={name}
                        size={size}
                        onChange={onChange}
                        checked={typeof value !== 'undefined' ? option.value === value : undefined}
                        {...option}
                    />
                );
            })}
        </FieldGroup>
    );
}
