import vistaUrlByLocale from './vistaUrlByLocale';

const ZENDESK_BASE_URL = 'https://support.99designs.com';
const ZENDESK_LANGUAGES = ['en', 'de', 'es', 'fr', 'it', 'ja', 'nl', 'pt'] as const;
const DEFAULT_LANGUAGE: ZendeskLanguage = 'en';
const HELP_CENTER_URLS: Record<string, string> = {
    en_AU: 'https://www.vistaprint.com.au/customer-care/help-centre/',
    de_AT: 'https://www.vistaprint.at/kundenservice/hilfe-seite/',
    en_AT: 'https://www.vistaprint.at/kundenservice/hilfe-seite/',
    nl_BE: 'https://www.vistaprint.be/klantenservice/help/',
    en_BE: 'https://www.vistaprint.be/klantenservice/help/',
    fr_BE: 'https://www.vistaprint.be/fr/service-client/centre-assistance/',
    pt_BR: 'https://www.vistaprint.com/customer-care/help-center/',
    en_BR: 'https://www.vistaprint.com/customer-care/help-center/',
    en_CA: 'https://www.vistaprint.ca/customer-care/help-center/',
    es_CL: 'https://www.vistaprint.com/es/atencion-al-cliente/centro-de-ayuda/',
    en_CL: 'https://www.vistaprint.com/customer-care/help-center/',
    es_CO: 'https://www.vistaprint.com/es/atencion-al-cliente/centro-de-ayuda/',
    en_CO: 'https://www.vistaprint.com/customer-care/help-center/',
    fr_FR: 'https://www.vistaprint.fr/service-client/centre-assistance/',
    en_FR: 'https://www.vistaprint.fr/service-client/centre-assistance/',
    de_DE: 'https://www.vistaprint.de/kundenservice/hilfe-seite/',
    en_DE: 'https://www.vistaprint.de/kundenservice/hilfe-seite/',
    en_HK: 'https://www.vistaprint.com/customer-care/help-center/',
    it_IT: 'https://www.vistaprint.it/assistenza-clienti/centro-assistenza/',
    en_IT: 'https://www.vistaprint.it/assistenza-clienti/centro-assistenza/',
    en_IE: 'https://www.vistaprint.ie/customer-care/help-centre/',
    ja_JP: 'https://vistaprintjp.zendesk.com/hc/ja',
    en_JP: 'https://www.vistaprint.com/customer-care/help-center/',
    es_MX: 'https://www.vistaprint.com/es/atencion-al-cliente/centro-de-ayuda/',
    en_MX: 'https://www.vistaprint.com/customer-care/help-center/',
    nl_NL: 'https://www.vistaprint.nl/klantenservice/help/',
    en_NL: 'https://www.vistaprint.com/customer-care/help-center/',
    en_NO: 'https://www.vistaprint.no/kundeservice/help/',
    pt_PT: 'https://www.vistaprint.pt/servico-apoio-cliente/centro-ajuda/',
    en_PT: 'https://www.vistaprint.pt/servico-apoio-cliente/centro-ajuda/',
    en_SG: 'https://www.vistaprint.sg/customer-care/help-centre/',
    es_ES: 'https://www.vistaprint.es/atencion-al-cliente/centro-de-ayuda/',
    en_ES: 'https://www.vistaprint.es/atencion-al-cliente/centro-de-ayuda/',
    de_CH: 'https://www.vistaprint.ch/kundenservice/hilfe-seite/',
    en_CH: 'https://www.vistaprint.ch/kundenservice/hilfe-seite/',
    fr_CH: 'https://www.vistaprint.ch/fr/service-client/centre-assistance/',
    it_CH: 'https://www.vistaprint.ch/it/assistenza-clienti/centro-assistenza/',
    en_GB: 'https://www.vistaprint.co.uk/customer-care/help-centre/',
    en_US: 'https://www.vistaprint.com/customer-care/help-center/',
    es_US: 'https://www.vistaprint.com/es/atencion-al-cliente/centro-de-ayuda/',
};

export type ZendeskLanguage = typeof ZENDESK_LANGUAGES[number];

type BrowseJobsFilter = 'available' | 'expressed-interest';
type ProjectsDashboardTab =
    | 'active'
    | 'drafts'
    | 'expressed-interest'
    | 'cancelled'
    | 'completed'
    | 'invited'
    | 'started';
export type WorkPageStatus = 'all' | 'in-progress' | 'completed' | 'canceled';

function supportBaseUrl(lang = DEFAULT_LANGUAGE) {
    //Convert from RFC3066 to ISO639
    lang = lang.substring(0, 2) as ZendeskLanguage;
    if (!ZENDESK_LANGUAGES.includes(lang)) {
        lang = DEFAULT_LANGUAGE;
    }
    return `${ZENDESK_BASE_URL}/hc/${lang}`;
}

export const zendeskArticlesIds = {
    vistaAccountMerge: '8788014705428',
};

export const linkTo = {
    home: () => '/',
    accountHome: () => '/account',
    accountProfile: () => '/account/profile',
    accountPortfolio: () => '/account/portfolio',
    accountYourLevel: () => '/account/your-level',
    accountCurationSamples: () => '/account/curation-samples',
    accountCurationAddSample: () => '/account/curation-samples/upload',
    accountCurationEditSample: (id: number) => `/account/curation-samples/edit/${id}`,
    accountPersonalInformation: () => '/account/personal-information',
    accountNotifications: () => '/account/notifications',
    accountLogInMethod: () => '/account/loginmethod',
    accountForgotPassword: (target?: string) => {
        if (target) return `/accounts/resetpassword?target=${target}`;
        return `/accounts/resetpassword`;
    },
    accountVerification: () => '/account/verification',
    accountVerifyID: () => '/accounts/verification?target=/account/verification',
    accountProfessionalBackground: () => '/account/professional-background',
    accountGettingStarted: () => '/account/getting-started',
    accountDesignerApplication: () => '/account/application',
    accountPreferences: () => `/account/preferences`, // deprecated, use accountMarketplaces
    accountMarketplaces: () => `/account/marketplaces`,
    accountCategoryPreferences: (category: string) => `/account/preferences/${category}`, // deprecated, use accountMarketplaceCategory
    accountMarketplaceCategory: (category: string) => `/account/marketplaces/${category}`,
    adminProductCreate: () => '/products/admin/create',
    adminProductView: (key: string) => `/products/admin/${key}`,
    adminProductEdit: (key: string) => `/products/admin/${key}/edit`,
    adminProductHome: () => '/products/admin',
    adminProductListView: (view: string, page?: number) =>
        page ? `/products/admin/list/${view}/page/${page}` : `/products/admin/list/${view}`,
    adminProductGroups: () => '/products/admin/groups',
    codeOfConduct: () => '/accounts/view-code-of-conduct',
    designerSearch: () => `/designers/search`,
    manageEmailSubscriptions: (unsubscribeHash: string, email: string) =>
        `/campaigns/email-subscriptions?e=${unsubscribeHash}&email=${encodeURIComponent(email)}`,
    messages: () => '/messages',
    messagesUser: (recipientId: string) => `/messages/${recipientId}`,
    projectAutoFinishRequest: (projectId: string, finishProjectRequestId: string) =>
        `/projects/${projectId}/complete/${finishProjectRequestId}`,
    projectActivity: (projectId: string) => `/projects/${projectId}/activity`,
    projectCancelInvites: (projectId: string) => `/projects/${projectId}/cancel-invites`,
    projectPayments: (projectId: string) => `/projects/${projectId}/payments`,
    projectActivityEvent: (projectId: string, eventId: string) =>
        `/projects/${projectId}/activity/events/${eventId}`,
    projectCancel: (projectId: string) => `/projects/${projectId}/cancel`,
    projectCreate: (userId?: string) =>
        userId ? `/projects/create/${userId}` : `/projects/create`,
    projectCreateInvoice: (projectId: string, from?: string) => {
        if (from) return `/projects/${projectId}/invoices/create?from=${from}`;
        return `/projects/${projectId}/invoices/create`;
    },
    projectEditInvoice: (projectId: string, invoiceId: string, from?: string) => {
        if (from) return `/projects/${projectId}/invoices/${invoiceId}/edit?from=${from}`;
        return `/projects/${projectId}/invoices/${invoiceId}/edit`;
    },
    projectInvoice: (projectId: string, invoiceId: string, from?: string) => {
        if (from) return `/projects/${projectId}/invoices/${invoiceId}?from=${from}`;
        return `/projects/${projectId}/invoices/${invoiceId}`;
    },
    projectDesignTransferAgreement: (projectId: string) =>
        `/projects/${projectId}/design-transfer-agreement`,
    projectDownloadFinalFiles: (projectId: string) =>
        `/projects/${projectId}/deliverables-download`,
    projectFinalFiles: (projectId: string) => `/projects/${projectId}/final-files`,
    projectFinalFilesVariation: (projectId: string, variationId: string) =>
        `/projects/${projectId}/final-files/variation/${variationId}`,
    projectCompleted: (projectId: string) => `/projects/${projectId}/completed`,
    projectFile: (projectId: string, fileId: string, params?: ProjectFileParams) => {
        const paramSet = new URLSearchParams('');

        if (params?.deliverableId) {
            paramSet.append('deliverableId', params.deliverableId);
        }
        if (params?.fileStage) {
            paramSet.append('fileStage', params.fileStage);
        }
        if (params?.folderId) {
            paramSet.append('folderId', params.folderId);
        }

        const paramStr = paramSet.toString();

        return paramStr
            ? `/projects/${projectId}/files/${fileId}?${paramStr}`
            : `/projects/${projectId}/files/${fileId}`;
    },
    projectFinish: (projectId: string, finishProjectRequestId?: string) =>
        `/projects/${projectId}/complete${
            finishProjectRequestId ? `/${finishProjectRequestId}` : ''
        }`,
    projectInvoiceRequestRevision: (projectId: string, invoiceId: string) =>
        `/projects/${projectId}/invoices/${invoiceId}/request-revision`,
    projectSearch: (projectId?: string) =>
        projectId ? `/projects/${projectId}/search` : `/projects/search`,
    projectShowFolder: (
        projectId: string,
        folderId: string,
        stage?: 'reference' | 'in-progress' | 'declined' | 'final'
    ) =>
        stage
            ? `/projects/${projectId}/files/folder/${folderId}/${stage}`
            : `/projects/${projectId}/files/folder/${folderId}`,
    projectReviewDesigner: (projectId: string) => `/projects/${projectId}/review-designer`,
    user: (userId: string) => `/users/${userId}`,
    userAvatar: (userId: string, size = 96) => `/avatars/users/${userId}/${size.toString()}`,
    userProfile: (userId: string) => `/profiles/${userId}`,
    userProfileCategory: (userId: string, categoryKey: string) =>
        `/profiles/${userId}?category=${categoryKey}&order=featured#filter-controls`,
    userLastSeenTime: (userId: string) => `/profiles/xhr/last-seen-time/users?ids=${userId}`,
    projectDraft: (projectDraftId: string) => `/projects/drafts/${projectDraftId}`,
    project: (projectId: string) => `/projects/${projectId}`,
    projectOverview: (projectId: string) => `/projects/${projectId}/overview`,
    projectFiles: (projectId: string) => `/projects/${projectId}/files`,
    projectFilesFolder: (projectId: string, folderId: string) =>
        `/projects/${projectId}/files/folder/${folderId}`,
    projectManageFolders: (projectId: string) => `/projects/${projectId}/files/folder/manage`,
    projectFilesFolderStage: (projectId: string, folderId: string, fileStage: string) =>
        `/projects/${projectId}/files/folder/${folderId}/${fileStage}`,
    projectFilesStage: (projectId: string, fileStage: string) =>
        `/projects/${projectId}/files/stage/${fileStage}`,
    projectFileDownload: (projectId: string, fileId: string) =>
        `/projects/${projectId}/files/${fileId}/download`,
    projectFilesDownload: (projectId: string, fileIds: string[]) =>
        `/projects/${projectId}/files-download?${fileIds.map((id) => `f[]=${id}`).join('&')}`,
    projectDesignTransferAgreementDownload: (projectId: string) =>
        `/projects/${projectId}/design-transfer-agreement/download`,
    activeProjects: () => `/projects/active`,
    projectDesigns: () => `/projects/dashboard/completed`,
    contestDesigns: () => `/user/all-designs`,
    worksampleUploader: () => `/profiles/designs/work-samples/add`,
    portfolioUploader: () => `/account/portfolio/upload`,
    portfolioEditDesign: (id: number) => `/account/portfolio/edit/${id}`,
    userCredits: () => `/user/credits`,
    userTransactions: () => `/user/transactions`,
    launch: () => `/launch`,
    howItWorks: () => `/how-it-works`,
    qualityDesignerResource: () => `/designer-resource-center/quality-standards`,
    projectsDesignerResource: () => `/designer-resource-center/introduce-1-to-1-projects`,
    setFeesAndClientExpectations: () =>
        `/designer-resource-center/set-fees-and-client-expectations`,
    buildYourBrand: () => `/designer-resource-center/freelancer-toolkit/build-your-brand`,
    privacyPolicy: () => `/legal/privacy`,
    termsOfUse: () => `/legal/terms-of-use`,
    termsAndConditions: () => `/legal/terms-and-conditions`,
    updatedTermsAndConditions: () => `/account/updated-terms-and-conditions`,
    studio: () => `/pro/studio`,
    inviteDesignerToWork: (userId: string) => `/profiles/${userId}/invites`,
    inviteUserToWork: (userId: string) => `/projects/create/${userId}`,
    yourClients: () => `/your-clients`,
    earningsPage: () => `/accounts/earnings`,
    browseContestsForCategory: (category: string) => `/${category}/contests`,
    browseContestsForIndustry: (industry: string) => `/contests?industry=${industry}`,
    handover: (handoverId: string) => `/handover/${handoverId}`,
    handoverFile: (handoverId: string, handoverFileId: string, selectedEventId?: string) =>
        `/handover/${handoverId}/files/${handoverFileId}${
            selectedEventId ? `?selectedEventId=${selectedEventId}` : ''
        }`,
    handoverFiles: (handoverId: string) => `/handover/${handoverId}/files`,
    handoverDta: (handoverId: string) => `/handover/${handoverId}/files#dta`,
    handoverAcceptWebsite: (handoverId: string) => `/handover/${handoverId}/files#website`,
    handoverMessages: (handoverId: string) => `/handover/${handoverId}/messages`,
    handoverHistory: (handoverId: string) => `/handover/${handoverId}/history`,
    goldContests: () => `/contests?status=open&mid-level=1`,
    platinumContests: () => `/contests?status=open&top-level=1`,
    yourContests: () => `/contests/your-contests`,
    projectsDashboard: (tab: ProjectsDashboardTab = 'active') => `/projects/dashboard/${tab}`,
    browseContests: () => `/contests`,
    contestsPurchaseAddonsModal: (listingId: string, handoverId: string) =>
        `/contests/${listingId}?showPurchaseAddonsModal=${handoverId}`,
    browseCategories: () => `/categories`,
    browseCategory: (key: string) => `/${key}`,
    yourDesigners: () => `/designers/contacts`,
    findDesigners: () => `/designers/search`,
    contestEntries: (listingId: string) => `/contests/${listingId}/entries`,
    contestPolls: (listingId: string) => `/contests/${listingId}/entries/polls`,
    contestViewBrief: (listingId: string) => `/contests/${listingId}/brief`,
    contestViewMessages: (listingId: string) => `/contests/${listingId}/messages`,
    launchBrief: (categoryKey: string, params?: LaunchBriefParams) => {
        const paramSet: string[] = [];
        if (params?.industry) {
            paramSet.push(`business_industry=${encodeURIComponent(params.industry)}`);
        }
        if (params?.requestCodingQuote) {
            paramSet.push(`coding_quote=1`);
        }

        if (params?.comments) {
            paramSet.push(`comments=${encodeURIComponent(params.comments)}`);
        }

        if (params?.packageType) {
            paramSet.push(`package=${encodeURIComponent(params.packageType)}`);
        }

        return paramSet.length
            ? `/launch/${categoryKey}?${paramSet.join('&')}`
            : `/launch/${categoryKey}`;
    },
    squareOnlineStoreBrief: () => '/briefs/square-online/create',
    squareSpaceBrief: () => '/briefs/squarespace/create',
    squareOnlineProjectTypes: () => {
        return 'https://99designs.community/t/square-online-projects/328288';
    },
    squarespacePricing: () => 'https://www.squarespace.com/pricing',
    squarespaceDomainNameSearch: () => 'https://www.squarespace.com/domain-name-search',
    squareOnlineSelfServeInfo: () => '/briefs/square-online-self-serve',
    squareOnlineSelfServeSetupGuide: () =>
        'https://squareup.com/help/us/en/article/7142-set-up-self-serve-ordering-and-qr-codes-with-square-online',
    genericWebBuilderBrief: (platform: string) => `/briefs/web-builder/create?platform=${platform}`,
    projectsBrief: (params?: ProjectsBriefParams) => {
        const paramSet: string[] = [];

        if (params?.category) {
            paramSet.push(`category=${params.category}`);
        }

        if (params?.designerId) {
            paramSet.push(`designerId=${params.designerId}`);
        }

        return paramSet.length
            ? `/briefs/projects/create?${paramSet.join('&')}`
            : '/briefs/projects/create';
    },
    brief: (id: string, step?: number) => (step ? `/briefs/${id}/${step}` : `/briefs/${id}`),
    briefCreate: (slug: string, designerId?: number) => {
        let path = `/briefs/create/${slug}`;
        if (designerId) {
            path = path + `?designerId=${designerId}`;
        }
        return path;
    },
    briefOrderItem: (orderItemId: string) => `/briefs/order-item/${orderItemId}`,
    designTransferAgreement: () => '/legal/design-transfer-agreement',
    webGetStarted: (source?: string) => `/web/get-started${source ? source : ''}`,
    brandingGetStarted: (source?: string) => `/branding-design/get-started${source ? source : ''}`,
    yourWork: (status?: WorkPageStatus) => `/work${status ? `/${status}` : ''}`,
    startNew: () => '/start-new',
    help: () => '/help',
    helpContact: () => '/help/contact',
    squareOnlineFAQs: () => {
        return 'https://99designs.community/t/so-marketplace-faqs/324094';
    },
    nationBuilderFAQs: () => 'https://support.nationbuilder.com/en/',
    facebookLandingPage: () => '/facebook',
    facebookAdHelp: () => 'https://www.facebook.com/business/ads',
    contestEntryDesign: (listingId: string, designId: string) =>
        `/contests/${listingId}/entries/${designId}`,
    websiteBuildersCompare: () => '/website-builders#compare',

    adminOrder: (orderId: string) => {
        return `https://admin.99designs.com/order/${orderId}`;
    },
    browseProjects: (filter?: BrowseJobsFilter) =>
        filter ? `/projects/browse/${filter}` : `/projects/browse`,
    browseProjectsJob: (jobId: string) => `/projects/job/${jobId}`,
    browseProjectsJobOverview: (jobId: string) => `/projects/job/${jobId}/overview`,
    browseProjectsJobDesigners: (jobId: string) => `/projects/job/${jobId}/designers`,
    browseProjectsJobApply: (jobId: string) => `/projects/job/${jobId}/apply`,
    ssoNewUser: () => `/auth/sso/new-user`,
    vistaprintHelpCentre: (locale: string) => HELP_CENTER_URLS[locale] || HELP_CENTER_URLS['en_US'],
    blog: () => `/blog`,
    blogPost: (key: 'tips' | 'freelancing', post: string) => `/blog/${key}/${post}/`,
    supportUrl: (
        zendeskId: string,
        sections: 'articles' | 'categories' | 'sections',
        lang: ZendeskLanguage
    ) => `${supportBaseUrl(lang)}/${sections}/${zendeskId}`,
    supportBaseUrl,
    supportContactUrl: (lang: ZendeskLanguage, role?: 'customer') => {
        const baseUrl = `${supportBaseUrl(lang)}/requests/new`;
        if (!role) {
            return baseUrl;
        }
        return `${baseUrl}?ticket_form_id=180343`;
    },
    oAuthLogin: () => '/oauth/login',
    vistaUrlByLocale,
};

interface LaunchBriefParams {
    requestCodingQuote?: boolean;
    comments?: string;
    industry?: string;
    packageType?: PackageType;
}

interface ProjectsBriefParams {
    category?: string;
    designerId?: string;
}

interface ProjectFileParams {
    deliverableId?: string;
    fileStage?: string;
    folderId?: string;
}

type PackageType = 'bronze' | 'silver' | 'gold' | 'platinum';
