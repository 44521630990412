import React, { ElementType } from 'react';
import * as CSS from 'csstype';
import styled, { css } from 'styled-components';
import { ResponsiveProp, resolveResponsiveProp } from '../../lib/responsiveProps';
import { colors, typography } from '../../settings';

export interface TextProps {
    align?: CSS.Property.TextAlign;
    as?: ElementType<React.HTMLAttributes<HTMLElement>>;
    children: React.ReactNode;
    color?: CSS.Property.Color | colors.Color;
    /**
     * Sets the font-family CSS property based on typography settings
     */
    fontFamily?: keyof typeof typography.fontFamilies;
    italic?: boolean;
    /**
     * Sets the line-height CSS property based on typography settings.
     */
    lineHeight?: keyof typeof typography.lineHeights;
    /**
     * Sets the font-size CSS property based on typography settings
     */
    size?: keyof typeof typography.typeScale;
    /**
     * Text must be block displayed.
     * Truncates the text with an ellipsis after one, or a specified amount
     * of lines.
     * For multi-line truncation, the Text element cannot have any bottom
     * padding.
     * May have undesired results if the Text element has child elements.
     */
    truncate?: boolean | ResponsiveProp<number>;
    /**
     * Sets the overflow-wrap CSS property
     */
    overflowWrap?: CSS.Property.OverflowWrap;
    /**
     * Sets the word-break CSS property
     */
    wordBreak?: CSS.Property.WordBreak;
    /**
     * Sets the font-weight CSS property based on typography settings
     */
    weight?: 'normal' | 'bold';
}

const truncateStyle = (truncate: TextProps['truncate']) => css`
    ${truncate &&
    css`
        overflow: hidden;

        ${typeof truncate === 'boolean'
            ? css`
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  max-width: 100%;
              `
            : css`
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  ${resolveResponsiveProp(truncate, '-webkit-line-clamp')};
              `}
    `}
`;

export function textStyles(props: TextProps) {
    const {
        align,
        color = 'grey800',
        fontFamily = 'root',
        italic,
        lineHeight,
        size = 'size3',
        truncate,
        overflowWrap,
        wordBreak,
        weight = 'root',
    } = props;

    return css`
        /* Removes the global margin applied to <p> */
        margin-bottom: 0;

        ${align &&
        css`
            text-align: ${align};
        `}
        ${italic &&
        css`
            font-style: italic;
        `}
        ${truncate && truncateStyle(truncate)}

        ${lineHeight &&
        css`
            line-height: ${typography.lineHeights[lineHeight]};
        `}
        ${overflowWrap &&
        css`
            overflow-wrap: ${overflowWrap};
        `}
        ${wordBreak &&
        css`
            word-break: ${wordBreak};
        `}

        color: ${colors.getColor(color)};
        font-family: ${typography.fontFamilies[fontFamily]};
        font-size: ${typography.typeScale[size]}px;
        font-weight: ${typography.fontWeights[weight]};
    `;
}

export const Text = styled.span.attrs<{ className?: string }>({ className: 'emu' }).withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
        !['color', 'fontFamily'].includes(prop) && defaultValidatorFn(prop),
})(textStyles);
