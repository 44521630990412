import React from 'react';
import { Heading, Link } from '@99designs/emu';

export interface JobCardTitleProps {
    url: string;
    text: string;
}

export const JobCardTitle = ({ url, text }: JobCardTitleProps) => {
    return (
        <Heading appearanceLevel={5} accessibilityLevel={5}>
            <Link href={url} fontFamily="heading" size="size4" underline="hover">
                {text}
            </Link>
        </Heading>
    );
};
