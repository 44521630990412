import React from 'react';
import { decode } from 'html-entities';
import styled from 'styled-components';
import { Box, Divider, SmallSectionSubheading, Stack } from '@99designs/emu';
import { colors, spaces, typography } from '@99designs/emu/settings';
import { IconPlus1, IconTick1 } from '@99designs/icons';
import { ProductMessageFormatAndLinkify } from '../FormatAndLinkify';

export interface Feature {
    title: string;
    description?: string;
    ctaLink?: string | null;
}

type Props = {
    featureColor?: string;
    features: Feature[];
    additionalDetails?: Feature[];
};

const TickColumn = styled.span`
    color: ${colors.forrestLyf};
`;

const List = styled.ul`
    list-style: none;
    margin: 0;
    padding-left: 0;
    padding-right: ${spaces['1.5bu']};
`;

const ListItemTitle = styled.span<{ color?: string }>`
    font-weight: ${typography.fontWeights.bold};
    color: ${({ color }) => color};
    :last-child {
        display: block;
        margin-bottom: ${spaces['1.5bu']};
    }
`;

export function ProductDetails({ features, featureColor, additionalDetails = [] }: Props) {
    return (
        <>
            <Stack space="2bu">
                <SmallSectionSubheading fontWeight="bold">
                    {__("What's included:")}
                </SmallSectionSubheading>
                <List>
                    <Stack space="0.75bu">
                        {features.map((feature, index) => (
                            <Box display="flex" key={`${index}-${feature.title}`}>
                                <Box maxWidth={spaces['1.5bu']} marginRight="1.5bu">
                                    <TickColumn>
                                        <IconTick1
                                            offsetRight={true}
                                            aria-label={__('available')}
                                        />
                                    </TickColumn>
                                </Box>
                                <div>
                                    <ListItemTitle color={featureColor}>
                                        {feature.title}
                                    </ListItemTitle>
                                    {feature.description && (
                                        <ProductMessageFormatAndLinkify
                                            rawHtml={decode(feature.description)}
                                            ctaLink={feature.ctaLink}
                                        />
                                    )}
                                </div>
                            </Box>
                        ))}
                    </Stack>
                </List>
            </Stack>
            {additionalDetails.length > 0 && (
                <Stack space="2bu">
                    <Divider />
                    <SmallSectionSubheading fontWeight="bold">
                        {__('Things that might cost more outside of this package:')}
                    </SmallSectionSubheading>
                    <List>
                        <Stack space="0.75bu">
                            {additionalDetails.map((detail, index) => (
                                <Box display="flex" key={`${index}-${detail.title}`}>
                                    <Box maxWidth={spaces['1.5bu']} marginRight="1.5bu">
                                        <IconPlus1
                                            offsetRight={true}
                                            aria-label={__('Optional add on')}
                                        />
                                    </Box>
                                    <div>
                                        <ListItemTitle color={featureColor}>
                                            {detail.title}
                                        </ListItemTitle>
                                        {detail.description && (
                                            <ProductMessageFormatAndLinkify
                                                rawHtml={decode(detail.description)}
                                                ctaLink={detail.ctaLink}
                                            />
                                        )}
                                    </div>
                                </Box>
                            ))}
                        </Stack>
                    </List>
                </Stack>
            )}
        </>
    );
}
