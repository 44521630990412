/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconCircle1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M4.78 5.026c-8.087 8.364-4.565 18.24-.024 22.776s14.911 7.537 22.73-.325c7.82-7.862 3.769-18.354-.174-22.277S12.867-3.337 4.78 5.027zm10.478-.253c15.196-.443 17.634 23.343 1.452 23.15S.062 5.216 15.258 4.773z"
            fill="currentColor"
        />
    );
}
