/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconVideo1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M17.733 23.2c0 .267-.133 3.2.133 3.333s7.6 2.267 8.4 2.4 1.333.533.933 1.733c-.267 1.2-.667 1.333-1.6 1.2s-7.467-1.867-8.667-2.267-1.733 0-2.533.267c-.8.133-7.733 1.867-8 2s-.933.4-1.333-1.067.4-2 .667-2 8.4-1.867 8.533-2.267c.267-.4.133-2.133.133-3.333H1.866c-1.067 0-2-.8-2-1.867.133-.8.267-17.333.267-18.133s1.2-1.6 1.867-1.6 12.533-.133 12.533-.133S14.666-.001 16-.001s1.067 1.333 1.467 1.467 11.467 0 12.133 0 2.133.933 2.133 2.133.267 16.267.267 17.6-1.2 2-1.733 2c-.267-.133-6.133 0-12.533 0zM20.4 12.8c0-.267-6.8-4.267-7.333-4.667-.4-.4-.667 0-.667.4s.267 7.067.267 7.733.4.4.8.267c.267-.133 6.133-3.067 6.533-3.2.267-.133.4-.267.4-.533z"
            fill="currentColor"
        />
    );
}
