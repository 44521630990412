/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconMenuList4(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M3.51 2.033C.918 2.301-.354 3.885-.233 5.831s1.256 3.791 3.86 3.776C6.231 9.591 7.625 8.485 7.494 5.8S6.102 1.765 3.51 2.033zM1.056 13.744c-1.548 1.927-1.281 3.9.177 5.195s3.961 1.289 5.425-.086 1.719-3.306-.268-5.117-3.786-1.92-5.334.008zM-.217 26.443c.268 2.458 1.852 3.664 3.798 3.548s3.713-1.89 3.776-3.897c.063-2.007-1.122-3.553-3.807-3.429s-4.035 1.319-3.767 3.777zM9.768 5.19c-.064 1.11-.189 1.388.063 2.144s1.78.711 2.216.711c.436 0 16.432-.264 17.769-.173s1.701-.823 1.763-1.356c.062-.534.255-1.426.127-2.05s-1.188-1.367-2.255-1.187c-1.067.18-16.465.226-17.529.317s-2.091.485-2.156 1.595zm-.031 9.958c0-.758.857-1.392 1.605-1.392s17.505-.001 18.388.162c.883.163 1.318.654 1.318 1.218s.127 1.755-.122 2.153c-.249.397-.886.641-1.509.641s-17.125.126-17.821.045c-.696-.081-2.108-.331-2.051-1.16s.192-.909.192-1.667zm-.011 9.936c-.12.591-.115 1.802-.053 2.284s.308.633 1.163.711c.855.077 18.358.255 18.927.133s1.177-.509 1.177-.911-.062-1.541 0-1.982.07-1.121-1.32-1.121c-1.39 0-17.485.085-18.232.085s-1.542.209-1.662.801z"
            fill="currentColor"
        />
    );
}
