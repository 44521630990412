import React from 'react';
import { DistributiveOmit } from '../../utils/helperTypes';
import { Field, FieldLabelType, FieldProps } from '../Field';
import { BaseCombobox, BaseComboboxProps } from './BaseCombobox';

// FieldLabelType is already included in BaseComboboxProps as it's needed
// by SingleSelect/MultiSelect
export type ComboboxProps = DistributiveOmit<FieldProps, keyof FieldLabelType> &
    DistributiveOmit<BaseComboboxProps, 'error'>;

/**
 * A pre-made form component for select-like inputs.
 * Allows for both single and multiple selections based on the props passed.
 *
 * Restricts value to allowed options only.
 *
 * Follows the 'combobox with list autocomplete' WAI-ARIA pattern.
 *
 * Requires one of: `label`, `aria-label`, or `aria-labelledby`.
 *
 * For shorter lists or when the additional features are not needed, @see Select.
 */
export const Combobox = React.forwardRef<HTMLInputElement, ComboboxProps>(function (
    { description, error, helperText, ...rest },
    ref
) {
    const { disabled, id, required } = rest;

    return (
        <Field
            description={description}
            disabled={disabled}
            error={error}
            helperText={helperText}
            id={id}
            label={'label' in rest ? rest.label : undefined}
            required={required}
        >
            <BaseCombobox ref={ref} error={!!error} {...rest} />
        </Field>
    );
});
