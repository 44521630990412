import styled, { css, keyframes } from 'styled-components';
import { colors } from '@99designs/emu/settings';

const bounceInAnimation = keyframes`
    0% {
        transform: scale(1);
        opacity: 0;
    }

    50% {
        transform: scale(1.3);
        opacity: 1;
    }

    75% {
        transform: scale(0.9);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

export interface IconButtonProps {
    bounceIn?: boolean;
    disabled?: boolean;
}

/**
 * @deprecated replaced by `IconButton` in `@99designs/emu`
 */
export const IconButton = styled.button<IconButtonProps>`
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    text-align: center;
    display: inline;
    position: relative;
    z-index: 0;
    overflow: visible;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        outline: none;

        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            width: 2em;
            height: 2em;
            border-radius: 50%;
        }
    }

    ${({ bounceIn = false }) =>
        bounceIn &&
        css`
            animation-name: ${bounceInAnimation};
            animation-duration: 0.2s;
            animation-timing-function: linear;
        `}

    ${({ disabled = false }) =>
        disabled &&
        css`
            &:disabled {
                &,
                &:hover,
                &:focus,
                &:active {
                    cursor: not-allowed;
                    color: ${colors.grey500};

                    &:before {
                        display: none;
                    }
                }
            }
        `}
`;
