import React from 'react';
import styled from 'styled-components';
import { breakpoints, typography } from '@99designs/emu/settings';

export type Width = 'paragraph' | 'small' | 'input-default' | 'focus-panel' | 'select-default';

interface MaxWidthProps {
    children: React.ReactNode;
    width: Width;
}

const WidthMapper: Record<Width, string> = {
    paragraph: `max-width: ${typography.maxWidths.paragraph};`,
    small: 'max-width: 26em;',
    'input-default': `max-width: ${typography.maxWidths.form.default};`,
    'focus-panel': 'max-width: 500px;',
    'select-default': `
        @media (min-width: ${breakpoints.medium[0]}px) {
            max-width: 18.125em;
        }
    `,
};

/**
 * @deprecated replaced by `Box` in `@99designs/emu`
 */
export const MaxWidth = styled.div<MaxWidthProps>`
    ${({ width }) => WidthMapper[width]}
`;
