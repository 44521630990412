export const appendUtmParamsToUrl = (
    baseUrl: string,
    utmQueryParams: Record<string, string>
): string => {
    const url = new URL(baseUrl);
    const params = new URLSearchParams(url.search);

    Object.entries(utmQueryParams).forEach(([key, value]) => {
        params.append(key, value);
    });

    url.search = params.toString();

    return url.toString();
};
