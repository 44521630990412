/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconNotGuaranteed1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M17.969 17.656c.05.038.1.069.144.106.206.163.375.363.506.587.131.231.194.512.194.837s-.063.619-.175.875c-.119.256-.281.475-.488.663s-.444.337-.712.45a3.242 3.242 0 01-.875.225V19.13l1.406-1.475z"
            fill="currentColor"
        />,
        <path
            d="M8.519 27.725l2.425-1.556.281 4.938 3.131-3.831 1.794 4.613 1.794-4.613 3.131 3.831.281-4.938 4.163 2.675-1.25-4.781 4.788 1.256-2.675-4.162 4.938-.281-3.831-3.131 4.612-1.794-4.613-1.794 3.831-3.131-4.938-.281 2.675-4.162-.475-.219-9.381 9.969c.063.044.125.087.181.131.375.281.675.637.9 1.063s.337.956.337 1.594c0 .575-.113 1.094-.325 1.556a3.646 3.646 0 01-.881 1.188c-.369.331-.8.594-1.294.781s-1.012.3-1.55.337v1.35h-1.012v-1.35a6.413 6.413 0 01-2.219-.375l-4.819 5.119zM23.575 4.3l-2.206 1.419-.281-4.938-3.131 3.831L16.157 0l-1.794 4.612L11.232.781l-.281 4.938-4.162-2.675 1.256 4.787-4.787-1.256 2.675 4.162-4.938.281 3.831 3.131-4.612 1.794 4.612 1.794-3.831 3.137 4.938.281-2.675 4.162.731-.194 8.956-9.506a.46.46 0 00-.063-.05c-.356-.3-.637-.662-.844-1.088s-.306-.938-.306-1.537c0-.537.106-1.025.319-1.45.213-.431.494-.806.844-1.125s.756-.575 1.219-.769a4.611 4.611 0 011.444-.344v-1.35h1.013v1.344c.688 0 1.363.119 2.012.344l4.994-5.294zm-6.238 6.638a3.11 3.11 0 00-.769-.113v.931l.769-.819zm-2.731 10.287c.313.106.625.163.95.181v-1.188l-.95 1.006z"
            fill="currentColor"
        />,
        <path
            d="M15.556 12.844v-2.019c-.6.1-1.088.325-1.463.681s-.563.838-.563 1.438c0 .3.056.563.169.775.056.106.262.387.494.588l1.363-1.463zM27.938.5l-26.5 28.206L4.063 31.5l26.5-28.206L27.938.5z"
            fill="currentColor"
        />
    );
}
