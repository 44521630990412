/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconDocument1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M14.379.24C13.265.313 4.521.021 4.521.021S3 .416 3 2.079v28.41s1.256 1.512 3.116 1.512c1.861 0 20.554-.308 20.554-.308s2.001.163 2.001-1.809V15.138s.369-1.444-1.194-1.444H17.26s-1.911.151-1.911-2.192V1.848s.143-1.68-.971-1.607zm3.604 5.911v3.584s0 1.528 1.259 1.528h9.567s.671-.683-.504-1.62S18.822.099 18.822.099s-.923-.767-.923 1.961.084 4.091.084 4.091z"
            fill="currentColor"
        />
    );
}
