/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconMail4(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M2.767 5.096c-1.207.05-2.105.12-1.34.979s11.975 9.949 12.697 10.737c.722.788 1.53 1.719 3.101.137S28.729 7.446 29.581 6.642c.852-.804 1.368-1.755-.487-1.631s-25.12.034-26.327.085zM1.135 8.991c-.636-.646-.917-.778-1.086.503S.21 22.66.151 23.548c-.059.888-.012.719.957-.054s6.499-5.976 7.174-6.821c.675-.845 1.87-1.079.328-2.078S1.772 9.638 1.136 8.992zm10.905 9.064c-.578-.63-.98-1.216-1.714-.381s-7.533 7.21-8.104 7.906c-.571.696-1.098 1.463.05 1.398s26.102.043 27.176.019c1.074-.024 1.64-.48.403-1.221s-6.514-6.134-7.311-6.881c-.796-.747-1.576-2.453-2.553-1.2s-2.891 2.265-3.446 2.905c-.555.64-.985.83-1.968-.146s-1.956-1.768-2.533-2.399zm11.756-3.475c-1.192.897-1.535 1.23-.665 1.936s6.659 6.291 7.295 6.937c.636.646 1.6 2.158 1.573.887s.019-14.504-.043-15.182c-.062-.678-.253-.667-1.207.05s-5.762 4.475-6.954 5.372z"
            fill="currentColor"
        />
    );
}
