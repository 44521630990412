/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconGoogle1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M16.25 13.1h15c.2 1 .4 1.95.4 3.3C31.6 25.5 25.5 32 16.25 32c-8.85 0-16-7.15-16-16s7.15-16 16-16c4.3 0 7.95 1.6 10.7 4.2L22.4 8.6c-1.15-1.1-3.15-2.35-6.15-2.35-5.3 0-9.6 4.4-9.6 9.8s4.3 9.8 9.6 9.8c6.15 0 8.4-4.25 8.8-6.75h-8.8v-6z"
            fill="currentColor"
        />
    );
}
