/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconCamera1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M19.931 21.287c2.664-2.686 1.284-6.269-.059-7.609s-4.921-2.916-7.676-.059c-2.755 2.857-1.555 6.23-.008 7.779s5.079 2.574 7.743-.111z"
            fill="currentColor"
        />,
        <path
            d="M16.527 29.647c4.052 0 5.905.13 7.364.232.947.066 1.727.121 2.834.121C29.538 30 32 28.59 32 24.711V10.96c0-3.173-1.407-5.289-4.923-5.289s-3.868 0-4.22-.353c-.08-.081-.142-.272-.224-.523-.275-.848-.771-2.378-2.941-2.65-2.813-.353-7.385 0-8.791.353-1.001.251-1.467 1.394-1.78 2.16-.127.311-.228.559-.33.661-.352.353-1.055.353-4.22.353S0 7.435 0 10.608v13.398c0 3.526 1.407 5.641 4.571 5.641h11.956zm5.208-17.808c4.239 4.088 1.901 9.396-.088 11.39s-7.306 4.041-11.291.088-2.464-9.195-.165-11.49 7.304-4.076 11.543.012z"
            fill="currentColor"
        />
    );
}
