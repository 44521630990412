import React from 'react';
import { Helmet } from 'react-helmet-async';
import { usePageElement } from '@99designs/tracking';

export function Page({
    title = undefined,
    children,
}: {
    title?: string;
    children: React.ReactNode;
}) {
    usePageElement();

    // Uses Helmet title prop until https://github.com/nfl/react-helmet/issues/437 is fixed
    return (
        <>
            {title && <Helmet title={title} />}
            {children}
        </>
    );
}
