/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconMinus1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M4.835 4.685c-8.087 8.364-4.565 18.24-.024 22.776s14.911 7.537 22.73-.325c7.82-7.862 3.769-18.354-.174-22.277S12.922-3.678 4.835 4.686zm1.207 9.877c.308-.135 19.912-.039 20.175.138s.213 2.534-.008 2.758c-.221.223-19.956.261-20.174.039s-.301-2.8.007-2.934z"
            fill="currentColor"
        />
    );
}
