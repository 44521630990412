import React from 'react';
import { Field, FieldProps } from '../Field';
import { BaseInput, BaseInputProps } from './BaseInput';

export type InputProps = FieldProps & Omit<BaseInputProps, 'error'>;

/**
 * A pre-made form component for text inputs.
 * Allows for additional context to be displayed, such as description, and error.
 *
 * Requires one of: `label`, `aria-label`, or `aria-labelledby`.
 */
export const Input = React.forwardRef<HTMLInputElement, InputProps>(function (
    { description, error, helperText, ...rest },
    ref
) {
    const { disabled, id, required } = rest;

    return (
        <Field
            description={description}
            disabled={disabled}
            error={error}
            helperText={helperText}
            id={id}
            label={'label' in rest ? rest.label : undefined}
            required={required}
        >
            <BaseInput ref={ref} error={!!error} {...rest} />;
        </Field>
    );
});
