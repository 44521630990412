import { breakpoints } from '../settings';

/**
 * Returns a media query that selects for only the passed screen size.
 * @param screenSize
 */
export function only(screenSize: breakpoints.ScreenSize): string {
    const [minWidth, maxWidth] = breakpoints[screenSize];

    let queryString = '';
    if (minWidth && maxWidth) {
        queryString = `(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
    } else if (!minWidth && maxWidth) {
        queryString = `(max-width: ${maxWidth}px)`;
    } else if (minWidth && !maxWidth) {
        queryString = `(min-width: ${minWidth}px)`;
    }

    return `@media ${queryString}`;
}

/**
 * Returns a media query that selects for the passed screen size and all larger screen sizes.
 * @param screenSize
 */
export function minimum(screenSize: breakpoints.ScreenSize): string {
    const [minWidth] = breakpoints[screenSize];
    return `@media (min-width: ${minWidth}px)`;
}

/**
 * Returns a media query that selects for the passed screen size and all smaller screen sizes.
 * @param screenSize
 */
export function maximum(screenSize: breakpoints.ScreenSize): string {
    const maxWidth = breakpoints[screenSize][1];

    // `large` doesn't really make sense here, but if supplied, just return an empty "always true" media query
    if (maxWidth === undefined) {
        return '@media';
    }
    return `@media (max-width: ${maxWidth}px)`;
}

/**
 * Returns a media query that selects between two given screen sizes.
 * @param from
 * @param to
 */
export function between(from: breakpoints.ScreenSize, to: breakpoints.ScreenSize): string {
    const [minWidth] = breakpoints[from];

    // `large` does not have the second value. In that case, use the first value
    const maxWidth = breakpoints[to][1] ? breakpoints[to][1] : breakpoints[to][0];

    const queryString = `(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;

    return `@media ${queryString}`;
}
