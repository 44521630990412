import React from 'react';
import styled, { css } from 'styled-components';
import { IconTick1, IconX1 } from '@99designs/icons';
import { boxShadows, colors } from '../../settings';
import { DistributiveOmit } from '../../utils/helperTypes';
import { FieldClickable, FieldClickableProps } from '../Field';

type HTMLInputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type ToggleProps = DistributiveOmit<FieldClickableProps, 'size'> & {
    checked: HTMLInputProps['checked'];
    onChange: HTMLInputProps['onChange'];
};

const WIDTH = '56px';
const HEIGHT = '32px';
const PADDING = '2px';
const CIRCLE = '28px';
const ICON = '12px';

const toggleIconStyles = css`
    position: absolute;
    top: calc(${HEIGHT} - (${ICON} * 2) + ${PADDING});
    width: ${ICON};
    height: ${ICON};
    color: ${colors.white};
`;

const OffIcon = styled(IconX1)`
    ${toggleIconStyles}

    right: calc((${WIDTH} / 2 - ${ICON}) / 2)
`;

const OnIcon = styled(IconTick1)`
    ${toggleIconStyles}

    left: calc((${WIDTH} / 2 - ${ICON}) / 2)
`;

export const HiddenInput = styled.input`
    cursor: pointer;
    opacity: 0;
    position: absolute;

    /* Increase specificity, due to CSS reset */
    && {
        width: ${WIDTH};
        height: ${HEIGHT};
    }
`;

const VisibleInput = styled.div`
    cursor: pointer;
    pointer-events: none;
    border-radius: 40px;
    padding: 2px;
    background-color: ${colors.grey500};
    width: ${WIDTH};
    height: ${HEIGHT};

    ::before {
        content: '';
        display: block;
        background-color: ${colors.white};
        border-radius: 50%;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
        width: ${CIRCLE};
        height: ${CIRCLE};
        transform: translateX(0px);
        transition: transform 0.1s;
    }

    ${HiddenInput}:not(:disabled):not(:checked):hover + & {
        background-color: ${colors.grey600};
    }
    ${HiddenInput}:not(:disabled):focus + & {
        box-shadow: 0 0 0 4px ${colors.carnaval};
    }
    ${HiddenInput}:not(:disabled):focus:not(:focus-visible) + & {
        box-shadow: unset;
    }
    ${HiddenInput}:not(:disabled):focus-visible + & {
        box-shadow: ${boxShadows.focusRing};
    }
    ${HiddenInput}:not(:disabled):checked + & {
        background-color: ${colors.grey900};
    }
    ${HiddenInput}:not(:disabled):checked:hover + & {
        background-color: ${colors.grey600};
    }
    ${HiddenInput}:checked + & {
        ::before {
            transform: translateX(calc(${WIDTH} - ${CIRCLE} - (${PADDING} * 2)));
        }
    }
    ${HiddenInput}:disabled + & {
        background-color: ${colors.grey200};

        ::before {
            background-color: ${colors.grey300};
            box-shadow: none;
        }
    }
`;

/**
 * Quickly switches between two states: on/off.
 * Should be used for actions that occur immediately after toggling.
 *
 * Requires one of: `label`, `aria-label`, or `aria-labelledby`.
 */
export const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>(function (
    { id, checked, disabled, ...rest },
    ref
) {
    return (
        <FieldClickable
            disabled={disabled}
            id={id}
            label={'label' in rest ? rest.label : undefined}
        >
            <HiddenInput
                ref={ref}
                id={id}
                type="checkbox"
                role="switch"
                aria-checked={checked}
                checked={checked}
                disabled={disabled}
                {...rest}
            />
            <VisibleInput>
                <OffIcon aria-hidden="true" />
                <OnIcon aria-hidden="true" />
            </VisibleInput>
        </FieldClickable>
    );
});
