import { FormDescriptionProps, FormErrorProps, FormHelperTextProps } from '../Form';

interface GetFormIDsParams {
    description?: FormDescriptionProps['children'];
    error?: FormErrorProps['children'];
    id: string;
    helperText?: FormHelperTextProps['children'];
}

export function getFormIDs({ description, error, id, helperText }: GetFormIDsParams) {
    const descriptionId = `${id}-description`;
    const errorId = `${id}-error`;
    const labelId = `${id}-label`;
    const helperTextId = `${id}-helper-text`;

    const showHelperText = helperText && !error;

    const ids = [
        description ? descriptionId : null,
        error ? errorId : null,
        showHelperText ? helperTextId : null,
    ];
    const validIds = ids.filter(Boolean);

    return {
        ariaDescribedBy: validIds.length ? validIds.join(' ') : undefined,
        descriptionId,
        errorId,
        helperTextId,
        labelId,
        showHelperText,
    };
}
