import React from 'react';
import styled, { css } from 'styled-components';
import { borders, colors, typography } from '../../settings';
import { Box } from '../Box';
import { Text } from '../Text';
import { DeleteIcon } from './DeleteIcon';
import { tagStyles } from './styles';
import { DeletableTagAppearance, TagProps, TextTagAppearance } from './types';

/**
 * A Tag is a dynamic, inline component that represents an input, attribute or action.
 * They allow users to filter content and trigger actions.
 *
 * - Acts as plain text, displaying selected option.
 * - Acts as plain text, with Tooltip when hovered.
 * - Acts as a link.
 * - Acts as a button with an X icon to remove itself. In this case:
 *   + The component can take the default grey colour as border colour ( `appearance=transparent` ) or grey background ( `appearance=filled` )
 *   + The X icon button can be triggered to call the delete handler.
 *   + `Tooltip` can be used to display extra information. The target is the root component.
 */
export const Tag = React.forwardRef<HTMLSpanElement, TagProps>((props, ref) => {
    const { deleteIconProps: _deleteIconProps, ...rest } = props;
    return (
        <TagRoot ref={ref} {...rest}>
            <Box
                paddingLeft="0.5bu"
                paddingRight={props.deleteIconProps ? 'none' : '0.5bu'}
                flexGrow={1}
            >
                <Text size="size2" color={getTagColor(colors.grey800, rest.appearance)}>
                    {rest.children}
                </Text>
            </Box>
            {props.deleteIconProps && (
                <DeleteIcon {...props.deleteIconProps} appearance={props.appearance} />
            )}
        </TagRoot>
    );
});

interface TagRootProps {
    appearance?: DeletableTagAppearance | TextTagAppearance;
}
const TagRoot = styled.span.attrs<{ className?: string }>({ className: 'emu' })<TagRootProps>(
    ({ appearance }) => {
        return css`
            display: inline-flex;
            height: ${tagStyles.height};
            font-family: ${typography.fontFamilies.root};
            font-size: ${typography.typeScale.size3}px;
            border: ${tagStyles.borderWidth} solid;
            line-height: ${typography.lineHeights.root};
            border-color: ${getTagColor(colors.grey300, appearance)};
            border-radius: ${borders.borderRadiuses.small}px;
            white-space: nowrap;
            outline: 0;
            ${appearance === 'filled' &&
            css`
                background-color: ${colors.grey200};
                border-color: ${colors.grey200};
            `};
        `;
    }
);

// TODO: We might want to make this type guard a utility to check if a dynamic prop exists in the object
// As of TypeScript 4.4, this functionality is not built-in when using `hasOwnProperty` or `key in obj`
function hasOwnProperty<T, K extends PropertyKey>(obj: T, prop: K): obj is T & Record<K, string> {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}

const semanticColours = {
    success: colors.experimental.green600,
    error: colors.experimental.red600,
    info: colors.grey800,
    caution: colors.experimental.yellow600,
    callout: colors.grey800,
    active: colors.grey800,
};

const packageColours = {
    bronze: colors.experimental.bronze500,
    silver: colors.grey600,
    gold: colors.experimental.gold500,
    platinum: colors.grey700,
    custom: colors.experimental.blue500,
};

const getTagColor = (
    defaultColor: string,
    appearance?: TextTagAppearance | DeletableTagAppearance
): string => {
    if (!appearance) {
        return defaultColor;
    }

    if (hasOwnProperty(semanticColours, appearance)) {
        return semanticColours[appearance];
    }

    if (hasOwnProperty(packageColours, appearance)) {
        return packageColours[appearance];
    }

    return defaultColor;
};
