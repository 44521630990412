/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconStar2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M11.844 9.582c.363-.86 3.404-7.609 3.625-8.279s.667-.924 1.247.365c.579 1.288 3.232 6.897 3.342 7.716s.985 1.599 2.022 1.599 8.338.75 9.138.75 1.145.349.258.983c-.888.634-7.09 5.861-7.541 6.287s-.766.86-.414 1.948c.352 1.088 2.029 7.856 2.303 8.705s-.122 1.3-1.222.545c-1.099-.755-6.444-3.943-7.1-4.319s-1.776-1.127-3.453.201c-1.677 1.328-6.211 3.788-7 4.367s-1.353.301-.992-.7 2.365-8.275 2.508-8.913-.06-1.649-.996-2.311c-.936-.661-6.784-5.587-7.274-5.933s-.492-.912.939-.912c1.43 0 7.933-.784 8.818-.784s1.428-.454 1.792-1.314z"
            fill="currentColor"
        />
    );
}
