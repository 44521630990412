/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconFacebook1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M20.514 31.6c3.911-.083 7.194-.141 7.991-.123 2.099.046 3.16-.956 3.403-3.029s-.065-23.615-.123-25.443c-.058-1.828-1.239-2.853-2.86-2.982S3.973.384 2.598.308C1.223.233-.055 2.4.001 3.993S.815 27.522.8 28.731c-.015 1.209.926 3.359 3.602 3.267 1.262-.043 6.225-.172 11.339-.291V20.141H11.75v-4.622h3.991V12.11c0-3.956 2.416-6.11 5.945-6.11 1.69 0 3.143.126 3.566.182v4.134l-2.448.001c-1.919 0-2.291.912-2.291 2.25v2.951h4.577l-.596 4.622h-3.981V31.6z"
            fill="currentColor"
        />
    );
}
