import * as CSS from 'csstype';

export const chameleon = 'inherit';

// orange
export const heritage = '#fe5f50';
export const heritageLight = '#ff8a74';
export const heritageDark = '#e94b41';
export const heritageDarkest = '#c42927';
export const sandyButt = '#f6caa2';

//red
export const risingSun = '#ce283D';
export const littlePiggy = '#f0a9b7';

// purple
export const berlinThing = '#752873';
export const lavendork = '#b36ea7';

// blue
export const pacific = '#1f3ca6';
export const pacificLight = '#354fae';
export const pacificDark = '#1b3491';
export const carnaval = '#81a3e6';

//green
export const forrestLyf = '#2f9780';
export const mildredsCar = '#a4d2d4';

// brown
export const looseAlmond = '#95837b';
export const theTanTan = '#cbbba5';

// neutrals
export const grey900 = '#1c1b1a';
export const grey800 = '#313030';
export const grey700 = '#5b5a58';
export const grey600 = '#6e6d6c';
export const grey500 = '#969694';
export const grey400 = '#b6b5b3';
export const grey300 = '#dad9d7';
export const grey200 = '#eae9e7';
export const grey100 = '#f3f2f0';
export const grey050 = '#faf9f7';
export const white = '#ffffff';

export const semantic = {
    success: forrestLyf,
    error: risingSun,
    info: grey800,
    caution: berlinThing,
    callout: heritage,
    active: heritage,
};

export const typography = {
    root: grey800,
    heading: grey800,
    linkText: pacific,
    small: grey500,

    blockquote: {
        base: 'inherit',
        cite: grey500,
    },

    attribution: grey700,
    displayName: grey800,
    hr: grey800,
    soft: grey700,
    softer: grey500,
    softText: grey500,
    softIcon: grey300,
    softIconFocus: grey500,
    actionHover: pacific,
    contentBorder: grey300,
    contentBackground: white,
};

export const vivid = {
    heritage,
    sandyButt,
    risingSun,
    littlePiggy,
    berlinThing,
    lavendork,
    pacific,
    carnaval,
    forrestLyf,
    mildredsCar,
    looseAlmond,
    theTanTan,
};

export const social = {
    twitter: '#55acee',
    facebook: '#3b5998',
    pinterest: '#bd081c',
    linkedin: '#0077b5',
};

export const packages = {
    bronze: '#e0b48c',
    silver: grey400,
    gold: '#e8b954',
    platinum: grey700,
    custom: carnaval,
};

// Experimental colours that we're testing before adding as
// first-class colour tokens.
export const experimental = {
    heritage100: '#FFF9F6',
    heritage200: '#FFEFE7',
    heritage300: '#FFD6C3',
    heritage400: '#FFA37C',
    heritage500: '#FE5F50',
    heritage600: '#C94E41',
    heritage700: '#9C3F35',
    heritage800: '#6A2E26',
    heritage900: '#3D1E19',
    green100: '#F2FDFD',
    green200: '#E2F6F5',
    green300: '#C1E6E4',
    green400: '#7FC7BD',
    green500: '#35A690',
    green600: '#0F856E',
    green700: '#176857',
    green800: '#17483D',
    green900: '#132B25',
    blue100: '#F6FBFF',
    blue200: '#E6F4FD',
    blue300: '#C8E2F9',
    blue400: '#9ABCF0',
    blue500: '#6E94DE',
    blue600: '#4C73CD',
    blue700: '#2F56BC',
    blue800: '#1A3797',
    blue900: '#1C2154',
    purple100: '#FFF9FF',
    purple200: '#FBEEFB',
    purple300: '#EFD9EE',
    purple400: '#D6ADD2',
    purple500: '#BE81B4',
    purple600: '#A45E9A',
    purple700: '#8A4184',
    purple800: '#672566',
    purple900: '#3B1A3A',
    red100: '#FFF9FB',
    red200: '#FEEEF3',
    red300: '#F9D7E1',
    red400: '#EFA6B4',
    red500: '#E27283',
    red600: '#D24255',
    red700: '#B02442',
    red800: '#762039',
    red900: '#441822',
    brown100: '#FCFAF9',
    brown200: '#F5F1EC',
    brown300: '#E6DED3',
    brown400: '#C8B7A2',
    brown500: '#A49186',
    brown600: '#83736C',
    brown700: '#665A55',
    brown800: '#463F3B',
    brown900: '#2A2524',
    yellow100: '#FFFBE8',
    yellow200: '#FDF2BF',
    yellow300: '#F5DF80',
    yellow400: '#E0B52C',
    yellow500: '#C08C17',
    yellow600: '#A46A04',
    yellow700: '#875004',
    yellow800: '#623505',
    yellow900: '#3D1F01',
    bronze100: '#FDFAF7',
    bronze200: '#FAF0E8',
    bronze300: '#F2DBC7',
    bronze400: '#DDB28A',
    bronze500: '#B18E70',
    bronze600: '#8C715A',
    bronze700: '#6E5947',
    bronze800: '#4B3E32',
    bronze900: '#2D251F',
    gold100: '#FFFAF3',
    gold200: '#FEF0DB',
    gold300: '#F8DBA9',
    gold400: '#E1B352',
    gold500: '#B48F44',
    gold600: '#8F7238',
    gold700: '#6F5A2E',
    gold800: '#4D3E22',
    gold900: '#2E2517',
};

/**
 * @param color - 6-digit hex value
 * @param alpha - accepts a value between 0 and 1.0, similar to rgba()
 */
export const withAlpha = (color: string, alpha: number): string => {
    if (color[0] !== '#') {
        return color;
    }
    if (alpha < 0 || alpha > 1) {
        return color;
    }
    const alphaHex = Math.round(alpha * 255)
        .toString(16)
        .padStart(2, '0');

    return `${color}${alphaHex}`;
};

export const getColor = (color: CSS.Property.Color | Color) => {
    const exists = color in colors;
    if (exists) return colors[color as Color];
    return color;
};

export type Color =
    | 'chameleon'
    | 'heritage'
    | 'heritageLight'
    | 'heritageDark'
    | 'heritageDarkest'
    | 'sandyButt'
    | 'risingSun'
    | 'littlePiggy'
    | 'berlinThing'
    | 'lavendork'
    | 'pacific'
    | 'pacificLight'
    | 'pacificDark'
    | 'carnaval'
    | 'forrestLyf'
    | 'mildredsCar'
    | 'looseAlmond'
    | 'theTanTan'
    | 'grey900'
    | 'grey800'
    | 'grey700'
    | 'grey600'
    | 'grey500'
    | 'grey400'
    | 'grey300'
    | 'grey200'
    | 'grey100'
    | 'grey050'
    | 'white';

export const colors = {
    chameleon,
    heritage,
    heritageLight,
    heritageDark,
    heritageDarkest,
    sandyButt,
    risingSun,
    littlePiggy,
    berlinThing,
    lavendork,
    pacific,
    pacificLight,
    pacificDark,
    carnaval,
    forrestLyf,
    mildredsCar,
    looseAlmond,
    theTanTan,
    grey900,
    grey800,
    grey700,
    grey600,
    grey500,
    grey400,
    grey300,
    grey200,
    grey100,
    grey050,
    white,
    experimental,
};
