import styled from 'styled-components';
import { colors } from '../../settings';

/**
 * Renders a stylised `hr`.
 */
export const Divider = styled.hr.attrs<{ className?: string }>({ className: 'emu' })`
    border: 0;
    margin: 0;
    background-color: ${colors.grey300};
    color: ${colors.grey300};
    height: 1px;
`;
