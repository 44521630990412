/* eslint-disable */
/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';

type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconMagic(props: Props) {
    const newProps = {
        ...props,
        viewBox: '0 0 24 24',
    };
    return React.createElement(
        SvgIcon,
        newProps,
        <rect
            x={3.061}
            y={19}
            width={18.5}
            height={2.5}
            rx={1.25}
            transform="rotate(-45 3.06 19)"
            stroke="#000"
            strokeWidth={1.5}
        />,
        <path
            stroke="#000"
            strokeWidth={1.5}
            d="M12.53 9.47l2 2M12 3.773l.072.155.155.072-.155.072-.072.155-.072-.155L11.773 4l.155-.072.072-.155zM20 3.773l.072.155.155.072-.155.072-.072.155-.072-.155L19.773 4l.155-.072.072-.155zM19 13.773l.275.59.115.247.247.115.59.275-.59.275-.247.115-.115.247-.275.59-.275-.59-.115-.247-.247-.115-.59-.275.59-.275.247-.115.115-.247.275-.59z"
            fill="currentColor"
        />
    );
}
