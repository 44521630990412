import styled, { css } from 'styled-components';
import { IconButton } from './IconButton';

export function circleIconButton() {
    return css`
        border-radius: 50%;
        width: 2em;
        height: 2em;
        margin: 0.25em;
    `;
}
/**
 * @deprecated replaced by `IconButton` in `@99designs/emu`
 */
export const CircleIconButton = styled(IconButton)(circleIconButton);
