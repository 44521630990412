/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useEffect, useRef } from 'react';

export { QuoteReplyButton } from './QuoteReplyButton';

export const MessageContext = createContext<null | {
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
}>(null);

export const ReplyToContext = createContext<{
    replyToId: string | null;
    setReplyToId: React.Dispatch<React.SetStateAction<string | null>>;
}>({ replyToId: null, setReplyToId: () => {} });

/**
 * useQuoteFocusRef is a custom hook that focuses the textarea when a message is quoted
 */
function useQuoteFocusRef() {
    const ref = useRef<HTMLTextAreaElement>(null);
    const messageCtx = useContext(MessageContext);
    useEffect(() => {
        if (ref.current && (messageCtx?.message.length ?? 0) > 0) {
            ref.current.focus();
        }
    }, [ref, messageCtx?.message]);
    return ref;
}

// text area ref context
const TextAreaRefContext = React.createContext<React.RefObject<HTMLTextAreaElement> | null>(null);

// package exports

export const QuoteFocusRefProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const ref = useQuoteFocusRef();
    return <TextAreaRefContext.Provider value={ref}>{children}</TextAreaRefContext.Provider>;
};

/**
 * This hook checks for a ref provided by an outer context.
 * If none is provided, it returns a ref to the inner element.
 */
export function useContextAwareRef() {
    const contextRef = useContext(TextAreaRefContext);
    const ref = useRef<HTMLTextAreaElement>(null);
    const inputRef = contextRef ?? ref;
    return inputRef;
}
