import React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import { Box } from '@99designs/emu';

const StyledPillGroup = styled.div<StyledPillGroupProps>`
    margin-bottom: 1.6rem;
    font-size: 0;
    margin-bottom: 0;
    display: ${({ display }) => (display ? display : 'block')};
`;

interface StyledPillGroupProps {
    display?: CSS.Property.Display;
}

export interface PillGroupProps {
    children: React.ReactNode;
    display?: CSS.Property.Display;
}

export const PillGroup = ({ children, display }: PillGroupProps) => (
    <StyledPillGroup display={display}>
        {React.Children.map(children, (child) =>
            child !== null && child !== undefined ? (
                <Box display="inline-block" marginRight="0.25bu" marginBottom="0.25bu">
                    {child}
                </Box>
            ) : null
        )}
    </StyledPillGroup>
);
