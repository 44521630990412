import React from 'react';
import styled from 'styled-components';
import { typography } from '../../settings';
import { ClickableWithRoutingProps } from '../Clickable/';
import { Link } from '../Link';

export type AttributionLinkProps = ClickableWithRoutingProps<unknown>;

const ItalicLink = styled(Link)`
    font-style: ${typography.fontStyles.attribution};
`;

/**
 * For Attributions that require links.
 * Opens in a new tab by default.
 *
 * @see Attribution
 */
export const AttributionLink = React.forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    AttributionLinkProps
>(({ openInNewTab = true, ...props }, ref) => {
    return (
        <ItalicLink
            {...props}
            openInNewTab={openInNewTab}
            fontFamily="attribution"
            color="grey700"
            size="size2"
            ref={ref}
        />
    );
});
