import React from 'react';
import styled from 'styled-components';
import { Box, Inline, InlineProps } from '@99designs/emu';
import { mediaQuery } from '@99designs/emu/css';

const ButtonContainerWrapper = styled.div`
    display: flex;
    & > * {
        ${mediaQuery.only('small')} {
            display: block;
            width: 100%;
        }
    }
`;

/**
 * Switches Button layout from side-by-side to stacked on mobile width.
 * Effectively: Stack on Mobile, Inline above Mobile
 */
export function ButtonContainer({ space, ...rest }: InlineProps) {
    return (
        <ButtonContainerWrapper>
            <Inline
                {...rest}
                space={space}
                as={{
                    root: 'div',
                    child: (props) => (
                        <Box {...props} width={['100%', 'auto', 'auto', 'auto']}>
                            {props.children}
                        </Box>
                    ),
                }}
            />
        </ButtonContainerWrapper>
    );
}
