import React from 'react';
import { Text } from '../Text';

export interface FormDescriptionProps {
    children: React.ReactNode;
    /**
     * Applies disabled styling
     */
    disabled?: boolean;
    id: string;
}

/**
 * Displays additional information about a form component above the <input>
 * Can be used internally by form components, or used directly when needed.
 * If used directly, remember to set `aria-describedby` on the linked <input>
 * for accessibility.
 */
export function FormDescription({ children, disabled, id }: FormDescriptionProps) {
    return (
        <Text id={id} color={disabled ? 'grey400' : undefined}>
            {children}
        </Text>
    );
}
