import { MenuSeparator as AriakitMenuSeparator } from '@ariakit/react';
import styled from 'styled-components';
import { colors, spaces } from '../../settings';

// FIXME - This is actually 1px too high
/**
 * Used to separate items within a Menu.
 */
export const MenuDivider = styled(AriakitMenuSeparator)`
    background-color: ${colors.grey300};
    border: 0;
    color: ${colors.grey300};
    margin: 12px ${spaces['1bu']};
`;
