export type ScreenSize = 'small' | 'medium' | 'normal' | 'large';

export const small = [0, 599],
    medium = [600, 899],
    normal = [900, 1299],
    large = [1300, null];

// This is a set of viewports for the Storybook viewport addon.  This could however also be used directly in cases
// where you want example viewports for our breakpoint sizes.
export const viewports = {
    small: {
        name: 'Small',
        styles: {
            height: '568px',
            width: '320px',
        },
        type: 'mobile',
    },
    medium: {
        name: 'Medium',
        styles: {
            height: '1194px',
            width: '896px',
        },
        type: 'tablet',
    },
    normal: {
        name: 'Normal',
        styles: {
            height: '720px',
            width: '1280px',
        },
        type: 'desktop',
    },
    large: {
        name: 'Large',
        styles: {
            height: '1080px',
            width: '1920px',
        },
        type: 'desktop',
    },
};
