import React from 'react';
import { Stack } from '@99designs/emu';
import { CheckboxField } from '../CheckboxField';

interface Props {
    id?: string;
    options: { value: string; label: string; subtext?: string }[];
    value: string[];
    onChange: (items: string[]) => void;
}

/**
 * @deprecated replaced by `CheckboxGroup` in `@99designs/emu`
 */
export function CheckboxList({ options, value, onChange }: Props) {
    return (
        <Stack space="0.5bu">
            {options.map((option) => (
                <div key={option.value}>
                    <CheckboxField
                        checked={value.includes(option.value)}
                        onChange={() => {
                            const items = [...value];
                            if (items.includes(option.value)) {
                                items.splice(items.indexOf(option.value), 1);
                            } else {
                                items.push(option.value);
                            }
                            onChange(items);
                        }}
                        key={option.value}
                        value={option.value}
                        fieldTitle={option.label}
                        subText={option.subtext}
                    />
                </div>
            ))}
        </Stack>
    );
}
