/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function MarketingCalendarHour(props: Props) {
    const newProps = { ...props, viewBox: '0 0 48 48' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            fill="currentColor"
            d="M23.744 23.427c.382-.416.749-.813 1.114-1.212.542-.591 1.077-1.185 1.624-1.77a.897.897 0 011.299 1.236l-2.868 3.118c-.083.099-.16.203-.231.311a1.011 1.011 0 01-.708.427 1.016 1.016 0 01-.792-.237l-5.908-4.853c-.239-.198-.481-.392-.718-.591a.895.895 0 01-.293-.961.913.913 0 01.443-.533.902.902 0 01.999.112l5.743 4.73c.085.071.178.134.298.223zM23.754 13.134c0 .293.013.586-.003.879a.682.682 0 01-.208.5.69.69 0 01-.504.2.7.7 0 01-.639-.458.687.687 0 01-.041-.273c.001-.594.017-1.188.046-1.782a.66.66 0 01.652-.693.73.73 0 01.702.696c.02.309.004.621.004.931h-.01zM24.519 35.96c-.055.373-.093.748-.167 1.117a.69.69 0 01-.759.582.727.727 0 01-.62-.79c.016-.42.03-.84.039-1.261.01-.46.238-.747.622-.773a.874.874 0 01.828.711c.018.136.023.274.035.411l.021.003zM12.172 24.402c.258 0 .517-.009.775.001a.653.653 0 01.653.689.647.647 0 01-.659.653 30.312 30.312 0 01-1.677-.064.603.603 0 01-.568-.594.603.603 0 01.548-.613c.308-.036.619-.044.928-.063v-.009zM34.88 24.454c.24-.018.479-.044.72-.054a.698.698 0 01.704.382.694.694 0 01.074.272.681.681 0 01-.181.535.676.676 0 01-.521.217 4.573 4.573 0 01-1.113-.105.612.612 0 01-.527-.733c.059-.346.312-.524.741-.524h.104l.001.007zM32.256 17.985c-.565-.007-.848-.489-.595-.852.171-.236.379-.444.616-.614a.597.597 0 01.823.17.605.605 0 01-.124.834c-.258.189-.539.346-.72.461zM14.37 15.805a1.376 1.376 0 01.988.742.556.556 0 01-.165.678.54.54 0 01-.692.035 3.743 3.743 0 01-.664-.778c-.205-.341.042-.684.532-.676zM15.455 33.392c-.29.005-.486-.175-.459-.422a.916.916 0 01.162-.451c.182-.229.386-.44.608-.63a.501.501 0 01.7.044.481.481 0 01.036.679 9.137 9.137 0 01-.824.699.654.654 0 01-.222.082zM31.636 33.504a1.345 1.345 0 01-.309.325.381.381 0 01-.512-.111 3.633 3.633 0 01-.458-.77.53.53 0 01.111-.456.578.578 0 01.44-.108 1.665 1.665 0 01.727 1.119z"
        />,
        <path
            fill="currentColor"
            d="M43.436 35.769a3.213 3.213 0 00-2.139-2.741 9.749 9.749 0 00-1.157-.293c.029-.055.061-.121.097-.186.414-.743.864-1.469 1.231-2.235a5.346 5.346 0 00.59-2.362.609.609 0 00-1.189-.249c-.065.15-.121.304-.168.46a15.162 15.162 0 01-2.321 4.446 1.317 1.317 0 01-1.014.504c2.894-4.803 3.505-9.845 1.394-15.05-2.115-5.213-6.063-8.413-11.456-9.838l-.465-.342c.234-.159.458-.289.654-.451a1.326 1.326 0 00.518-1.054 1.433 1.433 0 00-.863-1.34 13.942 13.942 0 00-1.945-.676 8.625 8.625 0 00-2.9-.182 4.23 4.23 0 00-2.163.61 5.26 5.26 0 00-1.137 1.036 1.08 1.08 0 00-.224 1.139c.124.373.388.683.737.864.171.072.328.179.532.292-6.706 1.887-10.968 6.125-12.603 12.928l-.073-.043c-.961-.537-1.59-.476-2.157.643a5.74 5.74 0 00-.497 1.695 7.604 7.604 0 00.511 4.794c.194.415.49.774.86 1.044a.793.793 0 00.926.03 1.301 1.301 0 00.715-.765c.039.12.062.183.08.249a16.427 16.427 0 0012.765 11.589c.837.169 1.684.286 2.535.352 1.176.078 2.359.043 3.536.11a8.122 8.122 0 011.591.301.618.618 0 01.364.381c.058.199-.097.37-.287.472-.105.06-.213.114-.323.161a7.303 7.303 0 01-2.692.386c-.989.002-1.978-.035-2.966-.052-.028 0-.056.024-.118.052.149.154.306.299.472.434a6.631 6.631 0 003.614 1.153 21.259 21.259 0 006.056-.501 30.297 30.297 0 005.804-1.908 13.494 13.494 0 003.982-2.551 3.8 3.8 0 001.295-3.309zM6.561 25.362h-.017c0 .362.012.723-.004 1.083a.59.59 0 01-.091.311c-.011.175-.063.382-.269.391-.231.011-.3-.215-.322-.403a4.506 4.506 0 01.099-2.068c.013-.038.028-.077.045-.114.016-.058.023-.116.043-.174.052-.149.114-.312.316-.273.195.039.204.203.203.367-.004.293-.001.586-.001.878zM20.722 6.108c.285-.11.579-.198.87-.295.259.037.499.177.512.358l-.001.007c.048.042.079.1.088.163.007.104-.022.207-.083.292s-.15.145-.252.17a9.38 9.38 0 01-.781.257.422.422 0 01-.326-.019.413.413 0 01-.21-.251c-.002-.006-.001-.012-.002-.018a.438.438 0 01-.103-.311.405.405 0 01.287-.352zm2.859 32.305a14.217 14.217 0 11.04-28.438 14.217 14.217 0 01-.04 28.44z"
        />
    );
}
