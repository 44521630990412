/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconInvoice1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 27 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M26.02 6.72c-1.2-.96-7.6-6.6-7.6-6.6a.487.487 0 00-.264.001l.003-.001c-.4-.12-.62.32-.66.7a.84.84 0 00.001.184V1v3.74a7.148 7.148 0 00.003 1.734l-.004-.034c.002.217.046.423.124.611l-.004-.011v.001a1.34 1.34 0 001.277 1.339h7.683s.64-.7-.56-1.66z"
            fill="currentColor"
        />,
        <path
            d="M25.16 10.88h-8.22a3.744 3.744 0 01-.601-.25l.021.01a2.623 2.623 0 01-1.34-2.28v-.68-2.36a16.746 16.746 0 00-.172-3.413L14.86 2s.14-1.8-1-1.72S1.64 0 1.64 0A2.164 2.164 0 00.08 2.075v.026V2.1v.84q-.16 4.3 0 8.58v18.64a2.004 2.004 0 002.149 1.539l-.008.001c1.096.204 2.356.321 3.644.321.329 0 .657-.008.982-.023L6.801 32h8.24a17.431 17.431 0 002.374-.003l-.054.003h.14q2.68 0 5.34-.12h1.42a1.82 1.82 0 002.001-1.811l-.001-.052V12.44a1.14 1.14 0 00-1.06-1.561l-.043.001h.002zm-14 15.74l-.3.18a6.07 6.07 0 01-1.425.396L9.4 27.2v2.54s-.04.26-.16.26h-1.7c-.017.01-.038.016-.06.016s-.043-.006-.061-.016h.001v-.12a1.668 1.668 0 010-.368l-.001.008v-2.4a2.001 2.001 0 01-.633-.165l.013.005a4.556 4.556 0 01-3.432-3.128l-.008-.032a4.13 4.13 0 01-.28-1.508v-.013-.004a.3.3 0 01.178-.274L3.259 22h2.54c.08.001.149.05.179.119v.001a.229.229 0 010 .122v-.002a2.292 2.292 0 00.758 1.798l.002.002a3.091 3.091 0 001.789.44h-.008a2.274 2.274 0 001.681-.601l-.002.001c.298-.315.481-.74.481-1.209l-.001-.054v.003c0-1.06-.78-1.7-2.84-2.4-3.14-.9-4.48-2.22-4.48-4.62a4.38 4.38 0 012.889-4.271l.031-.01a5.182 5.182 0 011.054-.237l.026-.003a11.091 11.091 0 00-.002-1.555l.002.035v-.24.24a.66.66 0 000 .163v-.863s0-.54.12-.54c.56 0 1.12-.12 1.68 0 .12 0 .18.2.14.34v2.42h.06a4.149 4.149 0 013.189 2.099l.011.021c.345.656.563 1.429.6 2.248v.012a.201.201 0 01-.199.18h-.001c-.84 0-1.68-.12-2.54 0a.24.24 0 01-.3-.199v-.001a2.012 2.012 0 00-.185-.712l.005.012a1.752 1.752 0 01-.117-.307l-.003-.013-.14-.22a1.991 1.991 0 00-1.409-.58h-.033.002a1.66 1.66 0 00-1.818 1.309l-.002.011a3.472 3.472 0 00.147 1.285l-.007-.025c.084.161.184.299.301.421l-.001-.001c.2.14.42.26.6.4a11.96 11.96 0 012.738 1.151l-.058-.031c.357.136.66.284.948.455l-.028-.015c1.512.594 2.563 2.041 2.563 3.733 0 .052-.001.103-.003.155v-.007a4.299 4.299 0 01-2.474 4.149l-.026.011z"
            fill="currentColor"
        />
    );
}
