/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconFindDesigner1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M16.45 13.3c-2.65 3.2-2.95 7.55-.3 11.35-.75.15-2.25.2-2.95.2-1 0-9.75.1-11.1.1S0 24.9 0 23.6s.9-3.35 2.4-3.7c1-.25 2.05-.45 3.25-.9S7 18.1 7 17.8c0-.75-.05-1.1-1.35-1.85S4.6 13.15 4.6 12.8c0-.35-1.5-.7-1.5-2s.95-1 .95-1.75S3.4 7.9 3.4 6.5s.95-3.1 1.85-3.45.5.55.6-.7c0-.05-.4.7 0-.1s3.45-.9 5.9-1.95c2.5-1.1 3.1 1.1 3.75 1.6s-.4-.05 1.1.85 1 1.4 1 2.9-.8 3.7-.8 3.7 1 .4.8 1.75c-.2 1.35-1.15 1.2-1.15 1.85v.35zm1.6 10.95c.2-.15.1-.5-.1-.95s-2.4-5.2.85-8.7c3.3-3.55 8.35-3.3 11.15-.25s2.6 8-.1 10.4c-2.65 2.4-6.15 3.2-9.35 1.2-.55-.3-.85.15-.85.15s-5.65 5.75-5.9 5.85-1.85-1.55-1.85-1.75c.1-.2 5.95-5.8 6.15-5.95zM29 19.7c.1-2.2-1.85-4.9-4.45-4.7S19.9 17.25 20 19.35c.1 2.1 1.75 4.8 4.7 4.65 2.9-.15 4.2-2.1 4.3-4.3z"
            fill="currentColor"
        />
    );
}
