/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconTrash2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M2.755 4.895c-.064-1.094.44-2.196 1.464-2.196s5.245 0 6.14-.193c.895-.193.32-1.03.32-1.733s.064-.772 1.087-.772 6.716.193 7.611.129 1.218.389 1.154 1.354c-.064.966.128 1.416.959 1.416s4.221-.064 5.82 0c1.599.064 1.471.901 1.407 1.931s.128 2.124-1.343 1.867c-1.471-.257-21.554.064-22.892-.064S2.819 5.99 2.755 4.896zm2.743 3.859c-.898 0-1.026-.064-.77 1.545s1.407 18.217 1.727 19.375c.32 1.159-.251 2.441 1.791 2.317s14.002-.189 15.153-.06c1.151.129 1.535-.901 1.663-2.253s1.599-18.217 1.791-19.311c.192-1.094.576-1.738-1.023-1.674s-19.433.06-20.331.06zm5.754 3.036c1.021 0 1.599.065 1.663 1.095s.64 13.26.768 14.805-.512 1.223-1.663 1.223-1.087-.451-1.151-1.416c-.064-.966-.959-14.097-1.023-14.993s.386-.714 1.407-.714zm8.965 0c1.021 0 1.471-.183 1.407.714s-.959 14.028-1.023 14.993c-.064.966 0 1.416-1.151 1.416s-1.791.322-1.663-1.223c.128-1.545.704-13.775.767-14.805s.642-1.095 1.663-1.095z"
            fill="currentColor"
        />
    );
}
