/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconParagraphJustify1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M.159 6.075c.233.755 1.648.71 2.052.71s26.43-.264 27.668-.173c1.238.091 1.575-.821 1.632-1.354s.236-1.423.118-2.046c-.118-.623-1.099-1.364-2.087-1.185s-26.46.226-27.445.316c-.985.091-1.936.484-1.996 1.592s-.175 1.385.058 2.14zM2.1 10.483c.969 0 26.67-.001 27.814.162s1.346-.175 1.614.671c.268.847.323 2.654 0 3.051s-1.212.282-2.019.282c-.807 0-26.178.125-27.08.045s-2.708-.05-2.633-.878c.075-.828-.087-.953-.036-2.199s1.371-1.134 2.34-1.134zM-.196 21.382c.049.481.241.632.91.709s29.575.254 30.019.133c.445-.122.921-.508.921-.909s-.049-1.538 0-1.978c.049-.44.055-1.119-1.033-1.119s-28.891.085-29.476.085c-.585 0-1.207.209-1.301.799s-.09 1.798-.041 2.28zm.109 5.455c-.18.59-.172 1.798-.079 2.28s.461.632 1.739.709c1.278.077 27.447.254 28.297.133s1.759-.508 1.759-.909-.093-1.538 0-1.978.105-1.119-1.973-1.119c-2.078 0-26.141.085-27.259.085s-2.305.209-2.485.799z"
            fill="currentColor"
        />
    );
}
