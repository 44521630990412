/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconLogout1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M18.291 3.354c0 .413.043.644-.561.644L4.118 3.98c-.254 0-.318-.075-.318.487s-.117 22.578-.117 23c0 .422-.1.606.602.606s12.987-.05 13.546-.05c.558 0 .589.111.558.618s-.023 2.493-.023 2.926c0 .433-.187.433-.718.433L.351 31.989c-.444 0-.561-.004-.561-.458L-.25.529C-.25.15-.287 0 .277 0l17.41.022c.521 0 .649.065.649.474s-.046 2.858-.046 2.858zm13.013 13.141c.36-.258.738-.358.109-1.005s-8.145-7.732-8.492-8.055c-.347-.323-.736-.277-.989.239s-.994 1.146-1.268 1.463c-.275.318-.485.565.021 1.187s2.997 3.028 3.29 3.373c.293.345.235.41-.495.41s-11.069.068-11.511.068c-.442 0-.961-.223-.961.429v3.093c0 .423.133.299.869.299s11.141.133 11.711.133c.57 0 .464.261.149.641s-3.056 2.908-3.2 3.139c-.144.231-.242.453.027.812s1.58 1.836 1.894 2.072c.314.236.362.386.956-.217s7.53-7.823 7.89-8.081z"
            fill="currentColor"
        />
    );
}
