/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconFolder1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M.171 28.186c-.036 1.341.46 1.837 1.91 1.849s25.691-.216 27.659-.125 2.267-.293 2.267-3.131-.02-16.332-.02-18.508c0-2.175-.902-2.175-2.31-2.175s-9.188.236-12.669.108-1.625-3.978-6.036-4.115-5.58.024-7.96.041S1.245 5.063.997 5.629s-1.004.594-.99 1.547c.039 2.672.2 19.668.164 21.009z"
            fill="currentColor"
        />
    );
}
