import React, { SyntheticEvent, useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import Fade from '@material-ui/core/Fade';
// eslint-disable-next-line no-restricted-imports
import Popper from '@material-ui/core/Popper';
import styled from 'styled-components';
import { Avatar, Box, Button, Link, SmallSectionHeading } from '@99designs/emu';
import { spaces } from '@99designs/emu/settings';
import ClickAwayListener from '@99designs/frontend-components/components/ClickAwayListener';
import { IconX1 } from '@99designs/icons';
import { linkTo } from '@99designs/routing';
import { PopperCard } from '@99designs/spa-common';
import { OnWhiteIconButton } from '@99designs/spa-common';

const ClosePopupAction = styled.div`
    position: absolute;
    top: ${spaces['1bu']};
    right: ${spaces['1bu']};
`;

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    attributionElement: any;
    designerId: string;
    designerName: string;
    onOpen?: () => void;
}

const AttributionPopup = ({ attributionElement, designerId, designerName, onOpen }: Props) => {
    const [open, setOpen] = useState(false);
    const [arrow, setArrow] = useState<HTMLSpanElement | null>(null);

    const handleToggle = (event: SyntheticEvent<Element, Event>) => {
        event.preventDefault();
        setOpen((prev) => !prev);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleArrowRef = (node: HTMLSpanElement | null) => {
        setArrow(node);
    };

    useEffect(() => {
        if (open && onOpen) {
            onOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        if (!attributionElement) return;
        attributionElement.addEventListener('click', handleToggle);

        return () => {
            attributionElement.removeEventListener('click', handleToggle);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Popper
            open={open}
            anchorEl={attributionElement}
            transition
            modifiers={{
                flip: { enabled: true },
                offset: { enabled: true, offset: '0, 10px' },
                arrow: { enabled: true, element: arrow },
            }}
            placement="top"
            style={{ zIndex: 99 }}
        >
            {({ TransitionProps }) => {
                return (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Fade {...TransitionProps}>
                            <PopperCard triggerEl={{}} arrowRef={handleArrowRef}>
                                <ClosePopupAction>
                                    <OnWhiteIconButton
                                        aria-label={__('Dismiss')}
                                        onClick={handleClose}
                                    >
                                        <IconX1 />
                                    </OnWhiteIconButton>
                                </ClosePopupAction>

                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    padding="1.5bu"
                                    width="300px"
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        paddingBottom="1bu"
                                    >
                                        <Avatar url={linkTo.userAvatar(designerId)} size="medium" />
                                        <Box paddingLeft="1bu">
                                            <SmallSectionHeading>
                                                {designerName}
                                            </SmallSectionHeading>
                                            <Link
                                                href={linkTo.userProfile(designerId)}
                                                color="grey700"
                                                size="size2"
                                            >
                                                {__('Portfolio')}
                                            </Link>
                                        </Box>
                                    </Box>

                                    <Box>
                                        <Button
                                            appearance="primary"
                                            width="fill"
                                            href={linkTo.inviteDesignerToWork(designerId)}
                                        >
                                            {__('Invite to work')}
                                        </Button>
                                    </Box>
                                </Box>
                            </PopperCard>
                        </Fade>
                    </ClickAwayListener>
                );
            }}
        </Popper>
    );
};

export default AttributionPopup;
