/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconExclamationPoint1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M15.886.011C24.199-.33 32 7.447 32 16.003s-5.883 16.009-15.838 15.998C6.208 31.99.005 25.138 0 16.315S7.573.353 15.886.012zm.174 6.823c-2.688.153-2.657-.426-2.301 3.117s.21 8.287.578 9.059c.368.771 3.323 1.081 3.58-.117s.405-6.223.627-9.138c.222-2.915.329-2.951-2.483-2.922zm.085 14.847c-3.065-.006-3.753 4.423-.061 4.38s3.126-4.374.061-4.38z"
            fill="currentColor"
        />
    );
}
