/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconAnnotate1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M24.395 11.487c-.168.298-.629.211-1.048.211s-13.783.094-14.251.034c-.467-.06-1.404-.037-1.365-.659.038-.622-.046-.715-.019-1.651.028-.935.711-.852 1.213-.852s14.039 0 14.632.122c.594.121.699-.132.838.504s.167 1.994 0 2.291zm-6.9 5.013c.023.622-.524.599-.797.66-.272.06-8.069-.035-8.313-.035-.245 0-.514.086-.611-.211-.098-.298-.082-1.655 0-2.291.08-.636.142-.383.488-.504.346-.122 8.242-.121 8.535-.121.294 0 .693-.085.708.851s-.034 1.03-.011 1.651zm14.03-1.243c.001-.01.007-.016.007-.025.059-.665.019-1.318-.036-1.972V7.564l.102-1.217a.478.478 0 00-.043-.235c.024-.539.092-1.158-.046-1.669a2.384 2.384 0 00-.901-1.713c-.023-.054-.042-.108-.084-.158-.38-.475-.868-.72-1.402-.879a4.74 4.74 0 00-2.517-.736h-4.142c-.018-.001-.03-.01-.049-.01-.894.001-1.788.002-2.682.005-.011 0-.017.005-.026.005h-8.947C8.923.905 7.085.861 5.233.94c-.046.001-.08.02-.12.029C2.713 1.031.75 2.865.429 5.197c-.026.082-.044.166-.062.247a2.5 2.5 0 00-.228.725c-.34 2.176.035 4.732.208 7.002v4.645c-.002.102-.006.202-.006.305 0 .544.002 1.087.006 1.631v.702l.002.029.001.277c.001.097.02.181.054.254.284 2.418 2.343 4.332 4.832 4.332h7.184a.845.845 0 01.334.24v3.43a1.53 1.53 0 00.04.957c.044.193.132.365.258.515.02.031.036.067.059.097.173.232.36.44.67.456.181.008.347-.064.516-.127.115-.024.226-.064.33-.12a.473.473 0 00.162-.097c.032-.024.071-.034.102-.06l4.796-4.519a14.544 14.544 0 012.076-.772h4.844c2.546 0 4.644-2.001 4.85-4.498.329-1.796.331-3.74.07-5.591z"
            fill="currentColor"
        />
    );
}
