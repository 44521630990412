/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconWatch1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M24.927 25.386c-1.46 0-3.479-.52-5.302-2.033s-1.9-3.798-2.167-4.325c-.901.527-1.728.469-1.728.469s-1.268-.043-1.776-.445c-.27 1.613-.535 2.223-2.287 4.323S6.606 25.4 5.195 25.228c-1.411-.172-3.528-2.023-4.38-3.235s-1.119-2.594-1.119-4.669c0-2.075 1.606-4 2.871-5.089s1.795-1.502 2.869-2.324c1.073-.822 2.777-.924 3.409-1.162s1.362-2.051 2.992-2.051 2.166 1.022 2.582 1.705c.46-.411.995-.411 1.433-.411s.553.164 1.143.411c.506-1.794 2.244-1.878 3.266-1.705s1.696 2.057 2.697 2.158c1 .102 2.947.99 3.239 1.212s2.007 1.556 3.578 3.054c1.773 1.691 2.531 4.966 1.361 7.881s-4.747 4.383-6.207 4.383zm-.308-2.729c2.788-.106 4.389-2.186 4.389-4.303s-1.493-4.591-4.215-4.703c-2.722-.111-4.456 2.184-4.566 4.279s1.603 4.833 4.392 4.727zm-17.888-.079c1.532 0 4.46-1.071 4.46-4.223s-2.677-4.534-4.302-4.534c-1.625 0-4.168.814-4.298 4.25s2.608 4.507 4.14 4.507zm9.003-5.194c.777 0 1.479-.526 1.479-1.483s-.518-1.483-1.368-1.483c-.851 0-1.572.563-1.572 1.483s.684 1.483 1.461 1.483z"
            fill="currentColor"
        />
    );
}
