import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Context, Props, TranslationStrings, defaultTranslationStrings } from '../../Context';
import { FloatingAlertProvider } from '../FloatingAlert/FloatingAlertContext';

export interface UIProviderProps {
    children: React.ReactNode;
    translationStrings?: TranslationStrings;
    components?: Props['components'];
}

/**
 * Adds an overlay to Emu components if the body has the `emu-overlay` class.
 * Requires Emu components to have the `emu` class already added to them.
 */
const OverlayStyles = createGlobalStyle`
    html.emu-overlay .emu {
        position: relative;

        ::after {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            pointer-events: none;
            background: rgba(0, 255, 0, 0.2);
        }
    }
`;
/**
 * Composes all providers necessary for UI components inside this library
 * See the root README for more information.
 */
export function UIProvider({ children, translationStrings, components }: UIProviderProps) {
    return (
        <Context.Provider
            value={{
                translationStrings: translationStrings ?? defaultTranslationStrings,
                components,
            }}
        >
            <FloatingAlertProvider>
                <OverlayStyles />
                {children}
            </FloatingAlertProvider>
        </Context.Provider>
    );
}
