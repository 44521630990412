import React from 'react';
import styled from 'styled-components';
import { IconAdmin1 } from '@99designs/icons';
import { colors } from '../../../settings';
import { BaseAlert, BaseAlertProps } from '../BaseAlert';

export const StyledAdminAlert = styled(BaseAlert)`
    background: repeating-linear-gradient(
        45deg,
        ${colors.grey800},
        ${colors.grey800} 10px,
        ${colors.grey700} 10px,
        ${colors.grey700} 20px
    );
    color: white;
`;

type AdminAlertProps = Omit<BaseAlertProps, 'icon'>;

export function AdminAlert({ children, ...rest }: AdminAlertProps) {
    return (
        <StyledAdminAlert icon={<IconAdmin1 />} {...rest}>
            {children}
        </StyledAdminAlert>
    );
}
