import React from 'react';
import styled from 'styled-components';
import { IconSuccess } from '@99designs/icons';
import { colors } from '../../../settings';
import { BaseAlert, BaseAlertProps } from '../BaseAlert';

export const StyledSuccessAlert = styled(BaseAlert)`
    background: ${colors.experimental.green600};
    color: white;
`;

type SuccessAlertProps = Omit<BaseAlertProps, 'icon'>;

export function SuccessAlert({ children, ...rest }: SuccessAlertProps) {
    return (
        <StyledSuccessAlert icon={<IconSuccess />} {...rest}>
            {children}
        </StyledSuccessAlert>
    );
}
