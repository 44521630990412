export class RedirectError extends Error {
    public redirectTo: string;

    constructor(redirectTo: string) {
        super('Redirecting to ' + redirectTo);

        this.constructor = RedirectError;
        this.redirectTo = redirectTo;
    }
}
