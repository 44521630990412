/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconGrid2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M13.033 17.696c-.611.033-10.984-.182-11.704-.125s-1.246.507-1.271 1.31c-.026.803-.163 10.266-.055 11.177s.58 1.351 1.513 1.331c.933-.02 9.526.188 10.716.229s1.608-.904 1.601-1.435c-.007-.531.33-10.167.355-10.867s-.543-1.652-1.155-1.619zM1.117.344C1.728.377 12.101.162 12.821.219s1.246.507 1.271 1.31c.026.803.163 10.266.055 11.177s-.58 1.351-1.513 1.331c-.933-.02-9.526.188-10.716.229S.31 13.362.317 12.831C.324 12.3-.013 2.664-.038 1.964S.505.312 1.117.345zm29.478 31.14c-.611-.033-10.984.182-11.704.125s-1.246-.507-1.271-1.31c-.026-.803-.163-10.266-.055-11.177s.58-1.351 1.513-1.331c.933.02 9.526-.188 10.716-.229s1.608.904 1.601 1.435c-.007.531.33 10.167.355 10.867s-.543 1.652-1.155 1.619zm0-17.352c-.611-.033-10.984.182-11.704.125s-1.246-.507-1.271-1.31c-.026-.803-.163-10.266-.055-11.177s.58-1.351 1.513-1.331c.933.02 9.526-.188 10.716-.229s1.608.904 1.601 1.435c-.007.531.33 10.167.355 10.867s-.543 1.652-1.155 1.619z"
            fill="currentColor"
        />
    );
}
