import React from 'react';

const useEnhancedEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

export interface NoSsrProps {
    /**
     * Components to only render on the client.
     */
    children?: React.ReactNode;
    /**
     * Fallback component for the server to render instead of children content.
     */
    fallback?: React.ReactNode;
}

/**
 * NoSsr purposely removes components from the subject of Server Side Rendering (SSR).
 *
 * This component can be useful in a variety of situations:
 *
 * -   Escape hatch for broken dependencies not supporting SSR.
 * -   Improve the time-to-first paint on the client by only rendering above the fold.
 * -   Reduce the rendering time on the server.
 */
export function NoSsr({ children = null, fallback = null }: NoSsrProps) {
    const [mountedState, setMountedState] = React.useState(false);
    useEnhancedEffect(() => setMountedState(true));
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{mountedState ? children : fallback}</>;
}
