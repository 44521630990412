import React from 'react';
import { SmallSectionHeading } from '@99designs/emu';

interface Props {
    children: React.ReactNode;
}

export function ItemHeading({ children }: Props) {
    return (
        <div>
            <SmallSectionHeading LEGACY_marginBottom="1bu">{children}</SmallSectionHeading>
        </div>
    );
}
