import React, { InputHTMLAttributes } from 'react';

export type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;

// eslint-disable-next-line react/display-name
/**
 * @deprecated replaced by Checkbox in `@99designs/emu`
 */
export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
    return <input ref={ref} {...props} type="checkbox" className="input-checkbox" />;
});
