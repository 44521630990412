import React from 'react';
import getComponentClasses from './getComponentClasses';

interface Props {
    variations?: string[];
    [key: string]: any;
    children?: React.ReactNode;
}

const bemComponent = (baseClassName: string, displayName = 'Block') => {
    const BemComponent = React.forwardRef<HTMLDivElement, Props>(
        ({ variations, className, ...props }, ref) => (
            <div
                className={getComponentClasses(baseClassName, variations, className)}
                ref={ref}
                {...props}
            />
        )
    );
    BemComponent.displayName = displayName;
    return BemComponent;
};

export default bemComponent;
