/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconAdd1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M2.551.316C1.176.241-.102 2.408-.046 4.001S.768 27.53.753 28.739c-.015 1.209.926 3.359 3.602 3.267s22.003-.567 24.102-.521c2.099.046 3.16-.956 3.403-3.029s-.065-23.615-.123-25.443C31.679 1.185 30.498.16 28.877.031S3.925.392 2.55.316zm3.604 14.315c.293-.127 6.745-.051 7.289-.094s1.109.163 1.155-.882c.046-1.045-.22-7.582.072-7.624s2.463-.022 2.63.061c.167.083.097 6.703.094 7.371s-.011 1.11.951 1.064c.961-.046 7.186-.194 7.436-.027s.202 2.406-.007 2.616c-.21.21-7.164.303-7.54.263s-.942.077-.946.923c-.004.846.101 7.143.053 7.44s-2.423.377-2.633.084c-.21-.294-.155-6.509-.152-7.345s-.495-1.166-1.122-1.122c-.627.044-7.102.096-7.31-.112s-.262-2.489.031-2.615z"
            fill="currentColor"
        />
    );
}
