import React, { useReducer } from 'react';
import styled from 'styled-components';
import { spaces } from '../../settings';
import { Box } from '../Box';
import { Stack } from '../Stack';
import { FloatingAlert } from './FloatingAlert';
import { Action, State } from './types';

const Container = styled(Box)`
    bottom: ${spaces['2bu']};
    z-index: 9999;
    pointer-events: none;
`;

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'ADD_ALERT': {
            const id = state.id + 1;

            return {
                ...state,
                id,
                floatingAlerts: [...state.floatingAlerts, { ...action.floatingAlert, id }],
            };
        }
        case 'REMOVE_ALERT':
            return {
                ...state,
                floatingAlerts: state.floatingAlerts.filter(
                    (floatingAlert) => floatingAlert.id !== action.id
                ),
            };
        default:
            return state;
    }
}

export const FloatingAlertContext = React.createContext<React.Dispatch<Action> | null>(null);

/**
 * Manages state of the `FloatingAlert`s that are currently visible.
 * Renders visible `FloatingAlert`s within a container.
 */
export function FloatingAlertProvider({ children }: { children: React.ReactNode }) {
    const [{ floatingAlerts }, dispatch] = useReducer(reducer, {
        floatingAlerts: [],
        id: 0,
    });

    return (
        <FloatingAlertContext.Provider value={dispatch}>
            <Container
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="fixed"
                width="100%"
            >
                <Stack space="2bu">
                    {floatingAlerts.map((floatingAlert) => {
                        return (
                            <FloatingAlert
                                key={floatingAlert.id}
                                dispatch={dispatch}
                                id={floatingAlert.id}
                                message={floatingAlert.message}
                                type={floatingAlert.type}
                            />
                        );
                    })}
                </Stack>
            </Container>
            {children}
        </FloatingAlertContext.Provider>
    );
}
