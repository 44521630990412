import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Grid } from '@material-ui/core';
import TileSelectItem from './TileSelectItem';

export interface Option<T> {
    label: React.ReactNode;
    value: T;
    icon?: string;
}

export interface TileSelectProps<T> {
    name: string;
    value?: T | T[];
    options: Option<T>[];
    onChange: (value: T, name: string) => void;
    matchItemHeight?: boolean;
    isMulti?: boolean;
}

export function TileSelect<T>({
    name,
    value,
    options,
    onChange,
    matchItemHeight = true,
    isMulti = false,
}: TileSelectProps<T>) {
    const handleChange = (value: T) => {
        onChange(value, name);
    };

    return (
        <Grid container spacing={3}>
            {options.map((option, i) => {
                const isSelected = Array.isArray(value)
                    ? value.includes(option.value)
                    : option.value === value;
                return (
                    <Grid item xs={12} sm={options.length < 3 ? 6 : 4} key={`${name}-${i}`}>
                        <TileSelectItem<T>
                            key={`option-${i}`}
                            option={option}
                            handleChange={handleChange}
                            isSelected={isSelected}
                            name={name}
                            matchItemHeight={matchItemHeight}
                            type={isMulti ? 'checkbox' : 'radio'}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}
