/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconCancel1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M22.761 6.622c-1.958-1.424-4.456-2.276-7.448-2.189-10.687.311-13.628 11.54-8.802 18.243 3.59-3.665 12.457-12.441 16.25-16.054zm2.788 2.884C21.946 13.3 13.294 21.984 9.55 25.565c1.895 1.224 4.3 1.983 7.216 2.018 11.2.134 13.48-11.22 8.784-18.077zM4.835 4.685c8.087-8.364 18.588-3.751 22.532.173s7.994 14.415.174 22.277c-7.82 7.862-18.19 4.861-22.73.325s-8.062-14.412.024-22.776z"
            fill="currentColor"
        />
    );
}
