import React from 'react';
import { Text, TextProps } from '../Text';

export interface LabelTextProps extends TextProps {
    /**
     * Applies disabled styling
     */
    disabled?: boolean;
    /**
     * If passed a string for `children`, suffixes an aria-hidden asterisk to it
     */
    required?: boolean;
}

/**
 * Renders Text styled as a label for a form input.
 * Note that this doesn't render a <label>, @see FormLabel
 */
export function LabelText({
    children,
    color,
    disabled,
    size = 'size3',
    required,
    ...rest
}: LabelTextProps) {
    return typeof children === 'string' ? (
        <Text {...rest} size={size} weight="bold" color={disabled ? 'grey400' : color}>
            {children}
            {required && (
                <Text color="risingSun" aria-hidden="true">
                    *
                </Text>
            )}
        </Text>
    ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{children}</>
    );
}
