/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconLink3(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M2.5 28.373c5.312 5.105 9.398 3.927 11.543 1.865s6.624-4.873 1.862-5.376c-2.134-.225-2.146-.747-4.185.87s-3.294 1.768-5.44-.404c-3.889-3.934 2.648-8.451 2.648-8.451s3.856-5.115 7.227-2.268c3.371 2.847 6.366-1.161 3.813-3.026s-7.774-4.114-13.282.985C1.177 17.666-2.81 23.266 2.501 28.372zm27-24.746C24.188-1.478 20.102-.3 17.957 1.762s-6.736 5.815-1.738 6.231c2.25.188 3.08-1.463 4.125-2.375s2.477-1.881 5.377 1.053c3.889 3.934-.941 7.005-2.648 8.451s-4.808 5.393-8.179 2.545c-3.371-2.847-5.362 1.367-3.426 3.132.741.675 2 1.685 5 2.31s5.448-.531 8.848-3.678c5.509-5.098 9.497-10.698 4.185-15.804z"
            fill="currentColor"
        />
    );
}
