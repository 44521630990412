import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../settings';
import { Box } from '../Box';
import { OnlineIndicator } from './OnlineIndicator';
import { avatarSizes } from './sizes';
import { AvatarSize } from './types';

export interface AvatarProps {
    /**
     * Adds an optional alt tag to the image, only use when necessary.
     * Note that Avatars are often used as decoration, e.g. next to a display name/other link.
     * In this case, no alt tag is required.
     */
    alt?: string;
    /**
     * Adds a stroke outline to the avatar.
     */
    border?: boolean;
    /**
     * Displays a green circular indicator, to show the user is currently online.
     */
    online?: boolean;
    size?: AvatarSize;
    url: string;
}

const Border = styled.div<{ size: AvatarSize }>`
    position: absolute;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px ${colors.white};
    width: 100%;
    height: 100%;
    top: 0;
`;

const AvatarImage = styled.img<{ size: AvatarSize }>`
    border-radius: 50%;
    width: ${({ size }) => avatarSizes[size]};
    height: ${({ size }) => avatarSizes[size]};
`;

/**
 * Displays a user's Avatar, with an optional border and online indicator.
 */
export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(function (
    { alt = '', border = false, online = false, size = 'medium', url },
    ref
) {
    return (
        <Box ref={ref} className="emu" display="inline-block" position="relative">
            <AvatarImage src={url} alt={alt} size={size} />
            {border && <Border size={size} />}
            {online && <OnlineIndicator size={size} />}
        </Box>
    );
});
