/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconShoppingCart1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M4.467 3.239c-2.322 0-3.039.199-3.039 1.172s.424 2.138 1.731 2.322c1.308.184 1.68-.474 1.68.815s.05 12.541.05 13.752c0 1.212 0 2.712 1.288 2.712s2.548-.367 1.635.328c-.913.695-1.457.831-1.457 2.487S7.892 29 8.659 29c.767 0 2.44-.595 2.44-2.627s-.983-2.052-1.246-2.303c-.264-.25 2.712.094 4.711.094s7.554-.339 7.951-.17c.397.17-.083.276-.543.513s-.896 1.523-.896 2.128c0 .606 1.416 2.209 2.331 2.209s2.704-.919 2.704-2.448-.868-1.641-1.301-1.937.056-.446.679-.446c.622 0 2.158-.433 2.158-1.86s-1.273-1.491-2.214-1.491c-.941 0-16.174.145-16.806.145s-.38-.423-.38-1.712-.231-1.838.941-1.838 15.408-.209 16.452-.209c1.044 0 2.317-.318 2.7-2.683s2.088-8.342 2.088-9.734c0-1.392.148-1.631-1.592-1.631s-22.044.239-24.366.239z"
            fill="currentColor"
        />
    );
}
