import React from 'react';
import {
    FormDescription,
    FormDescriptionProps,
    FormError,
    FormErrorProps,
    FormHelperText,
    FormHelperTextProps,
} from '../Form';
import { LabelText } from '../Form/LabelText';
import { Stack } from '../Stack';
import { Size } from './FieldClickable';
import { getFormIDs } from './getFormIDs';
import { FieldLabelType } from './types';

export type FieldGroupProps = {
    /**
     * Adds additional context above the field
     */
    description?: FormDescriptionProps['children'];
    /**
     * Adds error text when truthy below the field group.
     */
    error?: FormErrorProps['children'];
    /**
     * Adds additional context below the field group. Not shown when an error is present
     */
    helperText?: FormHelperTextProps['children'];
    id: string;
    size?: Size;
} & FieldLabelType;

type PrivateFieldGroupProps = FieldGroupProps & {
    children: React.ReactNode;
    role: string;
};

export function FieldGroup({
    children,
    description,
    error,
    helperText,
    id,
    role,
    size = 'regular',
    ...rest
}: PrivateFieldGroupProps) {
    const { ariaDescribedBy, descriptionId, errorId, helperTextId, labelId, showHelperText } =
        getFormIDs({
            description,
            error,
            helperText,
            id,
        });

    const hasVisualLabel = 'label' in rest && typeof rest.label !== 'undefined';
    let calculatedLabelId;
    if (hasVisualLabel) {
        calculatedLabelId = labelId;
    }
    if ('aria-labelledby' in rest && typeof rest['aria-labelledby'] !== 'undefined') {
        calculatedLabelId = rest['aria-labelledby'];
    }

    return (
        <div
            role={role}
            aria-label={
                'aria-label' in rest && typeof rest['aria-label'] !== 'undefined'
                    ? rest['aria-label']
                    : undefined
            }
            aria-labelledby={calculatedLabelId}
            aria-describedby={ariaDescribedBy}
            className="emu"
        >
            <Stack space="1bu">
                {(hasVisualLabel || !!description) && (
                    <Stack space="0.5bu">
                        {hasVisualLabel && (
                            <div id={labelId}>
                                <LabelText>{rest.label}</LabelText>
                            </div>
                        )}

                        {!!description && (
                            <FormDescription id={descriptionId}>{description}</FormDescription>
                        )}
                    </Stack>
                )}

                <Stack space={size === 'regular' ? '0.5bu' : '0.25bu'}>{children}</Stack>

                {!!error && <FormError id={errorId}>{error}</FormError>}

                {showHelperText && <FormHelperText id={helperTextId}>{helperText}</FormHelperText>}
            </Stack>
        </div>
    );
}
