/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconPromote1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M19.705 24.257c-.013 1.017.001 2.15.093 2.922.206 1.728-1.231 2.089-1.231 2.089s-7.834-.038-9.213.057c-1.38.095-1.605-1.064-1.605-1.064l.04-6.984c-1.911-.523-3.219-.928-3.387-1.091-.723-.702-1.24-7.023-1.01-7.673s22.476-7.995 22.923-7.857c.447.138 1.122 21.012.847 21.203-.106.073-3.403-.648-7.457-1.603zm-2.617-.623a438.25 438.25 0 01-6.643-1.652c-.029 1.374-.081 4.222.014 4.591.124.475 1.592.418 1.938.418s4.579.037 4.724-.191c.079-.124.029-1.732-.034-3.166zM2.46 12.568c-.173-.392-1.927-.465-2.273 0s.445 7.048.568 7.62c.124.572 1.999.492 2.171 0s-.294-7.228-.467-7.62zM27.907 3.68c.038-1.101 2.035-1.031 2.912-.344s1.34 9.423 1.276 11.258c-.064 1.835-.042 11.277-.416 11.926s-2.47.796-2.72-.095S27.868 4.781 27.907 3.68z"
            fill="currentColor"
        />
    );
}
