import { getComponentClasses } from '@99designs/frontend-components/utils/app';
import React, { ReactNode } from 'react';

export type FlexType = 'default' | 'shrink' | 'grow' | 'full' | 'responsive';

function getLayoutClass() {
    return getComponentClasses('flex-layout', []);
}

function getLayoutItemClass(type: FlexType) {
    return getComponentClasses('flex-layout__item', [type]);
}

export function FlexLayout({ children }: { children: ReactNode }) {
    return <div className={getLayoutClass()}>{children}</div>;
}

export interface FlexLayoutItemProps {
    type?: FlexType;
    children: ReactNode;
}

export function FlexLayoutItem({ type = 'default', children }: FlexLayoutItemProps) {
    return <div className={getLayoutItemClass(type)}>{children}</div>;
}
