import React from 'react';
import styled from 'styled-components';
import { Box, Divider, SmallSectionSubheading, Stack } from '@99designs/emu';
import { mediaQuery } from '@99designs/emu/css';
import { spaces } from '@99designs/emu/settings';
import { ProductPackageImage } from '@99designs/graph-utils/types';

export interface Image {
    title: string;
    src: string;
    points: string[];
}

type Props = ProductPackageImage;

const ImageColumn = styled.div`
    width: calc(100% - 250px);
    padding-right: ${spaces['3bu']};
    img {
        display: block;
        width: 100%;
    }

    ${mediaQuery.only('small')} {
        width: 100%;
        padding-right: 0;
    }
`;

const Number = styled.div`
    font-size: 0.875rem;
    height: 1.6em;
    width: 1.6em;
    line-height: 1.6em;
    text-align: center;
    font-weight: bold;
    position: relative;
    display: inline-block;
    margin-right: 0.8em;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        border: 1px solid currentColor;
    }
`;

const ProductImageContainer = styled.div`
    display: flex;
    justify-content: space-between;
    ${mediaQuery.only('small')} {
        flex-direction: column;
    }
`;

const TextColumn = styled.div`
    width: 240px;
    h4 {
        margin-top: 0;
    }
    ul {
        padding: 0;
    }
    li {
        list-style-type: none;
        margin-bottom: 0.75em;
        font-weight: bold;
    }
`;

export function ProductImage({ src, title, points }: Props) {
    return (
        <>
            <Box marginBottom="2bu">
                <Divider />
            </Box>
            <ProductImageContainer>
                <TextColumn>
                    <Stack space="1.5bu">
                        <SmallSectionSubheading fontWeight="bold">{title}</SmallSectionSubheading>
                        <ul>
                            {points.map((p, i) => (
                                <li key={p}>
                                    <Number>{i + 1}</Number>
                                    {p}
                                </li>
                            ))}
                        </ul>
                    </Stack>
                </TextColumn>
                <ImageColumn>
                    <img src={src} alt={title} />
                </ImageColumn>
            </ProductImageContainer>
        </>
    );
}
