/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconUp1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 28 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M4.458 22.804c-.376-.088-2.592-2.091-2.404-2.53.166-.386 8.85-9.008 11.166-11.066.315-.28.366-.216.634-.216s.252.005.699.396c2.697 2.358 11.406 10.677 11.487 10.978.094.351-2.22 2.705-2.502 2.618s-7.661-7.121-8.037-7.472c-.376-.351-1.599-1.755-3.292-.175s-7.375 7.556-7.751 7.468z"
            fill="currentColor"
        />
    );
}
