/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconX1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M1.998 5.002c0-.527 2.425-3.09 2.957-2.879s8.612 8.484 9.357 9.117c.744.633 1.595 1.613 2.977.347s9.328-9.886 9.753-9.57c.425.317 2.753 2.698 2.859 3.015s-8.371 8.616-9.222 9.46c-.851.844-1.42 1.789-.144 2.949s9.353 8.862 9.459 9.389c.106.527-2.397 2.909-2.928 2.909s-9.463-8.695-9.888-9.223c-.425-.527-1.688-1.096-2.764-.03s-8.923 9.178-9.361 9.495c-.438.317-3.153-2.197-3.047-2.834s8.052-8.444 9.115-9.499c1.063-1.055.851-2.501 0-3.24S1.998 5.529 1.998 5.002z"
            fill="currentColor"
        />
    );
}
