/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconPlus1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M4.835 4.685c-8.087 8.364-4.565 18.24-.024 22.776s14.911 7.537 22.73-.325c7.82-7.862 3.769-18.354-.174-22.277S12.922-3.678 4.835 4.686zm1.199 12.812c-.219-.222-.301-2.8.007-2.934s6.771.326 7.343.279c.572-.047 1.231.261 1.28-.852s-.231-8.277.077-8.323c.308-.046 2.46.082 2.635.17s.101 7.341.098 8.054c-.003.712-.094.888.918.84s7.561-.207 7.824-.03c.263.177.213 2.534-.008 2.758s-7.738.12-8.134.077c-.395-.043-.88-.085-.709.895s.105 7.607.055 7.924c-.05.317-2.421.504-2.642.191s-.062-7.034-.059-7.924c.004-.89-.435-1.155-1.094-1.108s-7.372.205-7.591-.017z"
            fill="currentColor"
        />
    );
}
