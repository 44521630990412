import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@99designs/emu/settings';

const FullscreenSidebar = styled.div`
    display: block;
    position: relative;

    @media (min-width: ${breakpoints.normal[0]}px) and (max-width: ${breakpoints.normal[1]}px),
        (min-width: ${breakpoints.large[0]}px) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow-y: scroll;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        min-height: 100%;
        overflow: hidden;
    }
`;
const FullscreenSidebarInner = styled.div`
    display: block;
    position: relative;

    @media (min-width: ${breakpoints.normal[0]}px) and (max-width: ${breakpoints.normal[1]}px),
        (min-width: ${breakpoints.large[0]}px) {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        min-height: 100%;
    }
`;

interface FullscreenSidebarMainProps {
    children: React.ReactNode;
}
function FullscreenSidebarMain({ children }: FullscreenSidebarMainProps) {
    return (
        <FullscreenSidebar>
            <FullscreenSidebarInner>{children}</FullscreenSidebarInner>
        </FullscreenSidebar>
    );
}
export default FullscreenSidebarMain;
