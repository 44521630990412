import { CurrencyCode } from '@99designs/i18n';

export enum CurrencySymbol {
    AUD = '$',
    CAD = '$',
    CHF = 'Fr.',
    DKK = 'Kr.',
    EUR = '€',
    GBP = '£',
    HKD = '$',
    JPY = '¥',
    MXN = '$',
    NOK = 'kr',
    SGD = 'S$',
    USD = '$',
}

const unitMultiplier: { [currency in CurrencyCode]: number } = {
    [CurrencyCode.AUD]: 100,
    [CurrencyCode.CAD]: 100,
    [CurrencyCode.CHF]: 100,
    [CurrencyCode.DKK]: 100,
    [CurrencyCode.EUR]: 100,
    [CurrencyCode.GBP]: 100,
    [CurrencyCode.HKD]: 100,
    [CurrencyCode.JPY]: 1,
    [CurrencyCode.MXN]: 100,
    [CurrencyCode.NOK]: 100,
    [CurrencyCode.SGD]: 100,
    [CurrencyCode.USD]: 100,
};

export const toSubUnit = (unit: number, currency: CurrencyCode): number =>
    unit * unitMultiplier[currency];
export const toUnit = (subUnit: number, currency: CurrencyCode): number =>
    subUnit / unitMultiplier[currency];
