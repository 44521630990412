/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconSqsp1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M7.424 16.703l9.257-9.547c.629-.649 1.466-1.006 2.358-1.006s1.728.357 2.357 1.005l.721.744 1.442-1.488-.721-.744c-1.013-1.045-2.362-1.62-3.8-1.62s-2.786.576-3.8 1.621l-9.257 9.547 1.442 1.488z"
            fill="currentColor"
        />,
        <path
            d="M19.324 10.544l-1.43-1.473-9.175 9.452c-1.289 1.327-3.385 1.328-4.673.001s-1.288-3.487 0-4.815l7.917-8.156-1.43-1.473-7.917 8.156c-2.077 2.14-2.077 5.621 0 7.761 1.004 1.035 2.342 1.604 3.766 1.604s2.762-.57 3.767-1.605l9.175-9.452zM29.03 11.985c-1.005-1.038-2.343-1.609-3.769-1.609s-2.764.572-3.769 1.61l-9.183 9.482 1.431 1.478 9.183-9.483a3.238 3.238 0 014.677-.001c.624.644.967 1.502.967 2.415s-.343 1.771-.967 2.415l-7.923 8.182 1.431 1.478 7.923-8.182c2.079-2.146 2.079-5.639 0-7.786z"
            fill="currentColor"
        />,
        <path
            d="M24.225 15.297l-9.256 9.547a3.266 3.266 0 01-4.714.001l-.721-.744-1.443 1.488.721.744c1.013 1.045 2.362 1.62 3.8 1.62s2.786-.576 3.8-1.621l9.256-9.547-1.442-1.488z"
            fill="currentColor"
        />
    );
}
