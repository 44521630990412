import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from '../../Context';
import { colors } from '../../settings';
import { Box } from '../Box';
import { StarIcon, StarIconProps } from './StarIcon';

interface ReadOnlyRatingProps {
    size: StarIconProps['size'];
    value: number;
}

const PRECISION = 0.25;

// Using exact quarters doesn't visually read better as quarters
const widthMap: Record<number, number> = {
    0: 0,
    25: 35,
    50: 50,
    75: 65,
    100: 100,
};

export function calculateStarWidth(position: number, value: ReadOnlyRatingProps['value']) {
    const width = (value + 1 - position) * 100;
    return widthMap[Math.max(0, Math.min(width, 100))];
}

const Overlay = styled.div<{ width: number }>`
    position: absolute;
    display: block;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: ${colors.grey800};

    ${({ width }) => css`
        width: ${width}%;
    `}
`;

export function ReadOnlyRating({ size, value }: ReadOnlyRatingProps) {
    const translations = useTranslations();
    const roundedValue = Math.round(value / PRECISION) * PRECISION;

    return (
        <Box display="flex" role="img" aria-label={translations.Rating.ReadOnlyLabel(value)}>
            {[...Array(5)].map((_, i) => {
                const position = i + 1;

                return (
                    <Box key={position} position="relative">
                        <StarIcon size={size} />
                        <Overlay width={calculateStarWidth(position, roundedValue)}>
                            <StarIcon size={size} />
                        </Overlay>
                    </Box>
                );
            })}
        </Box>
    );
}
