/**
 * Utility functions for positioning a given dom element around an anchor dom element.
 */

interface Direction {
    direction: string;
    alignment: string;
    coordinate: [number, number];
}

const locations: { [key: string]: Direction } = {
    'up-left': { direction: 'up', alignment: 'left', coordinate: [-1, -2] },
    'up-center': { direction: 'up', alignment: 'center', coordinate: [0, -2] },
    'up-right': { direction: 'up', alignment: 'right', coordinate: [1, -2] },
    'down-left': { direction: 'down', alignment: 'left', coordinate: [-1, 2] },
    'down-center': { direction: 'down', alignment: 'center', coordinate: [0, 2] },
    'down-right': { direction: 'down', alignment: 'right', coordinate: [1, 2] },
    'left-top': { direction: 'left', alignment: 'top', coordinate: [-6, -1] },
    'left-middle': { direction: 'left', alignment: 'middle', coordinate: [-6, 0] },
    'left-bottom': { direction: 'left', alignment: 'bottom', coordinate: [-6, 1] },
    'right-top': { direction: 'right', alignment: 'top', coordinate: [6, -1] },
    'right-middle': { direction: 'right', alignment: 'middle', coordinate: [6, 0] },
    'right-bottom': { direction: 'right', alignment: 'bottom', coordinate: [6, 1] },
    'up-fluid': { direction: 'up', alignment: 'fluid', coordinate: [0, -15] },
    'down-fluid': { direction: 'down', alignment: 'fluid', coordinate: [0, 15] },
};

function getDistanceSquared(locationNameA: string, locationNameB: string) {
    const locationA = locations[locationNameA];
    const locationB = locations[locationNameB];
    return (
        Math.pow(locationA.coordinate[0] - locationB.coordinate[0], 2) +
        Math.pow(locationA.coordinate[1] - locationB.coordinate[1], 2)
    );
}

function getPosition(
    anchorElement: any,
    element: any,
    location: Direction,
    distance = 0,
    alignmentOffset = 0
) {
    const anchorRect = anchorElement && anchorElement.getBoundingClientRect();
    const elemRect = element && element.getBoundingClientRect();
    const scrollTop = document.documentElement!.scrollTop;
    const position: { top?: number; left?: number } = {};

    if (!anchorRect || !elemRect) {
        return position;
    }

    switch (location.direction) {
        case 'up':
            position.top = anchorRect.top + scrollTop - distance - elemRect.height;
            break;
        case 'down':
            position.top = anchorRect.top + scrollTop + distance + anchorRect.height;
            break;
        case 'left':
            position.left = anchorRect.left - distance - elemRect.width;
            break;
        case 'right':
            position.left = anchorRect.left + distance + anchorRect.width;
            break;
    }

    if (location.alignment !== 'fluid') {
        switch (location.alignment) {
            case 'left':
                position.left = anchorRect.left + alignmentOffset;
                break;
            case 'center':
                position.left = anchorRect.left + anchorRect.width / 2 - elemRect.width / 2;
                break;
            case 'right':
                position.left =
                    anchorRect.left + anchorRect.width - elemRect.width - alignmentOffset;
                break;
            case 'top':
                position.top = anchorRect.top + scrollTop + alignmentOffset;
                break;
            case 'middle':
                position.top =
                    anchorRect.top + scrollTop + anchorRect.height / 2 - elemRect.height / 2;
                break;
            case 'bottom':
                position.top =
                    anchorRect.top +
                    scrollTop +
                    (anchorRect.height - elemRect.height) -
                    alignmentOffset;
                break;
        }
    }

    return position;
}

function isOffPage(element: any) {
    const elemRect = element && element.getBoundingClientRect();
    const windowWidth = document.documentElement!.clientWidth;
    const windowHeight = document.documentElement!.clientHeight;

    return (
        windowWidth &&
        windowHeight &&
        elemRect &&
        (elemRect.right > windowWidth || // overflow right?
        elemRect.bottom > windowHeight || // overflow bottom?
        elemRect.left < 0 || // overflow left?
            elemRect.top < 0)
    ); // overflow top?
}

export function positionElement(
    element: any,
    anchorElement: any,
    locationName: string,
    distance?: number,
    alignmentOffset?: number
) {
    const location = locations[locationName];

    element.style.top = '';
    element.style.left = '';
    element.style.width = '';

    // Setting to current width as wrapping occurs on right edge
    if (location.alignment !== 'fluid') {
        // Round up width up and add 1px to prevent edge case wrapping
        const width = element && Math.ceil(element.getBoundingClientRect().width) + 1;
        element.style.width = `${width}px`;
    }

    const newPosition = getPosition(anchorElement, element, location, distance, alignmentOffset);
    element.style.top = `${newPosition.top}px`;
    element.style.left = `${newPosition.left}px`;

    // Recalculate in case of wrapping
    if (location.direction === 'up') {
        const recalculatedNewPosition = getPosition(
            anchorElement,
            element,
            location,
            distance,
            alignmentOffset
        );
        element.style.top = `${recalculatedNewPosition.top}px`;
        element.style.left = `${recalculatedNewPosition.left}px`;
    }

    return locationName;
}

export function positionElementWithAutoAdjustment(
    element: any,
    anchorElement: any,
    locationName: string,
    distance?: number,
    alignmentOffset?: number
) {
    const orderedLocations = Object.keys(locations).sort(function(a, b) {
        return getDistanceSquared(a, locationName) - getDistanceSquared(b, locationName);
    });

    let finalLocationName: string | null = null;

    const isEveryAutoLocationOffPage = orderedLocations.every(function(locName) {
        positionElement(element, anchorElement, locName, distance, alignmentOffset);
        finalLocationName = locName;
        return isOffPage(element);
    });

    // If we cannot fit the element on page automatically with any of our settings,
    // use the default `locationName` otherwise the final location checked is the location used.
    if (isEveryAutoLocationOffPage) {
        positionElement(element, anchorElement, locationName, distance, alignmentOffset);
        finalLocationName = locationName;
    }

    return finalLocationName;
}

export function getPossiblePositions() {
    return Object.keys(locations);
}
