import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { UseComboboxProps, useCombobox } from 'downshift';
import { spaces } from '../../../settings';
import { Box } from '../../Box';
import { BaseInput } from '../../Input/BaseInput';
import { Chevron } from '../Chevron';
import { Menu } from '../Menu';
import { Option, OptionType } from '../Option';
import { SingleSelectProps } from '../types';

const optionToString = (option?: OptionType | null) => (option ? option.label : '');

const stateReducer: UseComboboxProps<OptionType>['stateReducer'] = (state, { changes, type }) => {
    switch (type) {
        // Don't clear input on esc key, even if menu is closed
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
            return {
                ...changes,
                isOpen: false,
                inputValue: optionToString(state.selectedItem),
                selectedItem: state.selectedItem,
            };

        // Reset to previously selected, or newly selected item on blur
        case useCombobox.stateChangeTypes.InputBlur:
            return {
                ...changes,
                inputValue: optionToString(changes.selectedItem),
            };

        // Clear selected item when setting inputValue to empty
        case useCombobox.stateChangeTypes.InputChange:
            if (state.inputValue && changes.inputValue === '') {
                return {
                    ...changes,
                    selectedItem: null,
                };
            }
            return changes;

        default:
            return changes;
    }
};

export const SingleSelect = React.forwardRef<HTMLInputElement, SingleSelectProps>(function (
    {
        'aria-describedby': ariaDescribedBy,
        disabled,
        error,
        id,
        menuIsOpen,
        onChange,
        options,
        placeholder,
        value,
        name,
        ...rest
    },
    ref
) {
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, spaces.px['0.75bu']],
                },
            },
        ],
    });

    const [renderedOptions, setRenderedOptions] = useState(options);
    const {
        getComboboxProps,
        getMenuProps,
        getInputProps,
        getItemProps,
        getToggleButtonProps,
        highlightedIndex,
        isOpen,
        openMenu,
        selectedItem,
    } = useCombobox<OptionType>({
        id,
        inputId: id,
        ...(typeof menuIsOpen !== 'undefined' && { isOpen: menuIsOpen }),
        items: renderedOptions,
        itemToString: optionToString,
        onInputValueChange: ({ inputValue, selectedItem }) => {
            if (inputValue) {
                if (selectedItem?.label === inputValue) {
                    setRenderedOptions(options);
                } else {
                    setRenderedOptions(
                        options.filter((option) =>
                            optionToString(option).toLowerCase().includes(inputValue.toLowerCase())
                        )
                    );
                }
            } else {
                setRenderedOptions(options);
            }
        },
        onSelectedItemChange: ({ selectedItem }) => onChange?.(selectedItem || null),
        selectedItem: value,
        stateReducer,
    });

    let labelProps = {};
    if ('aria-label' in rest) {
        labelProps = {
            'aria-label': rest['aria-label'],
            'aria-labelledby': null,
        };
    } else if ('aria-labelledby' in rest) {
        labelProps = {
            'aria-labelledby': rest['aria-labelledby'],
        };
    }

    return (
        <Box position="relative">
            <Box {...getComboboxProps({ ref: setReferenceElement })}>
                <BaseInput
                    {...getInputProps({
                        disabled,
                        onClick: () => {
                            if (!isOpen) {
                                openMenu();
                            }
                        },
                        onFocus: () => {
                            if (!isOpen) {
                                openMenu();
                            }
                        },
                        ref,
                        ...labelProps,
                    })}
                    aria-describedby={ariaDescribedBy}
                    disabled={disabled}
                    error={error}
                    placeholder={placeholder}
                    name={name}
                    suffix={
                        <Chevron
                            {...getToggleButtonProps({ disabled })}
                            rotate={isOpen}
                            type="button"
                        />
                    }
                />
            </Box>
            <Menu
                style={styles['popper']}
                isOpen={isOpen}
                {...getMenuProps({ ref: setPopperElement, ...labelProps })}
                {...attributes['popper']}
            >
                {isOpen &&
                    renderedOptions.map((option, index) => {
                        const selected = selectedItem?.value === option.value;

                        return (
                            <Option
                                selected={selected}
                                key={optionToString(option)}
                                highlighted={highlightedIndex === index}
                                {...getItemProps({
                                    disabled: option.disabled,
                                    item: option,
                                    index,
                                })}
                                {...option}
                            />
                        );
                    })}
            </Menu>
        </Box>
    );
});
