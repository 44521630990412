export enum CurrencyCode {
    AUD = 'AUD',
    CAD = 'CAD',
    CHF = 'CHF',
    DKK = 'DKK',
    EUR = 'EUR',
    GBP = 'GBP',
    HKD = 'HKD',
    JPY = 'JPY',
    MXN = 'MXN',
    NOK = 'NOK',
    SGD = 'SGD',
    USD = 'USD',
}
