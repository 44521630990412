/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconCompose2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M27.293 27.959c.561 0 .486-.064.486-.318l-.018-13.581c0-.602.23-.56.642-.56 0 0 2.444-.046 2.852-.046s.473.128.473.647l.022 17.37c0 .563-.15.526-.528.526l-30.931-.04c-.18 0-.289-.018-.355-.068-.205-.064-.186-.218-.186-.493L-.21.465c0-.453.117-.457.559-.457l17.258-.011c.531 0 .716 0 .716.432s-.008 2.414.023 2.919 0 .616-.557.616-12.814-.049-13.515-.049-.601.183-.601.604l.117 22.947c0 .561.064.486.318.486l23.184.003zM10.991 15.51c-.549.564-.953 1.254-.953 2.877s-.222 2.145.176 2.548.948.043 2.476.043 2.645-.869 3.259-1.387c.613-.517 9.553-9.944 10.08-10.303s1.089-1.211.491-1.728c-.598-.517-2.617-2.336-3.235-2.819s-1.205-.226-1.889.393c-.684.618-9.854 9.812-10.404 10.376zM24.862 2.3c-.742.685-.368 1.018.124 1.595s2.405 2.16 2.755 2.529c.35.369.828.742 1.46.077s1.375-1.642 1.871-2.228c.496-.586.722-1.514-.955-2.974s-2.52-1.526-3.174-.996c-.653.53-1.34 1.312-2.082 1.997z"
            fill="currentColor"
        />
    );
}
