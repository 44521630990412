/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconMaximize1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M3.653 2.053c-1.488 0-1.661.227-1.661 1.643s.13 6.016.13 6.856c0 .841.149 1.359 1.226 1.359s1.899.065 2.35.065c.451 0 .321.194.321-1.035s-.065-3.864-.065-4.459c0-.595.321-.533.71-.533h4.903s.424.084.424-.8v-2.43c0-.667-.713-.751-1.532-.751s-5.319.084-6.808.084zm17.18-.015c-.485 0-.991.347-.991.973s.065 1.482.065 2.099c0 .616-.195.962.367.962s3.963-.065 4.599-.065c.636 0 .994.089 1.059.776s.097 3.819.065 4.454c-.032.635.173.862.528.862s2.549-.097 2.827-.097.538.036.538-.889-.138-7.781-.138-8.289.323-.832-.616-.811-7.817.026-8.302.026zm5.673 17.939c-.451 0-.636.115-.636.936s.054 3.598.054 4.235c0 .636.062.885-.602.885s-4.41-.009-4.853-.009c-.443 0-.594.757-.594.757s.035 2.216.035 2.701c0 .485.196.485.571.485s8.586-.076 8.877-.076c.29 0 .542-.012.55-.627s.018-8.175.018-8.541c0-.366.054-.711-.431-.711s-2.536-.035-2.988-.035zm-21.048.007c.376 0 .505.195.505.582v4.652c0 .517.038.767.62.767s4.485.012 4.797.012c.312 0 .624.095.602.624s-.022 1.858-.022 2.344c0 .487.227.882-.581.882s-8.913.119-9.05.119c-.138 0-.331.064-.331-.723s-.073-8.175-.073-8.705c0-.53.307-.551.89-.551s2.266-.005 2.642-.005z"
            fill="currentColor"
        />
    );
}
