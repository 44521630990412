import React from 'react';
import styled from 'styled-components';
import { IconError } from '@99designs/icons';
import { colors } from '../../../settings';
import { BaseAlert, BaseAlertProps } from '../BaseAlert';

export const StyledErrorAlert = styled(BaseAlert)`
    background: ${colors.experimental.red600};
    color: white;
`;

type ErrorAlertProps = Omit<BaseAlertProps, 'icon'>;

export function ErrorAlert({ children, ...rest }: ErrorAlertProps) {
    return (
        <StyledErrorAlert icon={<IconError />} {...rest}>
            {children}
        </StyledErrorAlert>
    );
}
