/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconRight1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 20 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M3.196 25.542c.088.376 2.091 2.592 2.53 2.404.386-.166 9.008-8.85 11.066-11.166.28-.315.216-.366.216-.634s-.005-.252-.396-.699C14.254 12.75 5.935 4.041 5.634 3.96c-.351-.094-2.705 2.22-2.618 2.502s7.121 7.661 7.472 8.037c.351.376 1.755 1.599.175 3.292s-7.556 7.375-7.468 7.751z"
            fill="currentColor"
        />
    );
}
