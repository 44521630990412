import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AttributionText, Box, Divider, IconButton, Inline, Link } from '@99designs/emu';
import { mediaQuery } from '@99designs/emu/css';
import { spaces, typography } from '@99designs/emu/settings';
import { FormattedMessage } from '@99designs/frontend-components/i18n';
import { ProductPackageHeaderVideo, ProductPackageImage } from '@99designs/graph-utils/types';
import { IconSearch1, IconX1 } from '@99designs/icons';
import {
    FullscreenFrame,
    FullscreenFrameMain,
    FullscreenMain,
    FullscreenMainBody,
    FullscreenMainHeader,
    FullscreenOverlay,
} from '../Fullscreen';
import { ProductSummary, PropsWithProductSummary } from '../ProductSummary';
import { Feature, ProductDetails } from './ProductDetails';
import { ProductImage } from './ProductImage';

const ViewDetailLinkIcon = styled(IconSearch1)`
    margin-bottom: 4px;
`;

const ViewDetailLink = styled(Link)`
    text-align: left;
` as typeof Link;

const ModalContent = styled.div`
    max-width: 812px;
    ${mediaQuery.minimum('small')} {
        padding: 0 ${spaces['0.75bu']};
    }
`;

const AttributionWrapper = styled.div`
    text-align: right;
`;

const ViewDetailLinkContent = styled(Inline)`
    align-items: flex-start;
`;

type Props = PropsWithProductSummary & {
    featureColor?: string;
    turnaround?: Feature;
    features: Feature[];
    additionalDetails?: Feature[];
    onClick?: () => void;
    linkWeight?: keyof typeof typography.fontWeights;
    linkText?: string;
    linkSize?: keyof typeof typography.typeScale;
    linkUnderline?: 'always' | 'none' | 'hover';
    image?: ProductPackageImage | null;
    headerVideo?: ProductPackageHeaderVideo | null;
};

const Video = styled.video`
    max-width: 100%;
`;

export default function ViewProductDetails({
    featureColor,
    features,
    turnaround,
    additionalDetails = [],
    productSummary,
    onClick,
    linkText = __('View details'),
    linkWeight = 'bold',
    linkUnderline = 'hover',
    linkSize = 'size3',
    image,
    headerVideo,
}: Props) {
    const [showModal, setShowModal] = useState(false);

    const escFunction = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setShowModal(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [escFunction]);

    if (turnaround) {
        features = [...features, turnaround];
    }

    return (
        <>
            <ViewDetailLink
                weight={linkWeight}
                size={linkSize}
                onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);

                    if (onClick) {
                        onClick();
                    }
                }}
                underline={linkUnderline === 'always' ? undefined : linkUnderline}
            >
                <ViewDetailLinkContent noWrap space="0.5bu">
                    <ViewDetailLinkIcon />
                    <span>{linkText}</span>
                </ViewDetailLinkContent>
            </ViewDetailLink>
            {showModal && (
                <Modal
                    isVisible={showModal}
                    onClose={() => {
                        setShowModal(false);
                    }}
                >
                    <ModalContent>
                        <Box marginBottom="1.5bu">
                            <ProductSummary {...productSummary} />
                        </Box>

                        {headerVideo?.src && (
                            <Box marginBottom="1.5bu">
                                <Video autoPlay loop playsInline src={headerVideo.src} />
                                <AttributionWrapper>
                                    <AttributionText>
                                        <FormattedMessage
                                            message={__('by {designerName}')}
                                            values={{ designerName: headerVideo.attribution }}
                                        />
                                    </AttributionText>
                                </AttributionWrapper>
                            </Box>
                        )}

                        <Box marginBottom="2bu">
                            <Divider />
                        </Box>
                        <ProductDetails
                            featureColor={featureColor}
                            features={features}
                            additionalDetails={additionalDetails}
                        />
                        {image && <ProductImage {...image} />}
                    </ModalContent>
                </Modal>
            )}
        </>
    );
}

interface ModalProps {
    isVisible: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

function Modal({ isVisible, children, onClose }: ModalProps) {
    return (
        <FullscreenOverlay
            isClosing={!isVisible}
            isActive={isVisible}
            hasOpeningAndClosingAnimation={true}
            enableScroll={true}
        >
            <FullscreenFrame>
                <FullscreenFrameMain isSidebarHidden>
                    <FullscreenMain scrollable>
                        <FullscreenMainHeader>
                            <Box
                                display="flex"
                                width="100%"
                                justifyContent="flex-end"
                                paddingX="1bu"
                            >
                                <IconButton
                                    icon={IconX1}
                                    aria-label={__('Close')}
                                    onClick={onClose}
                                    data-testid="icon-button"
                                    appearance="transparent"
                                    noTooltip
                                />
                            </Box>
                        </FullscreenMainHeader>
                        <FullscreenMainBody hasHeader>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                padding="1bu"
                            >
                                {children}
                            </Box>
                        </FullscreenMainBody>
                    </FullscreenMain>
                </FullscreenFrameMain>
            </FullscreenFrame>
        </FullscreenOverlay>
    );
}
