/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function MarketingWebsiteHeaderDesign(props: Props) {
    const newProps = { ...props, viewBox: '0 0 48 48' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            fill="currentColor"
            d="M21.953 40.875h-2.25c-.071 0-.139-.034-.21-.045a1.052 1.052 0 01-.872-.698l-.002-.007a1.204 1.204 0 01.851-1.607l.008-.002c.341-.098.428-.195.454-.559.038-.596.068-1.192.105-1.789 0-.157-.067-.225-.214-.236h-6.566a33.144 33.144 0 01-4.1-.122l.128.01a3.171 3.171 0 01-2.712-2.097l-.007-.022a5.508 5.508 0 01-.262-2.086l-.001.016v-8.828a1.029 1.029 0 00-.051-.236l.002.007v-9.488c0-.082.026-.165.037-.247.053-.45.079-.908.173-1.346A5.056 5.056 0 019.25 7.831l.03-.013a6.944 6.944 0 012.797-.645h.008l.825-.049h2.25c.409 0 .817.038 1.226.038h20.817c.472 0 .936.033 1.391.096l-.052-.006a3.05 3.05 0 012.566 1.966l.007.021c.268.654.436 1.411.465 2.204v.012c.03.829 0 1.661.026 2.494q.052 2.666.109 5.336c0 .143.023.281.034.424v2.302a1.833 1.833 0 00-.03.243v.005l-.18 7.455a8.632 8.632 0 01-.12 1.367l.007-.05c-.439 2.355-1.796 3.9-4.125 4.571a10.975 10.975 0 01-3.071.299l.022.001c-1.875.019-3.776 0-5.666 0-.319 0-.375.064-.341.375s.071.75.082 1.102a3.588 3.588 0 01-.911 2.677l.003-.003a2.344 2.344 0 01-1.214.747l-.016.003c-.083 0-.161.041-.24.06h-2.164c-.42 0-.836-.023-1.256-.026-.191.011-.383.037-.578.037zm2.013-32.156h-2.884q-3.904 0-7.811.03a10.928 10.928 0 00-2.604.214l.07-.012a3.388 3.388 0 00-2.565 2.167l-.007.023a5.04 5.04 0 00-.289 1.698v.036-.002 15.649c0 .855 0 1.71.041 2.569.055.771.302 1.474.693 2.076l-.01-.017c.231.388.648.645 1.125.645h.915c1.59.03 3.18.075 4.77.09 3.75.034 7.534.045 11.299.083 2.25.023 4.534.075 6.799.12a4.4 4.4 0 01.51.06l-.026-.004a2.767 2.767 0 001.519-.085l-.02.006A4.392 4.392 0 0038 30.204v-.005q-.045-2.25-.094-4.537c-.026-1.5-.064-3-.075-4.477-.023-2.794-.026-5.588-.049-8.378 0-.844-.023-1.688-.079-2.528a1.5 1.5 0 00-1.27-1.461l-.008-.001a5.425 5.425 0 00-1.095-.109h-.032.002q-5.674.004-11.332.011zm-.198 28.297H21.75c-.124 0-.221.03-.255.157-.109.375-.221.769-.315 1.159a.344.344 0 00.337.421l.021-.001h-.001 3.971c.236 0 .334-.086.375-.326.049-.375.079-.72.124-1.08.034-.278 0-.319-.274-.319z"
        />,
        <path
            fill="currentColor"
            d="M22.688 29.835h-10.83a3.913 3.913 0 01-1.497-.249l.027.009a2.632 2.632 0 01-1.672-2.241l-.001-.009a5.988 5.988 0 01-.042-.722l.001-.12V13.185l-.001-.07c0-.896.441-1.69 1.118-2.175l.008-.005a3.034 3.034 0 011.643-.57h.007c1.001-.067 2.006-.146 3-.221a23.884 23.884 0 014.186-.013l-.087-.006c.889.086 1.785.09 2.681.101 2.85.034 5.7.053 8.554.082 1.376 0 2.753.049 4.125.071a2.766 2.766 0 012.733 2.478l.001.012c.017.131.027.286.03.443v13.466l.001.089c0 .474-.096.925-.268 1.336l.008-.023a2.574 2.574 0 01-2.283 1.605h-.005c-1.009.075-2.025.045-3.034.049zm2.954-10.575v-.049l.131-.109a3.994 3.994 0 00.894-5.267l.01.016a4.168 4.168 0 00-2.273-1.762l-.029-.008c-1.031-.337-2.044-.139-3.045.128a2.157 2.157 0 00-1.125.607 9.774 9.774 0 00-.804.939l-.018.025c-.266.364-.49.781-.653 1.229l-.011.035a3.307 3.307 0 00.044 1.899l-.007-.024a4.34 4.34 0 00.784 1.732l-.008-.011c.15.18.27.375.427.615h-.232q-3.555-.023-7.125-.037c-.191 0-.251-.041-.251-.248v-4.849l.001-.037a.622.622 0 00-.339-.554l-.004-.002a.63.63 0 00-.911.644v-.003 8.025c0 1.384.056 2.767.082 4.148a3.907 3.907 0 01-.001.652l.001-.014a.596.596 0 00.575.751l.045-.002h-.002c.75 0 1.5.041 2.25.049q3.038.034 6.075.049h6.019c1.2 0 2.4.079 3.6.12.563 0 1.125.045 1.695.049h2.843l.066.002a.991.991 0 00.96-.745l.001-.007c.036-.137.057-.295.057-.458v-.043V22.674v-3.232c0-.15-.037-.203-.195-.203h-8.475zM22.763 31.26c.375 0 .679.026.81.375a1.003 1.003 0 01-.114 1.101l.001-.002a1.123 1.123 0 01-.699.374l-.006.001a.69.69 0 01-.813-.547l-.001-.004a2.075 2.075 0 01-.037-.627l-.001.008c.037-.454.236-.637.694-.675.071-.007.143-.004.165-.004z"
        />,
        <path
            fill="currentColor"
            d="M23.156 19.249a3.637 3.637 0 01-2.004-.384l.021.01a2 2 0 01-1-.842l-.005-.009a2.55 2.55 0 00-.193-.303l.005.007c-.592-.75-.6-1.609-.435-2.501a5.157 5.157 0 01.58-1.544l-.013.025c.099-.17.236-.308.4-.406l.005-.003a5.483 5.483 0 013.121-.784h-.013a.473.473 0 01.446.175l.001.001c.026.038.105.041.161.06a3.352 3.352 0 012.295 2.506l.004.022a3.259 3.259 0 01-1.628 3.621l-.017.009a2 2 0 01-1.278.345h.006a3.496 3.496 0 00-.467-.004h.01zm1.185-1.875c0-.068-.052-.191-.098-.311-.304-.848-.611-1.695-.911-2.543-.056-.165-.124-.3-.322-.311h-.014a.407.407 0 00-.391.297l-.001.003c-.052.135-.09.274-.131.409l-.705 2.325c0 .038 0 .075-.03.113a.279.279 0 00.148.356l.002.001a.252.252 0 00.374-.099l.001-.002c.101-.199.161-.416.248-.626.026-.068.064-.161.116-.18a3.67 3.67 0 01.558-.147l.023-.003a.25.25 0 01.311.212v.001c.087.283.173.513.272.737l-.017-.043a.27.27 0 00.35.171l-.002.001c.142-.052.229-.15.218-.375zm-.112-2.198a.3.3 0 00.3-.288v-.001a.304.304 0 00-.3-.293.293.293 0 10-.033.584l.034-.002h-.001zM22.991 22.83h3.259l.029-.001a.58.58 0 01.521.327l.002.003a.554.554 0 01-.046.612l.001-.001a.618.618 0 01-.496.249l-.05-.002h.002-6.431a.596.596 0 11.003-1.184h-.003c1.08-.008 2.145-.004 3.21-.004z"
        />,
        <path fill="currentColor" d="M22.789 16.095c.064-.262.12-.484.176-.705h.045l.154.641z" />
    );
}
