import styled from 'styled-components';
import { colors, typography } from '@99designs/emu/settings';

export interface NotificationPillProps {
    backgroundColor?: string;
    color?: string;
}

export const NotificationPill = styled.span<NotificationPillProps>`
    display: inline-block;
    text-align: center;
    width: auto;
    font-family: ${typography.fontFamilies.root};
    border: 1px solid;
    line-height: ${typography.lineHeights.root};
    background-color: ${({ backgroundColor = colors.heritage }) => backgroundColor};
    border-color: ${({ backgroundColor = colors.heritage }) => backgroundColor};
    color: ${({ color = colors.white }) => color};
    border-radius: 28px;
    font-size: ${typography.typeScale.size2}px;
    white-space: nowrap;
    padding: 0 0.45em;
`;
