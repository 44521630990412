import { Size } from '../../Field';

export const sizes: Record<Size, number> = {
    regular: 20,
    small: 16,
};

export const radioInnerSizes: Record<Size, number> = {
    regular: 10,
    small: 8,
};

export const checkboxInnerSizes: Record<Size, number> = {
    regular: 16,
    small: 12,
};
