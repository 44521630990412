import React from 'react';
import styled, { css } from 'styled-components';
import { BehaviourReactRouterLink, Box, Inline, Link, PageHeading } from '@99designs/emu';
import { mediaQuery } from '@99designs/emu/css';
import { colors, maxWidths, spaces } from '@99designs/emu/settings';
import { IconLeft1, IconRight1 } from '@99designs/icons';
import { Mobile } from '../Hidden';

interface ContentHeaderProps {
    mainHeading: React.ReactNode;
    mobileMenu?: React.ReactNode;
    maxWidth: MaxWidth;
}

const StyledContentHeader = styled.div`
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;

        background-color: ${colors['grey100']};
        background-image: linear-gradient(${colors['white']}, ${colors['grey100']});
    }
`;

type MaxWidth = 'desktop' | 'widescreen' | 'responsive';

const StyledContentHeaderInner1 = styled.div<{ maxWidth: MaxWidth }>`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: ${spaces['1bu']};
    padding-right: ${spaces['1bu']};
    ${mediaQuery.only('small')} {
        padding-left: ${spaces['0.5bu']};
        padding-right: ${spaces['0.5bu']};
    }
    &:before,
    &:after {
        content: ' ';
        display: table;
    }
    &:after {
        clear: both;
    }

    ${({ maxWidth }) => {
        const maxWidthMap: Record<MaxWidth, ReturnType<typeof css>> = {
            desktop: css`
                max-width: ${maxWidths.content.desktop}px;
            `,
            widescreen: css`
                max-width: ${maxWidths.content.widescreen}px;
            `,
            responsive: css`
                max-width: ${maxWidths.content.desktop}px;
                ${mediaQuery.minimum('large')} {
                    max-width: ${maxWidths.content.widescreen}px;
                }
            `,
        };
        return maxWidthMap[maxWidth];
    }}
`;

const StyledContentHeaderInner2 = styled.div`
    position: relative;
    width: 100%;
    float: left;
    padding-left: ${spaces['1bu']};
    padding-right: ${spaces['1bu']};
    ${mediaQuery.only('small')} {
        padding-left: ${spaces['0.5bu']};
        padding-right: ${spaces['0.5bu']};
    }

    padding-top: ${spaces['3bu']};
    ${mediaQuery.between('small', 'medium')} {
        padding-top: ${spaces['2bu']};
    }

    margin-bottom: ${spaces['3bu']};
    ${mediaQuery.between('small', 'medium')} {
        margin-bottom: ${spaces['2bu']};
    }
`;

export function ContentHeader({ mainHeading, mobileMenu = null, maxWidth }: ContentHeaderProps) {
    return (
        <ContentHeaderBase maxWidth={maxWidth}>
            <Box display="flex">
                <PageHeading>{mainHeading}</PageHeading>
            </Box>
            {mobileMenu && (
                <Mobile>
                    <Box marginTop="1bu">{mobileMenu}</Box>
                </Mobile>
            )}
        </ContentHeaderBase>
    );
}

interface ContentHeaderBaseProps {
    children: React.ReactNode;
    maxWidth: MaxWidth;
}

export function ContentHeaderBase({ children, maxWidth }: ContentHeaderBaseProps) {
    return (
        <StyledContentHeader data-testid="ContentHeader">
            <StyledContentHeaderInner1 maxWidth={maxWidth}>
                <StyledContentHeaderInner2>{children}</StyledContentHeaderInner2>
            </StyledContentHeaderInner1>
        </StyledContentHeader>
    );
}

interface ContentHeaderLinkProps {
    linkText: React.ReactNode;
    url: string;
}

export function ContentHeaderLink({ url, linkText }: ContentHeaderLinkProps) {
    return (
        <Box marginBottom="2bu">
            <Link href={url} color="grey700" size="size2">
                <Inline space="0.25bu">{linkText}</Inline>
            </Link>
        </Box>
    );
}

export function ContentHeaderRouterLink({ url, linkText }: ContentHeaderLinkProps) {
    return (
        <Box marginBottom="2bu">
            <Link behaviour={BehaviourReactRouterLink} href={url}>
                <Inline space="0.25bu">{linkText}</Inline>
            </Link>
        </Box>
    );
}

export function ContentHeaderBackLink({ url, linkText = __('Back') }: ContentHeaderLinkProps) {
    return (
        <ContentHeaderLink
            url={url}
            linkText={
                <>
                    <IconLeft1 />
                    {linkText}
                </>
            }
        />
    );
}

export function ContentHeaderBackRouterLink({
    url,
    linkText = __('Back'),
}: ContentHeaderLinkProps) {
    return (
        <ContentHeaderRouterLink
            url={url}
            linkText={
                <>
                    <IconLeft1 />
                    {linkText}
                </>
            }
        />
    );
}

export function ContentHeaderNextLink({ url, linkText = __('Next') }: ContentHeaderLinkProps) {
    return (
        <ContentHeaderLink
            url={url}
            linkText={
                <>
                    {linkText}
                    <IconRight1 />
                </>
            }
        />
    );
}

export function ContentHeaderNextRouterLink({
    url,
    linkText = __('Next'),
}: ContentHeaderLinkProps) {
    return (
        <ContentHeaderRouterLink
            url={url}
            linkText={
                <>
                    {linkText}
                    <IconRight1 />
                </>
            }
        />
    );
}
