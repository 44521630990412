const rootFontFamily = 'Graphik, sans-serif';
const headingFontFamily = '"Graphik Medium", sans-serif';

export type FontFamily = 'heading' | 'text';
export type FontFamilyLinksSize = 'root' | 'subtle' | 'jumbo';
export type ParagraphSize = 'jumbo' | 'large' | 'regular' | 'small';

export const fontFamilies = {
    root: rootFontFamily,
    heading: headingFontFamily,
    text: rootFontFamily,
    code: 'Monaco, Menlo, Consolas, Courier New, monospace',
    attribution: 'Georgia, serif',
    cite: 'Georgia, serif',
    links: {
        root: rootFontFamily,
        subtle: rootFontFamily,
        jumbo: headingFontFamily,
    },
};

export const typeScale = {
    size1: 12,
    size2: 14,
    size3: 16,
    size4: 19,
    size5: 24,
    size6: 32,
    size7: 40,
    size8: 48,
    size9: 60,
};

export type TypeScale = keyof typeof typeScale;

const rootFontSize = typeScale.size3;

export const paragraphFontSizes: Record<ParagraphSize, TypeScale> = {
    jumbo: 'size5',
    large: 'size4',
    regular: 'size3',
    small: 'size2',
};

export const fontSizes = {
    root: rootFontSize,
    h0: {
        all: typeScale.size9,
        medium: typeScale.size8,
        small: typeScale.size8,
    },
    h1: {
        all: typeScale.size8,
        medium: typeScale.size7,
        small: typeScale.size7,
    },
    h2: {
        all: typeScale.size7,
        medium: typeScale.size6,
        small: typeScale.size6,
    },
    h3: {
        all: typeScale.size6,
        medium: typeScale.size5,
        small: typeScale.size5,
    },
    h4: {
        all: typeScale.size5,
        medium: typeScale.size4,
        small: typeScale.size4,
    },
    h5: {
        all: typeScale.size4,
        medium: typeScale.size3,
        small: typeScale.size3,
    },
    h6: {
        all: typeScale.size3,
        medium: typeScale.size2,
        small: typeScale.size2,
    },
    h7: {
        all: typeScale.size2,
        medium: typeScale.size1,
        small: typeScale.size1,
    },
    primaryCrosslead: {
        all: typeScale.size6,
        small: typeScale.size3,
    },
    primarySidelead: {
        all: typeScale.size6,
        small: typeScale.size3,
    },
    small: '70%',
    blockquote: {
        base: 'inherit',
        cite: '85%',
    },
    links: {
        root: typeScale.size3,
        subtle: typeScale.size2,
        jumbo: typeScale.size4,
    },
    code: rootFontSize,
    paragraphs: {
        jumbo: typeScale[paragraphFontSizes['jumbo']],
        large: typeScale[paragraphFontSizes['large']],
        regular: typeScale[paragraphFontSizes['regular']],
        small: typeScale[paragraphFontSizes['small']],
    },
};

export const fontWeights = {
    normal: 400,
    medium: 400,
    bold: 700,
    root: 400,
    text: 400,
    heading: 400,
};

export const headingLetterSpacing = {
    h0: '-0.045em',
    h1: '-0.035em',
    h2: '-0.025em',
    h3: '-0.015em',
    h4: '-0.005em',
    h5: 0,
    h6: 0,
    h7: 0,
};

export const letterSpacing = {
    h0: headingLetterSpacing.h0,
    h1: headingLetterSpacing.h1,
    h2: headingLetterSpacing.h2,
    h3: headingLetterSpacing.h3,
    h4: headingLetterSpacing.h4,
    h5: headingLetterSpacing.h5,
    h6: headingLetterSpacing.h6,
    h7: headingLetterSpacing.h7,
    typeScale: {
        size9: headingLetterSpacing.h0,
        size8: headingLetterSpacing.h1,
        size7: headingLetterSpacing.h2,
        size6: headingLetterSpacing.h3,
        size5: headingLetterSpacing.h4,
        size4: headingLetterSpacing.h5,
        size3: headingLetterSpacing.h6,
        size2: headingLetterSpacing.h7,
        size1: headingLetterSpacing.h7,
    },
};

export const paragraphLineHeights: Record<ParagraphSize, keyof typeof lineHeights> = {
    jumbo: 'textLarge',
    large: 'textLarge',
    regular: 'text',
    small: 'text',
};

const smallLineHeight = 1.2;
const mediumLineHeight = 1.3;
const largeLineHeight = 1.4;
const rootLineHeight = 1.6;
const h0LineHeight = 1.05;
const h1LineHeight = 1.05;
const h2LineHeight = 1.1;
const h3LineHeight = 1.15;
const h4LineHeight = 1.25;
const h5LineHeight = 1.3;
const h6LineHeight = 1.3;
const h7LineHeight = 1.3;

export const lineHeights = {
    small: smallLineHeight,
    medium: mediumLineHeight,
    root: rootLineHeight,
    h0: h0LineHeight,
    h1: h1LineHeight,
    h2: h2LineHeight,
    h3: h3LineHeight,
    h4: h4LineHeight,
    h5: h5LineHeight,
    h6: h6LineHeight,
    h7: h7LineHeight,
    text: rootLineHeight,
    textLarge: largeLineHeight,
    heading: rootLineHeight,
    typeScale: {
        size9: h0LineHeight,
        size8: h1LineHeight,
        size7: h2LineHeight,
        size6: h3LineHeight,
        size5: h4LineHeight,
        size4: h5LineHeight,
        size3: h6LineHeight,
        size2: h7LineHeight,
        size1: h7LineHeight,
    },
    paragraphs: {
        jumbo: largeLineHeight,
        large: largeLineHeight,
        regular: rootLineHeight,
        small: rootLineHeight,
    },
};

export type HeadingLevel = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
export const headings = {
    0: {
        fontSize: [fontSizes.h0.medium, fontSizes.h0.all],
        lineHeight: h0LineHeight,
        letterSpacing: headingLetterSpacing.h0,
    },
    1: {
        fontSize: [fontSizes.h1.medium, fontSizes.h1.all],
        lineHeight: h1LineHeight,
        letterSpacing: headingLetterSpacing.h1,
    },
    2: {
        fontSize: [fontSizes.h2.medium, fontSizes.h2.all],
        lineHeight: h2LineHeight,
        letterSpacing: headingLetterSpacing.h2,
    },
    3: {
        fontSize: [fontSizes.h3.medium, fontSizes.h3.all],
        lineHeight: h3LineHeight,
        letterSpacing: headingLetterSpacing.h3,
    },
    4: {
        fontSize: [fontSizes.h4.medium, fontSizes.h4.all],
        lineHeight: h4LineHeight,
        letterSpacing: headingLetterSpacing.h4,
    },
    5: {
        fontSize: [fontSizes.h5.medium, fontSizes.h5.all],
        lineHeight: h5LineHeight,
        letterSpacing: headingLetterSpacing.h5,
    },
    6: {
        fontSize: [fontSizes.h6.medium, fontSizes.h6.all],
        lineHeight: h6LineHeight,
        letterSpacing: headingLetterSpacing.h6,
    },
    7: {
        fontSize: [fontSizes.h7.medium, fontSizes.h7.all],
        lineHeight: h7LineHeight,
        letterSpacing: headingLetterSpacing.h7,
    },
};

export const maxWidths = {
    h0: '12em',
    h1: '12em',
    h2: '12em',
    h3: '12em',
    h4: '12em',
    h5: '12em',
    h6: '23em',
    h7: '23em',
    paragraph: '40em',
    form: {
        'xxx-short': '2.5em',
        'xx-short': '5em',
        'x-short': '10em',
        short: '20em',
        default: '35em',
        long: '45em',
        max: '100%',
    },
};

export const fontStyles = {
    em: 'italic',
    attribution: 'italic',
};

export const textRendering = {
    root: null,
    heading: 'optimizelegibility',
};

export const textDecoration = {
    anchor: {
        base: 'none',
        hover: 'underline',
        active: 'none',
    },
};
