import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { IconUser1 } from '@99designs/icons';
import { colors } from '../../settings';
import { Box } from '../Box';
import { Tooltip } from '../Tooltip';
import { avatarSizes } from './sizes';
import { AvatarSize } from './types';

const iconSize: Record<AvatarSize, string> = {
    'extra-small': '12px',
    small: '18px',
    medium: '24px',
    large: '36px',
    'extra-large': '48px',
    'extra-extra-large': '64px',
};

export interface AvatarPlaceholderProps {
    size?: AvatarSize;
    tooltip?: string;
}

const Container = styled(Box).attrs<{ className?: string }>({ className: 'emu' })<{
    size: AvatarSize;
}>`
    border-color: ${colors.grey500};
    border-style: dashed;
    border-width: 1px;
    border-radius: 50%;
    color: ${colors.grey500};
    width: ${({ size }) => avatarSizes[size]};
    height: ${({ size }) => avatarSizes[size]};
`;

export const AvatarPlaceholder = forwardRef<HTMLDivElement, AvatarPlaceholderProps>(function (
    { size = 'medium', tooltip },
    ref
) {
    const placeholderComponent = (
        <Container
            ref={ref}
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            size={size}
        >
            <IconUser1 width={iconSize[size]} height={iconSize[size]} aria-hidden="true" />
        </Container>
    );

    if (!tooltip) {
        return placeholderComponent;
    }

    return (
        <Tooltip title={tooltip}>
            <Container
                ref={ref}
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                size={size}
            >
                <IconUser1 width={iconSize[size]} height={iconSize[size]} aria-hidden="true" />
            </Container>
        </Tooltip>
    );
});
