/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconTick1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M3.325 13.354c.282 0 7.056 7.531 7.43 7.895s.543.617 1.136.042c.594-.574 16.493-17.494 16.792-17.494S32 6.7 32 6.902c0 .184-19.244 20.393-19.708 20.846s-1.033.297-1.205.136C10.916 27.723 0 16.525 0 16.397s3.043-3.044 3.325-3.044z"
            fill="currentColor"
        />
    );
}
