import React from 'react';
import styled from 'styled-components';
import { IconCaution } from '@99designs/icons';
import { colors } from '../../../settings';
import { BaseAlert, BaseAlertProps } from '../BaseAlert';

export const StyledCautionAlert = styled(BaseAlert)`
    background: ${colors.experimental.yellow400};
    color: ${colors.grey900};
`;

type CautionAlertProps = Omit<BaseAlertProps, 'icon'>;

export function CautionAlert({ children, ...rest }: CautionAlertProps) {
    return (
        <StyledCautionAlert icon={<IconCaution />} {...rest}>
            {children}
        </StyledCautionAlert>
    );
}
