/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconPhone1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M5.691.014c.978-.199 1.617 1.759 3.014 3.412 1.165 1.377 2.643 2.933 2.7 3.892.126 2.109-4.309 1.844-3.925 3.574s10.493 13.342 13.052 13.475c2.559.133 2.548-3.996 4.6-3.904.885.04 2.166 1.216 3.679 2.454 1.439 1.177 3.579 2.441 3.126 3.545-1.415 3.447-11.396 11.975-25.082-3.077S3.898.379 5.691.015z"
            fill="currentColor"
        />
    );
}
