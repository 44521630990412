import styled from 'styled-components';
import { borders, boxShadows, colors } from '../../settings';

export const Menu = styled.ul<{ isOpen: boolean }>`
    background-color: ${colors.white};
    border-radius: ${borders.borderRadiuses.medium}px;
    box-shadow: ${boxShadows.low};
    list-style: none;
    margin: 0;
    max-height: 400px;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    overflow-y: auto;
    padding: 0;
    transition: opacity 0.2s;
    width: 100%;
    z-index: 99;
`;
