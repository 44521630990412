import { isSsr } from '@99designs/browser';
import { RedirectError } from './RedirectError';

export interface Param {
    key: string;
    value: string;
}

export function createRedirectUrl(url: string, params?: Param[]): string {
    if (!params) {
        return url;
    }

    const str: string[] = [];
    params.forEach((param) => {
        str.push(`${param.key}=${encodeURIComponent(param.value)}`);
    });

    return `${url}?${str.join('&')}`;
}

export const redirectTo = (url: string, params?: Param[]) => {
    const parsedUrl = createRedirectUrl(url, params);

    if (isSsr()) {
        throw new RedirectError(parsedUrl);
    } else {
        window.location.assign(parsedUrl);
    }
    return null;
};
