/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconSort1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M8.281 18.212c-.857 0-2.948-.008-2.071 1.117s7.647 8.741 8.042 9.492c.395.75 1.838 1.892 2.682.58s7.871-9.261 8.258-9.74c0 0 1.817-1.728 0-1.658s-16.055.21-16.911.21zm15.438-4.424c.857 0 2.948.008 2.071-1.117s-7.647-8.741-8.042-9.492c-.395-.75-1.838-1.892-2.682-.58s-7.871 9.261-8.258 9.74c0 0-1.817 1.728 0 1.658s16.055-.21 16.911-.21z"
            fill="currentColor"
        />
    );
}
