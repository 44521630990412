/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconLike1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M7.971 14.057c-.09-.709-.659-1.041-1.57-1.041l-2.992-.039c-1.311.159-1.261.42-1.37 2.009l-.053 13.14c0 .875.769 1.349 1.672 1.349l2.808.028c.911 0 1.421-.625 1.53-1.378 3.879.316 3.575 2.207 13.211 2.312 2.815.026 4.827.044 5.486-1.059.469-.866.226-1.934.325-2.38.135-.613 1.218-1.234 1.362-2.118.126-.77-.108-1.426-.036-2.293.09-.989 1.435-2.196 1.48-3.168.036-.884-.388-1.838-.388-2.59.009-1.155.577-1.768.55-2.231-.135-1.978-1.85-2.511-3.293-2.643-1.435-.131-6.613 0-7.236 0-.614 0-.821-.525-.55-1.05.28-.534 2.68-5.268.659-7.604-1.543-1.785-3.41-.368-3.293.525.162 1.146-.343 2.109-1.642 3.693s-3.564 2.721-4.385 5.495c-.623 1.251-1.696.901-2.274 1.041z"
            fill="currentColor"
        />
    );
}
