import React from 'react';
import styled, { css } from 'styled-components';
import { typography } from '../../settings';
import { Box } from '../Box';
import { Inline } from '../Inline';
import { Text } from '../Text';
import { FieldLabelType } from './types';

export type Size = 'small' | 'regular';

type BaseFieldClickableProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> & {
    disabled?: boolean;
    id: string;
    /**
     * Visual size of the clickable item.
     */
    size?: Size;
};

export type FieldClickableProps = BaseFieldClickableProps & FieldLabelType;

type PrivateFieldClickableProps = BaseFieldClickableProps & {
    children: React.ReactNode;
    label?: string;
};

const ClickableBox = styled(Box)<{ disabled?: FieldClickableProps['disabled'] }>`
    ${({ disabled }) =>
        !disabled &&
        css`
            cursor: pointer;
        `}
`;

export function FieldClickable({
    children,
    disabled,
    id,
    label,
    size = 'regular',
}: PrivateFieldClickableProps) {
    return (
        <Box display="inline-block" className="emu">
            <Inline space={size === 'regular' ? '1bu' : '0.75bu'} noWrap>
                <Box position="relative" display="flex">
                    {children}
                </Box>
                {label && (
                    <ClickableBox as="label" htmlFor={id} display="block" disabled={disabled}>
                        <Text
                            color={disabled ? 'grey400' : undefined}
                            size={typography.paragraphFontSizes[size]}
                        >
                            {label}
                        </Text>
                    </ClickableBox>
                )}
            </Inline>
        </Box>
    );
}
