/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconBell1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M13.301 2.978S13.716-.063 15.55.001s2.236 1.42 2.509 2.679c1.804.542 7.554 2.476 8.22 6.844s.268 9.294 1.026 10.836c.758 1.541 3.615 3.491 2.396 4.287s-10.919.737-13.705.791c-2.786.055-11.668.036-12.871-.566s-1.542-2.106-.52-3.083c1.022-.977 2.502-1.035 2.371-5.668S5.925 6.533 8.51 4.86s4.791-1.883 4.791-1.883zm.072 25.083c-.794-.076-1.512.529-1.035 1.367s1.606 2.637 3.996 2.569c2.39-.068 3.848-2.541 4.01-3.38s-.559-.915-1.194-.839c-.635.076-4.983.358-5.777.282z"
            fill="currentColor"
        />
    );
}
