import { Menu as AriakitMenu, MenuButton, useMenuStore } from '@ariakit/react';
import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import styled from 'styled-components';
import { borders, boxShadows, colors, spaces } from '../../settings';
import { MenuDivider } from './MenuDivider';
import { MenuItem } from './MenuItem';

export interface MenuProps {
    children: React.ReactNode;
    disabled?: boolean;
    disclosure: React.ReactElement;
    isOpen?: boolean;
}

const StyledAriakitMenu = styled(AriakitMenu).attrs<{ className?: string }>({ className: 'emu' })`
    outline: none;
    z-index: 99; /* Same as tooltip */
`;

export const InnerMenu = styled.div`
    background-color: ${colors.white};
    border-radius: ${borders.borderRadiuses.medium}px;
    box-shadow: ${boxShadows.low};
    max-height: 50vh;
    min-width: 250px;
    opacity: 0;
    overflow-y: auto;
    padding: ${spaces['0.75bu']} 0;
    transition: opacity 0.2s;

    [data-enter] & {
        opacity: 1;
    }
`;

/**
 * Renders a list of options (buttons or links) inside of a small dialog.
 * The disclosure element should accept event listeners and aria-properties for accessibility.
 * Valid children are MenuItem and MenuDivider.
 * Follows the WAI-ARIA Menu pattern, with an WAI-ARIA Menu Button pattern used for the disclosure.
 * Note that this component isn't a replacement for all dialogs, nor should be used for `nav` like structures.
 */
export const Menu = React.forwardRef<HTMLButtonElement, MenuProps>(
    ({ children, disabled, disclosure, isOpen }, ref) => {
        const menu = useMenuStore({
            animated: 200, // Matches Select/Menu
            focusLoop: true,
            defaultOpen: isOpen,
        });
        const items = flattenChildren(children);
        if (
            !items.every(
                (item) =>
                    typeof item === 'object' &&
                    (item.type === MenuItem || item.type === MenuDivider)
            )
        ) {
            console.error(
                'All child nodes within a menu component must be a MenuItem or MenuDivider'
            );
        }

        return (
            <>
                <MenuButton ref={ref} store={menu} disabled={disabled} render={disclosure} />
                <StyledAriakitMenu gutter={spaces.px['0.5bu']} disabled={disabled} store={menu}>
                    <InnerMenu>{children}</InnerMenu>
                </StyledAriakitMenu>
            </>
        );
    }
);
