/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconPresentation1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M17.754 23.19c-.002.328-.173 3.205.106 3.338.28.133 7.601 2.237 8.39 2.415s1.281.568.972 1.792c-.308 1.224-.711 1.391-1.579 1.196s-7.476-1.895-8.654-2.203c-1.178-.309-1.741.056-2.48.216-.809.175-7.719 1.878-8.047 1.945s-.973.439-1.337-1.021.463-1.968.702-2.032 8.335-1.805 8.599-2.328c.225-.445.131-2.136.099-3.302-6.237.031-12.057.06-12.537.06-1.05 0-1.99-.84-1.99-1.813S.076 4.055.076 3.143c0-.912 1.195-1.552 1.856-1.552s12.576-.184 12.576-.184S14.68 0 16.036 0s1.027 1.312 1.41 1.44c.383.128 11.506.012 12.156.012s2.129.884 2.129 2.108c0 1.224.267 16.292.267 17.621s-1.168 1.947-1.74 1.947c-.266 0-6.163.03-12.506.062zm2.604-13.518c-1.867 0-3.17 1.205-3.17 3.252s1.634 3.11 3.963 3.11 2.832-1.981 2.832-3.536-1.756-2.826-3.624-2.826zM9.732 4.993c-.475 0-.742.337-.742.862s.152 4.089.219 4.504c.067.414.3.678.836.678s4.134-.2 4.551-.2c.417 0 1.1-.409.903-1.444s-.33-2.882-.397-3.766c-.067-.884-.675-.884-1.044-.884s-3.852.25-4.327.25zm2.383 9.414c-.48.848-1.573 2.91-2.152 3.995s0 1.024.42 1.024 4.035.111 5.516.111.778-.331.489-.904c-.289-.573-2.373-3.536-3.018-4.668s-.775-.406-1.256.442z"
            fill="currentColor"
        />
    );
}
