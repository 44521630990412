import styled, { css } from 'styled-components';
import { colors } from '@99designs/emu/settings';
import { IconButton, IconButtonProps } from './IconButton';

export interface OnColorIconButtonProps extends IconButtonProps {
    active?: boolean;
    iconColor: colors.Color;
    activeIconColor: colors.Color;
    activeBackgroundColor: colors.Color;
}
function onColorIconButton({
    active = false,
    iconColor,
    activeIconColor,
    activeBackgroundColor,
}: OnColorIconButtonProps) {
    return css`
        background-color: ${active ? colors[activeBackgroundColor] : 'transparent'};
        color: ${colors[iconColor]};

        &:hover,
        &:focus,
        &:active {
            color: ${colors[activeIconColor]};

            &:before {
                background: ${colors[activeBackgroundColor]};
            }
        }
    `;
}
/**
 * @deprecated replaced by `IconButton` in `@99designs/emu`
 */
export const OnColorIconButton = styled(IconButton)(onColorIconButton);
