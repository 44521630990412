import React, { useState } from 'react';
import { Placement } from 'popper.js';
import { Button, Heading, createButtonPrimaryColorConfig } from '@99designs/emu';
import ClickAwayListener from '@99designs/frontend-components/components/ClickAwayListener';
import { PopoverCard } from '@99designs/frontend-components/components/PopoverCard';
import { useHasDoneActionQuery, useSetCompletedActionMutation } from './TutorialTip.generated';

interface Props {
    title: React.ReactNode;
    message: React.ReactNode;
    layerSelector?: string;
    children: JSX.Element;
    placement?: Placement;
    tipName: string;
    enabled?: boolean;
}

export function TutorialTip({
    title,
    message,
    layerSelector,
    children,
    placement = 'bottom',
    enabled = true,
    tipName,
}: Props) {
    const [dismissed, setDismissed] = useState(false);
    const { data, loading, error } = useHasDoneActionQuery({
        variables: {
            name: `tutorial/${tipName}`,
        },
    });
    const [setCompleted] = useSetCompletedActionMutation();
    if (!enabled || dismissed) return children;
    if (error) throw error;
    if (loading || !data) return children;
    if (data.me.hasDone) return children;

    async function dismiss() {
        setDismissed(true);
        await setCompleted({
            variables: {
                name: `tutorial/${tipName}`,
            },
        });
    }

    return (
        <ClickAwayListener onClickAway={dismiss}>
            <PopoverCard
                placement={placement}
                variations={['onboarding-prompt']}
                layerSelector={layerSelector}
                card={
                    <div>
                        <Heading accessibilityLevel={5} color="pacific" LEGACY_marginBottom="1.5bu">
                            {title}
                        </Heading>

                        {message}

                        <Button
                            size="small"
                            appearance={createButtonPrimaryColorConfig({
                                color: 'pacific',
                                backgroundColorHover: 'pacificLight',
                                backgroundColorActive: 'pacificDark',
                            })}
                            onClick={dismiss}
                        >
                            {__('Got it!')}
                        </Button>
                    </div>
                }
            >
                {children}
            </PopoverCard>
        </ClickAwayListener>
    );
}
