// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../types/index.d.ts" />
import Ahocorasick from 'ahocorasick';

export function circumventionKeywordMatches(input: string): string[] {
    const matches: string[] = [];

    //check for paypal + email regex
    const regEx = /pay[ -]?pal \S+@\S+\.\S+/;
    const emailMatches = input.match(regEx);
    if (emailMatches) {
        matches.push(emailMatches[0]);
    }

    //check for other keywords
    const ac = new Ahocorasick(keywords);
    const acMatches = ac.search(input.toLowerCase());
    return acMatches.reduce<string[]>((acc, current) => {
        return acc.concat(current[1]);
    }, matches);
}

const keywords = [
    'through paypal',
    'through pay-pal',
    'through pay pal',
    'via paypal',
    'via pay-pal',
    'via pay pal',
    'have paypal',
    'have pay-pal',
    'have pay pal',
    'on paypal',
    'on pay-pal',
    'on pay pal',
    'paypal request',
    'pay-pal request',
    'pay pal request',
    'paypal.me',
    'my paypal email',
    'my pay-pal email',
    'my pay pal email',
    'paypal@',
    'pay-pal@',
    'pay pal@',
    'off 99d',
    'outside 99d',
    'outside of 99d',
    'venmo',
    'pay direct',
    'paydirect',
    'squarecash',
    'square cash',
    'gocardless',
    'transferwise',
    'payoneer',
    'payooner',
    'skrill',
    'cashapp',
    'cash app',
    '2checkout',
    'dwolla',
];
