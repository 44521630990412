import { MenuItem as AriakitMenuItem, MenuStore } from '@ariakit/react';
import React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import { colors, spaces } from '../../settings';
import { Box } from '../Box';
import { Clickable, ClickableProps } from '../Clickable';
import { Text } from '../Text';

interface MenuItemStylesProps {
    hide?: () => void;
    icon?: React.ComponentType<{ color?: CSS.Property.Color }>;
    store?: MenuStore;
}

const MenuItemContainer = styled(Clickable)`
    appearance: none;
    background: none;
    border: 0;
    color: ${colors.grey800};
    display: flex;
    font: inherit;
    margin: 0;
    outline: 0;
    padding: ${spaces['0.75bu']} ${spaces['1bu']};
    text-decoration: none;
    width: 100%;

    &:focus,
    &:hover {
        background-color: ${colors.grey100};
        color: ${colors.grey800};
        outline: none;
        text-decoration: none;
    }
`;

export type MenuItemProps = ClickableProps<MenuItemStylesProps>;

export const BaseMenuItem = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, MenuItemProps>(
    function ({ 'aria-disabled': ariaDisabled, children, disabled, icon: Icon, ...rest }, ref) {
        const isDisabled = disabled || ariaDisabled;

        return (
            <MenuItemContainer ref={ref} aria-disabled={ariaDisabled} disabled={disabled} {...rest}>
                {Icon && (
                    <Box marginRight="0.75bu">
                        <Icon color={isDisabled ? colors.grey300 : undefined} />
                    </Box>
                )}
                <Text color={isDisabled ? 'grey400' : undefined} truncate>
                    {children}
                </Text>
            </MenuItemContainer>
        );
    }
);

/**
 * Renders a menu item for use within the Menu component.
 * This will automatically close the menu when an item is clicked.
 */
export function MenuItem({ onClick, store, ...rest }: MenuItemProps) {
    const { hide } = rest;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClick(e: any) {
        onClick?.(e);
        hide?.();
    }

    return (
        <AriakitMenuItem
            as={BaseMenuItem}
            onClick={handleClick}
            store={store}
            {...rest}
            focusable
        />
    );
}
