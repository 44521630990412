import styled from 'styled-components';
import { borders, typography } from '@99designs/emu/settings';

export interface PillProps {
    color: string;
}

/**
 * @deprecated replaced by `Tag` in `@99designs/emu`
 */
export const Pill = styled.span<PillProps>`
    display: inline-block;
    text-align: center;
    width: auto;
    font-family: ${typography.fontFamilies.root};
    border: 1px solid;
    line-height: ${typography.lineHeights.root};
    border-color: ${({ color }) => color};
    color: ${({ color }) => color};
    border-radius: ${borders.borderRadiuses.small}px;
    font-size: ${typography.typeScale.size2}px;
    white-space: nowrap;
    padding: 0 1em;
`;
