import { useContext } from 'react';
import { FloatingAlertContext } from './FloatingAlertContext';
import { InternalFloatingAlert } from './types';

/**
 * Displays a `FloatingAlert` at the bottom of the screen for a specified duration.
 * Follows the WAI-ARIA Alert pattern.
 * Focus is not switched to the alert when shown, so any interaction within the
 * alert should be considered inaccessible.
 */
export function useFloatingAlert() {
    const dispatch = useContext(FloatingAlertContext);

    function addFloatingAlert(floatingAlert: Omit<InternalFloatingAlert, 'id'>) {
        if (dispatch == null) {
            throw new Error('useFloatingAlert must be used within a FloatingAlertProvider');
        }

        dispatch({
            type: 'ADD_ALERT',
            floatingAlert,
        });
    }

    return addFloatingAlert;
}
