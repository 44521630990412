import React from 'react';
import styled, { css } from 'styled-components';
import { borders, boxShadows, colors, spaces } from '@99designs/emu/settings';
import { InlinedSvg } from '../../InlinedSvg';
import { Option } from '../TileSelect';

export interface TileSelectItemProps<T> {
    handleChange: (value: T) => void;
    option: Option<T>;
    name: string;
    isSelected: boolean;
    matchItemHeight: boolean;
    type?: 'radio' | 'checkbox';
}
const royalBlue = `#4856DF`;
const skyBlue = `#3EC1F3`;
const toRightGradientVibrantBlue = `linear-gradient(to right, ${royalBlue}, ${skyBlue});`;

interface LabelProps {
    isSelected: boolean;
    matchItemHeight: boolean;
}

const SVG = styled(InlinedSvg)`
    width: 64px;
    margin-bottom: -${spaces['1bu']};
    margin-top: ${spaces['3bu']};
`;

const gradientBorderMixin = css`
    background-image: ${(props: { isSelected: boolean }) =>
        props.isSelected ? toRightGradientVibrantBlue : `none`};
    padding: 3px;
`;

const GradientBorderInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${colors.white};
    height: inherit;
    border-radius: ${borders.borderRadiuses.small}px;
`;

const Label = styled.label<LabelProps>`
    box-shadow: ${({ isSelected }) => (isSelected ? boxShadows.medium : boxShadows.low)};
    border: ${({ isSelected }) => (isSelected ? `none` : colors.grey300)};
    border-radius: ${borders.borderRadiuses.medium}px;
    ${gradientBorderMixin}

    display: block;
    cursor: pointer;
    height: ${({ matchItemHeight }) => (matchItemHeight ? '100%' : 'inherit')};

    &:hover {
        box-shadow: ${boxShadows.medium};
    }
`;

function TileSelectItem<T>({
    handleChange,
    isSelected,
    option,
    name,
    matchItemHeight,
    type = 'radio',
}: TileSelectItemProps<T>) {
    return (
        <Label
            isSelected={isSelected}
            tabIndex={0}
            onKeyPress={(e) => {
                e.preventDefault();

                if (e.key === 'Enter') {
                    handleChange(option.value);
                }
            }}
            matchItemHeight={matchItemHeight}
        >
            <GradientBorderInner>
                {option.icon && <SVG>{option.icon}</SVG>}
                <input
                    name={name}
                    type={type}
                    hidden
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    defaultValue={option.value as any}
                    checked={isSelected}
                    data-testid={'option-input'}
                    onChange={() => {
                        handleChange(option.value);
                    }}
                />

                {option.label}
            </GradientBorderInner>
        </Label>
    );
}

export default TileSelectItem;
