import { createContext, useContext } from 'react';
import { PaginationAppLevelProps } from './components/Pagination';

export const defaultTranslationStrings = {
    Alert: {
        Dismiss: 'Dismiss',
    },
    ButtonGroup: {
        Menu: 'More options',
    },
    Modal: {
        Close: 'Close',
    },
    Rating: {
        Legend: 'Rating',
        ReadOnlyLabel: (value: number) => `${value} out of 5 stars`,
        Value: (value: number) => (value === 1 ? '1 star' : `${value} stars`),
    },
    Select: {
        ClearAll: 'Clear all',
        Open: 'Open',
        Remove: 'Remove',
    },
    Pagination: {
        Next: 'Next',
        Previous: 'Previous',
    },
};

export type TranslationStrings = typeof defaultTranslationStrings;

export interface Props {
    translationStrings: TranslationStrings;
    components?: {
        Pagination: PaginationAppLevelProps;
    };
}

export const Context = createContext<Props>({
    translationStrings: defaultTranslationStrings,
});

export function useTranslations() {
    const { translationStrings } = useContext(Context);
    return translationStrings;
}

export function useComponentsProps(): Props['components'] {
    const { components } = useContext(Context);
    return components;
}
