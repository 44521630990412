/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconFilters1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M.337 3.815c0-2.588 9.577-4.052 16.343-4.052 6.659 0 15.663 1.485 15.657 3.516v.536c0 .393-11.332 13.122-11.993 14.254-.691 1.183.415 11.319-.469 12.744s-6.792 1.1-6.792 0 .264-11.082-.492-12.744S-.342 5.223.381 4.23a2.684 2.684 0 01-.043-.415zm15.705 2.633c5.056 0 12.213-.736 12.213-2.037s-5.553-1.614-12.137-1.614c-6.585 0-11.78.6-11.78 1.825s6.649 1.825 11.705 1.825z"
            fill="currentColor"
        />
    );
}
