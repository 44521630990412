/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconInstagram1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M16 .013c4.347 0 4.884.013 6.595.1 1.699.075 2.86.35 3.884.737 1.049.412 1.948.949 2.835 1.849a7.95 7.95 0 011.849 2.835c.4 1.012.662 2.173.737 3.884s.1 2.248.1 6.595c0 4.347-.012 4.884-.1 6.595-.075 1.699-.35 2.86-.737 3.884-.412 1.049-.949 1.948-1.849 2.835a7.95 7.95 0 01-2.835 1.849c-1.012.4-2.173.662-3.884.737s-2.248.1-6.595.1c-4.347 0-4.884-.013-6.595-.1-1.699-.075-2.86-.35-3.884-.737-1.049-.412-1.948-.949-2.835-1.849a7.95 7.95 0 01-1.849-2.835C.437 25.48.175 24.319.1 22.608S0 20.36 0 16.013c0-4.347.013-4.884.1-6.595.075-1.699.35-2.86.737-3.884.412-1.049.949-1.948 1.849-2.835A7.95 7.95 0 015.521.85C6.533.45 7.694.188 9.405.113c1.711-.087 2.248-.1 6.595-.1zm0 2.885c-4.272 0-4.771.013-6.457.087-1.561.075-2.411.337-2.973.55-.749.287-1.274.637-1.836 1.199S3.822 5.821 3.535 6.57c-.225.562-.475 1.411-.55 2.973-.075 1.686-.087 2.186-.087 6.457s.013 4.771.087 6.457c.075 1.561.337 2.411.55 2.973.287.749.637 1.274 1.199 1.836s1.087.912 1.836 1.199c.562.225 1.411.475 2.973.55 1.686.075 2.186.087 6.457.087s4.771-.012 6.457-.087c1.561-.075 2.411-.337 2.973-.55.749-.287 1.274-.637 1.836-1.199s.912-1.087 1.199-1.836c.225-.562.475-1.411.55-2.973.075-1.686.087-2.186.087-6.457s-.012-4.771-.087-6.457c-.075-1.561-.337-2.411-.55-2.973-.287-.749-.637-1.274-1.199-1.836s-1.087-.912-1.836-1.199c-.562-.225-1.411-.475-2.973-.55-1.686-.075-2.186-.087-6.457-.087zm-.037 4.858c4.534 0 8.206 3.672 8.206 8.206s-3.672 8.206-8.206 8.206c-4.534 0-8.206-3.672-8.206-8.206s3.672-8.206 8.206-8.206zm0 13.54c2.948 0 5.333-2.386 5.333-5.333s-2.386-5.333-5.333-5.333a5.33 5.33 0 00-5.333 5.333c.012 2.948 2.398 5.333 5.333 5.333zM26.442 7.457a1.924 1.924 0 11-3.848-.002 1.924 1.924 0 013.848.002z"
            fill="currentColor"
        />
    );
}
