import React from 'react';
import styled, { css } from 'styled-components';

// FIXME this should eventually pull from settings, however it's only a single value so hard-coding here while
// we move some dependencies around.
// import spaces from '../../settings/spaces';
export const iconOffset = '0.3em';

export interface SvgIconProps extends React.SVGProps<SVGSVGElement> {
    offsetRight?: boolean;
    offsetLeft?: boolean;
    minWidth?: string;
}

const StyledSvgIcon = styled.svg<SvgIconProps>`
    color: ${({ color }) => color};
    margin-right: ${({ offsetRight }) => (offsetRight ? iconOffset : null)};
    margin-left: ${({ offsetLeft }) => (offsetLeft ? iconOffset : null)};
    ${({ minWidth }) =>
        minWidth
            ? css`
                  min-width: ${minWidth};
              `
            : ''}
`;

export function SvgIcon(props: SvgIconProps) {
    const {
        ref: _ignored, // ref type is incompatible, so pull it off and ignore it as we don't need it (yet)
        children,
        viewBox = '0 0 32 32',
        width = '1em',
        height = '1em',
        fontSize = 'inherit',
        offsetRight = false,
        offsetLeft = false,
        ...rest
    } = props;

    return (
        <StyledSvgIcon
            viewBox={viewBox}
            width={width}
            height={height}
            fontSize={fontSize}
            offsetLeft={offsetLeft}
            offsetRight={offsetRight}
            {...rest}
        >
            {children}
        </StyledSvgIcon>
    );
}
