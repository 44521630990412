/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconGreat1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M32 16.187C31.951 10.613 27.713-.124 16.058.001S-.042 9.592.001 16.023C.044 22.454 5.324 31.961 16.436 32s15.613-10.239 15.565-15.813zm-21.751-5.336c2.85-.128 3.671 4.665.193 4.603s-3.043-4.475-.193-4.603zm11.407.019c3.114-.089 3.614 4.689.297 4.65s-3.411-4.561-.297-4.65zm-9.681 13.874c-1.474-1.57.364-2.777 1.826-1.505s4.131.491 5.141-.212c1.009-.704 3.4.425.835 2.244s-6.328 1.044-7.802-.526z"
            fill="currentColor"
        />
    );
}
