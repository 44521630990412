import styled from 'styled-components';
import { IconTick1 } from '@99designs/icons';
import { Size } from '../../Field';
import { checkboxInnerSizes } from './sizes';

export const Tick = styled(IconTick1)<{ size: Size }>`
    opacity: 0;
    width: ${({ size }) => checkboxInnerSizes[size]}px;
    height: ${({ size }) => checkboxInnerSizes[size]}px;
`;
