/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function MarketingWebsiteIconDesign(props: Props) {
    const newProps = { ...props, viewBox: '0 0 48 48' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            fill="currentColor"
            d="M41.445 10.373c-.48-2.396-1.695-3.248-4.155-3.229H16.849c-1.612 0-3.232-.06-4.841.019-3.615.173-5.715 2.396-5.734 6v9.375h.038c0 3.225-.026 6.45 0 9.675 0 1.875 1.058 3.225 2.711 3.536a14.615 14.615 0 002.648.15l-.023.001h8.194c.06 0 .165.079.165.116a42.8 42.8 0 01-.128 2.089.443.443 0 01-.267.31l-.003.001a1.224 1.224 0 00-1.087 1.297v-.004.005a1.125 1.125 0 001.231 1.12h-.004 5.917l.059.001c.666 0 1.264-.289 1.676-.749l.002-.002c1.084-1.166 1.024-2.565.829-3.998 0-.052.101-.176.158-.176h3.915a34.157 34.157 0 004.333-.15l-.14.011c3.034-.431 4.969-2.767 5.014-5.824.022-1.646.086-3.296.124-4.946.034-1.474.086-2.951.075-4.425 0-2.063-.071-4.125-.12-6.187-.011-1.331.12-2.715-.142-4.016zM25.826 38.569c0 .071-.214.158-.334.161h-4.08c-.086 0-.244-.24-.225-.341.086-.428.221-.848.345-1.267a.174.174 0 01.134-.094h.001c1.399 0 2.797 0 4.2.026.045 0 .124.056.124.079-.045.476-.086.96-.165 1.436zm9.375-4.414c-.566.172-1.256-.064-1.875-.079-3.281-.064-6.563-.127-9.84-.165-2.625-.03-5.25 0-7.875-.03-1.901 0-3.803-.105-5.704-.09-1.08 0-1.399-.75-1.691-1.5a4.796 4.796 0 01-.304-1.639v-.003c-.026-2.974 0-5.944 0-8.917v-8.674c0-2.674 1.294-4.088 3.967-4.249 1.219-.071 2.449-.056 3.671-.056h19.898c1.62 0 2.25.555 2.276 2.175.056 3.45.041 6.904.083 10.354.034 2.981.12 5.962.161 8.944.019 1.628-1.193 3.446-2.749 3.93z"
        />,
        <path
            fill="currentColor"
            d="M33.851 10.357c-1.913-.034-3.825-.071-5.734-.094-2.715-.026-5.426-.026-8.141-.071-1.144-.022-2.284-.214-3.424-.184-1.672.049-3.341.266-5.014.337h-.023a2.843 2.843 0 00-2.842 2.898v-.003 13.5c0 1.939 1.151 3.09 3.094 3.094h21.904c1.822 0 3-1.196 3-3V13.225l.001-.068a2.8 2.8 0 00-2.801-2.801h-.021.001zm1.545 16.482l.002.061c0 .371-.176.702-.449.912l-.003.002a1.025 1.025 0 01-.566.176h-.001c-1.834.037-3.668 0-5.501-.075-2.096-.071-4.192-.259-6.293-.285-2.366-.03-4.736.113-7.102.068a14.94 14.94 0 01-3.026-.547l.105.026c-.791-.18-1.17-.832-1.193-1.875-.049-2.25-.221-4.523-.124-6.776.173-2.217.44-4.208.809-6.162l-.055.35c.109-.362.274-.677.489-.951l-.005.006a.184.184 0 01.03-.049c.034-.06.165-.086.251-.086h8.179v.037h8.036c1.474 0 2.94.045 4.41.071a3.595 3.595 0 011.111.068l-.024-.004a1.337 1.337 0 01.918 1.374v-.005q-.004 6.836 0 13.665zM22.98 31.241c-.84-.034-1.099.203-1.095.81s.135 1.001.668 1.065a1.043 1.043 0 001.125-.902l.001-.005c.008-.495-.109-.945-.698-.968z"
        />,
        <path
            fill="currentColor"
            d="M21.33 21.394a.452.452 0 01.204-.034h-.001c.326 0 .656.038.99.06l.795.06c.244 0 .488.038.731.041a.666.666 0 00.652-.62v-.002c0-.188 0-.375.023-.559v-.791l.026-1.732h.023c0-.277-.023-.559-.052-.836a.51.51 0 00-.509-.481l-.028.001h.001-.589l-1.328-.026h-.319c-.277 0-.555-.019-.832-.023h-.03l-.033-.001a.509.509 0 00-.502.422v.003a1.624 1.624 0 00-.045.374v.001c0 .727.026 1.451.034 2.179v1.463l-.001.038a.533.533 0 00.795.464l-.003.001zM29.914 21.375a2.862 2.862 0 00.852-.002l-.016.002c.153-.024.291-.072.415-.142l-.006.003c.33-.186.589-.464.745-.799l.005-.011a3.072 3.072 0 00.273-1.413v.006l.001-.063c0-.193-.041-.376-.114-.542l.003.009a5.639 5.639 0 00-.63-1.103l.011.016a.863.863 0 00-.712-.376l-.04.001h.002c-.325.023-.628.08-.918.169l.029-.008a1.41 1.41 0 00-.938.712l-.004.008a2.228 2.228 0 00-.24.859v.007a3.151 3.151 0 00.413 2.044l-.008-.015c.178.33.493.567.866.636l.008.001zM14.299 21.375c.529 0 1.076 0 1.586-.026h.005c.231 0 .459-.016.681-.048l-.026.003a.57.57 0 00.464-.843l.001.003a265.54 265.54 0 00-.938-2.644 20.275 20.275 0 00-.352-.949.492.492 0 00-.839-.147l-.001.001a.986.986 0 00-.185.323l-.002.007q-.551 1.732-1.076 3.461a.55.55 0 00.028.487L13.644 21a.73.73 0 00.637.375h.021-.001zM24.259 22.399h-2.546a4.994 4.994 0 00-.584.001l.014-.001a.522.522 0 00.087 1.035h3.009a.605.605 0 00.41-.114l-.002.001a.525.525 0 00.167-.57l.001.004a.536.536 0 00-.561-.356h.002zM32.201 22.5a.74.74 0 00-.378-.071h.002-2.798a.536.536 0 00-.528.444v.003a.517.517 0 00.371.55l.004.001c.067.019.143.03.222.03h2.753c.092 0 .18-.012.265-.035l-.007.002a.46.46 0 00.337-.399v-.002a.48.48 0 00-.241-.52l-.002-.001zM16.444 22.125a.739.739 0 01-.43.101h.002-1.883l-.022-.001a.442.442 0 00-.408.271l-.001.003a.724.724 0 00.11.61l-.001-.002a.228.228 0 00.1.063l.002.001c.094.035.202.059.315.067H16.722a.528.528 0 00.554-.428l.001-.003a.681.681 0 00-.144-.489l.001.001a.488.488 0 00-.692-.194l.002-.001z"
        />
    );
}
