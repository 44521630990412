// This function relies on the inbuilt URL constructor which will build a complete url from the first parameter
// but if the first param is relative it will use the second parameter to form the base for the url (scheme, domain).
// This allows us to check if the url passed has a scheme/domain already and if they don't match the scheme, domain of
// our origin we can reject the url as unsafe.
// SSR requires origin to be passed in.
export const sanitizeUrlWithOrigin = (url: string, origin?: string) => {
    try {
        if (!origin) {
            origin = window.location.origin;
        }
        const parsedUrl = new URL(url, origin);

        if (parsedUrl.origin !== origin) {
            return null;
        }

        return parsedUrl.toString();
    } catch (e) {
        return null;
    }
};
