/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconWebsiteAdd1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M32 2a2 2 0 00-2-2H2a2 2 0 00-2 2v28c0 1.106.894 2 2 2h28c1.106 0 2-.894 2-2V2zM8.02 8h-4V4h4v4zM22 21c0 .552-.428 1-.98 1H18v3c0 .552-.428 1-.98 1h-2c-.554 0-1.02-.448-1.02-1v-3h-2.98c-.554 0-1.02-.448-1.02-1v-2c0-.552.466-1 1.02-1H14v-3c0-.552.466-1 1.02-1h2c.552 0 .98.448.98 1v3h3.02c.552 0 .98.448.98 1v2zm6-13H12V4h16v4z"
            fill="currentColor"
        />
    );
}
