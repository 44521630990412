import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ErrorAlert, InfoAlert, SuccessAlert } from '../Alert';
import { FloatingAlertProps } from './types';

function UnstyledFloatingAlert({ dispatch, id, message, type, ...rest }: FloatingAlertProps) {
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    const removeFloatingAlert = useCallback(() => {
        dispatch({
            type: 'REMOVE_ALERT',
            id,
        });
    }, [dispatch, id]);

    useEffect(() => {
        timeout.current = setTimeout(() => {
            removeFloatingAlert();
        }, 5000);

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, [id, removeFloatingAlert]);

    let AlertComponent;

    switch (type) {
        case 'error':
            AlertComponent = ErrorAlert;
            break;
        case 'info':
            AlertComponent = InfoAlert;
            break;
        case 'success':
            AlertComponent = SuccessAlert;
            break;
    }

    return (
        <AlertComponent role="alert" onDismiss={removeFloatingAlert} {...rest}>
            {message}
        </AlertComponent>
    );
}

/**
 * Renders an `Alert` component to be used with `FloatingContextProvider`.
 * Automatically hidden after a specified amount of time.
 *
 * @see useFloatingAlert
 */
export const FloatingAlert = styled(UnstyledFloatingAlert)`
    pointer-events: all;
`;
