import React from 'react';
import styled from 'styled-components';
import { IconRight4 } from '@99designs/icons';
import { Box } from '../Box';
import { Link, LinkProps } from './Link';

export const CTALink = styled(
    React.forwardRef<HTMLButtonElement | HTMLAnchorElement, LinkProps>(
        ({ children, ...rest }, ref) => {
            return (
                <Link {...rest} ref={ref} weight="bold" size="size4" underline="hover">
                    {children}
                    <Box as="span" marginLeft="0.25bu">
                        <IconRight4 />
                    </Box>
                </Link>
            );
        }
    )
)`
    border-bottom-width: 2px;
`;
