import { TranslationObject, createKey } from '@99designs/i18n-utils';
import i18n from '../../../lib/i18n';

// These functions are injected globally, so they do not need to be imported.

/**
 * i18next translation function
 */
export function __(
    translationKey: string,
    interpolationMap?: Record<string, unknown> | undefined
): string {
    const updatedKey = createKey(translationKey);
    const translation = i18n.t(updatedKey, interpolationMap);

    if (translation && typeof translation === 'object' && 'value' in translation) {
        return (translation as TranslationObject).value;
    }

    if (updatedKey !== translation) {
        return translation;
    }

    return i18n.t(translationKey, interpolationMap);
}

/**
 * denotes a translation that isn't yet finalised, so shouldn't be pushed to translators.
 */
export function __placeholder(msg: string): string {
    return msg;
}
