import React from 'react';
import { DistributiveOmit } from '../../utils/helperTypes';
import { ClickableInput, ClickableInputProps } from '../Form/ClickableInput';

export type RadioItemProps = DistributiveOmit<ClickableInputProps, 'type'> & {
    value: ClickableInputProps['value'];
};

/**
 * Creates a singular radio button.
 *
 * Requires one of: `label`, `aria-label`, or `aria-labelledby`.
 *
 * If creating a standard group of radio buttons, @see RadioGroup.
 */
export const RadioItem = React.forwardRef<HTMLInputElement, RadioItemProps>(function (props, ref) {
    return <ClickableInput {...props} ref={ref} type="radio" />;
});
