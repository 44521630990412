import { Dialog, useDialogStore } from '@ariakit/react';
import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { mediaQuery } from '../../css';
import { breakpoints, spaces } from '../../settings';
import { Box } from '../Box';
import { Card } from '../Card';
import { Backdrop, CloseButton } from '../Dialog';
import { Heading } from '../Heading';

const pageGutter = '2bu';

export interface ModalProps {
    'aria-label': string;
    children: React.ReactNode;
    /**
     * Callback fired when the modal is attempting to be closed.
     */
    onClose: () => void;
    size?: 'small' | 'medium' | 'auto';
    /**
     * Dictates if the modal is shown.
     */
    visible: boolean;
}

const AlignedBox = styled(Box)`
    align-items: flex-end;

    ${mediaQuery.minimum('medium')} {
        align-items: center;
    }
`;

const ModalCard = styled(Card).attrs(() => ({ appearance: 'elevated' }))<{
    children: React.ReactNode;
    size: ModalProps['size'];
}>`
    min-width: ${breakpoints.small[1] - spaces.px[pageGutter] * 2}px;
    overflow: hidden;
    position: relative;

    ${mediaQuery.only('small')} {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        min-width: 100%;
    }

    ${({ size }) => {
        switch (size) {
            case 'small':
                return css`
                    flex-grow: 1;
                    max-width: ${breakpoints.small[1] - spaces.px[pageGutter] * 2}px;
                `;

            case 'medium':
                return css`
                    flex-grow: 1;
                    max-width: ${breakpoints.medium[1] - spaces.px[pageGutter] * 2}px;
                `;

            case 'auto':
            default:
                return;
        }
    }}

    &:focus {
        outline: none;
    }
`;

/**
 * An accessible dialog component that follows the WAI-ARIA Dialog (Modal) pattern.
 *
 * Can be used with `ModalHeader`, `ModalContent`, and `ModalFooter` as needed.
 */
export function Modal({
    'aria-label': ariaLabel,
    children,
    onClose,
    size = 'auto',
    visible,
}: ModalProps) {
    const dialogRef = useRef<HTMLDivElement>(null);
    const dialogProps = useDialogStore({
        open: visible,
        setOpen(open) {
            if (!open) {
                onClose();
            }
        },
    });

    useEffect(() => {
        if (visible) {
            if (dialogRef?.current) {
                dialogRef.current.focus({
                    preventScroll: true,
                });
            }
        }
    }, [visible]);

    return (
        <Dialog
            ref={dialogRef}
            render={(props) => (
                <Backdrop visible={visible}>
                    <AlignedBox
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        minHeight="100%"
                        padding={['none', pageGutter, pageGutter]}
                        paddingTop={pageGutter}
                    >
                        <ModalCard size={size} {...props}>
                            {props.children}
                        </ModalCard>
                    </AlignedBox>
                </Backdrop>
            )}
            aria-label={ariaLabel}
            store={dialogProps}
            backdrop={false}
            autoFocusOnShow={false}
        >
            {children}
            <CloseButton store={dialogProps} />
        </Dialog>
    );
}

export const ModalHeader = ({ children }: { children: React.ReactNode }) => (
    <Box maxWidth="90%" marginBottom="1bu">
        <Heading accessibilityLevel={3} appearanceLevel={4}>
            {children}
        </Heading>
    </Box>
);

export const ModalContent = ({ children }: { children: React.ReactNode }) => <div>{children}</div>;

export const ModalFooter = ({ children }: { children: React.ReactNode }) => (
    <Box display="flex" justifyContent="flex-end" marginTop="1bu">
        {children}
    </Box>
);
