import { RefObject, useEffect, useState } from 'react';

/**
 * Calculates if a container has overflow hidden by a scrollbar, and if the container
 * hasn't been scrolled to its rightmost edge.
 */
export function useScrollOverlay<T extends HTMLElement>(elementRef: RefObject<T>) {
    const [showScrollOverlay, setShowScrollOverlay] = useState(false);

    useEffect(() => {
        function updateScrollOverlay() {
            if (!elementRef.current) {
                return;
            }

            const buffer = 20;
            const { offsetWidth, scrollLeft, scrollWidth } = elementRef.current;
            const scrollable = scrollWidth > offsetWidth;
            const scrolledToRight = scrollWidth - scrollLeft <= offsetWidth + buffer;
            setShowScrollOverlay(scrollable && !scrolledToRight);
        }

        updateScrollOverlay();
        const ref = elementRef.current;

        ref?.addEventListener('scroll', updateScrollOverlay);
        window.addEventListener('resize', updateScrollOverlay);

        return () => {
            ref?.removeEventListener('scroll', updateScrollOverlay);
            window.removeEventListener('resize', updateScrollOverlay);
        };
    }, [elementRef]);

    return showScrollOverlay;
}
