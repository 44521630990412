import styled from 'styled-components';
import { breakpoints, colors } from '@99designs/emu/settings';

const FullscreenFrame = styled.div`
    display: block;
    background: ${colors.typography.contentBackground};

    @media (min-width: ${breakpoints.normal[0]}px) and (max-width: ${breakpoints.normal[1]}px),
        (min-width: ${breakpoints.large[0]}px) {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
`;
export default FullscreenFrame;
