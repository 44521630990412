export class Industries {
    industries: { key: string; title: string }[];

    constructor(industries: { key: string; title: string }[]) {
        this.industries = industries;
    }

    find(key: string) {
        // eslint-disable-next-line eqeqeq
        const industry = this.industries.find((industry) => industry.key == key);

        return industry ? industry.title : null;
    }
}
