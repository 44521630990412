import React from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '@99designs/emu';
import { colors } from '@99designs/emu/settings';

export interface InfoBoxProps {
    children: React.ReactNode;
    borderColor?: colors.Color;
    padding?: BoxProps['padding'];
    backgroundColor?: string;
}

const StyledInfoBox = styled(Box)<{
    borderColor: colors.Color;
    backgroundColor?: colors.Color;
}>`
    border-left: 5px solid ${({ borderColor }) => colors.colors[borderColor]};
    position: relative;
    background: ${({ backgroundColor }) => backgroundColor};
`;

export function InfoBox({
    children,
    borderColor = 'grey800',
    padding = '1bu',
    backgroundColor = 'grey100',
    ...props
}: InfoBoxProps) {
    return (
        <StyledInfoBox
            borderColor={borderColor}
            background={backgroundColor}
            padding={padding}
            {...props}
        >
            {children}
        </StyledInfoBox>
    );
}
