/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconOk1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M32 16.187C31.951 10.613 27.713-.124 16.058.001S-.042 9.592.001 16.023C.044 22.454 5.324 31.961 16.436 32s15.613-10.239 15.565-15.813zm-21.751-5.336c2.85-.128 3.671 4.665.193 4.603s-3.043-4.475-.193-4.603zm11.407.019c3.114-.089 3.614 4.689.297 4.65s-3.411-4.561-.297-4.65zm-9.244 14.128c-1.496-.089-1.044-2.061.588-2.061.837 0 2.101-.05 3.333-.099 1.171-.046 2.312-.091 3.029-.091 1.473 0 1.69 2.356.085 2.356s-5.54-.015-7.036-.104z"
            fill="currentColor"
        />
    );
}
