import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from '../../Context';
import { borders, boxShadows, colors } from '../../settings';
import { VisuallyHidden } from '../VisuallyHidden';
import { StarIcon, StarIconProps } from './StarIcon';

export interface StarProps {
    checked: boolean;
    filled: boolean;
    id: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
    size: StarIconProps['size'];
    value: number;
}

const Label = styled.label<{ filled?: boolean }>`
    display: block;
    cursor: pointer;
    position: relative;

    &:not(:active):hover > * {
        transform: scale(1.25);
    }
    &:active > * {
        transition: transform 0ms;
    }
    ${({ filled }) =>
        filled &&
        css`
            color: ${colors.grey800};
        `}
    &:hover ~ & {
        color: transparent;
    }
`;

const Input = styled(VisuallyHidden)`
    &:focus + ${Label} {
        ::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: ${borders.borderRadiuses.small}px;
            box-shadow: 0 0 0 2px ${colors.carnaval};
            pointer-events: none;
        }
    }
    &:focus:not(:focus-visible) + ${Label} {
        ::after {
            content: none;
        }
    }
    &:focus-visible + ${Label} {
        ::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: ${borders.borderRadiuses.small}px;
            box-shadow: ${boxShadows.focusRing};
            pointer-events: none;
        }
    }
`;

export const Star = forwardRef<HTMLInputElement, StarProps>(function (
    { id, filled, size, value, ...rest },
    ref
) {
    const translations = useTranslations();

    return (
        <>
            <Input as="input" ref={ref} type="radio" id={id} value={value} {...rest} />
            <Label htmlFor={id} filled={filled}>
                <VisuallyHidden>{translations.Rating.Value(value)}</VisuallyHidden>
                <StarIcon size={size} />
            </Label>
        </>
    );
});
