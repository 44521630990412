import React from 'react';
import styled from 'styled-components';
import { Box } from '../Box';
import { FieldPadding } from '../Field';

const PrefixContainer = styled(Box)`
    padding-left: ${FieldPadding.x};
    padding-top: ${FieldPadding.y};
`;

export function Prefix({ children }: { children?: React.ReactNode }) {
    return (
        <PrefixContainer display="flex" alignItems="center" alignSelf="start">
            {children}
        </PrefixContainer>
    );
}
