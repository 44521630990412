import React from 'react';
import styled from 'styled-components';
import { IconCaution, IconError, IconInfo, IconSuccess } from '@99designs/icons';
import { colors } from '../../settings';
import { Box } from '../Box';
import { Inline } from '../Inline';
import { Text } from '../Text';

export interface StatusMessageProps {
    children: React.ReactNode;
    type: 'caution' | 'error' | 'info' | 'success';
}

const AlignedInline = styled(Inline).attrs<{ className?: string }>({ className: 'emu' })`
    align-items: flex-start;
`;

const semanticColours = {
    caution: colors.experimental.yellow600,
    success: colors.experimental.green600,
    error: colors.experimental.red600,
    info: colors.grey800,
};

export function StatusMessage({ children, type }: StatusMessageProps) {
    let Icon;
    const color = semanticColours[type];

    switch (type) {
        case 'caution':
            Icon = IconCaution;
            break;
        case 'error':
            Icon = IconError;
            break;
        case 'info':
            Icon = IconInfo;
            break;
        case 'success':
            Icon = IconSuccess;
            break;
    }

    return (
        <Box color={color}>
            <AlignedInline space="1bu" noWrap>
                <Box as="span" position="relative" display="flex" top="0.25bu">
                    <Icon />
                </Box>
                <Text weight="bold" color={color}>
                    {children}
                </Text>
            </AlignedInline>
        </Box>
    );
}
