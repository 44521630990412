/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconTerrible1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M10.249 10.851c2.85-.128 3.671 4.665.193 4.603s-3.043-4.475-.193-4.603zM21.656 10.87c3.114-.089 3.614 4.689.297 4.65s-3.411-4.561-.297-4.65z"
            fill="currentColor"
        />,
        <path
            d="M32 16.187C31.951 10.613 27.713-.124 16.058.001S-.042 9.592.001 16.023C.044 22.454 5.324 31.961 16.436 32s15.613-10.239 15.565-15.813zM10.196 8.591c5.441-.069 6.404 9.06.29 9.002s-5.731-8.933-.29-9.002zm11.378 0c5.441-.069 6.404 9.06.29 9.002s-5.731-8.933-.29-9.002zm-8.221 17.567c-3.369-.421-1.989-5.567.654-5.717 1.544-.088 1.649-.084 2.111-.065.328.013.837.034 2.171.034 3.21 0 2.542 5.385.41 5.648-.483.06-.849.119-1.165.17-1.077.175-1.576.256-4.181-.07z"
            fill="currentColor"
        />
    );
}
