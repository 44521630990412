import React, { useEffect, useState } from 'react';
import ReactSelect, { FocusEventHandler } from 'react-select';
import { NamedProps } from 'react-select';
import { GroupTypeBase } from 'react-select/src/types';
import { Stack } from '@99designs/emu';
import { colors } from '@99designs/emu/settings';
import { ErrorText } from '../ErrorText';

interface Props {
    value: string[];
    options: Option[];
    onChange: (value: string[]) => void;
    onFocus?: FocusEventHandler;
    error?: string;
    autoFocus?: boolean;
}

interface Option {
    label: string;
    value: string;
}

/**
 * Select form field that allows for multiple options to be selected.
 * @deprecated replaced by `Combobox` in `@99designs/emu`
 */
export function MultiSelectField({ value, options, onChange, onFocus, error, autoFocus }: Props) {
    const [selectedValues, updateSelectedValues] = useState<Option[]>([]);

    useEffect(() => {
        const prefs: Option[] = [];
        value.forEach((v) => {
            const option = options.filter((option) => option.value === v)[0];
            prefs.push(option);
        });

        updateSelectedValues(prefs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleChange: NamedProps<Option, true, GroupTypeBase<Option>>['onChange'] = (
        value,
        actionMeta
    ) => {
        if (actionMeta.action === 'clear') {
            onChange([]);
            return;
        }
        const newValues = value?.map((v) => v.value);
        onChange(newValues);
    };

    return (
        <Stack space="0.25bu">
            <ReactSelect
                isMulti
                autoFocus={autoFocus}
                closeMenuOnSelect={false}
                value={selectedValues}
                onChange={handleChange}
                onFocus={onFocus}
                options={options}
                menuPortalTarget={document.body}
                styles={{
                    control: (base) => ({
                        ...base,
                        ...(error && {
                            borderColor: `${colors.semantic.error}`,
                        }),
                    }),
                }}
            />
            {error && <ErrorText text={error} />}
        </Stack>
    );
}
