/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconPinterest2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M13.85 21.15c-.8 4.4-1.8 8.65-4.75 10.85-.9-6.65 1.35-11.65 2.35-16.95-1.75-3.1.2-9.3 3.95-7.75C20 9.2 11.45 18.8 17.2 20c6 1.25 8.45-10.8 4.75-14.7-5.4-5.65-15.65-.15-14.4 7.95.3 2 2.3 2.6.8 5.3-3.45-.8-4.45-3.6-4.35-7.35C4.2 5 9.35.7 14.45.1c6.5-.75 12.55 2.45 13.4 8.8.95 7.15-2.95 14.85-9.85 14.3-1.85-.15-2.65-1.1-4.15-2.05z"
            fill="currentColor"
        />
    );
}
