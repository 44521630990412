import { AvatarSize } from './types';

export const avatarSizes: Record<AvatarSize, string> = {
    'extra-small': '32px',
    small: '48px',
    medium: '64px',
    large: '96px',
    'extra-large': '128px',
    'extra-extra-large': '160px',
};
