import React from 'react';
import { DistributiveOmit } from '../../utils/helperTypes';
import { ClickableInput, ClickableInputProps } from '../Form/ClickableInput';

export type CheckboxProps = DistributiveOmit<ClickableInputProps, 'type'>;

/**
 * Creates a singular checkbox.
 *
 * Requires one of: `label`, `aria-label`, or `aria-labelledby`.
 *
 * If creating a related group of checkboxes, @see CheckboxGroup.
 */
export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(function (props, ref) {
    return <ClickableInput {...props} ref={ref} type="checkbox" />;
});
