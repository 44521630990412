import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from '@99designs/emu';
import { boxShadows, colors } from '@99designs/emu/settings';

const Container = styled(Box)<{ onClick?: () => void }>`
    ${({ onClick }) =>
        onClick &&
        css`
            cursor: pointer;
        `}
    padding-bottom: 100%;
`;

const MediaFrame = styled(Box)`
    overflow: hidden; // clip rounded corners of media
    box-shadow: ${boxShadows.low};
    transition-property: box-shadow;
    transition-duration: 0.3s !default;
    transition-timing-function: ease-out !default;
`;

const Media = styled.img`
    width: 100%;
    display: block;
`;

interface MediaBoxProps {
    imageUrl: string;
    webpUrl?: string;
    altText?: string;
    title?: string;
    onClick?: () => void;
}

export function MediaBox({ imageUrl, altText, title, webpUrl, onClick }: MediaBoxProps) {
    return (
        <Container display="block" background="transparent" position="relative" onClick={onClick}>
            <MediaFrame position="absolute" background={colors.white} width="100%" height="100%">
                <picture>
                    {webpUrl && (
                        <source data-testid="media-box-source" srcSet={webpUrl} type="image/webp" />
                    )}
                    <Media alt={altText} title={title} src={imageUrl} />
                </picture>
            </MediaFrame>
        </Container>
    );
}
