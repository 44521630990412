/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconFacebook2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M24.7 6h-3.95c-.6 0-1.95.95-1.95 2v4h5.9v6h-5.9v14h-5.9V18H7v-6h5.9V8c0-4.4 3.85-8 8-8h3.8v6z"
            fill="currentColor"
        />
    );
}
