import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Box } from '@99designs/emu';
import { borders, boxShadows, colors } from '@99designs/emu/settings';

interface PopperCardBoxProps {
    minWidth?: number;
    children: React.ReactNode;
}
const PopperCardBox = styled((props: PopperCardBoxProps) => {
    const { minWidth: _minWidthIgnored, ...boxProps } = props;
    return <Box {...boxProps} />;
})`
    ${({ minWidth }) =>
        minWidth &&
        css`
            min-width: ${minWidth}px;
        `}
`;
const PopperCardContent = styled.div`
    background-clip: padding-box;
    overflow: hidden;
    border-radius: ${borders.borderRadiuses.small}px;
    box-shadow: ${boxShadows.high};
    background-color: ${colors.white};
`;
const PopperCardArrow = styled.span`
    display: block;
    position: absolute;
    pointer-events: none;
    user-select: none;
    width: 3em;
    height: 3em;
    font-size: 10px; /* Controls arrow size */

    &::before {
        content: '';
        display: block;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
    }

    [x-placement*='bottom'] & {
        top: 0;
        left: 0;
        margin-top: -0.9em;
        width: 3em;
        height: 1em;

        &::before {
            border-width: 0 1em 1em 1em;
            border-color: transparent transparent white transparent;
        }
    }

    [x-placement*='top'] & {
        bottom: 0;
        left: 0;
        margin-bottom: -0.9em;
        width: 3em;
        height: 1em;

        &::before {
            border-width: 1em 1em 0 1em;
            border-color: white transparent transparent transparent;
        }
    }

    [x-placement*='right'] & {
        left: 0;
        margin-left: -0.9em;
        height: 3em;
        width: 1em;

        &::before {
            border-width: 1em 1em 1em 0;
            border-color: transparent white transparent transparent;
        }
    }

    [x-placement*='left'] & {
        right: 0;
        margin-right: -0.9em;
        height: 3em;
        width: 1em;

        &::before {
            border-width: 1em 0 1em 1em;
            border-color: transparent transparent transparent white;
        }
    }
`;

interface PopperCardProps {
    children: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    triggerEl: any;
    arrowRef?: React.Ref<HTMLSpanElement>;
}

export const PopperCard = forwardRef(function PopperCard(
    { children, arrowRef, triggerEl }: PopperCardProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref
) {
    return (
        <PopperCardBox minWidth={triggerEl.current ? triggerEl.current.clientWidth : undefined}>
            <PopperCardArrow ref={arrowRef} />
            <PopperCardContent>{children}</PopperCardContent>
        </PopperCardBox>
    );
});
PopperCard.displayName = 'PopperCard';
