/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconSent1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M7.77 19.416c-2.119 1.51-1.813.94-1.938 3.32s-.25 5.263-.272 7.231c-.022 1.968-.353 2.856 2.311.508s3.256-3.055 4.411-3.818c1.155-.763.961-.74 2.249.251s2.843 2.538 4.012 3.505c1.169.966 1.477 1.178 2.248-1.364S30.638 3.495 31.152 1.843c.514-1.652 1.487-2.666-1.49-1.378S2.569 10.619 1.029 10.874c-1.541.254-1.669 1.144-.642 2.034s.46.542 2.862 3.468c1.749 2.13 1.999 1.253 4.525-.237S22.38 7.282 23.487 6.728c1.107-.555.275.339-1.651 1.864S9.888 17.908 7.769 19.417z"
            fill="currentColor"
        />
    );
}
