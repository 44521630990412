import styled from 'styled-components';
import { mediaQuery } from '@99designs/emu/css';
import { borders, spaces } from '@99designs/emu/settings';

export const Item = styled.div`
    border-left: ${borders.content};
    display: table-cell;
    padding: 0 ${spaces['2bu']};
    width: 34.5%;

    &:first-child {
        border-left-width: 0;
        padding-left: 0;
        width: 31%;
    }

    ${mediaQuery.only('small')} {
        border: none;
        display: block;
        padding: 0;
        position: relative;
        width: 100%;

        &:first-child {
            width: 100%;
        }
    }

    ${mediaQuery.only('medium')} {
        padding: 0 ${spaces['1bu']};
    }
`;
