import React from 'react';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import styled from 'styled-components';
import {
    Field,
    FieldFocusStyling,
    FieldPlaceholderStyling,
    FieldProps,
    FieldStyling,
} from '../Field';

export type TextareaProps = FieldProps &
    TextareaAutosizeProps & {
        autoGrow?: boolean;
        id: string;
        maxRows?: number;
        minRows?: number;
    };

const StyledTextarea = styled(TextareaAutosize).withConfig({
    shouldForwardProp: (prop) => !['error'].includes(prop),
})<{
    disabled?: boolean;
    error?: boolean;
}>`
    ${FieldStyling}
    appearance: none;
    min-height: 44px;
    resize: vertical;

    &:focus {
        ${FieldFocusStyling}
    }

    &::placeholder {
        ${FieldPlaceholderStyling}
    }
`;

export const BaseTextarea = React.forwardRef<
    HTMLTextAreaElement,
    TextareaProps & { error?: boolean }
>(function (props, ref) {
    return (
        <div className="emu">
            <StyledTextarea ref={ref} {...props} />
        </div>
    );
});

/**
 * A pre-made form component for textareas.
 *
 * Supports a minimum and maximum amount of rows, and optionally can grow in size as
 * the content grows.
 *
 * Allows for additional context to be displayed, such as description, and error.
 *
 * Requires one of: `label`, `aria-label`, or `aria-labelledby`.
 */
export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(function (
    { autoGrow = false, description, error, helperText, maxRows, minRows = 3, ...rest },
    ref
) {
    const { disabled, id, required } = rest;

    const calcMinRows = Math.min(minRows, maxRows ?? Infinity);
    const calcMaxRows = autoGrow ? maxRows : calcMinRows;

    return (
        <Field
            description={description}
            disabled={disabled}
            error={error}
            helperText={helperText}
            id={id}
            label={'label' in rest ? rest.label : undefined}
            required={required}
        >
            <BaseTextarea
                ref={ref}
                aria-invalid={!!error}
                error={!!error}
                minRows={calcMinRows}
                maxRows={calcMaxRows}
                {...rest}
            />
        </Field>
    );
});
