import * as CSS from 'csstype';
import { colors } from '../../settings';
import { ButtonColorConfig, ColorCombo } from './types';

const defaultColorCombo: ButtonColorConfig = {
    normal: {
        backgroundColor: 'grey900',
        backgroundColorHover: 'grey700',
        backgroundColorActive: 'grey600',
        borderColor: 'grey900',
        borderColorHover: 'grey700',
        borderColorActive: 'grey600',
        textColor: 'white',
        textColorHover: 'white',
        textColorActive: 'white',
    },
    selected: {
        backgroundColor: 'grey600',
        backgroundColorHover: 'grey800',
        backgroundColorActive: 'grey700',
        borderColor: 'grey600',
        borderColorHover: 'grey800',
        borderColorActive: 'grey700',
        textColor: 'white',
        textColorHover: 'white',
        textColorActive: 'white',
    },
    disabled: {
        backgroundColor: 'grey200',
        backgroundColorHover: 'grey200',
        backgroundColorActive: 'grey200',
        borderColor: 'grey200',
        borderColorHover: 'grey200',
        borderColorActive: 'grey200',
        textColor: 'grey400',
        textColorHover: 'grey400',
        textColorActive: 'grey400',
    },
};

export function createButtonColorConfig(
    overrides: Partial<{
        normal?: Partial<ColorCombo>;
        selected?: Partial<ColorCombo>;
        disabled?: Partial<ColorCombo>;
    }> = {
        selected: undefined,
        disabled: undefined,
        normal: undefined,
    }
): ButtonColorConfig {
    const { normal = {}, disabled = {}, selected = {} } = overrides;
    return {
        normal: { ...defaultColorCombo.normal, ...normal },
        selected: { ...defaultColorCombo.selected, ...selected },
        disabled: { ...defaultColorCombo.disabled, ...disabled },
    };
}

export function createButtonPrimaryColorConfig(params: {
    color: CSS.Property.Color | colors.Color;
    backgroundColorHover: CSS.Property.Color | colors.Color;
    backgroundColorActive: CSS.Property.Color | colors.Color;
}): ButtonColorConfig {
    const { color, backgroundColorHover, backgroundColorActive } = params;
    return createButtonColorConfig({
        normal: {
            backgroundColor: color,
            backgroundColorHover,
            backgroundColorActive,
            borderColor: color,
            borderColorHover: backgroundColorHover,
            borderColorActive: backgroundColorActive,
        },
    });
}

export function createButtonSecondaryColorConfig(): ButtonColorConfig {
    return createButtonColorConfig({
        normal: {
            backgroundColor: 'grey300',
            backgroundColorHover: 'grey400',
            backgroundColorActive: 'grey500',
            borderColor: 'grey300',
            borderColorHover: 'grey400',
            borderColorActive: 'grey500',
            textColor: 'grey900',
            textColorHover: 'grey900',
            textColorActive: 'grey900',
        },
    });
}

export function createButtonTertiaryColorConfig(
    params: {
        color: CSS.Property.Color | colors.Color;
        backgroundColorHover: CSS.Property.Color | colors.Color;
        backgroundColorActive: CSS.Property.Color | colors.Color;
    } = {
        color: 'grey900',
        backgroundColorHover: 'grey200',
        backgroundColorActive: 'grey300',
    }
): ButtonColorConfig {
    const { color, backgroundColorHover, backgroundColorActive } = params;
    return createButtonColorConfig({
        normal: {
            backgroundColor: 'transparent',
            backgroundColorHover,
            backgroundColorActive,
            borderColor: color,
            borderColorHover: color,
            borderColorActive: color,
            textColor: color,
            textColorHover: color,
            textColorActive: color,
        },
        disabled: {
            backgroundColor: 'transparent',
            backgroundColorHover: 'transparent',
            backgroundColorActive: 'transparent',
            borderColor: 'grey300',
            borderColorHover: 'grey300',
            borderColorActive: 'grey300',
            textColor: 'grey400',
            textColorHover: 'grey400',
            textColorActive: 'grey400',
        },
    });
}

export function createButtonTransparentColorConfig(params?: {
    color: CSS.Property.Color | colors.Color;
    backgroundColorHover: CSS.Property.Color | colors.Color;
    backgroundColorActive: CSS.Property.Color | colors.Color;
}): ButtonColorConfig {
    // Note: since tertiary button styles and transparent styles are similar except the
    // transparent border, we can utilise the `createButtonTertiaryColorConfig` helper here
    const tertiaryColorConfig = createButtonTertiaryColorConfig(params);
    return createButtonColorConfig({
        ...tertiaryColorConfig,
        normal: {
            ...tertiaryColorConfig.normal,
            borderColor: 'transparent',
            borderColorHover: 'transparent',
            borderColorActive: 'transparent',
        },
        disabled: {
            ...tertiaryColorConfig.disabled,
            borderColor: 'transparent',
            borderColorHover: 'transparent',
            borderColorActive: 'transparent',
        },
    });
}
