import styled from 'styled-components';
import { breakpoints } from '@99designs/emu/settings';

const FullscreenMain = styled.div<{ scrollable?: boolean }>`
    display: block;
    position: relative;

    @media (min-width: ${breakpoints.normal[0]}px) and (max-width: ${breakpoints.normal[1]}px),
        (min-width: ${breakpoints.large[0]}px) {
        position: absolute;
        padding: 0;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: ${({ scrollable }) => (scrollable ? 'auto' : 'hidden')};
    }
`;
export default FullscreenMain;
