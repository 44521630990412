import React from 'react';
import { Text } from '../Text';

export interface FormHelperTextProps {
    children: React.ReactNode;
    /**
     * Applies disabled styling
     */
    disabled?: boolean;
    id: string;
}

/**
 * Displays additional information about a form component below the <input>
 * Can be used internally by form components, or used directly when needed.
 * If used directly, remember to set `aria-describedby` on the linked <input>
 * for accessibility.
 */
export function FormHelperText({ children, disabled, id }: FormHelperTextProps) {
    return (
        <Text id={id} color={disabled ? 'grey400' : undefined} size="size2">
            {children}
        </Text>
    );
}
