import { useCallback, useLayoutEffect, useState } from 'react';
import debounce from 'lodash.debounce';

type ClientRect = Record<keyof Omit<DOMRect, 'toJSON'>, number>;

function roundValues(_rect: ClientRect) {
    const rect = {
        ..._rect,
    };
    for (const key of Object.keys(rect) as Array<keyof ClientRect>) {
        rect[key] = Math.round(rect[key]);
    }
    return rect;
}

function shallowDiff(prev: ClientRect | undefined, next: ClientRect) {
    if (prev != null && next != null) {
        for (const key of Object.keys(next) as Array<keyof ClientRect>) {
            if (prev[key] !== next[key]) {
                return true;
            }
        }
    }
    // eslint-disable-next-line eqeqeq
    return prev != next;
}

type TextSelectionState = {
    clientRect?: ClientRect;
    isCollapsed?: boolean;
    textContent?: string;
    eventId?: string | null;
    eventSourceId?: string | null;
};

const defaultState: TextSelectionState = {};

/**
 * useTextSelection(ref)
 *
 * @description
 * hook to get information about the current text selection
 *
 */
export function useTextSelection() {
    const [{ clientRect, isCollapsed, textContent, eventId, eventSourceId }, setState] =
        useState<TextSelectionState>(defaultState);

    const changeHandler = useCallback(() => {
        let newRect: ClientRect;
        const selection = window.getSelection();
        const newState: TextSelectionState = {};

        if (selection == null || !selection.rangeCount) {
            setState(newState);
            return;
        }

        const range = selection.getRangeAt(0);

        if (range == null) {
            setState(newState);
            return;
        }

        const contents = range.cloneContents();

        if (contents.textContent != null) {
            newState.textContent = contents.textContent;
        }

        const rects = range.getClientRects();
        getEventId(range, newState);
        if (rects.length === 0 && range.commonAncestorContainer != null) {
            const el = range.commonAncestorContainer as HTMLElement;
            newRect = roundValues(el.getBoundingClientRect().toJSON());
        } else {
            if (rects.length < 1) return;
            newRect = roundValues(rects[0].toJSON());
        }
        if (shallowDiff(clientRect, newRect)) {
            newState.clientRect = newRect;
        }
        newState.isCollapsed = range.collapsed;

        setState(newState);
    }, [clientRect]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedHandler = useCallback(debounce(changeHandler, 300), [changeHandler]);
    const layoutChangeHandler = useCallback(() => {
        const length = textContent?.length ?? 0;
        if (length === 0) return;
        changeHandler();
    }, [changeHandler, textContent]);

    useLayoutEffect(() => {
        document.addEventListener('selectionchange', debouncedHandler);
        document.addEventListener('scroll', layoutChangeHandler, true);
        document.addEventListener('keydown', debouncedHandler);
        document.addEventListener('keyup', debouncedHandler);
        window.addEventListener('resize', layoutChangeHandler);

        return () => {
            document.removeEventListener('selectionchange', debouncedHandler);
            document.removeEventListener('scroll', layoutChangeHandler, true);
            document.removeEventListener('keydown', debouncedHandler);
            document.removeEventListener('keyup', debouncedHandler);
            window.removeEventListener('resize', layoutChangeHandler);
        };
    }, [debouncedHandler, layoutChangeHandler]);

    return {
        clientRect,
        isCollapsed,
        textContent,
        eventId,
        eventSourceId,
    };
}

function getEventId(range: Range, newState: TextSelectionState) {
    let parent = range.commonAncestorContainer.parentElement;
    while (parent != null) {
        if (parent.dataset['eventId'] != null) {
            newState.eventId = parent.dataset['eventId'];
            newState.eventSourceId = parent.dataset['eventSourceId'];
            break;
        }
        parent = parent.parentElement;
    }
}
