/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconCloseDoubleQuote1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M7.103 28.522c-.181.214-.325.515-.802.338s-2.208-.875-2.468-.894c-.26-.019-.561-.185-.211-.908s2.138-4.802 2.459-9.766c.026-.336-.163-.307-.373-.386S-.181 15.682 0 9.625c.181-6.057 6.301-6.728 7.112-6.712s7.302.351 7.343 7.678c.018 9.466-7.172 17.717-7.353 17.931zm14.441-.44c-.421-.092-.831-.206-.451-1.101s2.392-5.841 2.482-9.23c.02-.519.045-.689-.48-.829s-5.661-1.562-5.527-6.809c.133-5.247 4.367-7.232 6.992-7.199s7.239 1.202 7.43 7.969c.191 6.767-4.202 13.732-6.924 17.262-.31.478-.501.973-1.223.68s-1.879-.652-2.299-.744z"
            fill="currentColor"
        />
    );
}
