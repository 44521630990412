import styled, { css } from 'styled-components';
import { breakpoints } from '@99designs/emu/settings';
import { fullscreenMainHeaderHeight } from './fullscreenMainSettings';

interface FullscreenMainBodyProps {
    hasHeader?: boolean;
}
const FullscreenMainBody = styled.div<FullscreenMainBodyProps>`
    position: relative;

    @media (min-width: ${breakpoints.normal[0]}px) and (max-width: ${breakpoints.normal[1]}px),
        (min-width: ${breakpoints.large[0]}px) {
        position: absolute;
        padding: 0;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        ${({ hasHeader = false }) =>
            hasHeader &&
            css`
                margin-top: ${fullscreenMainHeaderHeight};
                height: calc(100% - ${fullscreenMainHeaderHeight});
            `}
    }
`;
export default FullscreenMainBody;
