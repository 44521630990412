/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconWithdraw1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M2.551.316C1.176.241-.102 2.408-.046 4.001S.768 27.53.753 28.739c-.015 1.209.926 3.359 3.602 3.267s22.003-.567 24.102-.521c2.099.046 3.16-.956 3.403-3.029s-.065-23.615-.123-25.443C31.679 1.185 30.498.16 28.877.031S3.925.392 2.55.316zm6.545 11.359c-.219-.114-.181-2.879.084-3.032.227-.132 10.31.049 13.303.234.496.031.49.018.648.176s.226.15.245.502c.14 2.59.125 12.839-.007 13.167-.15.372-2.648.259-2.922.089-.26-.161-.085-6.369-.115-8.668-2.924 2.891-9.429 9.313-9.862 9.746-.54.54-.609.09-1.648-.883s-.814-1.068-.655-1.227c.134-.134 7.471-7.379 9.94-9.819-1.301-.029-8.804-.177-9.009-.284z"
            fill="currentColor"
        />
    );
}
