import React from 'react';
import styled, { css } from 'styled-components';
import { mediaQuery } from '@99designs/emu/css';
import { colors, maxWidths, spaces } from '@99designs/emu/settings';

interface ContentBodyContainerProps {
    lazyApp?: boolean;
    children: React.ReactNode;
    className?: string;
    maxWidth: MaxWidth;
    overflow?: string;
}

/* Equivalent to mixin flex-vertical */
const FlexVerticalCss = css`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const ContentBodyMain = styled.div`
    ${FlexVerticalCss}
    background: ${colors.white};
`;

type MaxWidth = 'desktop' | 'widescreen' | 'responsive';

const ContentBodyInner1 = styled.div<{ maxWidth: MaxWidth }>`
    ${FlexVerticalCss}

    /* Equivalent to mixin grid-row */
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: ${spaces['1bu']};
    padding-right: ${spaces['1bu']};
    ${mediaQuery.only('small')} {
        padding-left: ${spaces['0.5bu']};
        padding-right: ${spaces['0.5bu']};
    }

    ${({ maxWidth }) => {
        const maxWidthMap: Record<MaxWidth, ReturnType<typeof css>> = {
            desktop: css`
                max-width: ${maxWidths.content.desktop}px;
            `,
            widescreen: css`
                max-width: ${maxWidths.content.widescreen}px;
            `,
            responsive: css`
                max-width: ${maxWidths.content.desktop}px;
                ${mediaQuery.minimum('large')} {
                    max-width: ${maxWidths.content.widescreen}px;
                }
            `,
        };
        return maxWidthMap[maxWidth];
    }}

    &:before,
    &:after {
        content: ' ';
        display: table;
    }
    &:after {
        clear: both;
    }
`;

const ContentBodyInner2 = styled.div<{ lazyApp?: boolean }>`
    ${FlexVerticalCss}

    /* Equivalent to mixin grid-column($columns: $grid_columns) */
    position: relative;
    width: 100%;
    float: left;
    padding-left: ${spaces['1bu']};
    padding-right: ${spaces['1bu']};
    ${mediaQuery.only('small')} {
        padding-left: ${spaces['0.5bu']};
        padding-right: ${spaces['0.5bu']};
    }

    /* Equivalent to mixin responsive-property($spaces_content-body_top-spacing_base, 'padding-top') and responsive-property($spaces_content-body_bottom-spacing, 'padding-bottom') */
    padding-top: ${spaces['2bu']};
    padding-bottom: ${spaces['6bu']};
    ${mediaQuery.between('small', 'medium')} {
        padding-top: ${spaces['1bu']};
        padding-bottom: ${spaces['4bu']};
    }

    /* Handles lazyApp variant */
    ${(props) => (props.lazyApp ? `min-height: 200px;` : ``)}
`;

export function ContentBody({
    children,
    lazyApp,
    className,
    maxWidth,
    overflow,
}: ContentBodyContainerProps) {
    return (
        <ContentBodyMain style={{ overflow }} className={className} data-testid="ContentBody">
            <ContentBodyInner1 maxWidth={maxWidth}>
                <ContentBodyInner2 lazyApp={lazyApp}>{children}</ContentBodyInner2>
            </ContentBodyInner1>
        </ContentBodyMain>
    );
}
