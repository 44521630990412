import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { default as MuiTooltip, TooltipProps as MuiTooltipProps } from '@material-ui/core/Tooltip';
import styled, { css } from 'styled-components';
import { borders, boxShadows, colors } from '@99designs/emu/settings';

interface TooltipStyleProps {
    noMaxWidth?: boolean;
    top?: string;
}

interface TooltipProps extends MuiTooltipProps, TooltipStyleProps {}

/**
 * @deprecated replaced by `Tooltip` in `@99designs/emu`
 */
export const Tooltip = styled((props: TooltipProps) => {
    const { noMaxWidth: _noMaxWidthIgnored, children, ...tooltipProps } = props;

    return (
        <MuiTooltip
            {...tooltipProps}
            arrow
            classes={{
                arrow: 'arrow',
                popper: tooltipProps.className,
                tooltip: 'tooltip',
            }}
        >
            <span>{children}</span>
        </MuiTooltip>
    );
})`
    & .arrow {
        color: ${colors.white};
        font-size: 10px;
    }

    & .tooltip {
        background-color: ${colors.white};
        color: inherit;
        font-family: inherit;
        box-shadow: ${boxShadows.high};
        border-radius: ${borders.borderRadiuses.small}px;
        padding: 0.5rem 1rem;
        font-size: inherit;
        line-height: inherit;
        max-width: ${({ noMaxWidth = false }) => (noMaxWidth ? 'none' : undefined)};

        /* A strange fix for Material UI failing to remove/reverse these styles when Tooltip is active */
        opacity: 1;
        visibility: visible;
    }

    ${({ top }) =>
        top &&
        css`
            top: ${top} !important;
        `}
`;
