import React, { ElementType } from 'react';
import { colors } from '../../settings';
import { Text } from '../Text';

export interface AttributionProps {
    as?: ElementType<React.HTMLAttributes<HTMLElement>>;
    children: React.ReactNode;
}

/**
 * Used for attributing work to designers or projects
 * If a link is needed, @see AttributionLink
 */
export const Attribution = function (props: AttributionProps) {
    return (
        <Text
            {...props}
            color={colors.typography.attribution}
            fontFamily="attribution"
            size="size2"
            italic
        />
    );
};
