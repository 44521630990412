export type StarSize = 'small' | 'medium';

export const starSizes: Record<StarSize, { icon: string; padding: string }> = {
    small: {
        icon: '20px',
        padding: '4px',
    },
    medium: {
        icon: '32px',
        padding: '6px',
    },
};
