import React from 'react';
import styled from 'styled-components';
import { InteractiveRating } from './InteractiveRating';
import { ReadOnlyRating } from './ReadOnlyRating';
import { StarProps } from './Star';
import { starSizes } from './sizes';

interface BaseRatingProps {
    size?: StarProps['size'];
}

export interface InteractiveRatingProps extends BaseRatingProps {
    /**
     * A unique name for the underlying radio buttons.
     */
    name: string;
    /**
     * Returns the newly selected value.
     */
    onChange: (rating: number) => void;
    readOnly?: never;
    value: number | null;
}

export interface ReadOnlyRatingProps extends BaseRatingProps {
    name?: never;
    onChange?: never;
    /**
     * Renders a non-interactive version of the rating.
     */
    readOnly: true;
    value: number;
}

export type RatingProps = InteractiveRatingProps | ReadOnlyRatingProps;

const MarginWrapper = styled.div.attrs<{ className?: string }>({ className: 'emu' })<{
    size: StarProps['size'];
}>`
    color: transparent;

    margin-left: ${({ size }) => `-${starSizes[size].padding}`};
`;

/**
 * The rating component allow a user to give feedback on designs and their experiences.
 * Under the hood, this component is a stylised radiogroup.
 * An accessible read-only variant exists, which displays ratings with a half-star precision.
 */
export function Rating({ size = 'medium', ...rest }: RatingProps) {
    if ('readOnly' in rest && rest.readOnly !== undefined) {
        const { value } = rest;

        return (
            <MarginWrapper size={size}>
                <ReadOnlyRating size={size} value={value} />
            </MarginWrapper>
        );
    }

    const { name, onChange, value } = rest;

    return (
        <MarginWrapper size={size}>
            <InteractiveRating name={name} onChange={onChange} size={size} value={value} />
        </MarginWrapper>
    );
}
