/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconRepeat1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M9.891 23.885c-.584.875-.909 1.077-1.363 1.75s-.13.875.39 1.279C9.437 27.318 11.58 29 16.715 29s9.152-3.567 10.707-6.865c1.555-3.298 1.558-4.846 1.558-5.384s.474-.549.474-.549 1.791.081 2.116.081.714-.538.13-1.211-2.922-3.971-3.506-4.644-.779-1.884-1.753-.808c-.974 1.077-3.766 4.98-4.285 5.451s-.779 1.211.195 1.144c.974-.067 1.587-.146 2.106-.011s.556.348.426 1.021c-.13.673-1.104 3.163-1.818 3.971s-1.753 3.096-4.999 3.567c-3.246.471-5.656-.132-6.558-1.009s-1.031-.743-1.616.132zM21.882 8.284c.346-.55 1.136-1.246 1.591-1.919s.13-.875-.39-1.279C22.564 4.682 20.421 3 15.286 3S6.134 6.567 4.579 9.865c-1.555 3.298-1.558 4.846-1.558 5.384s-.906.468-.906.468H.432c-.325 0-.714.538-.13 1.211s2.922 3.971 3.506 4.644c.584.673.779 1.884 1.753.808s3.766-4.98 4.285-5.451c.519-.471.779-1.211-.195-1.144s-1.808.035-2.328-.1c-.519-.135-.334-.237-.205-.91s1.104-3.163 1.818-3.971c.714-.808 1.753-3.096 4.999-3.567s5.656.132 6.558 1.01c.683.638 1.042.587 1.388.037z"
            fill="currentColor"
        />
    );
}
