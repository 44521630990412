type Page = { pageNumber: number };
type PaginationArray = (Page | '...')[];

interface GetPaginationArrayParams {
    currentPage: number;
    totalPageCount: number;
}

export function getPaginationArray({
    currentPage,
    totalPageCount,
}: GetPaginationArrayParams): PaginationArray {
    const maxNumberOfElements = 7; // max number of elements INCLUDING the ellipsis token

    if (totalPageCount <= maxNumberOfElements) {
        return createPageNumberArray(1, totalPageCount);
    }

    const upperContextPageLimit = 3;
    const hasEllipsisLeft = currentPage - upperContextPageLimit > 1;
    const hasEllipsisRight = currentPage + upperContextPageLimit < totalPageCount;
    const firstPage: Page = { pageNumber: 1 };
    const lastPage: Page = { pageNumber: totalPageCount };

    if (hasEllipsisLeft && !hasEllipsisRight) {
        return [
            firstPage,
            '...',
            ...createPageNumberArray(
                totalPageCount - 1 - upperContextPageLimit,
                totalPageCount - 1
            ),
            lastPage,
        ];
    }

    if (!hasEllipsisLeft && hasEllipsisRight) {
        return [firstPage, ...createPageNumberArray(2, 2 + upperContextPageLimit), '...', lastPage];
    }

    return [
        firstPage,
        '...',
        ...createPageNumberArray(currentPage - 1, currentPage + 1),
        '...',
        lastPage,
    ];
}

const createPageNumberArray = (startingPageNumber: number, endingPageNumber: number): Page[] => {
    return new Array(endingPageNumber - startingPageNumber + 1)
        .fill(undefined, 0)
        .map<Page>((_, index) => ({
            pageNumber: index + startingPageNumber,
        }));
};
