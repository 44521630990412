import React from 'react';
import styled, { css } from 'styled-components';
import { IconX1 } from '@99designs/icons';
import { borders, boxShadows, colors } from '../../settings';
import { Box } from '../Box';
import { UnstyledButton } from '../UnstyledButton';
import { tagStyles } from './styles';
import { DeletableTagAppearance, DeleteIconProps } from './types';

const clickableAreaSize = '40px'; // TODO: this could be shared with the exposed IconButton component

export const IconButton = styled(UnstyledButton)<{
    appearance: DeletableTagAppearance;
}>(() => {
    return css`
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: calc(${tagStyles.height} - ${tagStyles.borderWidth});
        // We need to 2x the border width for height because there are borders top and bottom
        height: calc(${tagStyles.height} - (${tagStyles.borderWidth} * 2));

        &:hover,
        &:focus {
            background-color: ${colors.grey300};
        }
        &:focus:not(:focus-visible) {
            background-color: unset;
        }
        &:focus-visible {
            box-shadow: ${boxShadows.focusRing};
            border-radius: ${borders.borderRadiuses.small}px;
            /* This is used to unset the existing :focus styles */
            background-color: unset;
        }
        &:active {
            background-color: ${colors.grey400};
        }

        &:after {
            content: '';
            position: absolute;
            inset: calc(
                    (${tagStyles.height} - (${tagStyles.borderWidth} * 2) - ${clickableAreaSize}) /
                        2
                )
                calc((${tagStyles.height} - ${tagStyles.borderWidth} - ${clickableAreaSize}) / 2);
        }
    `;
});

export function DeleteIcon(props: DeleteIconProps & { appearance?: DeletableTagAppearance }) {
    const {
        onDelete,
        ariaLabel,
        focusable = true,
        appearance = 'transparent',
        size = 'medium',
    } = props;
    const iconSize = tagStyles.iconSize[size];
    return (
        <Box as="span" display="flex" alignItems="center">
            <IconButton
                onClick={onDelete}
                aria-label={focusable ? ariaLabel : undefined}
                tabIndex={focusable ? 0 : -1}
                aria-hidden={focusable ? false : true}
                data-testid="tag-delete-icon"
                appearance={appearance}
            >
                <IconX1 width={iconSize} height={iconSize} />
            </IconButton>
        </Box>
    );
}
