import React from 'react';

export function InlinedSvg({ children, ...props }: { children: string }) {
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: children,
            }}
            {...props}
        />
    );
}
