/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconReconsider1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M15.963 20.75c.625-.688 6.447-6.564 7.26-7.372s-1.885-3.63-2.763-2.688c-.878.942-6.127 6.125-6.127 6.125s-1.68-1.813-2.563-2.688c-.883-.875-3.438 1.813-2.688 2.5s3.066 3.245 3.945 4.122c.88.878 2.31.688 2.935 0zm14.771-10.571C29.733 7.42 26.293 0 15.616 0S-.484 8.757.265 16.554C1.014 24.351 7.258 32 15.5 32s11.489-4.515 11.489-4.515 3.996 4.138 4.621 3.386.874-10.785.125-10.785-10.561-.752-10.561 0 3.318 4.389 3.318 4.389-3.624 3.445-8.295 3.445S5.261 25.102 4.012 16.449C2.763 7.796 10.679 3.652 15.924 3.652s9.563 3.333 11.066 7.262c1.503 3.928 4.745 2.024 3.745-.735z"
            fill="currentColor"
        />
    );
}
