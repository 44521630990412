import * as CSS from 'csstype';
import styled, { css } from 'styled-components';
import { borders, boxShadows, colors, typography } from '../../settings';
import {
    Clickable,
    ClickableComponent,
    ClickableNoRoutingProps,
    ClickableProps,
    ClickableWithRoutingProps,
} from '../Clickable';

type Underline = 'none' | 'hover';
interface LinkStylesProps {
    color?: CSS.Property.Color | colors.Color;
    /**
     * Sets the font-family CSS property based on typography settings
     */
    fontFamily?: keyof typeof typography.fontFamilies;
    /**
     * Sets the font-size CSS property based on typography settings
     */
    size?: keyof typeof typography.typeScale;
    /**
     * When to display an underline, defaults to always show
     */
    underline?: Underline;
    /**
     * Sets the font-weight CSS property based on typography settings
     */
    weight?: keyof typeof typography.fontWeights;
}

export type LinkWithRoutingProps = ClickableWithRoutingProps<LinkStylesProps>;
export type LinkNoRoutingProps = ClickableNoRoutingProps<LinkStylesProps>;
export type LinkProps = ClickableProps<LinkStylesProps>;

export const Link = styled(Clickable).attrs<{ className?: string }>({ className: 'emu' })(
    linkStyles
) as ClickableComponent<LinkStylesProps>;

export function linkStyles(props: LinkProps) {
    const {
        color = 'grey800',
        disabled,
        fontFamily = 'root',
        size = 'size3',
        underline,
        weight = 'root',
    } = props;

    const colorCode = colors.getColor(color);
    const isLink = 'href' in props && props.href !== undefined;

    return css`
        ${!isLink &&
        // Reset button styles
        css`
            border: none;
            background: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
            appearance: none;
        `}

        font-family: ${typography.fontFamilies[fontFamily]};
        font-size: ${typography.typeScale[size]}px;
        font-weight: ${typography.fontWeights[weight]};
        line-height: inherit;
        position: relative;

        &,
        &:hover,
        &:focus {
            color: ${colorCode};
            text-decoration: none;
        }
        &:focus-visible {
            outline: none;
            &::after {
                content: '';
                position: absolute;
                border-radius: ${borders.borderRadiuses.small}px;
                top: 0px;
                right: -2px;

                /* Link handles it's underline styles directly on the parent <a> element whereas Tab and Pagination
                   apply it on the child <span> element. This means we need to account for that 2px in the
                   focus-visible styles on Link and reset them on Tab and Pagination */
                bottom: -2px;
                left: -2px;
                box-shadow: ${boxShadows.focusRing};
                z-index: 1;
            }
        }

        ${getUnderlineStyles(colorCode, disabled, underline)}

        &[aria-disabled='true'] {
            color: ${colors.grey400};
        }
    `;
}

function getUnderlineStyles(colorCode: string, disabled?: boolean, underline?: Underline) {
    const disabledColor = colors.grey300;

    return css`
        border-bottom: 2px solid;
        transition: border-color 0.15s ease-out;

        ${() => {
            if (underline === 'none') {
                return css`
                    border-bottom: none;
                `;
            } else if (underline === 'hover') {
                return css`
                    &,
                    &:link,
                    &:visited {
                        border-color: transparent;
                    }
                    &:hover,
                    &:focus {
                        border-color: ${disabled ? disabledColor : colorCode};
                    }
                `;
            } else {
                return css`
                    &,
                    &:link,
                    &:visited {
                        border-color: ${disabled
                            ? disabledColor
                            : colors.withAlpha(colorCode, 0.2)};
                    }
                    &:hover,
                    &:focus {
                        border-color: ${disabled
                            ? disabledColor
                            : colors.withAlpha(colorCode, 0.4)};
                    }
                `;
            }
        }}
    `;
}
