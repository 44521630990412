import React from 'react';
import styled from 'styled-components';
import { colors } from '@99designs/emu/settings';

interface Props {
    text: React.ReactNode;
}

const Error = styled.div`
    color: ${colors.semantic.error};
`;

/**
 * @deprecated replaced by `Text` in `@99designs/emu`
 */
export function ErrorText({ text }: Props) {
    return <Error>{text}</Error>;
}
