import React from 'react';

export enum Partner {
    NationBuilder = 'NationBuilder',
    Packlane = 'Packlane',
    Square = 'Square',
    Squarespace = 'Squarespace',
    Prezi = 'Prezi',
    Facebook = 'Facebook',
    Wix = 'Wix',
    Vistaprint = 'Vistaprint',
}

export const PartnerMap: Record<string, Partner> = {
    nationbuilder: Partner.NationBuilder,
    packlane: Partner.Packlane,
    square: Partner.Square,
    squarespace: Partner.Squarespace,
    prezi: Partner.Prezi,
    facebook: Partner.Facebook,
    wix: Partner.Wix,
    vistaprint: Partner.Vistaprint,
};

export enum Color {
    Facebook = '#51687B',
}

export function partnerFromCategory(category?: string): [Color?, Partner?] {
    switch (category) {
        case 'facebook-ad':
            return [Color.Facebook, Partner.Facebook];
        case 'nationbuilder':
            return [undefined, Partner.NationBuilder];
        case 'square-online-store':
            return [undefined, Partner.Square];
        case 'squarespace':
            return [undefined, Partner.Squarespace];
        case 'prezi-design':
            return [undefined, Partner.Prezi];
        case 'product-packaging-design':
            return [undefined, Partner.Packlane];
        case 'wix-website':
            return [undefined, Partner.Wix];
    }
    return [undefined, undefined];
}

export function partnerFundedHeading(partner?: Partner): string | undefined {
    switch (partner) {
        case Partner.Facebook:
            return __('Funded for you by Facebook');
        default:
            return;
    }
}

interface PartnerContextType {
    partner?: Partner;
    color?: Color;
}

export const PartnerContext = React.createContext<PartnerContextType>({});
