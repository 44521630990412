import React from 'react';
import styled from 'styled-components';
import { Box, Heading, Stack, StackProps } from '@99designs/emu';
import { typography } from '@99designs/emu/settings';

export const BLANKSLATE_ICON_SIZE = `${typography.typeScale.size7 * 2}px`;

const maxWidthNormal = '300px';
const maxWidthWide = '450px';

interface BlankSlateWrapperProps {
    wide: boolean;
}
const BlankSlateWrapper = styled(Box)<BlankSlateWrapperProps>`
    text-align: center;
    max-width: ${({ wide }) => (wide ? maxWidthWide : maxWidthNormal)};
`;

export interface BlankSlateProps {
    icon: React.ReactNode;
    heading?: React.ReactNode;
    action?: React.ReactNode;
    description?: React.ReactNode;
    space?: StackProps['space'];
    wide?: BlankSlateWrapperProps['wide'];
}

export function BlankSlate({
    icon,
    heading,
    action,
    description,
    wide = false,
    space = 'none',
}: BlankSlateProps) {
    return (
        <BlankSlateWrapper marginX="auto" marginY="none" wide={wide} data-testid="BlankSlate">
            <Stack space={space}>
                {icon}
                {heading && (
                    <Heading accessibilityLevel={2} appearanceLevel={4} color="grey900">
                        {heading}
                    </Heading>
                )}
                {description && description}
                {action && action}
            </Stack>
        </BlankSlateWrapper>
    );
}
