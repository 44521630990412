import React from 'react';
import { FieldGroup, FieldGroupProps } from '../Field';
import { Checkbox, CheckboxProps } from './Checkbox';

type AllOrNone<T> = T | { [K in keyof T]?: never };

export interface CheckboxGroupItem {
    disabled?: CheckboxProps['disabled'];
    label: string;
    ref?: React.ForwardedRef<HTMLInputElement>;
    value: string;
}

export type CheckboxGroupProps = FieldGroupProps & {
    /**
     * Used as the `name` attribute for checkboxes.
     */
    name: CheckboxProps['name'];
    /**
     * A list of options to render as checkboxes.
     */
    options: CheckboxGroupItem[];
} & AllOrNone<{
        onChange: (v: string[]) => void;
        value: string[];
    }>;

/**
 * Creates an accessible group of vertically placed checkboxes.
 * This should only be used for checkboxes which are related in some way.
 *
 * Can be controlled using `onChange` & `value` together, which appropriately
 * forwards props to individual checkboxes.
 *
 * Requires one of: `label`, `aria-label`, or `aria-labelledby`.
 *
 * If more flexibility is required, `Checkbox` can be used directly.
 */
export const CheckboxGroup = ({
    description,
    error,
    helperText,
    id,
    onChange,
    options,
    name,
    size,
    value,
    ...rest
}: CheckboxGroupProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!onChange || !value) {
            return;
        }

        const optionValue = e.target.value;

        if (value.includes(optionValue)) {
            onChange(value.filter((v) => v !== optionValue));
        } else {
            onChange([...value, optionValue]);
        }
    };

    return (
        <FieldGroup
            description={description}
            error={error}
            helperText={helperText}
            id={id}
            role="group"
            size={size}
            {...rest}
        >
            {options.map((option) => {
                const optionId = `${name}_${option.value}`;

                return (
                    <Checkbox
                        key={optionId}
                        id={optionId}
                        name={name}
                        size={size}
                        onChange={handleChange}
                        checked={
                            typeof value !== 'undefined' ? value.includes(option.value) : undefined
                        }
                        {...option}
                    />
                );
            })}
        </FieldGroup>
    );
};
