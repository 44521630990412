import React, { InputHTMLAttributes } from 'react';
import { Stack } from '@99designs/emu';
import { colors } from '@99designs/emu/settings';
import { ErrorText } from '../ErrorText';

interface TextFieldProps {
    id: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    value?: InputHTMLAttributes<HTMLInputElement>['value'];
    name?: string;
    type?: InputHTMLAttributes<HTMLInputElement>['type'];
    placeholder?: string;
    fullWidth?: boolean;
    error?: string;
    autoFocus?: boolean;
}

/**
 * @deprecated replaced by `Input` in `@99designs/emu`
 */
export const TextField = React.forwardRef(function TextField(
    {
        id,
        name,
        onChange,
        onBlur,
        onFocus,
        value,
        type,
        placeholder,
        fullWidth,
        error,
        autoFocus,
    }: TextFieldProps,
    ref?: ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null
) {
    const style: React.CSSProperties = {};
    if (fullWidth) {
        style.maxWidth = '100%';
    }
    if (error) {
        style.borderColor = colors.semantic.error;
    }

    return (
        <Stack space="0.25bu">
            <input
                id={id}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                value={value}
                type={type ? type : 'text'}
                className="input-text"
                placeholder={placeholder}
                ref={ref}
                style={style}
                autoFocus={autoFocus}
                data-testid={name + '-input'}
            />
            {error && <ErrorText text={error} />}
        </Stack>
    );
});
