/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconPreferences1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M7.278.01c1.246.052 1.134 1.371 1.134 2.34v1.726c-.001 1.952-.003 5.321-.011 9.003 1.401.554 2.115 1.937 2.218 4.042.108 2.199-.685 3.466-2.245 4.008-.024 4.731-.062 8.607-.123 9.035-.163 1.144.175 1.346-.671 1.614s-2.654.323-3.051 0c-.397-.323-.282-1.212-.282-2.019v-.078l-.035-8.634c-1.545-.709-2.265-2.268-2.366-3.89-.104-1.663.688-3.093 2.34-3.829-.014-5.46-.017-10.266.017-10.649.08-.902.05-2.708.878-2.633S6.033-.041 7.28.01zm20.303.065c.59.094.799.716.799 1.301v.079l.05 16.98c1.801.496 2.606 1.96 2.55 3.741-.052 1.649-1.11 3.16-2.528 3.927l.012 4.495v.255c0 1.088-.679 1.081-1.119 1.033s-1.576 0-1.978 0c-.401 0-.787-.476-.909-.921-.03-.111-.042-2.009-.041-4.802v-.013c-1.108-.615-1.893-1.814-2.085-3.576-.221-2.026.521-3.301 2.106-3.906.036-8.126.111-17.348.154-17.723.077-.669.228-.862.709-.91s1.689-.053 2.28.041zM17.487.143c.59.18.799 1.367.799 2.485v.138l.008 2.762c.349.229.703.508 1.062.836 2.271 2.069 1.979 4.277.306 5.848a4.299 4.299 0 01-1.343.849c.023 6.811.049 14.755.052 16.582v.245c0 2.078-.679 2.066-1.119 1.973s-1.576 0-1.978 0c-.401 0-.787-.909-.909-1.759-.07-.492-.041-9.461.012-17.18a4.116 4.116 0 01-.916-.612c-1.666-1.48-1.972-3.735-.202-5.938.381-.475.776-.841 1.183-1.104.02-1.907.04-3.204.056-3.465.077-1.278.228-1.646.709-1.739s1.689-.101 2.28.079zm9.157 20.387c-1.343.062-2.019.66-1.884 1.89s.927 1.833 1.9 1.775c.974-.058 1.858-.945 1.889-1.949s-.562-1.778-1.905-1.715zM6.238 15.182c-1.229.129-1.833.894-1.775 1.833s.596 1.83 1.831 1.822c1.235-.008 1.896-.542 1.834-1.838s-.66-1.947-1.889-1.818zm8.79-7.233c-.775.964-.641 1.951.089 2.599s1.982.645 2.714-.043c.732-.688.86-1.654-.134-2.56s-1.894-.961-2.669.004z"
            fill="currentColor"
        />
    );
}
