import React from 'react';
import { Box } from '@99designs/emu';
import { JobCard, JobCardProps } from './JobCard';

interface JobListingsPageWithJobsProps {
    items: JobCardProps['jobForDesigner'][];
    pagination: React.ReactNode;
    refetch?: () => void;
    dismissible: boolean;
}
export function JobListings({
    items,
    pagination,
    refetch,
    dismissible,
}: JobListingsPageWithJobsProps) {
    return (
        <>
            <Box>
                {items.map((item) => (
                    <JobCard
                        key={item.job.id}
                        jobForDesigner={item}
                        onCtaActionCompleted={refetch}
                        dismissible={dismissible}
                    />
                ))}
            </Box>
            {pagination}
        </>
    );
}
