import React from 'react';
import { typography } from '../../settings';
import { Text, TextProps } from './Text';

export interface ParagraphProps extends Omit<TextProps, 'size'> {
    /**
     * Sets the font-size & line-height CSS property based on
     * typography settings. Only allows for paragraph-related sizes.
     */
    size?: keyof typeof typography.fontSizes.paragraphs;
}

/**
 * An opinionated wrapper around `Text` to create paragraph-sized
 * text with the correct line-height.
 */
export function Paragraph({ as = 'p', size = 'regular', ...rest }: ParagraphProps) {
    return (
        <Text
            {...rest}
            as={as}
            lineHeight={typography.paragraphLineHeights[size]}
            size={typography.paragraphFontSizes[size]}
        />
    );
}
