import React, { createContext, useContext } from 'react';
import { Site } from '@99designs/site-navigation/lib/Site';

interface SiteProviderProps {
    children: React.ReactNode;
    site: Site;
}

const SiteContext = createContext<Site | null>(null);

export function SiteProvider({ children, site }: SiteProviderProps) {
    return <SiteContext.Provider value={site}>{children}</SiteContext.Provider>;
}

export function useSite(): Site {
    const value = useContext(SiteContext);
    if (!value) {
        throw new Error('useSite must be used within a SiteProvider');
    }
    return value;
}
