import { getCookieByName, setCookieByName } from '@99designs/browser';

declare global {
    interface Window {
        analytics: SegmentAnalytics.AnalyticsJS;
        // Page & user agent data
        pageTrackParams: Record<string, unknown>;
        // Experiments data
        trackParams: Record<string, unknown>;
    }
}

export const page = () => {
    const sessionCount = handleSegmentSession();

    if (typeof window !== 'undefined') {
        window.analytics.page({
            ...window.trackParams,
            ...window.pageTrackParams,
            ...window.trackParams,
            domain_sessionidx: sessionCount,
            url: document.location.href,
            path: document.location.pathname,
        });
    }
};

const SESSION_COUNT_COOKIE = '99segment_sessionidx';
const SESSION_COUNT_COOKIE_EXPIRY_IN_MINUTES = 2 * 365 * 24 * 60; // two years
const SESSION_COOKIE = '99segment_session';
const SESSION_COOKIE_EXPIRY_IN_MINUTES = 30;

function handleSegmentSession() {
    let currSessionCount =
        parseInt(getCookieByName(SESSION_COUNT_COOKIE, document.cookie), 10) || 0;

    // If the session cookie is not set, either the 30m session expiry has lapsed or it has never been set
    if (!getCookieByName(SESSION_COOKIE, document.cookie)) {
        currSessionCount++;
        setCookieByName({
            cookieName: SESSION_COUNT_COOKIE,
            value: currSessionCount.toString(),
            expiryInMinutes: SESSION_COUNT_COOKIE_EXPIRY_IN_MINUTES,
        });
    }
    // On page view always update the session cookie with a new 30m offsets
    setCookieByName({
        cookieName: SESSION_COOKIE,
        expiryInMinutes: SESSION_COOKIE_EXPIRY_IN_MINUTES,
    });

    return currSessionCount;
}
