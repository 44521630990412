/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconGithub1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M20.331 31.604c3.992-.085 7.364-.145 8.174-.127 2.099.046 3.16-.956 3.403-3.029s-.065-23.615-.123-25.443c-.058-1.828-1.239-2.853-2.86-2.982S3.973.384 2.598.308C1.223.233-.055 2.4.001 3.993S.815 27.522.8 28.731c-.015 1.209.926 3.359 3.602 3.267 1.036-.036 4.569-.129 8.631-.227l-.02-3.442s-1.311.643-2.845.021-2.103-1.969-2.468-2.943c-.365-.974-1.701-1.204-1.701-1.744s1.288-.422 2.123.351c.835.773 1.452 2.516 2.981 2.495s2.02-.445 2.02-.445.173-1.874.916-1.96c-1.593-.293-6.285-.327-6.642-6.417-.127-2.48.622-3.835 1.502-4.643-.588-1.306-.279-2.468.11-3.939 1.744.109 3.216 1.012 3.984 1.566 1.355-.463 4.941-.856 7.325-.017 1.744-1.107 3.092-1.699 3.972-1.493.427 1.347.814 2.335.163 3.881 1.166 1.665 1.75 1.967 1.48 5.195s-1.485 5.139-5.902 5.802l-.704.084s.967.826.967 1.989c0 .798.022 3.73.035 5.494z"
            fill="currentColor"
        />
    );
}
