// Safely get property that might not exist from object that might not exist
export default function propertyOrNull(obj: any, ...props: string[]) {
    return props.reduce((obj, property) => {
        if (obj) {
            return obj[property] !== undefined ? obj[property] : null;
        } else {
            return null;
        }
    }, obj);
}
