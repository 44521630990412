/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconFlag1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M28.059 1.537c.291-.034.566-.076.825-.119a.682.682 0 01.783.667v16.183c0 .337-.25.616-.574.667 0 0-3.082.558-5.982.558s-4.749-.499-6.948-.971c-2.158-.465-4.199-1.309-6.382-1.309-1.766 0-1.673.152-2.548.329-.309.067-.84.404-.96.667V32H2.999V1.376l.342-.245C3.507 1.022 5.099 0 9.781 0c2.337 0 4.544.477 6.683.939l.032.007c2.116.456 4.207 1.03 6.515 1.03 1.483 0 3.949-.295 5.048-.439v-.001z"
            fill="currentColor"
        />
    );
}
