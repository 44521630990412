import React from 'react';
import { FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import { mediaQuery } from '@99designs/emu/css';
import { typography } from '@99designs/emu/settings';
import { FormattedReactMessage } from '@99designs/frontend-components/i18n/index';
import { CurrencyCode } from '@99designs/i18n';
import { toUnit } from './currency';

interface Money {
    amountInCents: number;
    currency: string;
}

const StyledPriceText = styled.div`
    font-size: ${typography.typeScale.size3}px;

    ${mediaQuery.between('small', 'medium')} {
        display: inline;
        font-size: ${typography.typeScale.size4}px;
    }
`;

function Price({ amount, currency }: { amount: number; currency: CurrencyCode }) {
    return (
        <FormattedNumber
            value={Math.round(toUnit(amount, currency as CurrencyCode))}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency={currency}
            minimumFractionDigits={0}
        />
    );
}

export interface ProductPriceProps {
    fromPrice?: Money;
    toPrice?: Money;
    showFixedPrice?: boolean;
}

export function ProductPrice({ fromPrice, toPrice, showFixedPrice }: ProductPriceProps) {
    if (!fromPrice) {
        throw Error('fromPrice prop is undefined');
    }

    if (showFixedPrice) {
        return (
            <>
                <Price
                    amount={fromPrice.amountInCents}
                    currency={fromPrice.currency as CurrencyCode}
                />{' '}
                <StyledPriceText>{__('Fixed price')}</StyledPriceText>
            </>
        );
    }

    if (toPrice) {
        return (
            <FormattedReactMessage
                message={__(
                    '<StyledPriceText>From</StyledPriceText> <FromPrice>price_noop</FromPrice> - <ToPrice>price_noop</ToPrice>+'
                )}
                replacements={{
                    StyledPriceText: <StyledPriceText />,
                    FromPrice: (
                        <Price
                            amount={fromPrice.amountInCents}
                            currency={fromPrice.currency as CurrencyCode}
                        />
                    ),
                    ToPrice: (
                        <Price
                            amount={toPrice.amountInCents}
                            currency={toPrice.currency as CurrencyCode}
                        />
                    ),
                }}
            />
        );
    }

    return (
        <FormattedReactMessage
            message={__(
                '<StyledPriceText>From</StyledPriceText> <FromPrice>price_noop</FromPrice>'
            )}
            replacements={{
                StyledPriceText: <StyledPriceText />,
                FromPrice: (
                    <Price
                        amount={fromPrice.amountInCents}
                        currency={fromPrice.currency as CurrencyCode}
                    />
                ),
            }}
        />
    );
}
