/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconHeart1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M23.675 1.75C27.867 1.75 32 5.753 32 10.118s-1.935 8.043-4.602 10.246c-2.667 2.203-10.376 8.728-10.619 9.061s-.755.499-1.193.061c-.438-.438-10.526-8.473-12.101-10.228s-3.601-5.34-3.48-9.176C.127 6.246 4.081 1.643 7.75 1.797s7.38 3.434 8.319 5.784c1.555-2.495 3.414-5.831 7.605-5.831z"
            fill="currentColor"
        />
    );
}
