/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconMenu2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M2.128 7.887c-.081 1.08-.237 1.35.079 2.086s2.237.692 2.785.692 20.648-.257 22.328-.169 2.137-.8 2.216-1.32c.078-.519.32-1.387.16-1.995s-1.492-1.33-2.833-1.155c-1.341.175-20.689.22-22.026.308s-2.628.472-2.709 1.552zm-.368 7.246c.051-1.215 1.362-1.106 2.325-1.106s22.541-.001 23.678.158c1.137.159 1.338-.171 1.604.654s.321 2.588 0 2.974c-.321.387-1.205.275-2.007.275s-22.052.122-22.948.044c-.896-.078-2.691-.049-2.617-.856s-.087-.929-.035-2.143zm.134 7.784c-.156.575-.149 1.753-.068 2.222s.401.616 1.512.691c1.111.075 23.868.248 24.607.129s1.53-.495 1.53-.886-.081-1.499 0-1.928.091-1.091-1.716-1.091-22.732.083-23.705.083-2.005.203-2.161.779z"
            fill="currentColor"
        />
    );
}
