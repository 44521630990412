/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconNotPlatinum1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M5.244 31.188l4.338-4.619v5.494H5.244v-.875zM26.731 8.313c.019.238.025.469.025.7 0 .994-.15 2.025-.45 3.094s-.837 2.031-1.606 2.894c-.769.856-1.819 1.569-3.144 2.125-1.056.444-2.344.712-3.856.8l9.031-9.613zm-5.144-1.901c-.006-.019-.019-.031-.025-.05-.394-.675-.913-1.2-1.563-1.556a6.677 6.677 0 00-2.212-.744 15.24 15.24 0 00-2.506-.206H9.587v10.306h4.725l-3.569 3.8H9.587v1.231l-4.344 4.619V.062h11.256c2.05 0 3.738.281 5.063.838s2.375 1.263 3.144 2.125l.038.038-3.156 3.35zM27.938.5l-26.5 28.206L4.063 31.5l26.5-28.206L27.938.5z"
            fill="currentColor"
        />
    );
}
