/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconExternal1(props: Props) {
    const newProps = { ...props, viewBox: '0 0 32 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M4.012 8.483l.003 19.163c0 .254-.074.317.483.317.157 0 19.039.117 19.281.117.418 0 .6.1.6-.6s-.049-7.962-.049-8.518c0-.556.11-.587.612-.556s2.469.023 2.898.023c.429 0 .429.186.429.716l-.011 12.258c0 .442-.004.559-.453.559l-27.207.04c-.273 0-.426.02-.489-.186-.05-.066-.068-.175-.068-.354L.001 4.56c0-.378-.037-.528.522-.528s11.776.022 12.292.022.643.065.643.472-.045 2.849-.045 2.849c0 .412.042.642-.556.642s-8.278-.018-8.53-.018c-.252 0-.315-.074-.315.486zM31.215.171c.434-.07.769-.264.785.632s-.255 11.155-.27 11.625c-.015.47-.32.71-.861.524s-1.503-.112-1.92-.143c-.416-.032-.738-.059-.822-.851s-.035-4.233-.073-4.682c-.038-.448-.125-.453-.636.058s-7.8 7.704-8.11 8.014c-.31.31-.516.83-.976.371l-2.18-2.18c-.298-.298-.117-.304.398-.819s7.709-7.896 8.108-8.296c.399-.399.141-.509-.347-.556s-4.191.091-4.454.029c-.263-.062-.489-.15-.553-.591s-.187-2.4-.134-2.787c.054-.387-.018-.526.823-.517s10.788.24 11.221.17z"
            fill="currentColor"
        />
    );
}
