interface SegmentOpts {
    integrations?: unknown;
    anonymousId?: string | undefined;
    context?: Record<string, unknown> | undefined;
}

/**
 * Segment analytics event tracking that includes experiment data.
 *
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/ajs-classic/
 *
 * If logging is required, use window.analytics.debug()
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/ajs-classic/#debug
 */
export const trackEvent = (
    event: string,
    properties?: Record<string, unknown>,
    options?: SegmentOpts
) => {
    if (typeof window === 'undefined' || !window.analytics) {
        return;
    }

    const trackParams = window.trackParams || {};

    window.analytics.track(
        event,
        { url: window.location.href, ...trackParams, ...properties },
        options
    );
};
