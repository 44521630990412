/* eslint-disable */

/* This file is generated, don't modify directly. See README.md in Icon folder */
import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';
type Props = Omit<SvgIconProps, 'viewBox'>;
export function IconGplus2(props: Props) {
    const newProps = { ...props, viewBox: '0 0 45 32' };
    return React.createElement(
        SvgIcon,
        newProps,
        <path
            d="M14 14v4.8h7.95c-.3 2.05-2.4 6.05-7.95 6.05-4.8 0-8.7-3.95-8.7-8.85S9.25 7.15 14 7.15c2.7 0 4.55 1.15 5.6 2.15l3.8-3.65C20.95 3.4 17.8 2 14 2 6.25 2 0 8.25 0 16s6.25 14 14 14c8.1 0 13.45-5.7 13.45-13.7 0-.9-.1-1.6-.2-2.3H14zm30.4-.05h-4.05V9.9H36.3v4.05h-4.05V18h4.05v4.05h4.05V18h4.05v-4.05z"
            fill="currentColor"
        />
    );
}
