import React from 'react';
import styled, { css } from 'styled-components';
import { Box, Button, Divider } from '@99designs/emu';
import { mediaQuery } from '@99designs/emu/css';
import { borders, colors, spaces, typography } from '@99designs/emu/settings';
import { CurrencyCode } from '@99designs/i18n';
import { IconTick1 } from '@99designs/icons';
import { Price } from '../ProductPrice';
import { ViewProductDetails } from '../ViewProductDetails';

interface ProductFeature {
    title: string;
    description?: string;
}

const Wrapper = styled.div<{ selected: boolean; featureColor: string }>`
    padding: ${spaces['2bu']};
    border-top-right-radius: ${borders.borderRadiuses.large}px;
    border-bottom-right-radius: ${borders.borderRadiuses.large}px;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ selected, featureColor }) => (selected ? featureColor : colors.grey300)};
    border-left: 0;
    margin-left: 25px;
    position: relative;

    &::before {
        content: '';
        border-left: 25px solid ${({ featureColor }) => featureColor};
        border-top-left-radius: ${borders.borderRadiuses.large}px;
        border-bottom-left-radius: ${borders.borderRadiuses.large}px;
        position: absolute;
        top: calc(0px - 1px);
        bottom: calc(0px - 1px);
        left: calc(0px - 25px);
    }
`;

const StyledHeading = styled.p<{ featureColor?: string }>`
    color: ${({ featureColor }) => featureColor};
    font-weight: ${typography.fontWeights.bold};
    font-size: ${typography.typeScale.size5}px;
    font-family: ${typography.fontFamilies.heading};
    margin-bottom: 0;

    ${mediaQuery.only('small')} {
        font-size: ${typography.typeScale.size4}px;
    }
`;

const Description = styled.p`
    margin: ${spaces['0.75bu']} ${spaces['1.5bu']} 0 0;
    width: 75%;

    ${mediaQuery.only('small')} {
        margin-right: 0;
        width: 100%;
    }
`;

const FixedPriceText = styled.p`
    margin-bottom: 0;
    font-weight: ${typography.fontWeights.bold};
    text-align: right;

    ${mediaQuery.only('small')} {
        display: none;
    }
`;

const Logo = styled.div`
    height: 46px;
    margin-bottom: ${spaces['0.75bu']};

    ${mediaQuery.only('small')} {
        height: 35px;
    }

    svg {
        height: 100%;
    }
`;
const CTAsWrapper = styled(Box)`
    ${mediaQuery.only('small')} {
        flex-direction: column;
        width: 100%;
    }
`;

const ViewProductDetailsWrapper = styled.div<{ applyOffset: boolean }>`
    ${({ applyOffset }) =>
        css`
            margin-right: ${applyOffset ? spaces['1bu'] : 0};

            ${mediaQuery.only('small')} {
                order: 2;
                margin-top: ${applyOffset ? spaces['1bu'] : 0};
                margin-right: 0;
            }
        `}
`;

const SelectButtonWrapper = styled.div`
    width: 180px;

    ${mediaQuery.only('small')} {
        width: 100%;
    }
`;

const SelectButton = styled(Button)<{ selected: boolean }>`
    cursor: ${({ selected }) => selected && 'default'};
` as typeof Button;

interface PackageCardProps {
    id: string;
    title: string;
    description: string;
    price: Price;
    content: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    logo?: any;
    featureColor?: string;
    isSelected?: boolean;
    isSummaryCard?: boolean;
    onSelection?: (value: string) => void;
    features?: ProductFeature[];
}

interface Price {
    currency: CurrencyCode;
    amountInCents: number;
    isFixed?: boolean;
}

function toKebabCase(id: string): string {
    return id.toLocaleLowerCase().split('_').join('-');
}

const PackageCard = ({
    id,
    title,
    description,
    price,
    content,
    logo,
    featureColor = colors.grey800,
    isSummaryCard = false,
    isSelected = false,
    onSelection,
    features,
}: PackageCardProps) => {
    return (
        <Wrapper
            selected={isSelected}
            featureColor={featureColor}
            data-testid={`package-card-${toKebabCase(id)}`}
        >
            {logo && (
                <Logo
                    dangerouslySetInnerHTML={{
                        __html: logo,
                    }}
                />
            )}
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <StyledHeading featureColor={featureColor}>{title}</StyledHeading>
                {!isSummaryCard && (
                    <StyledHeading>
                        <Price
                            amount={price.amountInCents}
                            currency={price.currency}
                            minimumFractionDigits={0}
                        />
                    </StyledHeading>
                )}
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                paddingBottom="1.5bu"
            >
                <Description>{description}</Description>
                {price.isFixed && !isSummaryCard && (
                    <FixedPriceText>{__('Fixed price')}</FixedPriceText>
                )}
            </Box>
            <Divider />
            <Box display="flex" flexDirection="column" paddingTop="1.5bu">
                <Box>{content}</Box>

                <CTAsWrapper
                    display="flex"
                    alignSelf="flex-end"
                    marginTop="1.5bu"
                    alignItems="center"
                >
                    {features && (
                        <ViewProductDetailsWrapper applyOffset={!isSummaryCard}>
                            <Box display="flex" justifyContent="center">
                                <ViewProductDetails
                                    features={features}
                                    productSummary={{
                                        title,
                                        description,
                                        fromPrice: price,
                                        logo,
                                        showFixedPrice: price.isFixed,
                                    }}
                                />
                            </Box>
                        </ViewProductDetailsWrapper>
                    )}
                    {!isSummaryCard && (
                        <SelectButtonWrapper>
                            <SelectButton
                                onClick={() => {
                                    if (onSelection) {
                                        onSelection(id);
                                    }
                                }}
                                appearance="secondary"
                                size="medium"
                                width="fill"
                                selected={isSelected}
                                icon={isSelected ? IconTick1 : undefined}
                                data-testid={[`select-package-card-${title}`]}
                            >
                                {isSelected ? __('Selected') : __('Select')}
                            </SelectButton>
                        </SelectButtonWrapper>
                    )}
                </CTAsWrapper>
            </Box>
        </Wrapper>
    );
};

export default PackageCard;
