import { circumventionKeywordMatches } from './circumventionKeywordMatches';
import { useEffect, useState } from 'react';

export function useCircumventionWarning(message: string, isEnabled: boolean): [string[], boolean] {
    const [matches, setMatches] = useState<string[]>([]);
    useEffect(() => {
        if (isEnabled) {
            setMatches(circumventionKeywordMatches(message));
        }
    }, [message, isEnabled]);

    const [showCircumventionWarning, setShowCircumventionWarning] = useState(false);
    useEffect(() => {
        if (isEnabled) {
            setShowCircumventionWarning(matches.length > 0);
        }
    }, [matches, isEnabled]);

    return [matches, showCircumventionWarning];
}
