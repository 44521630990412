import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@99designs/emu/settings';

const FullscreenSidebarBodyOuter = styled.div`
    position: relative;

    @media (min-width: ${breakpoints.normal[0]}px) and (max-width: ${breakpoints.normal[1]}px),
        (min-width: ${breakpoints.large[0]}px) {
        flex: 1;
    }
`;
const FullscreenSidebarBodyInner = styled.div`
    @media (min-width: ${breakpoints.normal[0]}px) and (max-width: ${breakpoints.normal[1]}px),
        (min-width: ${breakpoints.large[0]}px) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }
`;

interface FullscreenSidebarBodyProps {
    children: React.ReactNode;
}
function FullscreenSidebarBody({ children }: FullscreenSidebarBodyProps) {
    return (
        <FullscreenSidebarBodyOuter>
            <FullscreenSidebarBodyInner>{children}</FullscreenSidebarBodyInner>
        </FullscreenSidebarBodyOuter>
    );
}
export default FullscreenSidebarBody;
