import React from 'react';
import styled from 'styled-components';
import { IconX1 } from '@99designs/icons';
import { useTranslations } from '../../../Context';
import { colors } from '../../../settings';
import { UnstyledButton, UnstyledButtonProps } from '../../UnstyledButton';

const ClearIcon = styled(IconX1)<Pick<UnstyledButtonProps, 'disabled'>>`
    color: ${({ disabled }) => (disabled ? colors.grey400 : colors.grey800)};

    &:hover,
    &:focus {
        color: ${colors.grey900};
    }
`;

export const Clear = React.forwardRef<HTMLButtonElement, UnstyledButtonProps>(function (
    { disabled, ...rest },
    ref
) {
    const translations = useTranslations();

    return (
        <UnstyledButton
            ref={ref}
            aria-label={translations.Select.ClearAll}
            disabled={disabled}
            tabIndex={-1}
            {...rest}
        >
            <ClearIcon disabled={disabled} />
        </UnstyledButton>
    );
});
