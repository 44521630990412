import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Space, spaces } from '../../settings';
import { Avatar } from '../Avatar';
import { AvatarPlaceholder } from '../Avatar/AvatarPlaceholder';
import { AvatarSize } from '../Avatar/types';
import { Box } from '../Box';

export interface AvatarGroupProps {
    urls: string[];
    size?: AvatarSize;
}

const Bleed = styled(Box)`
    margin-left: -${(props: { gap: Space }) => spaces[props.gap]};
`;

const gapMap: Record<AvatarSize, Space> = {
    'extra-small': '0.5bu',
    small: '0.75bu',
    medium: '1bu',
    large: '1.5bu',
    'extra-large': '2bu',
    'extra-extra-large': '3bu',
};

export const AvatarGroup = forwardRef<HTMLDivElement, AvatarGroupProps>(function (
    { urls, size = 'extra-small' },
    ref
) {
    return (
        <Box ref={ref} display="flex" marginLeft={gapMap[size]}>
            {urls.map((url) => (
                <Bleed key={url} gap={gapMap[size]}>
                    {url ? (
                        <Avatar size={size} url={url} border />
                    ) : (
                        <AvatarPlaceholder size={size} />
                    )}
                </Bleed>
            ))}
        </Box>
    );
});
