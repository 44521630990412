/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type HasDoneActionQueryVariables = Types.Exact<{
    name: Types.Scalars['String'];
}>;

export type HasDoneActionQuery = {
    __typename: 'Query';
    me: { __typename: 'Viewer'; id: string; hasDone: boolean };
};

export type SetCompletedActionMutationVariables = Types.Exact<{
    name: Types.Scalars['String'];
}>;

export type SetCompletedActionMutation = {
    __typename: 'Mutation';
    setCompletedAction: { __typename: 'Viewer'; id: string; hasDone: boolean };
};

export const HasDoneActionDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'HasDoneAction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'me' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasDone' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'action' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

/**
 * __useHasDoneActionQuery__
 *
 * To run a query within a React component, call `useHasDoneActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasDoneActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasDoneActionQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useHasDoneActionQuery(
    baseOptions: Apollo.QueryHookOptions<HasDoneActionQuery, HasDoneActionQueryVariables>
) {
    return Apollo.useQuery<HasDoneActionQuery, HasDoneActionQueryVariables>(
        HasDoneActionDocument,
        baseOptions
    );
}
export function useHasDoneActionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<HasDoneActionQuery, HasDoneActionQueryVariables>
) {
    return Apollo.useLazyQuery<HasDoneActionQuery, HasDoneActionQueryVariables>(
        HasDoneActionDocument,
        baseOptions
    );
}
export type HasDoneActionQueryHookResult = ReturnType<typeof useHasDoneActionQuery>;
export type HasDoneActionLazyQueryHookResult = ReturnType<typeof useHasDoneActionLazyQuery>;
export type HasDoneActionQueryResult = Apollo.QueryResult<
    HasDoneActionQuery,
    HasDoneActionQueryVariables
>;
export const SetCompletedActionDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'SetCompletedAction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setCompletedAction' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'action' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasDone' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'action' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export type SetCompletedActionMutationFn = Apollo.MutationFunction<
    SetCompletedActionMutation,
    SetCompletedActionMutationVariables
>;

/**
 * __useSetCompletedActionMutation__
 *
 * To run a mutation, you first call `useSetCompletedActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCompletedActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCompletedActionMutation, { data, loading, error }] = useSetCompletedActionMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSetCompletedActionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SetCompletedActionMutation,
        SetCompletedActionMutationVariables
    >
) {
    return Apollo.useMutation<SetCompletedActionMutation, SetCompletedActionMutationVariables>(
        SetCompletedActionDocument,
        baseOptions
    );
}
export type SetCompletedActionMutationHookResult = ReturnType<typeof useSetCompletedActionMutation>;
export type SetCompletedActionMutationResult = Apollo.MutationResult<SetCompletedActionMutation>;
export type SetCompletedActionMutationOptions = Apollo.BaseMutationOptions<
    SetCompletedActionMutation,
    SetCompletedActionMutationVariables
>;
