import React from 'react';
import * as CSS from 'csstype';
import styled, { css } from 'styled-components';
import { ResponsiveProp, resolveResponsiveProp } from '../../lib/responsiveProps';
import { Space, boxShadows, colors, spaces, typography } from '../../settings';
import { Box } from '../Box';
import {
    Clickable,
    ClickableComponent,
    ClickableNoRoutingProps,
    ClickableProps,
    ClickableWithRoutingProps,
} from '../Clickable';
import { Spinner } from '../Spinner';
import {
    createButtonPrimaryColorConfig,
    createButtonSecondaryColorConfig,
    createButtonTertiaryColorConfig,
    createButtonTransparentColorConfig,
} from './createButtonColorConfig';
import { ButtonAppearance, ButtonColorConfig, ButtonSize, ButtonWidth } from './types';

interface IconProp {
    component: React.ComponentType<{ width?: string; height?: string }>;
    position: 'left' | 'right';
}

interface ButtonStylesProps {
    appearance?: ButtonAppearance | ButtonColorConfig;
    size?: ButtonSize;
    width?: ResponsiveProp<ButtonWidth>;
    selected?: boolean;
    loading?: boolean;
    icon?: React.ComponentType | IconProp;
}

interface ButtonStylesPropsWithChildren extends ButtonStylesProps {
    children: React.ReactNode;
}

export type ButtonWithRoutingProps = ClickableWithRoutingProps<ButtonStylesPropsWithChildren>;
export type ButtonNoRoutingProps = ClickableNoRoutingProps<ButtonStylesPropsWithChildren>;
export type ButtonProps = ClickableProps<ButtonStylesPropsWithChildren>;
export type ButtonComponent<E = unknown> = ClickableComponent<ButtonStylesPropsWithChildren & E>;

const UnstyledButton = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
    (props, ref) => {
        const { children, loading = false, disabled, size = 'medium', icon, ...rest } = props;
        const finalDisabled = loading || disabled;
        const spaceKey: keyof typeof elementSpaceMap = icon && !children ? 'iconOnly' : size;
        const space = elementSpaceMap[spaceKey];
        const iconSize = iconSizeMap[size];

        const iconProp = normalizeIconProp(icon);
        const leftAdornment = getLeftAdornment({
            disabled,
            space,
            loading,
            iconProp,
            size: iconSize,
        });
        const rightAdornment = getRightAdornment({
            disabled,
            space,
            loading,
            iconProp,
            size: iconSize,
        });

        return (
            <Clickable {...rest} disabled={finalDisabled} ref={ref}>
                <ButtonInner>
                    {leftAdornment}
                    <span>{children}</span>
                    {rightAdornment}
                </ButtonInner>
            </Clickable>
        );
    }
);

export const Button = styled(UnstyledButton).attrs<{ className?: string }>({ className: 'emu' })(
    buttonStyles
) as ButtonComponent;

// ButtonInner is required to fix a cross-browser issue with some browsers ( e.g. older desktop/mobile Safari )
// not being able to apply display: inline-grid on a button.
// We must also keep these rules in `buttonStyles` as well for it to work the same for new browsers.
// https://stackoverflow.com/questions/35464067/flex-grid-layouts-not-working-on-button-or-fieldset-elements
const ButtonInner = styled.span`
    display: inline-grid;
    align-items: center;
    justify-content: center;
    grid-auto-flow: column;
    vertical-align: middle;
`;

const widths = {
    auto: 'auto',
    fill: '100%',
};

const elementSpaceMap: Record<ButtonSize | 'iconOnly', Space> = {
    iconOnly: 'none',
    small: '0.5bu',
    medium: '0.75bu',
    large: '1bu',
};

const iconSizeMap: Record<ButtonSize, string> = {
    small: '12px',
    medium: '16px',
    large: '20px',
};

function getColorConfig(
    appearance: ButtonStylesProps['appearance'] = 'primary'
): ButtonColorConfig {
    if (typeof appearance === 'string') {
        return buttonColorMap[appearance];
    }
    return appearance;
}

function buttonStyles(props: ButtonProps) {
    const { appearance, size = 'medium', width = 'auto', selected = false, icon, children } = props;

    let fontSize: CSS.Property.FontSize;
    let paddingVertical: CSS.Property.Padding;
    let paddingHorizontal: CSS.Property.Padding;
    let minHeight: CSS.Property.MinHeight;
    let minWidth: CSS.Property.MinWidth;

    const borderWidth: CSS.Property.BorderWidth = '1px';

    switch (size) {
        case 'small':
            fontSize = `${typography.typeScale.size2}px`;
            paddingVertical = `2px`;
            paddingHorizontal = `${spaces['0.75bu']}`;
            minHeight = '28px';
            minWidth = '28px';
            break;
        case 'medium':
            fontSize = `${typography.typeScale.size3}px`;
            paddingVertical = `9px`;
            paddingHorizontal = `${spaces['1.5bu']}`;
            minHeight = '44px';
            minWidth = '44px';
            break;
        case 'large':
            fontSize = `${typography.typeScale.size4}px`;
            paddingVertical = `12px`;
            paddingHorizontal = `${spaces['2bu']}`;
            minHeight = '56px';
            minWidth = '56px';
            break;
    }

    if (icon && !children) {
        paddingHorizontal = 0;
    }

    const colorConfig = getColorConfig(appearance);
    const disabledButtonColorCombo = colorConfig['disabled'];
    const buttonColorCombo = colorConfig[selected ? 'selected' : 'normal'];

    const normalizedBackgroundColor = colors.getColor(buttonColorCombo.backgroundColor);
    const hoverBackgroundColor = colors.getColor(buttonColorCombo.backgroundColorHover);
    const activeBackgroundColor = colors.getColor(buttonColorCombo.backgroundColorActive);

    const normalizedBorderColor = colors.getColor(buttonColorCombo.borderColor);
    const hoverBorderColor = colors.getColor(buttonColorCombo.borderColorHover);
    const activeBorderColor = colors.getColor(buttonColorCombo.borderColorActive);

    const normalizedTextColor = colors.getColor(buttonColorCombo.textColor);
    const hoverTextColor = colors.getColor(buttonColorCombo.textColorHover);
    const activeTextColor = colors.getColor(buttonColorCombo.textColorActive);

    return css`
        display: inline-grid;
        position: relative;
        align-items: center;
        justify-content: center;
        grid-auto-flow: column;
        vertical-align: middle;
        margin: 0;
        padding: ${paddingVertical} ${paddingHorizontal};
        line-height: ${typography.lineHeights.typeScale.size3};
        min-height: ${minHeight};
        min-width: ${minWidth};
        text-align: center;
        font-weight: ${typography.fontWeights.bold};
        font-size: ${fontSize};

        background: ${normalizedBackgroundColor};
        border-color: ${normalizedBorderColor};
        color: ${normalizedTextColor};
        border-width: ${borderWidth};
        border-style: solid;
        transition: background-color 0.1s, border-color 0.1s;

        -moz-appearance: none;
        -webkit-appearance: none;
        cursor: pointer;
        text-decoration: none;

        /* Round with a high number, not a percentage, to get a pill shape */
        border-radius: 999px;

        ${resolveResponsiveProp(width, 'width', widths)};

        &:hover,
        &:focus {
            color: ${hoverTextColor};
            background-color: ${hoverBackgroundColor};
            border-color: ${hoverBorderColor};
            text-decoration: none;
            outline: none;
        }

        &:focus:not(:focus-visible):not(:active):not([aria-disabled='true']) {
            background: ${normalizedBackgroundColor};
            border-color: ${normalizedBorderColor};
            color: ${normalizedTextColor};
        }
        &:focus-visible {
            outline: none;
            /* Once we remove focus styles we should be able to remove these three lines: */
            background: ${normalizedBackgroundColor};
            border-color: ${normalizedBorderColor};
            color: ${normalizedTextColor};
            &::after {
                content: '';
                position: absolute;
                border-radius: inherit;
                /* Containing block is calculated using padding edge of the ancestor */
                top: -${borderWidth};
                right: -${borderWidth};
                bottom: -${borderWidth};
                left: -${borderWidth};
                box-shadow: ${boxShadows.focusRing};
                z-index: 1;
                pointer-events: none;
            }
        }

        &:active {
            background-color: ${activeBackgroundColor};
            border-color: ${activeBorderColor};
            color: ${activeTextColor};
        }

        &:disabled,
        &[disabled],
        &[aria-disabled='true'] {
            cursor: not-allowed;
            background: ${colors.getColor(disabledButtonColorCombo.backgroundColor)};
            border-color: ${colors.getColor(disabledButtonColorCombo.borderColor)};
            color: ${colors.getColor(disabledButtonColorCombo.textColor)};

            &:hover,
            &:focus {
                background: ${colors.getColor(disabledButtonColorCombo.backgroundColorHover)};
                border-color: ${colors.getColor(disabledButtonColorCombo.borderColorHover)};
                color: ${colors.getColor(disabledButtonColorCombo.textColorHover)};
            }

            &:active {
                background: ${colors.getColor(disabledButtonColorCombo.backgroundColorActive)};
                border-color: ${colors.getColor(disabledButtonColorCombo.borderColorActive)};
                color: ${colors.getColor(disabledButtonColorCombo.textColorActive)};
            }
        }
    `;
}

type ButtonColorMap = Record<ButtonAppearance, ButtonColorConfig>;
const buttonColorMap: ButtonColorMap = {
    primary: createButtonPrimaryColorConfig({
        color: 'grey900',
        backgroundColorHover: 'grey700',
        backgroundColorActive: 'grey600',
    }),
    secondary: createButtonSecondaryColorConfig(),
    tertiary: createButtonTertiaryColorConfig(),
    transparent: createButtonTransparentColorConfig(),
};

function normalizeIconProp(icon?: React.ComponentType | IconProp): IconProp | undefined {
    if (!icon) {
        return undefined;
    }

    if (typeof icon === 'function') {
        return { component: icon, position: 'left' };
    }

    return icon;
}

interface GetAdornmentParams {
    disabled?: boolean;
    iconProp?: IconProp;
    loading: boolean;
    space: Space;
    size: string;
}

const disabledIconColor = 'grey300';

function getLeftAdornment({
    disabled,
    iconProp,
    loading,
    space,
    size,
}: GetAdornmentParams): React.ReactNode {
    const iconColor = disabled ? disabledIconColor : undefined;

    if (!loading && iconProp?.position === 'left') {
        return (
            <Box as="span" display="flex" marginRight={space} color={iconColor}>
                <iconProp.component width={size} height={size} />
            </Box>
        );
    }

    if (loading && (!iconProp || iconProp.position === 'left')) {
        return (
            <Box as="span" display="flex" marginRight={space}>
                <Spinner color={disabledIconColor} />
            </Box>
        );
    }

    return null;
}

function getRightAdornment({
    disabled,
    iconProp,
    loading,
    space,
    size,
}: GetAdornmentParams): React.ReactNode {
    const iconColor = disabled ? disabledIconColor : undefined;

    if (!loading && iconProp?.position === 'right') {
        return (
            <Box as="span" display="flex" marginLeft={space} color={iconColor}>
                <iconProp.component width={size} height={size} />
            </Box>
        );
    }

    if (loading && iconProp?.position === 'right') {
        return (
            <Box as="span" display="flex" marginLeft={space}>
                <Spinner color={disabledIconColor} />
            </Box>
        );
    }

    return null;
}
