import { useCallback } from 'react';
import { useIntl, FormatDateOptions } from 'react-intl';

type DateSource = string | number | Date;
type Options = 'absolute' | 'longDate' | FormatDateOptions;

export function useFormatDate(options: Options) {
    const intl = useIntl();

    let formatDateOption: FormatDateOptions;
    switch (options) {
        case 'absolute':
            formatDateOption = {
                weekday: 'short',
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
                timeZoneName: 'short',
            };
            break;
        case 'longDate':
            formatDateOption = {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            };
            break;
        default:
            formatDateOption = options;
            break;
    }

    const formatDate = useCallback(
        (value: DateSource, overridingOptions: FormatDateOptions = {}) => {
            return intl.formatDate(value, {
                ...formatDateOption,
                ...overridingOptions,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [intl.formatDate, formatDateOption]
    );

    return formatDate;
}
